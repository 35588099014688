import moment from "moment"

export default {

  calcHour(prevDate, key){
    const momentKey = moment(key)
    const date = prevDate ? moment(prevDate) : moment()

    date.set("year", momentKey.get("Y"))
    date.set("month", momentKey.get("M"))
    date.set("date", momentKey.get("D"))
    date.set("hour", momentKey.get("H"))
    
    return date.format()
  },

  calcHourOfDay(prevDate, key){
    const date = prevDate ? moment(prevDate) : moment()
    date.hour(key)

    return date.format()
  },

  calcDay(prevDate, key){
    const momentKey = moment(key)
    const date = prevDate ? moment(prevDate) : moment()

    date.set("year", momentKey.get("Y"))
    date.set("month", momentKey.get("M"))
    date.set("date", momentKey.get("D"))
  
    return date.format()
  },

  calcDayOfWeek(prevDate, key){
    const date = prevDate ? moment(prevDate) : moment()
    date.day(+key)
    return date.format()
  },

  calcWeek(prevDate, key){
    const date = prevDate ? moment(prevDate) : moment()

    const [year, weekOfYear] = key.split("-").map(d => +d)

    date.set("year", year)
    date.week(+weekOfYear + 1)

    return date.format()
  },

  calcWeekOfYear(prevDate, key){
    const date = prevDate ? moment(prevDate) : moment()

    date.week(+key)

    return date.format()
  },

  calcMonth(prevDate, key){
    const date = prevDate ? moment(prevDate) : moment()

    const [year, month] = key.split("-").map(d => +d)

    date.set("year", year)
    date.set("month", month - 1)

    return date.format()
    
  },  

  calcMonthOfYear(prevDate, key){
    const date = prevDate ? moment(prevDate) : moment()

    date.set("month", key - 1)

    return date.format()
  },

  calcYear(prevDate, key){
    const date = prevDate ? moment(prevDate) : moment()

    date.set("year", +key)

    return date.format()
  }

}