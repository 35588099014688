import moment from "moment";

import { RANGE_FORMAT } from "../formats";

const calcRange = (view, date, format = RANGE_FORMAT) => {
  switch (view) {
    case "month":
      return [
        moment(date)
          .startOf("month")
          .startOf("week")
          .format(format),
        moment(date)
          .endOf("month")
          .endOf("week")
          .format(format)
      ];
    case "year":
      return [
        moment(date)
          .startOf("year")
          .format(format),
        moment(date)
          .endOf("year")
          .format(format)
      ];
    case "week":
      return [
        moment(date)
          .startOf("week")
          .format(format),
        moment(date)
          .endOf("week")
          .format(format)
      ];
    case "day":
      return [
        moment(date)
          .startOf("day")
          .format(format),
        moment(date)
          .endOf("day")
          .format(format)
      ];
  }
};

export default calcRange;
