import * as COLORS from "./colors";

export default [
  COLORS.RED,
  COLORS.ORANGE,
  COLORS.YELLOW,
  COLORS.LEMON,
  COLORS.PINK,
  COLORS.WHITE,
  COLORS.GREY,
  COLORS.GREEN,
  COLORS.PURPLE,
  COLORS.BLUE,
  COLORS.SKY,
  COLORS.MINT
];

export const Labels = t => [
  t("colors.red"),
  t("colors.orange"),
  t("colors.yellow"),
  t("colors.lemon"),
  t("colors.scarlet"),
  t("colors.white"),
  t("colors.grey"),
  t("colors.green"),
  t("colors.violet"),
  t("colors.blue"),
  t("colors.lightBlue"),
  t("colors.mint")
];
