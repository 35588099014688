import React from "react";
import { Row } from "antd";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import LoadingSpin from "../../../common/LoadingSpin";
import HistoryUserFilter from "../../../History/HistoryUserFilter";
import FieldFilter from "../../../History/FieldFilter";
import History from "./History";
import apiActions from "../../../../actions/apiActions";
import { connect } from "../../../StateProvider";
import styles from "./history.less";

class TabHistory extends React.PureComponent {
  getFieldsToRender = (props = this.props) => {
    const { fields, userSettingsFields } = props;
    if (!userSettingsFields) {
      return fields;
    }
    return fields.filter(field => {
      let visible =
        userSettingsFields &&
        userSettingsFields.getIn([field.get("id"), "visible", "visible"]);

      return visible === undefined ? true : visible;
    });
  };

  render() {
    const {
      catalogId,
      recordId,
      history,
      fields,
      filter,
      sceneId,
      t
    } = this.props;
    return (
      <div className={styles.tabHistoryContainer}>
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          className={styles.sectionHeader}
        >
          <span className={styles.sectionTitle}>
            <strong className={styles.historyTitle}>
              {t("record.history.title")}
            </strong>
            {history.get("loading") ? <LoadingSpin /> : null}
          </span>
          <div className={styles.historyFiltersContainer}>
            <HistoryUserFilter
              filter={filter}
              sceneId={sceneId}
              catalogId={catalogId}
              recordId={recordId}
            />
            <FieldFilter
              filter={filter}
              getFieldsToRender={this.getFieldsToRender}
              catalogId={catalogId}
              recordId={recordId}
              fields={fields}
              viewMode={"recordHistory"}
            />
          </div>
        </Row>
        <History
          {...{
            filter,
            sceneId,
            catalogId,
            recordId,
            history,
            getFieldsToRender: this.getFieldsToRender
          }}
        />
      </div>
    );
  }
}

TabHistory.propTypes = {
  catalogId: PropTypes.string.isRequired,
  history: PropTypes.object,
  recordId: PropTypes.string,
  fields: PropTypes.object
};

export default withTranslation()(
  connect(
    TabHistory,
    {
      userSettings: ["userSettings", "catalogs"],
      records: ["records"]
    },
    ({ catalogId, recordId, ...props }, { userSettings, records }) => {
      return {
        recordId,
        catalogId,
        ...props,
        userSettingsFields: userSettings.getIn([
          catalogId,
          "viewMode",
          "recordHistory",
          "fields"
        ]),
        filter: records.getIn([catalogId, recordId, "history", "filter"])
      };
    }
  )
);
