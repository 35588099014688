import React from 'react';

import Icon from '../../common/UI/Icon';

import styles from "./header.less";

const Item = ({ listItem, opportunity }) => {
    return (
        <div
            key={opportunity.key}
            className={listItem}
        >
            <Icon
                type={`icon ${opportunity.icon}`}
                className={styles.settingIcon}
            />
            {opportunity.title}
        </div>
    )
};

export default Item;