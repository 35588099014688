import { Button, Drawer, Empty } from "antd";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Immutable from "immutable";
import classNames from "classnames";

import ChatsItems from "./ChatsItems";
import ButtonTransparent from "../../../common/UI/ButtonTransparent";
import Icon from "../../../common/UI/Icon";
import LoadingSpin from "../../../common/LoadingSpin";
import { connect } from "../../../StateProvider";
import chatsActions from "../../../../actions/chatsActions";
import BetaMessage from "../../../common/UI/BetaMessage";

import styles from "./chats.less";

const Chats = props => {
    const [open, setOpen] = useState(false);

    const items = (props.chats && props.chats.get("items")) || Immutable.List();
    const loadingMessages = props.chats && props.chats.get("loadingMessages");
    const visibleSkeleton = props.chats && props.chats.get("visibleSkeleton");

    const allLoadedMessages = props.chats && props.chats.get("allLoadedMessages");
    const isEmptyData = loadingMessages === false && (items && items.size <= 0);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const getNewChats = () => {
        chatsActions.getNewChats();
    };

    useEffect(
        () => {
            if (open === true) {
                chatsActions.loadChats(25, true);
            }
        },
        [open]
    );

    return (
        <>
            <ButtonTransparent
                className={classNames(
                    "rc-color-picker-trigger btn-glass btn-light",
                    styles.chatsPreviewIconContainer
                )}
                light
                title={props.t("chats.title")}
                onClick={showDrawer}
            >
                <Icon
                    className={styles.chatsPreviewIcon}
                    type="icon communication-91"
                />
            </ButtonTransparent>
            <Drawer
                title={
                    <div className={styles.chatsHeaderContainer}>
                        <h2 className={styles.chatsHeaderTitle}>{props.t("chats.title")}</h2>
                        <BetaMessage name={props.t("chats.title")} />
                    </div>
                }
                // inline style потому что antd в хедер только inline style, можно конечно перееопределить, но вдруг будем использовать дальше эту компонету
                headerStyle={{
                    backgroundColor: "#eff1f4",
                    padding: "12px 20px 11px",
                    borderBottom: "1px solid rgba(0, 0, 0, 7%)"
                }}
                bodyStyle={{
                    padding: 0
                }}
                zIndex={99}
                closable={false}
                placement="right"
                onClose={onClose}
                open={open}
                extra={
                    <Button title={props.t("buttons.refresh")} onClick={getNewChats}>
                        <LoadingSpin spin={loadingMessages} />
                    </Button>
                }
            >
                {!isEmptyData ? (
                    <ChatsItems
                        items={items}
                        allLoadedMessages={allLoadedMessages}
                        loadingMessages={loadingMessages}
                        visibleSkeleton={visibleSkeleton}
                        openRecordParams={props.openRecordParams}
                        user={props.user}
                        t={props.t}
                    />
                ) : (
                    <div className={styles.emptyData}>
                        <Empty
                            description={<span>{props.t("chats.emptyChats")}</span>}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                    </div>
                )}
            </Drawer>
        </>
    );
};

export default withTranslation()(
    connect(
        Chats,
        ["chats", "user", "modal", "scenes"],
        (props, state) => {
            const activeSceneId = state.modal && state.modal.get("activeScene");
            const activeScene = state.scenes && state.scenes.get(activeSceneId);
            const visible = (state.modal && state.modal.get("visible")) || false;

            let openRecordParams =
                (activeScene && activeScene.get("params")) || Immutable.Map();
            openRecordParams = openRecordParams.set("visible", visible);
            return {
                openRecordParams,
                chats: state.chats,
                user: state.user,
                ...props
            };
        }
    )
);
