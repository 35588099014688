import React from "react";
import OverlayDropdown from "./OverlayDropdown";
import { Row } from "antd";
import cn from "classnames";
import _ from "lodash";
import Immutable from "immutable";

import dndContext from "../../../../services/dndContext";
import BaseMenuList from "./BaseMenuList";
import styles from "./abstractMenu.less";

const EmptyList = Immutable.List();

class Horizontal extends BaseMenuList {
  countVisibleChildrens = () => {
    const childrens = _.toArray(this.ul.children);
    const firstChild = childrens[0];
    if (firstChild) {
      const topFirst = firstChild.getBoundingClientRect().top;
      const index = childrens.findIndex(
        el => el.getBoundingClientRect().top > topFirst
      );
      const newArr = index > -1 ? this.state.order.slice(index) : EmptyList;
      this.setState({
        dropdownMenuItems: newArr
      });
    } else {
      this.setState({
        dropdownMenuItems: EmptyList
      });
    }
  };

  render() {
    const {
      className,
      horizontal,
      vertical,
      dragType,
      canDrag,
      direction,
      filterFn,
      buttonMoreProps
    } = this.props;
    const { dropdownMenuItems } = this.state;
    const reverse = direction === "row-reverse";

    return (
      <Row
        type="flex"
        justify="space-between"
        align="top"
        className={cn(styles.container, className)}
      >
        <div
          className={styles.wrapper}
          style={reverse ? { paddingLeft: "30px" } : { paddingRight: "30px" }}
        >
          {this.renderList(
            {
              menu: cn(horizontal.menu, styles.menuHorizontal),
              item: cn(horizontal.item, styles.menuItem)
            },
            { flexDirection: direction }
          )}
          <div
            className={styles.overlayDropdown}
            style={reverse ? { left: 0 } : { right: 0 }}
          >
            <OverlayDropdown
              items={dropdownMenuItems}
              dragType={dragType}
              vertical={vertical}
              onDragEnd={this.onDragEnd}
              onMoveItem={this.onMoveItem}
              canDrag={canDrag}
              onVisibleChange={this.countVisibleChildrens}
              filterFn={filterFn}
              buttonMoreProps={buttonMoreProps}
            />
          </div>
        </div>
      </Row>
    );
  }
}

export default dndContext(Horizontal);
