import React, { PureComponent, Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import cn from "classnames";
import { Row, Col } from "antd";


import FIELD_TYPES from "../../../../configs/fieldTypes";


import Icon from '../Icon';
import Hint from "./controls/common/Hint";

import styles from "./controlList.less";

export const HORISONTAL_VIEW = "$horisontal";
export const VERTICAL_VIEW = "$vertical";

class ContainerControlItem extends Component {
  render() {
    const { type, labelOnTop, children } = this.props;

    const containerProps = {};
    if (!labelOnTop) {
      containerProps.type = "flex";
      containerProps.justify = "start";
    }

    if (type === FIELD_TYPES.FILE) {
      containerProps.style = {
        position: "relative"
      };
    }
  
    if (containerProps.type == "flex") {
      return <Row {...containerProps}>{children}</Row>;
    } else {
      return <Col {...containerProps}>{children}</Col>;
    }
  }
}


class ControlItem extends PureComponent {
  static propTypes = {
    hint: PropTypes.string,
    error: PropTypes.string,
    required: PropTypes.bool,
    hasValue: PropTypes.bool,
    compact: PropTypes.bool,
    labelRef: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
  };

  state = {
    showHint: false
  };

  toggleHint = e => {
    const showHint = this.state.showHint;
    this.setState({ showHint: !showHint });
  };

  render() {
    let {
      name,
      required,
      hasValue,
      type,
      editable,
      readOnly,
      error,
      hint,
      htmlId,
      labelRef,
      compact,
      labelOnTop
    } = this.props;

    const headerClass = cn(styles.fieldHeader, {
      [styles.fieldHeaderError]: error,
      [styles.fieldHeaderRequired]: required && !hasValue
    });

    if (readOnly) {
      required = false;
      editable = false;
    }

    let compactStyle = null;
    compactStyle = compact ? styles.fieldCompact : compactStyle;
    compactStyle = compact && editable ? styles.fieldCompactEdit : compactStyle;

    let title = "";
    if (hint) {
      title = hint;
      if (required) {
        title = title + "\n[" + this.props.t("isRequired") + "]";
      }
    }
    const showHint = this.state.showHint;
    const inProcess =
      this.props.updateProcess && this.props.updateProcess.get("inProcess");

    return (
        // до antd 3.26.19 мы использовали один компонент Row
        // передавая в него пропс type мы могли определять вид отображения (в виде строки или столбца)
        // начиная с v4. antd убрал возможность передавать type в Row
        // Теперь нам нужно самостоятельно определять что выводить Col или Row
        <ContainerControlItem type={type} labelOnTop={labelOnTop}>
          {name || !labelOnTop ? (
            <div className={cn(headerClass, compactStyle)} title={title}>
              {//className={cn( styles.fieldHint, {[styles.fieldHintRequired]: required }
                hint ? (
                  <Icon
                    type="icon interface-58"
                    className={styles.fieldHint}
                    onClick={this.toggleHint}
                  />
                ) : null}
              <label
                aria-label
                ref={labelRef}
                className={cn(styles.headerLabel, {
                  [!!error && editable]: styles.error
                })}
                htmlFor={!inProcess ? htmlId : null}
              >
                {name}
                {/*required && ! hint ? <div className={styles.fieldRequiredAsterisk} /> : null*/}
              </label>
            </div>
          ) : (
            <div className={cn(headerClass, compactStyle)} />
          )}

          <div className={cn(styles.fieldBody, compactStyle)}>
            {inProcess && <div className={styles.disabledBlock} />}
            {this.props.children}
            {showHint ? <Hint text={hint} /> : null}
            {editable && this.props.error ? (
              <p className={styles.errorMessage}>{error}</p>
            ) : null}
          </div>
        </ContainerControlItem>
      );
    } 
}

ControlItem.defaultProps = {
  view: HORISONTAL_VIEW
};

export default withTranslation(undefined, { withRef: true })(ControlItem);
