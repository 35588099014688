import React from "react";
import PureRenderMixin from "react-addons-pure-render-mixin";
import PropTypes from "prop-types";
import ChangeDirection from "./ChangeDirection";
import Stars from "../../common/UI/ControlList/controls/Stars";

import styles from "./changes.less";
const ChangeStars = props => {
  const fromObj = <Stars value={props.change.get("oldValue")} />;
  const toObj = <Stars value={props.change.get("newValue")} />;

  return (
    <ChangeDirection
      containerClass={styles.flexContainer}
      fromObj={fromObj}
      toObj={toObj}
      strikeRemoved={false}
    />
  );
};

ChangeStars.propTypes = {
  change: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  isNewRecord: PropTypes.bool
};

export default ChangeStars;
