import React, { createRef } from "react";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import Immutable from "immutable";
import VisibilitySensor from "react-visibility-sensor";
import getDataMixin from "./getDataMixin";
import cn from "classnames";
import { withTranslation, useTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";

import "./fix-react-chartjs-2";

import Loading from "../../../common/Loading";
import { connect } from "../../../StateProvider";
import Chart from "./chart";
import Header from "./header";
import * as CHART_TYPES from "../../../../configs/reports/widget/chartTypes";

import styles from "./widget.less";

function getDefaultChartType(widget) {
  const axis = widget.get("axis");

  if (axis) {
    return CHART_TYPES.BARS;
  } else {
    return CHART_TYPES.NUMBER;
  }
}

function WidgetChartZone(props) {
  const { t } = useTranslation();
  const { widget, chartDataObj, license, inEditMode, chartRef, style } = props;
  const loading = chartDataObj.get("loading");
  const chartData = chartDataObj.get("data");
  const error = chartDataObj.get("error");
  const axis = widget.get("axis");
  const type = widget.get("chartType") || getDefaultChartType(widget);

  const classes = cn(styles.widgetChart, styles["widgetChart--" + type]);

  if (!license && !inEditMode) {
    return (
      <div className={`${classes} ${styles.widgetChartInfo}`}>
        <center>{t("reports.widget.common.messages.noLicense")}</center>
      </div>
    );
  }

  /*if (!axis) {
    return (
      <div className={`${classes} ${styles.widgetChartInfo}`}>
        <center>{trs("reports.widget.common.messages.axisNotSet")}</center>
      </div>
    );
  }*/

  const loadingNode = !loading ? null : (
    <Loading text="" className={styles.loadingSpinner} />
  );

  const initial = loading === undefined;
  const chartNode =
    chartData && chartData.length ? (
      <Chart {...props} chartData={chartData} chartRef={chartRef} />
    ) : (
      !initial &&
      !loading && (
        <div className={`${classes} ${styles.widgetChartInfo}`}>
          <center>
            {t(
              "reports.widget.common.messages." + (error ? "error" : "noData")
            )}
          </center>
        </div>
      )
    );

  return (
    <>
      {style && <Loading text="" className={styles.loadingSpinner} />}
      <div style={style} className={classes}>
        {loadingNode}
        {chartNode}
      </div>
    </>
  );
}

class Widget extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      style: null,
    };
    this.chartRef = createRef();
  };

  downloadChart = () => {
    const chartInstance = this.chartRef?.current?.chartInstance;

    if (!chartInstance) {
      return;
    };

    const width = chartInstance.width >= 900 ? chartInstance.width : chartInstance.width * 3;
    const height = chartInstance.height >= 900 ? chartInstance.height : chartInstance.height * 3;
    const chartName = chartInstance.config.type;

    // after setState has worked, we call the chart change function and set a delay of a millisecond for a smooth change
    const downloadImage = () => {
      // wrapped in setTimeout because styles don't have time to apply
      // To-do: when we download report
      // we must have color pick option for the image background
      setTimeout(() => {
        const base64Image = chartInstance.toBase64Image();
        const formatDate = moment(new Date()).format("YYYY_MM_DD_h_mm_ss");
        if (base64Image) {
          const link = document.createElement('a');
          link.download = `chart_${chartName}_${formatDate}`;
          link.href = base64Image;
          link.click();
          this.setState({ style: null });
        };
      }, 100);
    };

    this.setState({
      style: {
        width,
        height,
        position: "absolute",
        visibility: "hidden"
      }
    }, downloadImage);
  };

  render() {
    const {
      addFullWidgetUid,
      removeFullWidgetUid,
      isFull,
      widget,
      catalog,
      scene,
      viewId,
      board,
      onChange,
      readonly,
      moving,
      license,
      editable,
      widgetsChartData,
      t,
      inEditMode,
      className,
      allowEditTitle,
    } = this.props;
    const { style } = this.state;

    const chartDataObj =
      widgetsChartData &&
      widgetsChartData.getIn([widget.get("uid"), "values"], Immutable.Map());

    const totalsDataObj =
      widgetsChartData &&
      widgetsChartData.getIn([widget.get("uid"), "totals"], Immutable.Map());

    const totalsData = totalsDataObj && totalsDataObj.get("data");
    const fields = catalog.get("fields");


    return (
      <VisibilitySensor
        partialVisibility={true}
        onChange={isVisible => this.setVisible(isVisible)}
      >
        <div
          className={cn(
            styles.widget,
            { [styles.widgetEditMode]: !className && inEditMode },
            { [className]: className }
          )}
        >
          <Header
            {...{
              catalog,
              scene,
              widget,
              fields,
              board,
              onChange,
              readonly,
              totalsData,
              moving,
              license,
              editable,
              isFull,
              inEditMode,
              allowEditTitle,
              addFullWidgetUid,
              removeFullWidgetUid,
              downloadChart: this.downloadChart,
              hover: this.state.hover,
            }}
          />
          {chartDataObj && (
            <WidgetChartZone
              {...{
                widget,
                chartDataObj,
                fields,
                license,
                catalog,
                scene,
                viewId,
                chartRef: this.chartRef,
                style
              }}
            />
          )}
        </div>
      </VisibilitySensor>
    );
  }
}

Widget.propTypes = {
  widget: ImmutablePropTypes
    .contains({
      id: PropTypes.string
    })
    .isRequired,
  catalog: ImmutablePropTypes.map.isRequired
};

export default withTranslation()(
  connect(
    getDataMixin(Widget),
    ["widgetsChartData"]
  )
);
