import React from "react";

import FileRow from "./FileRow";

export default class DefaultViewer extends React.Component {
  static viewerName = "DefaultViewer";
  render() {
    return (
      <React.Fragment>
        {this.props.files.map((file, i) => {
          return <FileRow key={i} {...this.props} file={file} />;
        })}
      </React.Fragment>
    );
  }
}
