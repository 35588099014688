import debug from "debug";
import pluralize from "pluralize";
import makeApiRequest, {
  makeRequestWithCredentials
} from "../utils/makeApiRequest";
import createAsyncAction from "../helpers/createAsyncAction";
import _ from "lodash";
import capitalize from "lodash.capitalize"; // v3, lodash > v3
import request from "superagent";

// ["parent", "methods: crud", "abort concurent read request: bool" ]
const resources = {
  section: ["", "crud", true],
  catalog: ["", "crud", true],
  view: ["catalog", "crud", true],
  field: ["catalog", "crud", true],
  linkedCatalog: ["catalog", "r", true],
  record: ["catalog", "crud", true],
  export: ["catalog", "r", true],
  linkedRecord: ["catalog.records", "r", true],
  history: ["", "cr", true],
  comment: ["catalog.record", "cr", true],
  chatOption: ["catalog.record", "crud", true],
  message: ["catalog.record", "crud", true],
  chatsStatuses: ["", "r", true],
  chats: ["", "r", true],
  availableRecord: ["catalog.field", "r", true],
  relation: ["catalog.record", "r", true],
  change: ["catalog.record", "c"],
  right: ["", "cr", false],
  privilege: ["", "r", true],
  filterKey: ["", "r", true],
  user: ["", "r", true],
  board: ["", "crud", true],
  widget: ["board", "crud", true],
  values: ["board.widget", "r", true],
  totals: ["board.widget", "r", true]
};

const log = debug("CRM:action:API");

// const domains = window.location.host.split('.');
// const mainDomain = domains.slice(domains.length - 2).join('.');

const actions = {};

actions.uploadFileRecord = createAsyncAction(function actionHandler(
  params,
  data
) {
  let resourcePath = "/files";
  return makeRequest(
    this,
    "post",
    resourcePath,
    params,
    _.extend({}, params, data),
    {}
  );
});

actions.removeFileRecord = createAsyncAction(function actionHandler(
  params,
  data
) {
  let resourcePath = "/files/" + params.fileId;
  return makeRequest(
    this,
    "delete",
    resourcePath,
    params,
    _.extend({}, params, data),
    {}
  );
});

actions.updateFileRecord = createAsyncAction(function actionHandler(
  params,
  data
) {
  let resourcePath = "/files/" + params.fileId;
  return makeRequest(
    this,
    "patch",
    resourcePath,
    params,
    _.extend({}, params, data),
    {}
  );
});

actions.getCompanies = createAsyncAction(function() {
  return makeAuthRequest(this, "get", "/auth/companies");
});

actions.getCompanyInfo = createAsyncAction(function() {
  return makeAuthRequest(this, "get", "/auth/company");
});

actions.getAccountByInvite = createAsyncAction(async function(token) {
  return makeRequestWithCredentials("", "/auth/accountByInvite", {
    method: "get",
    query: { token }
  }).then(result => result);
});

actions.getLicense = createAsyncAction(function() {
  return makeAuthRequest(this, "get", "/auth/license");
});

actions.getVendor = createAsyncAction(function() {
  return makeAuthRequest(this, "get", "/auth/vendor");
});

actions.getHelpInformation = createAsyncAction(async function(locale = "ru") {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await request
        .get(
          `https://raw.githubusercontent.com/Bpium/tutorial/main/${locale}.json`
        )
        .set("Accept", "json")
        .accept("json");
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
});

actions.getProfile = createAsyncAction(function() {
  return makeRequest(this, "get", "profile/me");
});

actions.getAllViews = createAsyncAction(function(params, query, actionParams) {
  return makeRequest(this, "get", "views", params, {}, query, actionParams);
});

// Получаем агрегацию для статистики.
actions.getStatisticsValue = createAsyncAction(function(params, query, actionParams) {
  return makeRequest(this, "get", `/catalogs/${params.catalogId}/values`, params, {}, query, actionParams);
});

actions.getStatisticsTotal = createAsyncAction(function(params, query, actionParams) {
  return makeRequest(this, "get", `/catalogs/${params.catalogId}/totals`, params, {}, query, actionParams);
});

actions.login = createAsyncAction(async function(email, password) {
  return makeRequestWithCredentials("", "/auth/login", {
    method: "post",
    body: {
      email,
      password
    }
  }).then(result => result);
});

actions.feedBack = createAsyncAction(async function(params, feedbackUrl) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await request
        .post(feedbackUrl)
        .set("Accept", "json")
        .accept("json")
        .send(params);
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
});

actions.payment = createAsyncAction(function() {
  return makeRequestWithCredentials("", "/auth/payment/required", {
    method: "get"
  }).then(result => result);
});

actions.activate = createAsyncAction(function(license) {
  return makeRequestWithCredentials("", "/auth/license", {
    method: "post",
    body: {
      license: license
    }
  }).then(result => result);
});

actions.createCompanyWithAccount = createAsyncAction(function(body) {
  return makeRequestWithCredentials("", "/auth/company/create", {
    method: "post",
    body
  }).then(result => result);
});

actions.registerCompany = createAsyncAction(function(body) {
  return makeRequestWithCredentials("", "/auth/register", {
    method: "post",
    body
  }).then(result => result);
});

actions.getSessions = createAsyncAction(function() {
  return makeRequestWithCredentials("", "/auth/sessions", {
    method: "get"
  }).then(result => result);
});

actions.resetPass = createAsyncAction(function(email) {
  return makeRequestWithCredentials("", "/auth/resetPass", {
    method: "post",
    body: {
      email
    }
  }).then(result => result);
});

actions.setNewPass = createAsyncAction(function(token, password) {
  return makeRequestWithCredentials("", "/auth/setPass", {
    method: "post",
    query: { token },
    body: {
      password,
      token
    }
  }).then(result => result);
});

actions.setPass = createAsyncAction(function(token, password) {
  return makeRequestWithCredentials("", "/auth/register", {
    method: "post",
    query: { token },
    body: {
      password,
      token
    }
  }).then(result => result);
});

actions.register = createAsyncAction(function(token, password) {
  return makeApiRequest(
    this,
    "post",
    "/auth/register",
    null,
    { token: token },
    {
      token,
      password
    }
  );
});

function makeAuthRequest(action, method, path, params, query, data) {
  let resolvedPath = path
    .split("/")
    .map(key => {
      if (key[0] === ":") {
        return params[key.slice(1)] || "";
      }

      return key;
    })
    .join("/");

  return makeRequestWithCredentials("", resolvedPath, {
    method: method,
    query: query || {},
    body: data
  }).then(
    result => {
      action.completed(result, params, data, query);
    },
    err => {
      log(path, arguments);
      action.failed(err.text);
    }
  );
}

function makeRequest(
  action,
  method,
  path,
  params,
  data,
  query,
  actionParams = {},
  abortCurrentGET
) {
  return makeApiRequest(
    path,
    {
      method: method,
      query: query,
      body: data
    },
    abortCurrentGET
  ).then(
    res => {
      action.completed &&
        action.completed(res.body, params, data, query, res, actionParams);
      return res.body;
    },
    function(err) {
      log(path, arguments);
      action.failed && action.failed(err, params, data, query, actionParams);
      throw err;
    }
  );
}

_.forEach(resources, (cfg, name) => {
  const methods = cfg[1];
  let abortCurrentGET = cfg[2];
  _.forEach(methods, op => {
    let pathCfg = _.compact(cfg[0].split(".")).map(res => ({
      path: res,
      param: res + "Id"
    }));
    let requiredParams = pathCfg.map(p => p.param);
    let mainParamName = name + "Id";

    function getPath(params, withMainParam) {
      let path = pathCfg.map(p => pluralize(p.path) + "/" + params[p.param]);
      path.push(pluralize(name));
      if (withMainParam) {
        path.push(params[mainParamName]);
      }
      return path.join("/");
    }

    switch (op) {
      case "c":
        actions["create" + capitalize(name)] = createAsyncAction(
          function actionHandler(params, data, actionParams) {
            let unsetParams = requiredParams.filter(p => params[p] == null);
            if (unsetParams.length) {
              throw new Error(
                "unset required params: " + unsetParams.join(", ")
              );
            }

            return makeRequest(
              this,
              "post",
              getPath(params),
              params,
              data,
              {},
              actionParams
            );
          }
        );
        break;

      case "r":
        actions["get" + capitalize(name)] = createAsyncAction(
          function actionHandler(
            params = {},
            query = {},
            actionParams,
            abortRequest
          ) {
            abortCurrentGET = _.isUndefined(abortRequest)
              ? abortCurrentGET
              : abortRequest;

            let reqParams = requiredParams.slice();
            reqParams.push(mainParamName);
            let unsetParams = reqParams.filter(p => params[p] == null);
            if (unsetParams.length) {
              throw new Error(
                "unset required params: " + unsetParams.join(", ")
              );
            }

            return makeRequest(
              this,
              "get",
              getPath(params, true),
              params,
              null,
              query,
              actionParams,
              abortCurrentGET
            );
          },
          {
            children: ["ready"]
          }
        );

        // todo: query -> RequestRecords
        actions["get" + capitalize(pluralize(name))] = createAsyncAction(
          function actionHandler(
            params = {},
            query = {},
            actionParams,
            abortRequest
          ) {
            abortCurrentGET = _.isUndefined(abortRequest)
              ? abortCurrentGET
              : abortRequest;

            let unsetParams = requiredParams.filter(p => params[p] == null);
            if (unsetParams.length) {
              throw new Error(
                "unset required params: " + unsetParams.join(", ")
              );
            }

            return makeRequest(
              this,
              "get",
              getPath(params),
              params,
              null,
              query,
              actionParams,
              abortCurrentGET
            );
          },
          {
            children: ["ready"]
          }
        );

        break;

      case "u":
        actions["update" + capitalize(name)] = createAsyncAction(
          function actionHandler(params, data, actionParams) {
            let reqParams = requiredParams.slice();
            reqParams.push(mainParamName);
            let unsetParams = reqParams.filter(p => params[p] == null);
            if (unsetParams.length) {
              throw new Error(
                "unset required params: " + unsetParams.join(", ")
              );
            }

            return makeRequest(
              this,
              "patch",
              getPath(params, true),
              params,
              data,
              {},
              actionParams
            );
          }
        );
        break;

      case "d":
        actions["delete" + capitalize(name)] = createAsyncAction(
          function actionHandler(params, actionParams) {
            let reqParams = requiredParams.slice();
            reqParams.push(mainParamName);
            let unsetParams = reqParams.filter(p => params[p] == null);
            if (unsetParams.length) {
              throw new Error(
                "unset required params: " + unsetParams.join(", ")
              );
            }

            return makeRequest(
              this,
              "del",
              getPath(params, true),
              params,
              {},
              {},
              actionParams
            );
          }
        );
        break;

      default:
        break;
    }
  });
});

if (process.env.NODE_ENV === "development") {
  window.__apiActions = actions;
}

export default actions;
