import _ from "lodash";

import Axis from "../../../popup/data/data/Select/Axis";
import * as AXIS_TYPES from "../../../../../../configs/reports/widget/axisTypes";
import Immutable from "immutable";

const { getAvailableValues: getAxis } = Axis.prototype;

function compare(val1, val2) {
  // to be equal: '' and null
  return (val1 || "") == (val2 || "");
}

function find(array, value) {
  value = (value && value.toJS()) || {};

  return _.find(array, item => {
    return (
      compare(item.type, value.type) &&
      compare(item.subType, value.subType) &&
      compare(item.value, value.value)
    );
  });
}

export default function(widget, fields, param = "axis") {
  const axises = getAxis(fields);

  const axis = find(axises, widget.get(param));
  if (axis && axis.type == AXIS_TYPES.FIELD) {
    const field = fields.find(field => {
      return field.get("id") === axis.value;
    });
    axis.field = field && field.toJS();
  }

  return Immutable.fromJS(axis);
}
