import React from "react";
import Immutable from "immutable";

import { connect } from "../StateProvider";
import recordActions from "../../actions/recordActions";
import userSettingsActions from "../../actions/userSettingsActions";

import FIELD_TYPES from "../../configs/fieldTypes";

import dndContext from "../../services/dndContext";
import Header from "./Header";
import Grid from "./Grid";

import styles from "./cards.less";
import _ from "lodash";
import UrlParamSync from "../UrlParamSync";
import cn from "classnames";

class Cards extends React.Component {
  onSortFieldChange = value => {
    if (!value) return;
    const { settings, fields } = this.props;
    const isValid =
      value === "id" || fields.some(field => field.get("id") === value);

    const sortType = settings && settings.getIn(["sortingRecords", "sortType"]);

    if (!isValid) {
      value = "id";
    }

    this.props.onSortChange(value, sortType);
  };

  onSortTypeChange = value => {
    if (!value) return;
    const { settings } = this.props;

    let sortField = settings && settings.getIn(["sortingRecords", "sortField"]);

    value = Number(value) < 0 ? -1 : 1;

    this.props.onSortChange(sortField, value);
  };

  onColorChange = (value, key = "color") => {
    const { fields } = this.props;

    const isValid =
      fields &&
      fields
        .filter(field => field.get("type") === FIELD_TYPES.DROPDOWN)
        .some(field => field.get("id") === value.key);

    if (!isValid) {
      value = null;
    }

    this.props.onChange(key, value);
  };

  onAxisXChange = (value, key = "axisX") => {
    this.props.onChange(key, value);
  };

  onAxisYChange = (value, key = "axisY") => {
    this.props.onChange(key, value);
  };

  render() {
    const { settings } = this.props;
    const sortType = settings && settings.getIn(["sortingRecords", "sortType"]);
    const sortField =
      settings && settings.getIn(["sortingRecords", "sortField"]);

    const axisX = settings && settings.getIn(["options", "axisX", "value"]);
    const axisY = settings && settings.getIn(["options", "axisY", "value"]);

    const color = settings && settings.getIn(["options", "color", "value"]);

    return (
      <div className={styles.container}>
        <Header
          className={styles.header}
          catalog={this.props.catalog}
          settings={this.props.settings}
          scene={this.props.scene}
          sceneId={this.props.sceneId}
          viewId={this.props.viewId}
        />
        <div className={styles.body}>
          <Grid
            catalog={this.props.catalog}
            catalogId={this.props.catalogId}
            scene={this.props.scene}
            sceneId={this.props.sceneId}
            viewId={this.props.viewId}
            settings={this.props.settings}
            loadData={this.props.loadData}
          />
        </div>
        <UrlParamSync
          name="sortType"
          value={sortType}
          onChange={this.onSortTypeChange}
        />
        <UrlParamSync
          name="sortField"
          value={sortField}
          onChange={this.onSortFieldChange}
        />
        <UrlParamSync
          name="axisX"
          value={axisX}
          onChange={this.onAxisXChange}
        />
        <UrlParamSync
          name="axisY"
          value={axisY}
          onChange={this.onAxisYChange}
        />
        <UrlParamSync
          name="colorField"
          value={color}
          onChange={this.onColorChange}
        />
      </div>
    );
  }
}

class DataProvider extends React.Component {
  state = {};

  componentDidMount() {
    this.loadData(this.props);
  }

  componentDidUpdate(prevProps) {
    const { scene, sceneId, viewId, settings } = prevProps;
    const {
      scene: newScene,
      sceneId: newSceneId,
      viewId: newViewId,
      settings: newSettings
    } = this.props;

    const filters = scene.getIn(["views", viewId, "filters"]);
    const newFilters =
      newScene && newScene.getIn(["views", newViewId, "filters"]);

    const sorting = settings && settings.getIn(["sortingRecords"]);
    const newSorting = newSettings && newSettings.getIn(["sortingRecords"]);

    if (
      newSceneId !== sceneId ||
      viewId !== newViewId ||
      filters !== newFilters ||
      sorting !== newSorting ||
      this.props.shouldReload
    ) {
      this.loadData();
    }
  }

  loadData = () => {
    const { catalogId, sceneId, viewId, settingsLoaded } = this.props;
    if (!settingsLoaded || !catalogId) {
      return;
    }
    recordActions.requestForRecordsImmediate(
      catalogId,
      sceneId,
      { viewId: viewId },
      "cards"
    );
  };

  loadDataDebounced = _.debounce(() => this.loadData(), 200);

  onChange = (key, value) => {
    const { catalogId } = this.props;
    userSettingsActions.setOption({
      catalogId,
      viewMode: "cards",
      option: key,
      value
    });
  };

  onSortChange = (sortField, sortType) => {
    const { catalogId } = this.props;
    userSettingsActions.setSortingRecords(
      { catalogId, viewMode: "cards" },
      { sortField, sortType }
    );
  };

  render() {
    const { catalog, settingsLoaded } = this.props;

    if (!settingsLoaded || !catalog) {
      return null;
    }

    return (
      <Cards
        loadData={this.loadDataDebounced}
        onChange={this.onChange}
        onSortChange={this.onSortChange}
        {...this.props}
      />
    );
  }
}

export default connect(
  dndContext(DataProvider),
  {
    userSettings: ["userSettings", "catalogs"],
    catalogs: ["catalogs"]
  },
  function(props, { userSettings, catalogs }) {
    const shouldReload = props.scene && props.scene.get("shouldReload");
    const settings = userSettings.getIn([props.catalogId, "viewMode", "cards"]);
    const fields = catalogs && catalogs.getIn([props.catalogId, "fields"]);

    // default settings
    if (settings && !settings.get("fields")) {
      const defaultField = props.catalog
        .get("fields")
        .find(f => f.get("type") !== FIELD_TYPES.GROUP);
      if (defaultField) {
        userSettingsActions.setFieldVisibility({
          catalogId: props.catalogId,
          viewMode: "cards",
          fieldId: defaultField.get("id"),
          visible: true
        });
      }
    }

    return {
      shouldReload,
      settings,
      fields,
      settingsLoaded: !!settings,
      ...props
    };
  }
);
