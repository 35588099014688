// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".chatStatusMessage__iconStatusMessage___-Mdsy {\n  position: absolute;\n  left: -35px;\n  bottom: -5px;\n  cursor: pointer;\n}\n.chatStatusMessage__iconStatusMessageError___SCv8v {\n  position: absolute;\n  left: -35px;\n  bottom: -5px;\n  cursor: pointer;\n  color: rgba(190, 0, 0, 0.6);\n}\n", "",{"version":3,"sources":["webpack://./src/components/Record/RecordBody/chatTab/ChatStatusMessage/chatStatusMessage.less"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;AADJ;AAIA;EANI,kBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EAKA,2BAAA;AACJ","sourcesContent":["@import (reference) \"../../../../../styles/helpers/index.less\";\n\n.iconStatusMessage {\n    position: absolute;\n    left: -35px;\n    bottom: -5px;\n    cursor: pointer;\n}\n\n.iconStatusMessageError {\n    .iconStatusMessage;\n    color: @TEXT_ERROR;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconStatusMessage": "chatStatusMessage__iconStatusMessage___-Mdsy",
	"iconStatusMessageError": "chatStatusMessage__iconStatusMessageError___SCv8v"
};
export default ___CSS_LOADER_EXPORT___;
