import React from "react";
import { Modal } from "antd";

const Base = ({ visible, ...props }) => {
  const width = props.width || "60%";
  const wrapClassName = props.wrapClassName || "base-modal-bpium";
  return (
    <Modal
      open={visible !== undefined ? visible : true}
      closable={false}
      maskClosable={true}
      footer=""
      width={width}
      style={{ transition: "width 0.5s ease 0s" }}
      {...props}
      wrapClassName={wrapClassName}
    />
  );
};

export default Base;
