import { Button } from 'antd';
import PropTypes from "prop-types";
import React from 'react';

import styles from "./iconsModalFooter.less";

const IconsModalFooter = ({ icon, save, onCancel, onRemove, t }) => {
    return (
        <div className={styles.footerContainer}>
            <div>
                <Button
                    type="primary"
                    disabled={!icon}
                    onClick={save}
                >
                    {t("modals.save")}
                </Button>
                <Button type="default" onClick={onCancel}>
                    {t("modals.cancel")}
                </Button>
            </div>
            {onRemove && <Button type="primary" onClick={onRemove} danger>{t("modals.removeIcon.remove")}</Button>}
        </div>
    );
};
IconsModalFooter.propTypes = {
    icon: PropTypes.string,
    save: PropTypes.func,
    onCancel: PropTypes.func,
    onRemove: PropTypes.func
};

export default IconsModalFooter;