import React from "react";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";

import EventContent from "./EventContent";

import styles from "../../calendar.less";

const SuggestedItem = ({
  title,
  fields,
  values,
  recordId,
  style,
  onDragStart,
  onClickRecord
}) => {
  return (
    <div
      key={recordId}
      draggable="true"
      style={style}
      className={styles.eventContainer}
      onDragStart={onDragStart}
      onClick={onClickRecord}
    >
      <EventContent
        title={title}
        fields={fields}
        values={values}
        className={styles.eventContent}
      />
    </div>
  );
};

SuggestedItem.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
  fields: ImmutablePropTypes.list,
  values: ImmutablePropTypes.map,
  recordId: PropTypes.string,
  onDragStart: PropTypes.func,
  onClick: PropTypes.func
};

export default SuggestedItem;
