import React from "react";
import PropTypes from "prop-types";

import TabHistory from "./TabHistory";

const TabHistoryController = ({ catalog, record, sceneId }) => {
  const recordId = record.get("id");
  const history = record.get("history");
  const fields = catalog.get("fields");
  return (
    recordId && (
      <TabHistory
        {...{
          sceneId,
          catalogId: catalog.get("id"),
          recordId,
          history,
          fields
        }}
      />
    )
  );
};

TabHistoryController.propTypes = {
  catalog: PropTypes.object.isRequired,
  record: PropTypes.object
};

export default TabHistoryController;
