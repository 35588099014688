import React from "react";
import cn from "classnames";
import Immutable from "immutable";
import PropTypes from "prop-types";
import { Select } from "antd";
import { useTranslation } from 'react-i18next';
import raf from "raf";

import Category from "../../common/UI/Category";


import styles from "./selectors.less";

const Option = Select.Option;

const CategorySelector = props => {
  const { t } = useTranslation();

  const onEndEditing = () => {
    raf(() => {
      return props.onEndEditing && props.onEndEditing(props);
    });
  };

  const onChange = value => {
    if (!props.config.get("multiselect")) {
      return props.onChange && props.onChange(Immutable.List([value]));
    }

    const [item] = value;

    if (props.value.includes(item)) {
      const newValue = props.value.filter(i => i !== item);
      return props.onChange && props.onChange(newValue);
    }

    const newValue = props.value.push(item);
    props.onChange && props.onChange(newValue);
  };

  const filterOption = (inputValue, option) => {
    return (
      option.props.title.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
    );
  };

  const isActiveItem = item => props.value.includes(item);

  let additionalItems = [];

  props.config.get("items").forEach(item => {
    const isActive = isActiveItem(item.get("id"));

    additionalItems.push(
      <Option
        title={item.get("name")}
        checked={true}
        className={cn(
          {
            [styles.selectorActiveOption]: isActive
          },
          styles.multipleOption,
          styles.option
        )}
        style={{ minHeight: "30px" }}
        key={item.get("id")}
      >
        <Category
          className={styles.tag}
          readOnly={!props.editable}
          checked={isActive}
          alwaysColorize={true}
          title={item.get("name")}
          backgroundColor={"#" + item.get("color")}
        />
      </Option>
    );
  });

  const multiselect = props.config.get("multiselect");
  const mode = multiselect && "multiple";

  const value = multiselect ? [] : props.value.get(0);

  const placeholder = multiselect
    ? t("record.values.count", { count: props.value && props.value.size })
    : props.field.get("name");

  return (
    <Select
      popupClassName={styles.selectorDropdown}
      mode={mode}
      dropdownMatchSelectWidth={false}
      dropdownStyle={{maxWidth: "300px"}}
      className={props.className}
      style={{ width: "100%" }}
      placeholder={placeholder}
      value={value}
      autoFocus={props.autoFocus}
      defaultOpen={props.autoFocus}
      onChange={onChange}
      onDropdownVisibleChange={onEndEditing}
      onInputKeyDown={props.onKeyPress}
      filterOption={filterOption}
      showArrow={false}
    >
      {additionalItems}
    </Select>
  );
};

CategorySelector.defaultProps = {
  value: Immutable.List()
};

CategorySelector.propTypes = {
  value: PropTypes.object,
  config: PropTypes.object,
  onChange: PropTypes.func,
  onEndEditing: PropTypes.func,
  editable: PropTypes.bool,
  onKeyPress: PropTypes.func
};

export default CategorySelector;
