import React from "react";
import ReactDOM from "react-dom";
import { ConfigProvider } from "antd";
import ruRu from "antd/lib/locale-provider/ru_RU";

export default function renderComponentToBody(Component, props = {}) {
  const div = document.createElement("div");
  document.body.appendChild(div);

  ReactDOM.render(
    <ConfigProvider locale={ruRu}>
      <Component {...props} />
    </ConfigProvider>,
    div
  );

  return function destroy() {
    ReactDOM.unmountComponentAtNode(div);
  };
}
