import _ from "lodash";
import Immutable from "immutable";

import { checkAccessOnObject } from "../utils/rights";
import PRIVILEGE_CODES from "../configs/privilegeCodes";
import RESOURCE_TYPES from "../configs/resourceTypes";

import appState from "../appState";

export default {
  create(data = {}) {
    var view = {};
    view.__name = "View";

    view.uuid = Math.random();
    view.id = data.id != null ? String(data.id) : null;

    if (data.index !== undefined) {
      view.index = data.index;
    }

    // get privilege
    const catalog = appState.get("catalogs").get(data.catalogId);
    view.isAdmin =
      catalog &&
      checkAccessOnObject(
        RESOURCE_TYPES.CATALOG,
        { privilegeCode: catalog.get("privilegeCode") },
        PRIVILEGE_CODES.ACCESS
      );

    // name
    view.name = data.name;
    view.originName = data.originName || data.name;
    view.displayName = view.isAdmin ? view.originName : view.name;

    // other props
    view.forRights = data.forRights;
    view.privilegeCode = data.privilegeCode;
    view.fieldPrivilegeCodes = data.fieldPrivilegeCodes || {};
    view.c = data.privilegeCode;

    view.catalogId = data.catalogId;

    if (_.isEmpty(data)) {
      view.isNew = true;
    }

    // filters
    view.filters = data.filters || {};
    view.filtersChanged = data.filtersChanged || false;

    return Immutable.fromJS(view);
  }
};
