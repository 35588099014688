import React from "react";
import { Row } from "antd";
import { useTranslation } from "react-i18next";

import Dropdown from "../../../../common/Dropdown";
import FIELD_TYPES from "../../../../../configs/fieldTypes";
import { getFieldsByType } from "../../../helpers";

import styles from "../config.less";

const Color = props => {
  const { t } = useTranslation();
  const value = props.settings.getIn(["color", "key"]) || null;
  const items = getFieldsByType(props.fields, FIELD_TYPES.DROPDOWN);
  return items ? (
    <Row className={styles.controlRow}>
      <label className={styles.controlLabel}>
        {t("calendar.config.color")}
      </label>
      <Dropdown
        disabled={!items.length}
        items={[{ key: null, text: "", sort: -1 }, ...items]}
        value={value}
        autocomplete={true}
        withButton={true}
        multiselect={false}
        onSelectItems={([item]) => props.handler(item, "color")}
      />
    </Row>
  ) : null;
};

export default Color;
