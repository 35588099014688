export default t => ({
  element: "bpmn:ServiceTask",
  service: "setvar",
  title: t("script.controlls.setvar.title"),
  helplink: "",
  class: "other",
  color: "#666B70",
  border: 2,
  icon: "anticon-icon multimedia-72",
  offset_x: 5,
  offset_y: -13,
  toolbar_offset_x: 13,
  toolbar_offset_y: -7,
  icon_rotate: 90,
  priority: 1,
  config: [
    {
      id: "commonSection",
      name: t("script.common.general"),
      type: "group"
    },
    {
      id: "name",
      name: t("script.common.title"),
      type: "text",
      readOnly: false,
      config: {
        defaultEmptyValue: t("script.controlls.setvar.title"),
        type: "text",
        valueType: "const",
        map: "input"
      }
    },
    {
      id: "description",
      name: t("script.common.description"),
      type: "text",
      readOnly: false,
      config: {
        type: "multiline",
        valueType: "const",
        map: "input"
      }
    },
    {
      id: "paramsSection",
      name: t("script.controlls.setvar.title"),
      type: "group"
    },
    {
      id: "vars",
      name: t("script.controlls.setvar.variables"),
      type: "pair",
      readOnly: false,
      config: {
        placeholderValue: t("script.common.variableName"),
        placeholderSubValue: t("script.common.valueExpression"),
        type: "script",
        theme: "script",
        map: "output"
      }
    }
  ],
  exits: []
});
