export default t => ({
  element: "bpmn:ServiceTask",
  service: "code",
  title: t("script.controlls.code.title"),
  helplink: "",
  class: "other",
  color: "#666B70",
  border: 2,
  icon: "anticon-icon programing-28",
  offset_x: 0,
  offset_y: -15,
  priority: 2,
  config: [
    {
      id: "commonSection",
      name: t("script.common.general"),
      type: "group"
    },
    {
      id: "name",
      name: t("script.common.title"),
      type: "text",
      readOnly: false,
      config: {
        defaultEmptyValue: t("script.controlls.code.title"),
        type: "text",
        valueType: "const",
        map: "input"
      }
    },
    {
      id: "description",
      name: t("script.common.description"),
      type: "text",
      readOnly: false,
      config: {
        type: "multiline",
        valueType: "const",
        map: "input"
      }
    },
    {
      id: "section",
      name: t("script.controlls.code.code"),
      type: "group"
    },
    {
      id: "variables",
      name: t("script.controlls.code.inputVariables"),
      type: "pair",
      readOnly: false,
      config: {
        placeholderValue: t("script.common.variableName"),
        placeholderSubValue: t("script.common.valueExpression"),
        type: "script",
        theme: "script",
        map: "input"
      }
    },
    {
      id: "source",
      name: t("script.controlls.code.code"),
      type: "text",
      readOnly: false,
      hint: t("script.controlls.code.sourceHint"),
      config: {
        type: "script",
        theme: "codeEditor",
        map: "input",
        valueType: "const",
        placeholder: t("script.controlls.code.sourcePlaceholder")
      }
    },
    {
      id: "section1",
      name: t("script.common.result"),
      type: "group"
    },
    {
      id: "output",
      name: t("script.common.saveTo"),
      type: "text",
      readOnly: false,
      config: {
        type: "text",
        map: "output",
        placeholder: t("script.common.variableName")
      }
    }
  ],
  exits: []
});
