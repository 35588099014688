import React from "react";
import PureRenderMixin from "react-addons-pure-render-mixin";
import _ from "lodash";
import { Modal, Button, Row } from "antd";
import PropTypes from "prop-types";
import cn from "classnames";
import { withTranslation } from "react-i18next";

import icons from "../../configs/icons";
import classNames from "classnames";
import ButtonClose from "../common/UI/ButtonClose";
import IconsModalFooter from "./UI/IconsModalFooter";

import styles from "./controls.less";

const getIconsGroups = _.memoize(function () {
  return Array.from(
    icons.reduce(function (res, icon) {
      const group = icon.match(/([a-z]+)?-/)[1];

      if (!res.get(group)) {
        res.set(group, []);
      }

      res.get(group).push(icon);

      return res;
    }, new Map())
  );
});

class IconWrapper extends React.PureComponent {
  onClick = () => this.props.select(this.props.icon);
  render() {
    return (
      <div
        className={classNames(styles.iconWrapper, {
          [styles.iconsModalSelected]: this.props.isSelected
        })}
        onClick={this.onClick}
      >
        <span className={"anticon-icon " + this.props.icon} />
      </div>
    );
  }
}

IconWrapper.propTypes = {
  select: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  icon: PropTypes.string
};

class IconsModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { icon: null };
  }

  selectIcon = icon => this.setState({ icon: icon });

  save = () => this.props.onOk(this.state.icon);

  render() {
    const { t } = this.props;
    return (
      <Modal
        open={true}
        maskClosable={false}
        closable={false}
        footer={[
          <IconsModalFooter
            icon={this.state.icon}
            save={this.save}
            onCancel={this.props.onCancel}
            onRemove={this.props.onRemove}
            t={t}
          />
        ]}
        width="60%"
      >
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          className={styles.headerModal}
        >
          <Row type="flex" align="middle">
            <h2>
              <span
                className={cn(
                  "anticon-icon " + this.props.currentIcon,
                  styles.iconHeaderModal
                )}
              />
              {this.props.header}
            </h2>
          </Row>
          <ButtonClose large shiftRight onClick={this.props.onCancel} />
        </Row>
        <div className={styles.iconsModalBody}>
          {getIconsGroups().map(([groupName, icons]) => {
            return (
              <div key={groupName} className={styles.iconsGroup}>
                <div className={styles.iconsGroupTitle}>
                  {t(`modals.icons.groupName.${groupName}`)}
                </div>
                <div className={styles.iconGroupBody}>
                  {icons.map((icon, i) => (
                    <IconWrapper
                      key={i}
                      icon={icon}
                      select={this.selectIcon}
                      isSelected={icon === this.state.icon}
                    />
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
    );
  }
}

IconsModal.propTypes = {
  header: PropTypes.string,
  currentIcon: PropTypes.string.isRequired,
  onSave: PropTypes.func
};

export default withTranslation()(IconsModal);
