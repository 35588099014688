import React, {
  useMemo,
  useEffect,
  useCallback,
  createContext,
  useState,
  useLayoutEffect
} from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import Immutable, { fromJS } from "immutable";
import _ from "lodash";
import routes from "../../routes";
import getLink from "../common/router/getLink";

import useRange from "./hooks/useRange.hook";
import { RANGE_FORMAT, REQUEST_FORMAT, TOTALS_FORMAT } from "./formats";
import { prepareDateForUrl } from "./helpers";
import useSettings from "./hooks/useSettings.hook";

import { checkAccessOnObject } from "../../utils/rights";
import FIELD_TYPES from "../../configs/fieldTypes";
import PRIVILEGE_CODES from "../../configs/privilegeCodes";
import RESOURCE_TYPES from "../../configs/resourceTypes";
import calendarActions from "../../actions/calendarActions";
import catalogActions from "../../actions/catalogActions";
import sceneActions from "../../actions/sceneActions";
import userSettingsActions from "../../actions/userSettingsActions";
import { connect } from "../StateProvider";
import recordActions from "../../actions/recordActions";
import apiActions from "../../actions/apiActions";
import filterUtils from "../../utils/filters";

import Header from "./Header";
import Body from "./Body";

import styles from "./calendar.less";
import guid from "guid";
import SCENE_TYPE from "../../configs/sceneTypes";
import SCENE_CONTAINER from "../../configs/sceneContainer";
import UrlParamSync from "../UrlParamSync";

export const DataContext = createContext();

const RECORD_LIMITS = {
  day: 250,
  week: 250,
  month: 250,
  year: 1
};

const DataProvider = ({
  match,
  view,
  sceneId,
  boardId,
  sceneFilters,
  filters,
  shouldReload,
  userSettings,
  suggestRecords,
  adjustedRecords,
  recordsCount,
  ...props
}) => {
  let { type, date, catalogId, viewId } = match.params;

  const [summary, setSummary] = useState(Immutable.Map({}));
  const [fields, setFields] = useState(Immutable.List([]));
  const [suggestRecordsSceneId, setSuggestRecordsSceneId] = useState(null);

  const { settings, userSettingsHandler } = useMemo(
    () => useSettings(userSettings, catalogId, fields),
    [userSettings]
  );
  const startId = settings.getIn(["start", "key"]);
  const endId = settings.getIn(["end", "key"]);
  const sortType = settings.get("sortType");
  const sortFieldId = settings.getIn(["sortField", "key"]);

  const requestDependencies = [
    startId,
    endId,
    sortType,
    sortFieldId,
    sceneId,
    catalogId,
    type,
    date,
    filters
  ];

  if (date === "today") {
    date = moment().format(RANGE_FORMAT);
  }

  //eslint-disable-next-line react-hooks/exhaustive-deps
  const [at, to] = useMemo(() => useRange(type, date, REQUEST_FORMAT), [
    type,
    date
  ]);

  const getFiltersForRequest = () => {
    let filters;

    if (!startId) {
      return;
    }

    if (endId) {
      filters = [
        {
          fieldId: startId,
          value: { to }
        },
        {
          fieldId: endId,
          value: { at }
        }
      ];
    } else {
      filters = [
        {
          fieldId: startId,
          value: { at, to }
        }
      ];
    }

    return filters;
  };

  const loadSummary = () => {
    if (!settings.get("start")) {
      return;
    }

    if (!boardId || type !== "month") {
      return;
    }

    let filters = getFiltersForRequest();

    const axisStart = {
      type: "field",
      subType: "day",
      value: settings.getIn(["start", "key"])
    };

    apiActions
      .getValues(
        { boardId, widgetId: "new" },
        {
          axis: axisStart,
          value: { type: "recordsCount" },
          order: "value",
          limit: 100,
          recordsFilter: {
            filters
          }
        },
        { uid: "new_1" }
      )
      .then(response => {
        const summary = {};
        response.forEach(
          ({ axis, values }) =>
            (summary[moment(axis).format(TOTALS_FORMAT)] = values[0].value)
        );
        setSummary(fromJS(summary));
      });
  };

  const loadData = () => {
    if (!settings.get("start")) {
      return;
    }

    const limit = RECORD_LIMITS[type];
    const requestFilters = getFiltersForRequest();
    const request = { viewId, limit };

    recordActions.requestForRecordsImmediate(
      catalogId,
      sceneId,
      request,
      "table",
      requestFilters,
      { sortField: sortFieldId, sortType },
      undefined,
      false
    );
  };

  const loadDataDebounced = _.debounce(loadData, 400);

  const switchDay = useCallback(
    date => {
      date = moment(date).format(RANGE_FORMAT);
      const link = getLink(props.location, routes.calendarSettings, {
        date,
        type: "day"
      });
      props.history.push(link);
    },
    [props.location]
  );

  const onChangeUrl = useCallback(
    (date, calendarView = type) => {
      date = prepareDateForUrl(date);
      const link = getLink(props.location, routes.calendarSettings, {
        date,
        type: calendarView
      });
      props.history.push(link);
    },
    [props.location]
  );

  const setUrlItemsToStore = useCallback(({ view, date }) => {
    calendarActions.setUrlItems({ view, date });
  }, []);

  useEffect(
    () => {
      catalogActions.clearCatalog(catalogId);
      sceneActions.deleteRecordsFromScene(sceneId);
    },
    [type, date]
  );

  useEffect(() => {
    loadDataDebounced();
  }, requestDependencies);

  useEffect(
    () => {
      const filteredFields = props.fields.filter(field => !field.get("hidden"));
      setFields(filteredFields);
    },
    [props.fields]
  );

  useEffect(() => {
    const filteredFields = props.fields.filter(field => !field.get("hidden"));
    setFields(filteredFields);
  }, []);

  useEffect(
    () => {
      shouldReload && loadData();
    },
    [shouldReload]
  );

  useEffect(
    () => {
      apiActions.getBoards({}, { catalogId });
    },
    [catalogId, sceneId]
  );

  useEffect(
    () => {
      loadSummary();
    },
    [boardId, props.adjustedRecords, type]
  );

  const openSuggestRecords = () => {
    const type = SCENE_TYPE.CATALOG;
    const container = SCENE_CONTAINER.WINDOW;

    const params = {
      catalogId,
      viewId
    };

    const callbacks = {
      onCreate: ({ sceneId: suggestedRecordsSceneId }) => {
        setSuggestRecordsSceneId(suggestedRecordsSceneId);
        sceneActions.copyViews(sceneId, suggestedRecordsSceneId);
      }
    };

    const data = {
      filters: sceneFilters
    };

    sceneActions.createScene(
      {
        sceneId: suggestRecordsSceneId,
        params,
        data,
        type,
        parentSceneId: sceneId,
        container
      },
      callbacks
    );
  };

  useLayoutEffect(
    () => {
      if (suggestRecords) {
        openSuggestRecords();
      } else {
        sceneActions.deleteScene(suggestRecordsSceneId);
        setSuggestRecordsSceneId(undefined);
      }

      return () => {
        suggestRecordsSceneId &&
          sceneActions.deleteScene(suggestRecordsSceneId);
      };
    },
    [suggestRecords]
  );

  const onStartChange = (value, key = "start") => {
    userSettingsHandler(value, key);
  };
  const onEndChange = (value, key = "end") => {
    userSettingsHandler(value, key);
  };
  const onSortTypeChange = (sortType, key = "sortType") => {
    sortType = Number(sortType) < 0 ? -1 : 1;
    userSettingsHandler(sortType, key);
  };
  const onSortFieldChange = (sortField, key = "sortField") => {
    userSettingsHandler(sortField, key);
  };
  const onColorChange = (color, key = "color") => {
    userSettingsHandler(color, key);
  };
  const onSplitChange = (split, key = "split") => {
    userSettingsHandler(split, key);
  };

  return (
    <DataContext.Provider
      value={{
        setUrlItemsToStore,
        date,
        calendarView: type,
        summary,
        catalogId,
        viewId,
        startId
      }}
    >
      <div className={styles.container}>
        <Header
          userSettingsHandler={userSettingsHandler}
          setUrlItemsToStore={setUrlItemsToStore}
          onChangeUrl={onChangeUrl}
          view={type}
          date={date}
          suggestRecords={suggestRecords}
          catalogId={catalogId}
          sceneId={sceneId}
          fields={fields}
          settings={settings}
          switchDay={switchDay}
        />
        <Body
          loadSummary={loadSummary}
          setUrlItemsToStore={setUrlItemsToStore}
          onChangeUrl={onChangeUrl}
          loadData={loadData}
          settings={settings}
          calendarView={type}
          date={date}
          catalogId={catalogId}
          sceneId={sceneId}
          suggestRecordsSceneId={suggestRecordsSceneId}
          viewId={viewId}
          fields={fields}
          filters={filters}
          suggestRecords={suggestRecords}
          canCreate={props.canCreate}
          shouldReload={shouldReload}
          adjustedRecords={adjustedRecords} // рекорды с временем
          summary={summary}
          fieldsOrder={props.fieldsOrder}
          visibleFields={props.visibleFields}
        />
      </div>
      <UrlParamSync
        name="start"
        value={settings && settings.get("start")}
        onChange={onStartChange}
      />
      <UrlParamSync
        name="end"
        value={settings && settings.get("end")}
        onChange={onEndChange}
      />
      <UrlParamSync
        name="colorField"
        value={settings && settings.get("color")}
        onChange={onColorChange}
      />
      <UrlParamSync
        name="split"
        value={settings && settings.get("split")}
        onChange={onSplitChange}
      />
      <UrlParamSync
        name="sortField"
        value={settings && settings.get("sortField")}
        onChange={onSortFieldChange}
      />
      <UrlParamSync
        name="sortType"
        value={sortType}
        onChange={onSortTypeChange}
      />
    </DataContext.Provider>
  );
};

export default connect(
  withRouter(DataProvider),
  ["userSettings", "boards"],
  function({ catalog, scene, viewId, match }, { userSettings, boards }) {
    const fields = catalog.get("fields");
    const catalogId = catalog.get("id");
    const sceneId = scene.get("sceneId");

    const board = boards
      .get("list")
      .find(board => board.get("catalogId") === catalogId);
    const boardId = board && board.get("id");

    const view = scene.getIn(["views", viewId]);

    const sceneFilters = scene.getIn(["data", "filters"]);
    const viewFilters = view && view.get("filters");

    const filters = filterUtils.mergeFilters(sceneFilters, viewFilters);

    const adjustedRecords = scene.get("records") || Immutable.Map({});

    const shouldReload = scene.get("shouldReload");

    const isAccessCreateRecordAtCatalog = checkAccessOnObject(
      RESOURCE_TYPES.CATALOG,
      catalog,
      PRIVILEGE_CODES.CREATE
    );

    let isAccessCreateRecordAtViews = true;

    if (view) {
      isAccessCreateRecordAtViews = checkAccessOnObject(
        RESOURCE_TYPES.VIEW,
        view,
        PRIVILEGE_CODES.CREATE
      );
    }
    const canCreate =
      isAccessCreateRecordAtCatalog || isAccessCreateRecordAtViews;

    const userSettingsLoaded = !!userSettings;

    let suggestRecords = userSettings.getIn([
      "catalogs",
      catalogId,
      "viewMode",
      "calendar",
      "suggestRecords"
    ]);

    suggestRecords = userSettingsLoaded
      ? suggestRecords || _.isUndefined(suggestRecords)
      : false;

    const visibleFields = userSettings.getIn([
      "catalogs",
      catalogId,
      "viewMode",
      "calendar",
      "fields"
    ]);
    const fieldsOrder = userSettings.getIn([
      "catalogs",
      catalogId,
      "viewMode",
      "calendar",
      "fieldsOrder",
      "fieldsOrder"
    ]);
    if (!visibleFields) {
      const defaultField = fields.find(
        f => f.get("type") !== FIELD_TYPES.GROUP
      );
      if (catalog) {
        userSettingsActions.setFieldVisibility({
          catalogId: catalogId,
          viewMode: "calendar",
          fieldId: defaultField.get("id"),
          visible: true
        });
      }
    }

    return {
      boardId,
      shouldReload,
      sceneId,
      catalogId,
      canCreate,
      userSettings,
      adjustedRecords,
      view,
      filters,
      params: match.params,
      fields,
      viewId,
      visibleFields,
      fieldsOrder,
      suggestRecords,
      sceneFilters
    };
  }
);
