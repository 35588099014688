import React from "react";
import PropTypes from "prop-types";
import Guid from "guid";
import Immutable from "immutable";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { Input } from "antd";

import Icon from '../../common/UI/Icon';
import DROPDOWN_COLORS from "../../../configs/dropdownColors";
import KEYS from "../../../configs/keys";
import ItemListEditorItem from "./ItemListEditorItem";
import editorActions from "../../../actions/editorActions";

import styles from "./fields.less";

class ItemListEditor extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      items: this.props.field.getIn(["config", "items"])
    };
  }

  onClickAdd = () => {
    if (this.onAddItem(this.state.value)) {
      this.setState({
        value: ""
      });
    }
  };

  onKeyDown = e => {
    if (e.keyCode === KEYS.ENTER && this.state.value) {
      e.preventDefault();
      if (this.onAddItem(this.state.value)) {
        this.setState({
          value: ""
        });
      }
    }
  };

  onChange = e => {
    this.setState({
      value: e.target.value
    });
  };

  moveItem = (itemName, afterItemName) => {
    let items = this.state.items;
    let itemIndex = items.findIndex(i => i.get("name") === itemName);
    let afterItemIndex = items.findIndex(i => i.get("name") === afterItemName);

    let item = items.get(itemIndex);
    items = items.splice(itemIndex, 1);
    items = items.splice(
      afterItemIndex > itemIndex ? afterItemIndex : afterItemIndex,
      0,
      item
    );

    this.setState({
      items: items,
      itemsMoved: true
    });
  };

  onChangeItemOrder = () => {
    editorActions.changeFieldConfig(
      this.props.sectionId,
      this.props.fieldIndex,
      {
        items: this.state.items
      }
    );
  };

  onAddItem = text => {
    if (
      !this.props.field
        .getIn(["config", "items"])
        .find(item => item.get("name") === text)
    ) {
      editorActions.changeFieldConfig(
        this.props.sectionId,
        this.props.fieldIndex,
        {
          items: this.props.field.getIn(["config", "items"]).concat(
            Immutable.fromJS([
              {
                name: text,
                color: this.props.withColor ? DROPDOWN_COLORS[9] : undefined,
                _cid: Guid.raw()
              }
            ])
          )
        }
      );
      return true;
    }
    return false;
  };

  onRemoveItem = itemIndex => {
    let items = this.props.field.getIn(["config", "items"]);
    items = items["delete"](itemIndex);
    editorActions.changeFieldConfig(
      this.props.sectionId,
      this.props.fieldIndex,
      {
        items: items
      }
    );
  };

  onChangeItemColor = (itemIndex, color) => {
    let items = this.props.field.getIn(["config", "items"]);
    items = items.setIn([itemIndex, "color"], color);
    editorActions.changeFieldConfig(
      this.props.sectionId,
      this.props.fieldIndex,
      {
        items: items
      }
    );
  };

  onChangeItemIcon = (itemIndex, icon) => {
    let items = this.props.field.getIn(["config", "items"]);
    items = items.setIn([itemIndex, "icon"], icon);
    editorActions.changeFieldConfig(
      this.props.sectionId,
      this.props.fieldIndex,
      {
        items: items
      }
    );
  };

  onRemoveItemIcon = (itemIndex) => {
    let items = this.props.field.getIn(["config", "items"]);
    items = items.deleteIn([itemIndex, "icon"]);
    editorActions.changeFieldConfig(
      this.props.sectionId,
      this.props.fieldIndex,
      {
        items: items
      }
    );
  };

  onChangeItemName = (itemIndex, name) => {
    let items = this.props.field.getIn(["config", "items"]);
    items = items.setIn([itemIndex, "name"], name);
    editorActions.changeFieldConfig(
      this.props.sectionId,
      this.props.fieldIndex,
      {
        items: items
      }
    );
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    let oldItems = this.props.field.getIn(["config", "items"]);
    let newItems = nextProps.field.getIn(["config", "items"]);
    if (!Immutable.is(oldItems, newItems)) {
      this.setState({
        items: newItems
      });
    }
  }

  render() {
    let items = [];

    if (this.state.items && this.state.items.size) {
      items = this.state.items.map((item, i) => {
        return (
          <ItemListEditorItem
            fieldIndex={this.props.fieldIndex}
            key={item.get("id") || item.get("_cid")} // bad work react-dnd with key=i
            disabled={this.props.disabled}
            itemId={item.get("id")}
            itemIndex={i}
            name={item.get("name")}
            color={item.get("color")}
            icon={item.get("icon")}
            isChangeIcon={this.props.isChangeIcon}
            withColor={this.props.withColor}
            moveItem={this.moveItem}
            onChangeName={this.onChangeItemName}
            onChangeColor={this.onChangeItemColor}
            onChangeItemIcon={this.onChangeItemIcon}
            onRemoveItemIcon={this.onRemoveItemIcon}
            onRemove={this.onRemoveItem}
            onDragEnd={this.onChangeItemOrder}
          />
        );
      });
    }

    const action = (
      <div className={styles.itemsInputAction} onClick={this.onClickAdd}>
        <Icon type="icon keyboard-20" />
        <span className={styles.itemsInputActionText}>
          {this.props.t("catalogEditor.field.list.add.button")}
        </span>
      </div>
    );

    return (
      <div>
        <div
          className={styles.itemsList}
          style={{ display: (items && items.size) === 0 ? "none" : "" }}
        >
          {items}
        </div>
        <Input
          type="text"
          className={styles.itemsInput}
          disabled={this.props.disabled}
          value={this.state.value}
          placeholder={this.props.t("catalogEditor.field.list.add.placeholder")}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          suffix={action}
        />
      </div>
    );
  }
}

ItemListEditor.propTypes = {
  field: PropTypes.object.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  withColor: PropTypes.bool,
  sectionId: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default withTranslation()(ItemListEditor);
