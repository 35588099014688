import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import cn from "classnames";
import { Radio, Input } from "antd";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import SelectWithFilter from "../../SelectWithFilter";

import styles from "./controls.less";

const RadioGroup = Radio.Group;
class Radiobutton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value
    };
  }

  onChangeItem = itemId => {
    if (!this.props.editable) {
      return false;
    }

    this.setState({ value: itemId });
    this.props.onChange && this.props.onChange(itemId);
    this.props.onEndEditing && this.props.onEndEditing(itemId);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      let newValue = nextProps.value;
      this.setState({
        value: newValue
      });
    }
  }

  render() {
    let items = this.props.config.get("items");
    let mode = this.props.config.get("mode"); // mode is obsolet
    let type = this.props.config.get("type");

    const { editable } = this.props;
    let checkedItem;
    let content;
    if (!editable) {
      items = items.filter(item => item.get("id") === this.props.value);
    }
    if (
      ((items && items.size) >= 7 && type != "radio") ||
      mode == "dropdown" ||
      type == "select"
    ) {
      if (!editable) {
        checkedItem = items.find(item => item.get("id") === this.props.value);
        content = (
          <Input
            className={styles.radioSelectReadOnly}
            readOnly={true}
            value={checkedItem && checkedItem.get("name")}
          />
        );
      } else {
        content = (
          <SelectWithFilter
            mode="single"
            value={{
              key: this.props.value
            }}
            onChange={this.onChangeItem}
            placeholder={this.props.placeholder}
            notFoundContent={this.props.t("dropdown.noitems")}
            items={items
              .map(item => {
                return {
                  key: item.get("id"),
                  text: item.get("name")
                };
              })
              .toJS()}
            className={styles.radioSelect}
            showSearch
          />
        );
      }
    } else {
      content = (
        <RadioGroup 
          value={this.state.value}
          onChange={e => this.onChangeItem(e.target.value)} 
        >
          {items.map((item, key) => {
            let ref = key ? "input" : "inputFirst";
            const id = String(item.get("id"));
            return (
              <Radio
                key={id}
                className={cn(styles.radioItem, { readonly: !editable })}
                disabled={editable ? false : true} // need specify disabled to readonly
                ref={ref}
                checked={this.state.value === id}
                value={id}
              >
                {item.get("name")}
              </Radio>
            );
          })}
        </RadioGroup>
      );
    }
    return content;
  }
}

Radiobutton.propTypes = {
  value: PropTypes.string,
  config: PropTypes.object,
  onChange: PropTypes.func,
  onEndEditing: PropTypes.func,
  editable: PropTypes.bool
};

export default withTranslation()(Radiobutton);
