import React from "react";
import PureRenderMixin from "react-addons-pure-render-mixin";
import PropTypes from "prop-types";
import cn from "classnames";

import TextInput from "../../common/UI/ControlList/controls/common/TextInput";

import styles from "./changes.less";

class ChangeAddress extends React.PureComponent {
  render() {
    const newValue = this.props.change.get("newValue");
    const oldValue = this.props.change.get("oldValue");
    return (
      <div>
        {oldValue && (
          <TextInput
            className={cn(styles.removed, styles.inputHistory)}
            value={oldValue}
            readOnly
          />
        )}
        <TextInput value={newValue} readOnly className={styles.inputHistory} />
      </div>
    );
  }
}

ChangeAddress.propTypes = {
  change: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  isNewRecord: PropTypes.bool
};

export default ChangeAddress;
