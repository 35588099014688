import React from "react";
import PropTypes from "prop-types";
import PureRenderMixin from "react-addons-pure-render-mixin";
import ImmutablePropTypes from "react-immutable-proptypes";
import cn from "classnames";
import Link from "../router/Link";
import { EMAIL, SITE } from "../../../configs/contactFieldSubTypes";

import styles from "./dataTypes.less";

class ContactField extends React.PureComponent {
  onClick = e => {
    const event = e.nativeEvent;
    const isKey =
      event.metaKey || event.altKey || event.ctrlKey || event.shiftKey;
    if (isKey) {
      e.stopPropagation(); // open site and prevent show record in sidebar
    } else {
      e.preventDefault(); // open record in sidebar and prevent open site
      return true;
    }
  };

  render() {
    const value = this.props.value || [];
    const type = this.props.config.get("type");

    let contacts = value;

    /*
    // no links in table
    
    if (type == EMAIL || type == SITE) {
      contacts = contacts.map((item, index) => {
        let site = _.trim(item.get("contact"));
        let comment = item.get("comment");
        let href;

        if (type == SITE) {
          if (/^([a-z]+:\/\/|\/\/)/.test(site)) {
            href = site;
          } else {
            href = "http://" + site;
          }
        } else if (type == EMAIL) {
          href = "mailto:" + site;
        }

        return (
          <span key={index}>
            <a
              href={href}
              title={comment}
              onClick={this.onClick}
              target="_blank"
            >
              {site}
            </a>
            {index < (contacts && contacts.size) - 1 ? ", " : ""}
          </span>
        );
      });

      return <span className={className}>{contacts}</span>;
    } else {
    */
    contacts = contacts.map(item => item.get("contact"));
    return (
      <span className={cn(this.props.containerClassName, styles.simpleField)}>
        {contacts.join(", ")}
      </span>
    );
    /*}*/
  }
}

ContactField.propTypes = {
  value: ImmutablePropTypes.list,
  config: ImmutablePropTypes.contains({
    type: PropTypes.string
  }).isRequired
};

export default ContactField;
