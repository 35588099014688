import React from "react";
import Calendar from "rc-year-calendar";

import styles from "../calendar.less";

const YearCalendar = props => {
  return (
    <div className={styles.body}>
      <Calendar
        enableRangeSelection={false}
        style="border"
        minDate={new Date()}
        sceneId={props.sceneId}
      />
    </div>
  );
};

export default YearCalendar;
