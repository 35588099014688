import React from "react";
import Immutable, { isImmutable } from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";
import PropTypes from "prop-types";
import cn from "classnames";
import { API_PREFIX } from "../../../configs/records";
import LinkedItem from "../UI/LinkedItem";
import FileViewer from "../UI/ControlList/controls/fileViewer";
import {
  getFileType,
  getViewer
} from "../UI/ControlList/controls/fileViewer/getViewerType";
import { Image } from "antd";

import Icon from "../../common/UI/Icon";

import styles from "./dataTypes.less";
import { antImageViewer } from "../UI/ControlList/controls/fileViewer/viewers/Image/AntImage";

function makeLinkOnFile(file) {
  return API_PREFIX + "files/" + file.id;
}

class FileField extends React.PureComponent {
  onClickItem = (e, item) => {
    const event = e.nativeEvent;
    const isKey =
      event.metaKey || event.altKey || event.ctrlKey || event.shiftKey;
    if (isKey) {
      e.stopPropagation(); // open file and prevent show record in sidebar
      if (this.props.removed) {
        e.preventDefault();
      }
    } else {
      e.preventDefault(); // open record in sidebar and prevent load file
      return true;
    }
  };

  render() {
    const values = this.props.value || Immutable.List();
    if (!values.size) {
      return <span />; // для позиционирования в форме поповера для таблицы
    }

    if (this.props.extended) {
      // multi items
      return (
        <ul
          className={cn(this.props.containerClassName, styles.listField)}
          onClick={e => e.stopPropagation()}
        >
          {values.map((val, i) => {
            return (
              <li key={i} className={styles.listItem}>
                <LinkedItem
                  item={{
                    icon: "files-13",
                    text: val.get("title")
                  }}
                  type="link"
                  linkProps={{
                    download: true,
                    target: "_blank",
                    onClick: this.onClickItem,
                    href: makeLinkOnFile(val.toJS())
                  }}
                  simple={true}
                />
              </li>
            );
          })}
        </ul>
      );
    } else {
      // simple as 1 item
      const text = values.map(u => u.get("title"));

      const files = values.map(val => {
        val.url = makeLinkOnFile(val.toJS());
        return val.toJS();
      });

      // In here we are watch cell for image sign
      const includedImages = [];
      let otherFiles = [];
      _.forEach(files.toJS(), file => {
        const fileType = getFileType(file);
        const isImage =
          _.get(fileType, "viewer.viewerName", "") == "ImageViewer";
        if (isImage) {
          const viewer = getViewer(file);
          const isMyFile = viewer.isMyFile(file); // check image size, cause image size do not more allowed size
          if (isMyFile) {
            includedImages.push(file);
            return;
          }
        }
        otherFiles.push(_.get(file, "title", "")); // return empty if files do not includes image
      });

      otherFiles = _.compact(otherFiles);

      return (
        <div className={styles.fileFieldContainer} onClick={this.onClickItem}>
          <div onClick={e => e.stopPropagation()}>
            <Image.PreviewGroup>
              <FileViewer
                files={includedImages}
                componentType={antImageViewer}
                containerClassName={styles.fileFieldImageContainer}
                wrapperClassName={styles.fileFieldImageWrapper}
                imageClassName={styles.fileFieldImage}
                isSmallImage={true}
                height={27}
                updateProgress={Immutable.Map()}
              />
            </Image.PreviewGroup>
          </div>
          <span
            className={cn(this.props.containerClassName, styles.simpleField)}
          >
            {_.size(otherFiles) >= 1 ? (
              <>
                <Icon type={"icon files-13"} className={styles.icon} />
                <span className={styles.prefixData}>
                  {String(_.size(otherFiles))}:{" "}
                </span>
              </>
            ) : null}
            {otherFiles.join(", ")}
          </span>
        </div>
      );
    }
  }
}

FileField.propTypes = {
  value: PropTypes.object,
  config: PropTypes.object,
  inContainers: PropTypes.bool,
  removed: PropTypes.bool,
  extended: PropTypes.bool
};

export default FileField;
