import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import styles from "../../calendar.less";

const MonthHeader = props => {
  const { t } = useTranslation();
  return (
    <div className={styles.monthHeader}>
      {t(`calendar.month.weekDays.${moment(props.date).day()}`)}
    </div>
  );
};

export default MonthHeader;
