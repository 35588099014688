export default t => ({
  element: "bpmn:ServiceTask",
  service: "getMail",
  title: t("script.controlls.getMail.title"),
  helplink: "",
  class: "other",
  color: "#666B70",
  border: 2,
  icon: "anticon-icon communication-60",
  offset_x: 0,
  offset_y: -15,
  priority: 7,
  config: [
    {
      id: "commonSection",
      name: t("script.common.general"),
      type: "group"
    },
    {
      id: "name",
      name: t("script.common.title"),
      type: "text",
      readOnly: false,
      config: {
        defaultEmptyValue: t("script.controlls.getMail.title"),
        type: "text",
        valueType: "const",
        map: "input"
      }
    },
    {
      id: "description",
      name: t("script.common.description"),
      type: "text",
      readOnly: false,
      config: {
        type: "multiline",
        valueType: "const",
        map: "input"
      }
    },
    {
      id: "connectionsSection",
      name: t("script.controlls.getMail.connection"),
      type: "group"
    },
    {
      id: "connectionProtocol",
      name: t("script.controlls.getMail.protocol"),
      type: "dropdown",
      readOnly: false,
      config: {
        items: [
          {
            id: "imap",
            name: "IMAP",
            color: "E7E9ED"
          }
        ],
        defaultEmptyValue: ["imap"],
        map: "input"
      }
    },
    {
      id: "connectionType",
      name: t("script.controlls.getMail.connectionType"),
      type: "dropdown",
      readOnly: false,
      config: {
        items: [
          {
            id: "parameters",
            name: t("script.common.params"),
            color: "E7E9ED"
          },
          {
            id: "connection",
            name: t("script.common.connectionString"),
            color: "E7E9ED"
          }
        ],
        defaultEmptyValue: ["parameters"],
        map: "input"
      }
    },
    {
      id: "connectionHost",
      name: t("script.controlls.getMail.host"),
      type: "text",
      readOnly: false,
      config: {
        type: "text",
        theme: "script",
        placeholder: t("script.common.valueExpression"),
        map: "input"
      },
      visible: {
        connectionType: {
          $include: "parameters"
        }
      }
    },
    {
      id: "connectionPort",
      name: t("script.controlls.getMail.port"),
      type: "text",
      readOnly: false,
      config: {
        type: "text",
        theme: "script",
        placeholder: t("script.common.numberExpression"),
        map: "input"
      },
      visible: {
        connectionType: {
          $include: "parameters"
        }
      }
    },
    {
      id: "encryption",
      name: t("script.controlls.getMail.encryption"),
      type: "dropdown",
      readOnly: false,
      config: {
        items: [
          {
            id: "encrypted",
            name: t("script.controlls.getMail.encrypted"),
            color: "E7E9ED"
          },
          {
            id: "plain",
            name: t("script.controlls.getMail.plain"),
            color: "E7E9ED"
          }
        ],
        defaultEmptyValue: ["encrypted"]
      },
      visible: {
        connectionType: {
          $include: "parameters"
        }
      }
    },
    {
      id: "encriptionselfsign",
      name: t("script.controlls.getMail.encriptionselfsign"),
      type: "dropdown",
      readOnly: false,
      visible: false,
      config: {
        items: [
          {
            id: "yes",
            name: t("script.common.yes"),
            color: "E7E9ED"
          },
          {
            id: "no",
            name: t("script.common.no"),
            color: "E7E9ED"
          }
        ],
        defaultEmptyValue: ["no"]
      }
    },
    {
      id: "connectionString",
      name: t("script.common.connectionString"),
      type: "text",
      readOnly: false,
      config: {
        placeholder: t("script.common.valueExpression"),
        map: "input",
        type: "multiline",
        theme: "script"
      },
      visible: {
        connectionType: {
          $include: "connection"
        }
      }
    },
    {
      id: "login",
      name: t("script.controlls.getMail.login"),
      type: "text",
      readOnly: false,
      config: {
        type: "text",
        theme: "script",
        placeholder: t("script.common.valueExpression"),
        map: "input"
      },
      visible: {
        connectionType: {
          $include: "parameters"
        }
      }
    },
    {
      id: "password",
      name: t("script.controlls.getMail.password"),
      type: "text",
      readOnly: false,
      config: {
        type: "text",
        theme: "script",
        placeholder: t("script.common.valueExpression"),
        map: "input"
      },
      visible: {
        connectionType: {
          $include: "parameters"
        }
      }
    },
    {
      id: "accessToken",
      name: t("script.controlls.getMail.token"),
      type: "text",
      readOnly: false,
      config: {
        type: "text",
        theme: "script",
        placeholder: t("script.common.valueExpression"),
        map: "input"
      },
      visible: {
        connectionType: {
          $include: "parameters"
        }
      }
    },
    {
      id: "filtersSection",
      name: t("script.controlls.getMail.searchCriteria"),
      type: "group"
    },
    {
      id: "filterType",
      name: t("script.controlls.getMail.filterType"),
      type: "dropdown",
      readOnly: false,
      config: {
        items: [
          {
            id: "standart",
            name: t("script.controlls.getMail.standart"),
            color: "E7E9ED"
          },
          {
            id: "custom",
            name: t("script.controlls.getMail.custom"),
            color: "E7E9ED"
          }
        ],
        defaultEmptyValue: ["standart"],
        map: "input"
      }
    },
    {
      id: "mailFlag",
      name: t("script.controlls.getMail.flagTitle"),
      type: "radiobutton",
      readOnly: false,
      hint: "",
      config: {
        items: [
          {
            id: "ALL",
            name: t("script.controlls.getMail.flag.all"),
            color: "E7E9ED"
          },
          {
            id: "ANSWERED",
            name: t("script.controlls.getMail.flag.answered"),
            color: "E7E9ED"
          },
          {
            id: "DRAFT",
            name: t("script.controlls.getMail.flag.draft"),
            color: "E7E9ED"
          },
          {
            id: "DELETED",
            name: t("script.controlls.getMail.flag.deleted"),
            color: "E7E9ED"
          },
          {
            id: "NEW",
            name: t("script.controlls.getMail.flag.new"),
            color: "E7E9ED"
          },
          {
            id: "RECENT",
            name: t("script.controlls.getMail.flag.recent"),
            color: "E7E9ED"
          },
          {
            id: "UNSEEN",
            name: t("script.controlls.getMail.flag.unseen"),
            color: "E7E9ED"
          },
          {
            id: "UNFLAGGED",
            name: t("script.controlls.getMail.flag.unflagged"),
            color: "E7E9ED"
          }
        ],
        type: "select",
        defaultEmptyValue: "UNSEEN",
        valueType: "const",
        map: "input"
      },
      visible: {
        filterType: {
          $include: "standart"
        }
      },
      configOld: {
        type: "text",
        theme: "script",
        map: "input",
        placeholder: ""
      }
    },
    {
      id: "customFilters",
      name: t("script.controlls.getMail.filters"),
      type: "text",
      hint: t("script.controlls.getMail.filtersHint"),
      readOnly: false,
      config: {
        type: "multiline",
        placeholder: t("script.common.valueExpression"),
        map: "input",
        theme: "script"
      },
      visible: {
        filterType: {
          $include: "custom"
        }
      }
    },
    {
      id: "filterSentAfter",
      name: t("script.controlls.getMail.sentAfter"),
      type: "text",
      readOnly: false,
      hint: t("script.controlls.getMail.sentHint"),
      config: {
        placeholder: t("script.common.valueExpression"),
        map: "input",
        theme: "script"
      },
      visible: {
        filterType: {
          $include: "standart"
        }
      }
    },
    {
      id: "filterSentBefore",
      name: t("script.controlls.getMail.sentBefore"),
      type: "text",
      readOnly: false,
      hint: t("script.controlls.getMail.sentHint"),
      config: {
        placeholder: t("script.common.valueExpression"),
        map: "input",
        theme: "script"
      },
      visible: {
        filterType: {
          $include: "standart"
        }
      }
    },
    {
      id: "filterSender",
      name: t("script.controlls.getMail.sender"),
      hint: "",
      type: "text",
      readOnly: false,
      config: {
        placeholder: t("script.common.valueExpression"),
        map: "input",
        theme: "script"
      },
      visible: {
        filterType: {
          $include: "standart"
        }
      }
    },
    {
      id: "filterSubject",
      name: t("script.controlls.getMail.subject"),
      hint: t("script.controlls.getMail.subjectHint"),
      type: "text",
      readOnly: false,
      config: {
        placeholder: t("script.common.valueExpression"),
        map: "input",
        theme: "script"
      },
      visible: {
        filterType: {
          $include: "standart"
        }
      }
    },
    {
      id: "rulesSection",
      name: t("script.controlls.getMail.rules"),
      type: "group"
    },
    {
      id: "offset",
      name: t("script.controlls.getMail.offset"),
      hint: t("script.controlls.getMail.offsetHint"),
      type: "text",
      readOnly: false,
      config: {
        defaultEmptyValue: "0",
        type: "text",
        map: "input",
        theme: "script",
        placeholder: t("script.common.valueExpression")
      }
    },
    {
      id: "attachments",
      name: t("script.controlls.getMail.attachments"),
      type: "dropdown",
      readOnly: false,
      config: {
        items: [
          {
            id: "skip",
            name: t("script.controlls.getMail.skip"),
            color: "E7E9ED"
          },
          {
            id: "links",
            name: t("script.controlls.getMail.links"),
            color: "E7E9ED"
          },
          {
            id: "buffer",
            name: t("script.controlls.getMail.buffer"),
            color: "E7E9ED"
          }
        ],
        defaultEmptyValue: ["skip"],
        map: "input"
      }
    },
    {
      id: "markSeen",
      name: t("script.controlls.getMail.markSeen"),
      type: "dropdown",
      readOnly: false,
      config: {
        items: [
          {
            id: "yes",
            name: t("script.common.yes"),
            color: "E7E9ED"
          },
          {
            id: "no",
            name: t("script.common.no"),
            color: "E7E9ED"
          }
        ],
        defaultEmptyValue: ["yes"],
        map: "input"
      }
    },
    {
      id: "resultSection",
      name: t("script.common.result"),
      type: "group"
    },
    {
      id: "outputContent",
      name: t("script.controlls.getMail.saveMessage"),
      type: "text",
      readOnly: false,
      config: {
        type: "text",
        theme: "script",
        map: "output",
        placeholder: t("script.common.variableName")
      }
    },
    {
      id: "outputCount",
      name: t("script.controlls.getMail.saveCount"),
      type: "text",
      readOnly: false,
      config: {
        type: "text",
        theme: "script",
        map: "output",
        placeholder: t("script.common.variableName")
      }
    }
  ],
  exits: []
});
