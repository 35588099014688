import React from "react";
import PureRenderMixin from "react-addons-pure-render-mixin";
import PropTypes from "prop-types";
import LinkedItem from "../../common/UI/LinkedItem";

class ChangeFile extends React.PureComponent {
  render() {
    let oldFiles = this.props.change.get("oldValue");
    let newFiles = this.props.change.get("newValue");

    let newFilesList = newFiles
      .filter(file => {
        return !oldFiles.find(testFile => file.get("id") == testFile.get("id"));
      })
      .map((value, i) => {
        return (
          <LinkedItem
            key={i}
            item={{
              icon: "files-13",
              text: value.get("title")
            }}
            href={value.get("url")}
            linkProps={{
              target: "_blank",
              download: value.get("title")
            }}
          />
        );
      });

    let oldFilesList = oldFiles
      .filter(file => {
        return !newFiles.find(testFile => file.get("id") == testFile.get("id"));
      })
      .map((value, i) => {
        return (
          <LinkedItem
            key={i}
            item={{
              icon: "files-13",
              text: value.get("title")
            }}
            href={value.get("url")}
            linkProps={{
              target: "_blank",
              style: { cursor: "pointer" },
              download: value.get("title")
            }}
            removed
          />
        );
      });

    return (
      <ul className="object-list">
        {newFilesList}
        {oldFilesList}
      </ul>
    );
  }
}

ChangeFile.propTypes = {
  change: PropTypes.object.isRequired,
  isNewRecord: PropTypes.bool
};

export default ChangeFile;
