import * as React from "react";
import cn from "classnames";

import { DropTarget } from "react-dnd";
import { GRID } from "../../../configs/dndTargets";

import styles from "./grid.less";
import { createContext } from "react";

export const HandlerContext = createContext();

const dropTarget = DropTarget(
  GRID,
  {
    drop(props, monitor) {
      const {
        axisXField,
        axisYField,
        axisXkey: dropAxisXkey,
        axisYkey: dropAxisYkey
      } = props;

      const {
        axisXkey: dragAxisXkey,
        axisYkey: dragAxisYkey,
        recordId
      } = monitor.getItem();

      props.onDropCard(
        dragAxisXkey,
        dragAxisYkey,
        recordId,
        dropAxisYkey,
        dropAxisXkey,
        axisXField,
        axisYField
      );
    },
    canDrop(props) {
      return props.canDrop;
    }
  },
  function collect(connect, monitor) {
    return {
      isHovering: monitor.isOver(),
      connectDropTarget: connect.dropTarget()
    };
  }
);

const GridItem = props => {
  let onClickHandler = () => {
    const axisFields = {
      xField: props.axisXField,
      yField: props.axisYField
    };

    const axisKeys = {
      x: props.axisXkey,
      y: props.axisYkey
    };

    props.onCreateRecord(axisFields, axisKeys);
  };

  const {
    canCreate,
    canDrop,
    connectDropTarget,
    isHovering,
    className,
    style,
    children
  } = props;

  return connectDropTarget(
    <div
      className={cn(styles.gridItem, className, {
        [styles.gridItemHover]: isHovering && canDrop,
        [styles.gridItemCreate]: canCreate
      })}
      style={style}
    >
      {children ? (
        <HandlerContext.Provider
          value={{ onClickHandler, isHovering, canDrop }}
        >
          {children}
        </HandlerContext.Provider>
      ) : null}
    </div>
  );
};

export default dropTarget(GridItem);
