import React from "react";
import PureRenderMixin from "react-addons-pure-render-mixin";
import PropTypes from "prop-types";
import { Radio } from "antd";
import cn from "classnames";

import styles from "./changes.less";

const ChangeRadiobutton = props => {
  let itemsById = {};
  props.config.get("items").forEach(item => {
    itemsById[item.get("id")] = item.get("name");
  });

  let oldValue = null;
  let newValue = null;

  if (itemsById[props.change.get("oldValue")]) {
    oldValue = (
      <Radio
        className={cn(styles.removed, styles.radioItem, "readonly")}
        disabled={true} // need specify disabled to readonly
      >
        {itemsById[props.change.get("oldValue")]}
      </Radio>
    );
  }

  if (itemsById[props.change.get("newValue")]) {
    newValue = (
      <Radio
        className={cn(styles.radioItem, "readonly")}
        disabled={true} // need specify disabled to readonly
        checked={true}
      >
        {itemsById[props.change.get("newValue")]}
      </Radio>
    );
  }

  return (
    <div>
      {oldValue}
      {newValue}
    </div>
  );
};

ChangeRadiobutton.propTypes = {
  change: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  isNewRecord: PropTypes.bool
};

export default ChangeRadiobutton;
