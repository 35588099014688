import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Checkbox from "../../common/UI/Checkbox";

import styles from "./changes.less";

const ChangeCheckboxes = props => {
  const { t } = useTranslation();
  let itemsById = {};
  props.config.get("items").forEach(item => {
    itemsById[item.get("id")] = item.get("name");
  });

  let oldValues = props.change.get("oldValue").map(item => {
    let isInNewValues = props.change.get("newValue").find(testItem => {
      return item == testItem;
    });
    if (isInNewValues) {
      return null;
    }
    return itemsById[item] || t("record.history.unknown");
  });

  let newValues = props.change.get("newValue").map(item => {
    let isInOldValues = props.change.get("oldValue").find(testItem => {
      return item == testItem;
    });
    if (isInOldValues) {
      return null;
    }
    return itemsById[item] || t("record.history.unknown");
  });

  return (
    <div>
      {oldValues.map((item, i) => {
        if (!item) {
          return null;
        }
        return (
          <Checkbox key={i} readOnly title={item} className={styles.removed} />
        );
      })}
      {newValues.map((item, i) => {
        if (!item) {
          return null;
        }
        return <Checkbox key={i} readOnly checked title={item} />;
      })}
    </div>
  );
};

ChangeCheckboxes.propTypes = {
  change: PropTypes.object.isRequired,
  isNewRecord: PropTypes.bool
};

export default ChangeCheckboxes;
