import React from 'react';
import { Avatar } from 'antd';

import color from "../../../../utils/colorFromString";


const AvatarUser = ({ userName, className, size = "default", onClick, icon = null }) => {

    if (icon) {
        return (
            <Avatar
                size={size}
                className={className}
                onClick={onClick}
                icon={icon}
            />
        )
    } else {
        return (
            <Avatar
                style={{ background: color.colorUserIcon(userName), verticalAlign: 'middle', }}
                size={size}
                className={className}
                onClick={onClick}
            >
                {color.getShortName(userName)}
            </Avatar>
        );
    }

};

export default AvatarUser;