import React, { Component } from "react";
import moment from "moment";

export default class Timer extends Component {
  state = {};

  componentDidUpdate({ nextTime }) {
    if (nextTime !== this.props.nextTime) {
      this.startUpdateDuration(this.props.nextTime);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  componentDidMount() {
    this.startUpdateDuration();
  }

  startUpdateDuration(nextTime = this.props.nextTime) {
    if (!nextTime) {
      return;
    }

    const duration = Math.max(nextTime - Date.now(), 0);

    this.setState({
      duration
    });

    clearTimeout(this.timer);
    if (duration) {
      this.timer = setTimeout(
        this.startUpdateDuration.bind(this),
        Math.min(duration, 1000)
      );
    } else {
      const { onAlarm } = this.props;
      if (onAlarm) {
        onAlarm();
      }
    }
  }

  render() {
    const { duration } = this.state;
    const { format, className } = this.props;

    return (
      <span className={className ? className : ""}>
        {moment(duration).format(format || "m:ss")}
      </span>
    );
  }
}
