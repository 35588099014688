import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Switch } from 'antd';

import Icon from '../../../../common/UI/Icon';

import styles from "./chatSwitchSubscribe.less"
import ButtonTransparent from '../../../../common/UI/ButtonTransparent';

const ICON_MENTIOS = "icon multimedia-63";
const ICON_SUBSCRIBE = "icon multimedia-65";

const ChatSwitchSubscribe = ({ onChange, checked, t }) => {

    const [subscribeOptions, setSubscribeOptions] = useState({});

    const onClick = () => {
        onChange(!checked);
    };

    useEffect(() => {
        if (_.isUndefined(checked)) {
            setSubscribeOptions({ loaded: false });
        } else if (checked == true) {
            setSubscribeOptions({
                loaded: true, icon: ICON_SUBSCRIBE
            });
        } else if (checked == false) {
            setSubscribeOptions({
                loaded: true, icon: ICON_MENTIOS
            });
        }
    }, [checked]);

    if (subscribeOptions.loaded) {
        return (
            <ButtonTransparent onClick={onClick} className={styles.chatSwitchSubscribeContainer}>
                <Icon className={styles.chatSwitchSubscribeIcon} type={subscribeOptions.icon} />
                <span className={styles.chatSwitchSubscribeNotifications}>{t("record.chat.subscribe.notifications")}</span>
                <Switch checked={checked} />
            </ButtonTransparent>
        )
    } else {
        return null;
    }
};

export default ChatSwitchSubscribe;