import React from "react";
import cn from "classnames";
import _ from "lodash";
import { dragSource, dropTarget } from "../dndSourceTarget";

function Item({
  item,
  vertical,
  connectDragSource,
  connectDragPreview,
  connectDropTarget
}) {
  return _.flow(
    connectDragSource,
    connectDragPreview,
    connectDropTarget
  )(<li className={cn(vertical.item)}>{item}</li>);
}

export default _.flow(
  dragSource,
  dropTarget
)(Item);
