import React from "react";
import cn from "classnames";

import DateSetting from "./configComponents/Date";
import Color from "./configComponents/Color";
import SortField from "./configComponents/SortField";
import Split from "./configComponents/Split";
import SortType from "./configComponents/SortType";

import styles from "./config.less";

const Overlay = props => {
  return (
    <div
      className={cn(
        "ant-dropdown-menu ant-dropdown-menu-vertical",
        styles.overlay
      )}
    >
      <DateSetting
        name={"start"}
        settings={props.settings}
        fields={props.fields}
        handler={props.userSettingsHandler}
      />
      <DateSetting
        name={"end"}
        settings={props.settings}
        fields={props.fields}
        handler={props.userSettingsHandler}
      />
      <Color
        settings={props.settings}
        fields={props.fields}
        handler={props.userSettingsHandler}
      />
      <Split
        settings={props.settings}
        fields={props.fields}
        handler={props.userSettingsHandler}
        view={props.view}
      />
      <SortField
        settings={props.settings}
        fields={props.fields}
        handler={props.userSettingsHandler}
      />
      <SortType settings={props.settings} handler={props.userSettingsHandler} />
    </div>
  );
};

export default React.memo(Overlay);
