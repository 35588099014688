import _ from "lodash";
import React from "react";
import Immutable from "immutable";

import Lines from "./lines";
import Columns from "./columns";
import Bars from "./bars";
import Table from "./table";
import Number from "./number";
import Pie from "./pie";
import Radar from "./radar";

import * as CHART_TYPES from "../../../../../configs/reports/widget/chartTypes";
import sceneActions from "../../../../../actions/sceneActions";
import userSettingsActions from "../../../../../actions/userSettingsActions";
import filtersUtils from "../../../../../utils/filters";

const charts = {
  [CHART_TYPES.LINES]: Lines,
  [CHART_TYPES.COLUMNS]: Columns,
  [CHART_TYPES.TABLE]: Table,
  [CHART_TYPES.BARS]: Bars,
  [CHART_TYPES.NUMBER]: Number,
  [CHART_TYPES.PIE]: Pie,
  [CHART_TYPES.RADAR]: Radar
};

function getDefaultChartType(widget) {
  const axis = widget.get("axis");

  if (axis) {
    return CHART_TYPES.COLUMNS;
  } else {
    return CHART_TYPES.NUMBER;
  }
}

const WidgetChart = props => {
  const { widget, chartData } = props;

  const onPieceClick = async data => {
    const { catalog, scene, viewId } = props;
    const catalogId = catalog.get("id");

    const widgetFilters = widget && widget.getIn(["recordsFilter", "filters"]);

    let filters = data && data.get("filters");
    filters = filtersUtils.mergeFilters(widgetFilters, filters);

    data = data.set("filters", filters);
    data = Immutable.fromJS(data);

    await userSettingsActions.setOption({
      catalogId: catalogId,
      viewMode: undefined,
      option: "defaultViewMode",
      value: Immutable.fromJS("table")
    });

    const callbacks = {
      onCreate: ({ sceneId }) => {
        sceneActions.copyViews(scene.get("sceneId"), sceneId);
      }
    };

    sceneActions.openCatalog({ catalogId, viewId }, data, callbacks);
  };

  const id = widget.get("id");
  const chartType = widget.getIn(["chartType"]) || getDefaultChartType(widget);
  const Chart = charts[chartType];
  return Chart ? (
    <Chart onPieceClick={onPieceClick} {...props} />
  ) : (
    <div>
      Widget "{id}" chart type "{chartType}" data:{" "}
      {chartData && JSON.stringify(chartData)}
    </div>
  );
};

export default WidgetChart;
