import React from "react";
import { Row } from "antd";
import PropTypes from "prop-types";
import cn from "classnames";
import { withTranslation } from "react-i18next";

import Icon from '../../../common/UI/Icon';
import sceneActions from "../../../../actions/sceneActions";
import { connect } from "../../../StateProvider";
import PRIVILEGE_CODES from "../../../../configs/privilegeCodes";
import RESOURCE_TYPES from "../../../../configs/resourceTypes";
import { checkAccessOnObject } from "../../../../utils/rights";
import FIELD_TYPES from "../../../../configs/fieldTypes";
import LinkedRecordCreate from "../../LinkedRecordCreate";
import ButtonTransparent from "../../../common/UI/ButtonTransparent";
import apiActions from "../../../../actions/apiActions";

import styles from "./linkedData.less";

class LinkedCatalogHeader extends React.PureComponent {
  state = {
    hoverStatus: false
  };

  openCatalog = () => {
    const { record, catalog, linkedCatalog, catalogInfo, sceneId } = this.props;
    const catalogId = catalogInfo.get("id");

    const linkedField =
      linkedCatalog &&
      linkedCatalog
        .get("fields")
        .find(field => field.get("id") === catalogInfo.get("fieldId"));
    const fieldType = linkedField && linkedField.get("type");

    let filters;
    if (fieldType === FIELD_TYPES.USER) {
      filters = {
        [catalogInfo.get("fieldId")]: [
          {
            value: [
              {
                id: record.get("id"),
                title: record.get("title"),
                removable: false
              }
            ],
            readOnly: true
          }
        ]
      };
    } else if (fieldType === FIELD_TYPES.OBJECT) {
      filters = {
        [catalogInfo.get("fieldId")]: [
          {
            value: [
              {
                catalogIcon: catalog.get("icon"),
                catalogId: catalog.get("id"),
                catalogTitle: catalog.get("name"),
                recordId: record.get("id"),
                recordTitle: record.get("title"),
                sectionId: catalog.get("sectionId"),
                isRemoved: false
              }
            ],
            readOnly: true
          }
        ]
      };
    }
    const data = { filters };

    sceneActions.openCatalog({ catalogId, parentSceneId: sceneId }, data, {
      onCreateRecord: this.onCreate
    });
    // передать в качестве третьего аргумента коллбек
  };
  onCreate = () => {
    const { sceneId, loadLinkedData } = this.props;
    loadLinkedData && loadLinkedData(sceneId);
  };
  setHoverStatus = (e, status) => {
    this.setState({ hoverStatus: status });
  };

  render() {
    const {
      catalogId,
      sceneId,
      catalog,
      record,
      catalogInfo,
      loadLinkedData,
      onClickHeader,
      t
    } = this.props;

    /* доступ на создание записи */
    const isAccessToCreateRecord = checkAccessOnObject(
      RESOURCE_TYPES.CATALOG,
      catalogInfo,
      PRIVILEGE_CODES.CREATE
    );

    /* доступ на просмотр связанного каталога */
    const isAccessToOpenCatalog = checkAccessOnObject(
      RESOURCE_TYPES.CATALOG,
      catalogInfo,
      PRIVILEGE_CODES.AVAILABLE
    );

    return (
      <Row
        type="flex"
        align="middle"
        className={styles.sectionHeader}
        onMouseEnter={e => this.setHoverStatus(e, true)}
        onMouseLeave={e => this.setHoverStatus(e, false)}
      >
        <div onClick={onClickHeader} className={styles.sectionToggle}>
          <Icon
            className={styles.headerIcon}
            type={"icon " + catalogInfo.get("icon")}
          />
          <span className={styles.headerText}>
            {catalogInfo.get("title")}:{" "}
          </span>
          <span className={styles.subHeaderText}>
            {catalogInfo.get("fieldName")}
          </span>
          {catalogInfo.get("recordsTotal") ? (
            <span className={styles.recordsTotal}>
              {catalogInfo.get("recordsTotal")}
            </span>
          ) : null}
        </div>

        <ButtonTransparent
          title={t(
            "record.linkedData.linkedCatalogOpen"
          )} /* "Раскрыть каталог" */
          onClick={isAccessToOpenCatalog && this.openCatalog}
          className={styles.linkedCatalogOpenWrapper}
        >
          <Icon
            className={styles.linkedCatalogOpenIcon}
            type={"icon programing-39"}
          />
          {/* {trs(
            "record.linkedData.linkedCatalogOpen"
          )} */}
        </ButtonTransparent>

        {isAccessToCreateRecord ? (
          <LinkedRecordCreate
            fromCatalogId={catalogId}
            fromRecordId={record.get("id")}
            catalogId={this.props.catalogInfo.get("id")}
            parentSceneId={sceneId}
            values={{
              [catalogInfo.get("fieldId")]: [
                {
                  catalogId: catalogId,
                  catalogTitle: catalog.get("title"),
                  catalogIcon: catalog.get("icon"),
                  recordId: record.get("id"),
                  recordTitle: record.get("title"),
                  recordValues: record.get("values"),
                  isRemoved: false
                }
              ]
            }}
            linkProps={{
              children: (
                <span className={styles.linkedRecordWrapper}>
                  <span className="anticon-icon interface-72" />
                  {/* {trs("record.linkedData.create")} */}
                </span>
              ),
              className: cn("ant-btn", styles.linkLikeButton),
              onMouseOver: e => {
                e.stopPropagation();
                if (e.currentTarget == e.target) {
                  this.setHoverStatus(e, false);
                }
              },
              onMouseOut: e => {
                e.stopPropagation();
                this.setHoverStatus(e, true);
              }
            }}
            onCreate={loadLinkedData}
          />
        ) : null}
      </Row>
    );
  }
}

LinkedCatalogHeader.propTypes = {
  catalogInfo: PropTypes.object.isRequired
};

/* connect catalog from catalogs */
export default withTranslation()(
  connect(
    LinkedCatalogHeader,
    {
      catalogs: ["catalogs"]
    },
    (props, { catalogs }) => {
      const { catalogId, catalogInfo } = props;
      const catalog = catalogs.get(catalogId); // parent catalog
      const linkedCatalog = catalogs.get(catalogInfo.get("id"));
      return {
        ...props,
        catalog,
        linkedCatalog
      };
    }
  )
);
