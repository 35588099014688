import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import routes from "../../../routes";
import NavLink from "../../common/router/Link";
import Board from "./Board";

import styles from "./header.less";

class HeaderBoard extends React.PureComponent {
  getName = () => {
    let name = this.props.board.get("name");
    if (!name) {
      if (this.props.board.get("viewId")) {
        name = this.props.t("reports.boards.forView");
      } else {
        name = this.props.t("reports.boards.forCatalog");
      }
    }
    return name;
  };

  render() {
    const { board, viewId } = this.props;
    let name = this.getName();
    return (
      <NavLink
        route={routes.board}
        params={{ boardId: board.get("id"), viewId: viewId || 0 }}
        render={props => {
          return (
            <Link to={props.link} className={styles.listItemLink}>
              <Board
                readOnly={this.props.readOnly}
                boardId={board.get("id")}
                isActive={props.isActive}
                opportunities={this.props.opportunities}
                onClick={this.props.onClick}
              >
                <span className={styles.listItemName}>{name}</span>
              </Board>
            </Link>
          );
        }}
      />
    );
  }
}

export default withTranslation()(HeaderBoard);
