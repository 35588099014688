import React from "react";
import { Modal, Button, Row, Input, Radio } from "antd";
import PropTypes from "prop-types";

import { withTranslation } from "react-i18next";
import ButtonClose from "../common/UI/ButtonClose";

import styles from "./viewInputModal.less";

const RadioGroup = Radio.Group;

class ViewInputModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      name: this.props.name,
      originName: this.props.originName,
      rights: Number(this.props.rights || false)
    };
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  isValid() {
    const isForRights = Boolean(+this.state.rights);

    if (isForRights) {
      return !!this.state.originName;
    } else {
      return !!this.state.name;
    }
  }

  onSave = e => {
    e.preventDefault();
    if (!this.isValid()) {
      return;
    }

    const isForRights = Boolean(+this.state.rights);

    this.props.onOk({
      name: this.state.name,
      originName: isForRights ? this.state.originName : this.state.name,
      forRights: Boolean(+this.state.rights)
    });
  };

  componentDidMount() {
    setTimeout(() => this.inputRef.current && this.inputRef.current.focus(), 0);
  }

  onSelectRights = e => {
    this.setState({ rights: e.target.value });
  };

  render() {
    let saveButton = null;
    let modalInput = null;
    const { t } = this.props;
    if (this.state.rights === 1) {
      if (this.props.isNew) {
        saveButton = t("views.modal.createViewAndSaveAccess");
      } else {
        saveButton = t("views.modal.saveViewAndSaveAccess");
      }

      modalInput = (
        <div className={styles.labelsWrapper}>
          <label className={styles.label}>
            <span className={styles.labelText}>{t("rights.originName")}</span>
            &nbsp;
            <span className={styles.requiredFieldAsterisk}>*</span>
            <Input
              value={this.state.originName}
              name="originName"
              required="required"
              onChange={this.onChange}
            />
          </label>
          <label className={styles.label}>
            <span className={styles.labelText}>
              {t("rights.name")} {t("views.modal.labelNameHint")}
            </span>
            <Input
              value={this.state.name}
              name="name"
              ref={node => (this.input = node)}
              onChange={this.onChange}
            />
          </label>
        </div>
      );
    } else {
      if (this.props.isNew) {
        saveButton = t("buttons.create");
      } else {
        saveButton = t("buttons.save");
      }

      modalInput = (
        <div className={styles.labelsWrapper}>
          <label className={styles.label}>
            <span className={styles.labelText}>
              {t("views.modal.inputPlaceholder")}
            </span>
            &nbsp;
            <span className={styles.requiredFieldAsterisk}>*</span>
            <Input
              name="name"
              required
              value={this.state.name}
              ref={this.inputRef}
              onChange={this.onChange}
            />
          </label>
        </div>
      );
    }

    return (
      <Modal
        open={true}
        maskClosable={false}
        closable={false}
        footer={[
          <Button
            key="submit"
            type="primary"
            disabled={!this.isValid()}
            onClick={this.onSave}
          >
            {saveButton}
          </Button>,
          <Button key="back" type="default" onClick={this.props.onCancel}>
            {t("buttons.cancel")}
          </Button>
        ]}
        width="60%"
      >
        <Row
          type="flex"
          justify="space-between"
          align="top"
          className={styles.modalHeader}
        >
          <div>
            <h2>
              {this.props.headerText}
              <br />
            </h2>
            <span className={styles.headerSubTitle}>
              {t("views.modal.headerTextSub")}
            </span>
          </div>
          <ButtonClose large shiftRight onClick={this.props.onCancel} />
        </Row>
        <div className={styles.modalBody}>
          <form onSubmit={this.onSave}>
            {this.props.disabledChangeType ? null : (
              <div>
                <span className={styles.labelText}>
                  {t("views.modal.labelForType")}
                </span>
                <br />
                <RadioGroup
                  onChange={this.onSelectRights}
                  value={this.state.rights}
                >
                  <Radio className={styles.radioButton} value={0}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t("views.modal.labelForPrivateView")
                      }}
                    />
                  </Radio>
                  <Radio className={styles.radioButton} value={1}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t("views.modal.labelForRigthsView")
                      }}
                    />
                  </Radio>
                </RadioGroup>
              </div>
            )}
            {modalInput}
          </form>
        </div>
      </Modal>
    );
  }
}

ViewInputModal.propTypes = {
  headerText: PropTypes.string,
  value: PropTypes.string,
  rights: PropTypes.bool,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  okText: PropTypes.string,
  disabledChangeType: PropTypes.bool
};

export default withTranslation()(ViewInputModal);
