import * as AXIS_TYPES from "../../../../../../configs/reports/widget/axisTypes";
import * as AXIS_SUB_TYPES from "../../../../../../configs/reports/widget/axisSubTypes";
import FIELD_TYPES from "../../../../../../configs/fieldTypes";

export default function getAxisDirection(data, axisConfig, fields) {
  switch (axisConfig && axisConfig.get("type")) {
    case AXIS_TYPES.FIELD:
      const field = fields.find(f => f.get("id") === axisConfig.get("value"));
      const fieldType = field && field.get("type");

      switch (fieldType) {
        case FIELD_TYPES.DATE:
          return [...data].reverse();
      }
  }

  return data;
}
