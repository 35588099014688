import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import cn from "classnames";

import styles from "./changes.less";

const ChangeDirection = props => {
  let strikeRemoved = props.strikeRemoved !== false;

  return (
    <div className={props.containerClass}>
      {_.isEmpty(props.toObj) && !_.isEmpty(props.fromObj) ? (
        <span className={styles.removed}>{props.fromObj}</span>
      ) : (
        props.fromObj
      )}

      {!strikeRemoved ||
      (!_.isEmpty(props.toObj) && !_.isEmpty(props.fromObj)) ? (
        <span
          className={cn(
            "anticon-icon arrows-linear-big-2-01 " + styles.changeDirection,
            props.arrowCN
          )}
        />
      ) : null}
      {props.toObj}
    </div>
  );
};

ChangeDirection.propTypes = {
  fromObj: PropTypes.object,
  toObj: PropTypes.object.isRequired,
  containerClass: PropTypes.string,
  arrowCN: PropTypes.string
};

export default ChangeDirection;
