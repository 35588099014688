import React, { Fragment } from "react";
import cn from "classnames";
import Immutable from "immutable";
import PropTypes from "prop-types";
import _ from "lodash";
import { Row } from "antd";
import { withTranslation } from "react-i18next";

import FIELD_TYPES from "../../../../../configs/fieldTypes";
import userSettingsActions from "../../../../../actions/userSettingsActions";

import SelectAxisX from "./Select/AxisX";
import SelectAxisY from "./Select/AxisY";
import SelectColor from "./Select/Color";
import SelectSort from "./Select/Sort";
import SelectSortDirection from "./Select/SortDirection";

import styles from "../config.less";

class ConfigOverlay extends React.Component {
  static propTypes = {
    catalog: PropTypes.object.isRequired,
    settings: PropTypes.object,
    viewMode: PropTypes.string
  };

  // UNSAFE_componentWillReceiveProps(nextProps) {}

  onChange(key, value) {
    const { viewMode } = this.props;
    userSettingsActions.setOption({
      catalogId: this.props.catalog.get("id"),
      viewMode,
      option: key,
      value
    });
  }

  onSortChange(sortField, sortType) {
    const { viewMode } = this.props;
    const catalogId = this.props.catalog.get("id");

    sortField = sortField && sortField.get("value");
    userSettingsActions.setSortingRecords(
      { catalogId, viewMode },
      { sortField, sortType }
    );
  }

  onSortFieldChange = value => {
    const { settings } = this.props;
    const sortType =
      (settings && settings.getIn(["sortingRecords", "sortType"])) || -1;
    this.onSortChange(value, sortType);
  };

  onSortTypeChange = value => {
    const { settings } = this.props;
    let sortField = settings && settings.getIn(["sortingRecords", "sortField"]);
    sortField =
      sortField && Immutable.fromJS({ type: "field", value: sortField });
    this.onSortChange(sortField, value);
  };

  render() {
    const { catalog, settings, viewMode, t } = this.props;
    const fields = catalog && catalog.get("fields");
    const config = settings && settings.getIn(["options"]);

    const axisX = config && config.getIn(["axisX", "value"]);
    const axisY = config && config.getIn(["axisY", "value"]);
    const color = config && config.getIn(["color", "value"]);

    const sortingRecords = settings && settings.getIn(["sortingRecords"]);
    let sortField = sortingRecords && sortingRecords.get("sortField");
    sortField =
      sortField && Immutable.fromJS({ type: "field", value: sortField });
    const sortType = (sortingRecords && sortingRecords.get("sortType")) || -1;

    const hasColor =
      fields &&
      fields.find(field => field.get("type") === FIELD_TYPES.DROPDOWN);

    return (
      <div
        className={cn(
          "ant-dropdown-menu ant-dropdown-menu-vertical",
          styles.overlay
        )}
      >
        {viewMode === "cards" ? (
          <Fragment>
            <Row className={styles.controlRow}>
              <label className={styles.controlLabel}>
                {t("records.cards.config.axisX.label")}
              </label>
              <SelectAxisX
                fields={fields}
                value={axisX}
                onChange={value => this.onChange("axisX", value)}
              />
            </Row>
            <Row className={styles.controlRow}>
              <label className={styles.controlLabel}>
                {t("records.cards.config.axisY.label")}
              </label>
              <SelectAxisY
                fields={fields}
                value={axisY}
                onChange={value => this.onChange("axisY", value)}
              />
            </Row>
          </Fragment>
        ) : null}

        {hasColor ? (
          <Row className={styles.controlRow}>
            <label className={styles.controlLabel}>
              {t("records.cards.config.color.label")}
            </label>
            <SelectColor
              fields={fields}
              value={color}
              onChange={value => this.onChange("color", value)}
            />
          </Row>
        ) : null}

        <Row className={styles.controlRow}>
          <label className={styles.controlLabel}>
            {t("records.cards.config.sort.label")}
          </label>
          <SelectSort
            fields={fields}
            value={sortField}
            onChange={this.onSortFieldChange}
          />
          <SelectSortDirection
            catalog={catalog}
            value={sortType}
            style={{ marginTop: "4px" }}
            onChange={this.onSortTypeChange}
          />
        </Row>
      </div>
    );
  }
}

export default withTranslation()(ConfigOverlay);
