import React from "react";
import moment from "moment";
import _ from "lodash";
import PropTypes from "prop-types";
import cn from "classnames";
import { DatePicker, TimePicker, Input } from "antd";
import { withTranslation } from "react-i18next";

import styles from "./controls.less";

const log = require("debug")("CRM:Component:Record:DateField");
const API_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";
// const DATA_PICKER_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const MOMENT_DATE_FORMAT = "DD.MM.YYYY";
const MOMENT_TIME_FORMAT = "HH:mm";

class DateControl extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      date: null
    };
  }

  onChange = (picker, val) => {
    if (picker === "time" && val === null) {
      // если у нас уже есть выбранная дата и мы хотим обнулить только время
      val = 
        this.state.date &&
        moment(this.state.date).format("YYYY-MM-DD");
    }
    // нужно для сохранения выбранной даты, если меняем время или обнуляем его
    if (picker === "date") {
      this.setState({date: val});
    }

    log("Changed field %s value", this.props.fieldId, val);
    val = val !== null ? moment(val).format(API_FORMAT) : val;
    this.props.onChange && this.props.onChange(val);
    this.props.onEndEditing && this.props.onEndEditing(val);
  };

  selectTime = (value) => {
    const val = moment(value).format(API_FORMAT);
    this.props.onChange && this.props.onChange(val);
    this.props.onEndEditing && this.props.onEndEditing(val);
  }

  render() {
    const withTime = this.props.config.get("time");
    const { value, editable } = this.props;
    let momentValue = moment(value || "");

    const formattedDateValue = momentValue.isValid()
      ? momentValue.format("L")
      : value;

    const dateComponent = !editable ? (
      <Input
        readOnly={true}
        value={formattedDateValue}
        className={styles.datePickerReadOnly}
      />
    ) : (
      <DatePicker
        autoFocus={this.props.autoFocus}
        value={value ? moment(value) : null}
        format={MOMENT_DATE_FORMAT}
        className={styles.datePicker}
        onChange={val => this.onChange("date", val)}
        placeholder={this.props.t("fieldTypes.date.name")}
      />
    );

    let timeComponent;
    if (withTime) {
      const formattedTimeValue = momentValue.isValid()
        ? momentValue.format("LTS")
        : value;
      timeComponent = !editable ? (
        <Input
          readOnly={true}
          value={formattedTimeValue}
          className={styles.timePickerReadOnly}
        />
      ) : (
        <TimePicker
          hideDisabledOptions={true}
          value={value ? moment(value) : null}
          className={styles.timePicker}
          popupClassName={styles.timePickerPopup}
          format={MOMENT_TIME_FORMAT}
          onChange={val => this.onChange("time", val)}
          placeholder={this.props.t("fieldTypes.time.name")}
          onSelect={this.selectTime}
        />
      );
    }

    let pasteTime = moment(value, API_FORMAT).fromNow();
    // hide pastTime for current day if without time
    if (!withTime && formattedDateValue === moment().format("L")) {
      pasteTime = "";
    }

    return (
      <div className={styles.dateContainer}>
        {dateComponent}
        {timeComponent}
        {value && (
          <span
            className={cn(styles.oneLine, styles.subInfo)}
            title={pasteTime}
          >
            {pasteTime}
          </span>
        )}
      </div>
    );
  }
}

DateControl.propTypes = {
  value: PropTypes.string,
  error: PropTypes.string,
  config: PropTypes.object,
  onChange: PropTypes.func,
  onEndEditing: PropTypes.func,
  editable: PropTypes.bool
};

export default withTranslation()(DateControl);
