import _ from "lodash";
import React, { useEffect, useState } from "react";
import queryString from "query-string";
import qs from "qs";
import { message } from "antd";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { SERVER_ERROR } from "../../helpers/const";
import routes from "../../../../routes";
import { connect } from "../../../StateProvider";
import apiActions from "../../../../actions/apiActions";
import LoginForm from "./LoginForm";

import styles from "../../styles.less";

const HttpStatusCode = {
  Unauthorized: 401,
  BadRequest: 400,
  TooManyRequests: 429
};

const LoginFormPage = props => {
  const { t } = useTranslation();

  const authUser = (username, password) => {
    apiActions
      .login(username, password)
      .then(response => {
        // Here 3 possible answers
        // Unauthorized - ask to provide correct credentails
        // Login - redirect to default catalog or back url
        // half-login, need to set password - redirect to setPass form
        // If token returned, redirect ro setPass
        if (!response) return;

        // UPD: if url has user login and password in query string
        // we must authorize him through auth forms
        // and returned to the back url

        if (response.body && response.body.redirect) {
          window.location.href = "/#/auth/setPass?token=" + response.body.token;
        } else {
          let search = queryString.parse(props.location.search) || {};
          let backUrl;
          if (search.back_url) {
            backUrl = decodeURIComponent(search.back_url);
          }
          window.location.href = backUrl ? backUrl : "/";
        }
      })
      .catch(err => {
        const status = err.status;
        if (status === HttpStatusCode.Unauthorized) {
          message.error(t("auth.errors.invalidCredentials"));
        } else if (status === HttpStatusCode.TooManyRequests) {
          message.error(t("auth.errors.tooManyRequests"));
        } else {
          message.error(t("auth.errors.somethingWrong"));
        }
      });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(props.location.search);
    const login = urlParams.get("login");
    const password = urlParams.get("password");

    if (login && password) {
      authUser(login, password);
    } else {
      apiActions.getCompanies();
    }
  }, []);

  return (
    <div className={cn(styles.formContainer, styles.formContainerLogin)}>
      {props.companies &&
        props.companies.size && (
          <Link
            to={routes.companies.path}
            className={cn(
              styles.sessionItem,
              styles.sessionEnter,
              styles.loginEnter
            )}
          >
            {t("auth.toCompanyList")}
          </Link>
        )}
      <div className={styles.formInner}>
        <LoginForm authUser={authUser} />
      </div>
    </div>
  );
};

export default connect(
  LoginFormPage,
  { companies: ["companies"] }
);
