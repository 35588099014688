import React, { Component } from "react";
import tinycolor from "tinycolor2";
import _ from "lodash";
import PropTypes from "prop-types";
import cn from "classnames";
import { Tag } from "antd";

import styles from "./category.less";

export default class TagWithColor extends Component {
  static propTypes = {
    checked: PropTypes.bool,
    readOnly: PropTypes.bool,
    alwaysColorize: PropTypes.bool,
    backgroundColor: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  };
  render() {
    const {
      checked,
      readOnly,
      onClick,
      className,
      title,
      alwaysColorize
    } = this.props;

    let backgroundColor = this.props.backgroundColor;
    let color = tinycolor(backgroundColor)
      .darken(65)
      .toString();
    let borderColor = "rgba(0,0,0,0.05)";
    if (backgroundColor.toLowerCase() == "#ffffff") {
      backgroundColor = "#f8f8f8";
      color = "#333";
      borderColor = "rgba(0,0,0,0.1)";
    }

    return (
      <Tag
        onClick={onClick}
        style={
          checked || alwaysColorize
            ? {
                backgroundColor: backgroundColor,
                border: "1px solid",
                borderColor: borderColor,
                color: color
              }
            : { backgroundColor: "transparent" }
        }
        className={cn(className, styles.category, { readonly: readOnly })}
        checked={checked}
      >
        {title}
      </Tag>
    );
  }
}
