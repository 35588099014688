// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OverlayDropdown__input___6PoTf {\n  background-color: white;\n  width: auto;\n}\n.OverlayDropdown__input___6PoTf:focus {\n  outline: none;\n  box-shadow: none;\n}\n.OverlayDropdown__input___6PoTf::-webkit-input-placeholder {\n  color: #ced1d6;\n}\n.OverlayDropdown__itemSearch___CltLz {\n  padding: 10px;\n  background-color: white !important;\n}\n.OverlayDropdown__menu___\\+2CZC {\n  overflow-y: auto;\n  overflow-x: hidden;\n  max-height: 80vh;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/menu/AbstractMenu/OverlayDropdown/OverlayDropdown.less","webpack://./src/styles/helpers/inputs.less"],"names":[],"mappings":"AAEA;ECDE,uBAAA;EDGA,WAAA;AADF;ACDE;EACE,aAAA;EACA,gBAAA;ADGJ;ACDE;EACE,cAAA;ADGJ;AAHA;EACE,aAAA;EACA,kCAAA;AAKF;AAFA;EACE,gBAAA;EACA,kBAAA;EACA,gBAAA;AAIF","sourcesContent":["@import (reference) \"../../../../../styles/helpers/index.less\";\n\n.input {\n  .input-search;\n  width: auto;\n}\n\n.itemSearch {\n  padding: @PADDING_BASE;\n  background-color: white !important;\n}\n\n.menu {\n  overflow-y: auto;\n  overflow-x: hidden;\n  max-height: 80vh;\n}\n",".input-search {\n  background-color: white;\n  &:focus {\n    outline: none;\n    box-shadow: none;\n  }\n  &::-webkit-input-placeholder {\n    color: @TEXT_PLACEHOLDER;\n  }\n}\n\n.input-transparent {\n  /*padding-left: 0;*/\n  background-color: transparent !important;\n  border-color: transparent !important;\n  &:hover,\n  &:focus {\n    box-shadow: none;\n    border-color: transparent !important;\n  }\n}\n\n.input-readonly {\n  .input-transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "OverlayDropdown__input___6PoTf",
	"itemSearch": "OverlayDropdown__itemSearch___CltLz",
	"menu": "OverlayDropdown__menu___+2CZC"
};
export default ___CSS_LOADER_EXPORT___;
