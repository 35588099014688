import React from "react";
import Draggable from "react-draggable";

import AxisFieldValue from "../Helpers/AxisFieldValue";

import styles from "./grid.less";

export default class GridFixedColumn extends React.PureComponent {

  onDrag = (event, { deltaY }) => {
    this.props.onRowResize(this.props.rowIndex, deltaY);
  };

  onStop = () => {
    this.props.onRowResizeFinished(this.props.rowIndex);
  };

  render() {
    const { field, value, count } = this.props;

    return (
      <div className={styles.gridHeader}>
        <AxisFieldValue field={field} value={value} />
        {count && count.size && <span className={styles.badgeCountRecords}>{count.size}</span>}
        <Draggable
          axis="y"
          defaultClassName={styles.gridRowDragHandle}
          defaultClassNameDragging={styles.gridRowDragHandleActive}
          onDrag={this.onDrag}
          onStop={this.onStop}
          position={{ y: 0 }}
          zIndex={999}
        >
          <span className={styles.gridHeaderDragHandleIcon} />
        </Draggable>
      </div>

    );
  }
}
