import Immutable from "immutable";
import _ from "lodash";
import guid from "guid";

import apiActions from "../actions/apiActions";
import FIELD_TYPES from "../configs/fieldTypes";

const systemCatalogs = {
    USERS: "3"
};

export default {

    maxLimit: 50,
    currentLoading: null,

    loadMessages(params, firstLoad = false, limit = this.maxLimit, isGetNewMessage = false) {
        const { catalogId, recordId } = params;
        const chatExists = this.getIn(["records", catalogId, recordId, "chat"]);

        if (!chatExists) {
            this.preparingToLoadingMessage({ catalogId, recordId });
        };

        this.setFirstLoadFlag({ catalogId, recordId }, firstLoad);
        this.setFlagisGetNewMessage({ catalogId, recordId }, isGetNewMessage);
        const chat = this.getIn(["records", catalogId, recordId, "chat"]);
        if (chat && (!chat.get("allLoadedMessages") || isGetNewMessage)) {
            let query = {
                limit
            };
            let lastId = chat.get("lastId");
            if (lastId && !firstLoad && !isGetNewMessage) {
                query["from"] = lastId;
            };
            if (
                this.currentLoading &&
                this.currentLoading.catalogId === catalogId &&
                this.currentLoading.recordId === recordId &&
                this.currentLoading.lastId === lastId
            ) {
                return true;
            } else {
                this.currentLoading = {
                    catalogId,
                    recordId,
                    lastId
                };
            };
            apiActions.getMessages({ catalogId, recordId }, query);
            return !chat.get("allLoaded");
        } else {
            return false;
        };
    },
    mergeUsersWithLinkedCatalog(params) {
        const { catalogId, recordId } = params;
        const fieldsLinkedCatalog = this.getIn(["catalogs", catalogId, "fields"])
            .filter((field) => field.get("type") === FIELD_TYPES.OBJECT);
        const fieldsIdsLinkedCatalog = fieldsLinkedCatalog.map((field) => field.get("id"));

        const currentRecord = this.getIn(["records", catalogId, recordId]) || Immutable.Map();
        const currentCatalogIcon = this.getIn(["catalogs", catalogId, "icon"]);
        const mentionCurrentRecord = Immutable.Map({
            id: `${currentRecord.get("catalogId")}:${currentRecord.get("id")}`,
            display: currentRecord.get("title"),
            icon: currentCatalogIcon
        });

        let recordValues = this.getIn(["records", catalogId, recordId, "values"]);
        recordValues = recordValues
            .filter((value, fieldId) => fieldsIdsLinkedCatalog.includes(fieldId))
            .reduce((acc, value, fieldId) => {
                const field = fieldsLinkedCatalog.find((field) => field.get("id") === fieldId);
                const fieldName = field && field.get("name");
                acc[fieldName] = value;
                return acc;
            }, {});

        const catalogEmployee = this.get("catalogs").find((catalog) => catalog.get("id") == systemCatalogs.USERS);

        let mergeValues = [];
        _.forEach(recordValues, (values, fieldName) => {
            values = values.map((value) => {
                let catalogId = value.get("catalogId");
                if (catalogEmployee && catalogId === catalogEmployee.get("id")) {
                    catalogId = "$users"
                };
                return {
                    id: `${catalogId}:${value.get("recordId")}`,
                    display: value.get("recordTitle"),
                    icon: value.get("catalogIcon"),
                    subText: fieldName
                };
            }).toJS();
            mergeValues = mergeValues.concat(values);
        });

        let mentions;

        const usersForMention = this.getIn(["users"]);

        if (!_.isEmpty(usersForMention)) {
            mentions = usersForMention.map(user => ({
                id: `$users:${user.get("id")}`,
                display: user.get("title")
            })).toJS();
        } else {
            mentions = [];
        }

        mentions = mergeValues.concat(mentions);
        // добавляем текушую запись, как упоминание...
        mentions.unshift(mentionCurrentRecord);
        mentions = _.uniqBy(mentions, "id");
        this.setIn(["records", catalogId, recordId, "chatOptions", "mentions"], Immutable.fromJS(mentions));
    },
    getMessages(params, query) {
        const { catalogId, recordId } = params;
        let chat = this.getIn(["records", catalogId, recordId, "chat"]);
        if (chat) {
            chat = chat.set("loadingMessages", true);
            this.setIn(["records", catalogId, recordId, "chat"], chat);
        };
        this.changed("Сообщения для записи");
    },
    getMessagesCompleted(body, params, data, query, response, actionParams) {
        const { catalogId, recordId } = params;
        let messages = body;
        const firstLoad = this.getIn(["records", catalogId, recordId, "chat", "firstLoad"]);
        let total = Number(response.headers["x-total-count"]) || 0;
        this.mergeUsersWithLinkedCatalog(params);
        if (firstLoad) {
            this.setStartIndex(params, total);
        }
        let startNewMessages;
        messages.forEach((message, index) => {
            if (message.newMessage) {
                startNewMessages = index;
            }
        });
        if (startNewMessages && firstLoad) {
            this.setStartNewMessagesIndex(params, startNewMessages);
        };
        if (query.from || firstLoad) {
            this.setItemIndex({ catalogId, recordId }, messages.length);
        };
        const { limit } = query;
        this._updateNewMessages(params);
        let chat = this.getIn(["records", catalogId, recordId, "chat"]);
        const isGetNewMessage = chat.get("isGetNewMessage");
        const currentTotal = chat && chat.get("total");
        // если первая загрузка
        if (currentTotal === undefined) {
            chat = this._updateChat(chat, limit, messages, true, total);
            this.setIn(["records", catalogId, recordId, "chat"], chat);
            this.currentLoading = null;
            this.changed();
            return;
        };
        // при скролле у нас зашиты квери с этими квери сервак возвращается все меньше и меньше тотал поэтому при скролле не нужно считать тотал
        if (!isGetNewMessage) {
            total = currentTotal;
        };
        const countNewMessages = total - currentTotal;
        // если у нас новых сообщений больше чем мы запросили, то проверяем дальше
        if (countNewMessages > limit) {
            if (countNewMessages > this.maxLimit) {
                // если у нас новых сообщений больше чем 50 (максималка сколько мы запрашиваем), то мы последние 20 сообщений и записываем стирая пред
                chat = this._updateChat(chat, limit, messages, true, total);
            } else {
                /* если у нас новых сообщений меньше чем 50 (максималка сколько мы запрашиваем), 
                то мы записываем эти 20 сообщений и рекурсивно вызываем update для следующих новых сообщений */
                const currentTotal = total - (countNewMessages - limit);
                chat = this._updateChat(chat, limit, messages, false, currentTotal);
                this.setIn(
                    ["records", catalogId, recordId, "chat"],
                    chat
                );
                this.currentLoading = null;
                this.changed();
                return this.loadMessages({ catalogId, recordId }, false, 50);
            };
        } else {
            // если у нас новых сообщений меньше чем мы запросили, то объединяем с теми которые у нас уже есть
            chat = this._updateChat(chat, limit, messages, false, total);
        };
        this.setIn(
            ["records", catalogId, recordId, "chat"],
            chat
        );
        this.currentLoading = null;
        this.changed();
    },
    _updateChat(chat, limit = this.maxLimit, body, replace = false, total) {
        let lastId = chat.get("lastId", null);
        let newIds = {};
        const firstLoad = chat.get("firstLoad", null);
        let messages;
        body = body.map((message, i) => {
            return {
                ...message,
                sending: true
            };
        });
        body.forEach(h => {
            newIds[h.id] = true;
        });

        if (body.length) {
            lastId = _.last(body).id;
        };
        messages = chat.get("messages") || new Immutable.List();
        messages = messages.filter(h => !newIds[h.get("id")]);
        if (!replace) {
            messages = Immutable.fromJS(body).concat(messages);
        } else {
            messages = Immutable.fromJS(body);
        }
        messages = messages
            .sort((a, b) => a.get("id") - b.get("id"))
            .filter((m, i) => m.get("sending") == true || m.get("sending") == "error");

        let allLoadedMessages = false;
        if (body.length < limit) {
            allLoadedMessages = true;
        };

        chat = chat.set("messages", messages);
        chat = chat.set("loadingMessages", false);
        chat = chat.set("allLoadedMessages", allLoadedMessages);

        if (lastId) {
            chat = chat.set("lastId", lastId);
        };

        if (firstLoad) {
            chat = chat.set("messageCount", total);
        };

        chat = chat.set("total", total);

        return chat;
    },
    getMessagesFailed(body, params, data, query, res, actionParams) {
        const { catalogId, recordId } = params;
        let chat = this.getIn([
            "records",
            catalogId,
            recordId,
            "chat"
        ]);
        if (chat) {
            chat = chat.set("loadingMessages", false);
            this.setIn(
                ["records", catalogId, recordId, "chat"],
                chat
            );
            this.changed();
        };
    },

    // тут мы сначала записываем в стор сообщение чтоб отобразить что загрузка пошла на сервер
    createMessage(params, data) {
        this.setBeingSentMessage(params, true);
        const message = this._formatMessage(params, data);
        let messages = this.getIn(["records", params.catalogId, params.recordId, "chat", "messages"]);
        messages = messages.push(message);
        this.setIn(["records", params.catalogId, params.recordId, "chat", "messages"], messages);
        this.setMessageText(params, Immutable.Map({}));
        this.cancelReplyMessage(params);
        this.changed();
    },
    _formatMessage(params, data) {
        const { catalogId, recordId } = params;
        let message = _.cloneDeep(data);
        const replyMessageId = data.get("replyMessageId");
        const author = this.get("user");
        let reply = Immutable.List();
        if (replyMessageId) {
            reply = this.getIn(["records", catalogId, recordId, "chat", "messages"]).find((message, i) => message.get("id") == replyMessageId);
        }
        // создаем временный айди
        message = message.set("id", guid.raw());
        message = message.set("reply", reply);
        message = message.set("deleted", false);
        message = message.set("author", Immutable.Map({
            recordId: author.get("id"),
            recordTitle: author.get("title")
        }));
        message = message.set("sending", false);
        message = message.set("createdDate", new Date().toISOString());
        message = message.set("catalogId", catalogId);
        message = message.set("recordId", recordId);
        return message;
    },
    createMessageCompleted(body, params, data, query, res, actionParams) {
        body = body || {};
        const { catalogId, recordId } = params;
        this.setBeingSentMessage(params, false);
        this.currentLoading = null;
        this.changed();
        this.loadMessages({ catalogId, recordId }, false, 50, true);
    },
    createMessageFailed(body, params, data, query, res, actionParams) {
        this.setBeingSentMessage(params, false);
        const messages = this.getIn(["records", params.catalogId, params.recordId, "chat", "messages"]).map((message, i) => {
            if (message.get("sending") === false) {
                message = message.set("sending", "error");
            };
            return message;
        });
        this.setIn(["records", params.catalogId, params.recordId, "chat", "messages"], messages);
        this.changed();
    },

    deleteMessage(params, query) {
    },
    deleteMessageCompleted(body, params, data, query, res, actionParams) {
        const { catalogId, recordId, messageId } = params;
        let messages = this.getIn(["records", catalogId, recordId, "chat", "messages"]);
        messages = this._deleteMessage(messages, messageId);
        this.setIn(
            ["records", catalogId, recordId, "chat", "messages"],
            messages
        );
        this.changed();
    },
    _deleteMessage(messages, id) {
        return messages.map((message) => {
            if (message.get("id") === id) {
                return message.set("deleted", true);
            };
            return message;
        });
    },
    deleteMessageFailed(body, params, data, query, res, actionParams) {

    },

    updateMessage(...args) {
        // console.log(...args, "updateMessage");
    },
    updateMessageCompleted(body, params, data, query, res, actionParams) {
        const { catalogId, recordId, messageId } = params;
        this.cancelEditMessage(params);
        this._updateMessage({ catalogId, recordId, messageId }, data);
        this.changed();
    },
    _updateMessage(params, data) {
        let messages = this.getIn(["records", params.catalogId, params.recordId, "chat", "messages"]);
        let index = null;
        let message = messages.find((message, i) => {
            if (message.get("id") === params.messageId) {
                index = i;
            }
            return message.get("id") === params.messageId;
        });
        if (index !== null) {
            message = message.set("attachments", data.get("attachments"));
            message = message.set("text", data.get("text"));
            message = message.set("updatedDate", new Date());
            messages = messages.set(index, message);
            this.setIn(["records", params.catalogId, params.recordId, "chat", "messages"], messages);
        }
    },
    updateMessageFailed(body, params, data, query, res, actionParams) {

    },

    getReplyMessage(params) {
        const { catalogId, recordId, messageId } = params;
        const message = this.getIn(["records", params.catalogId, params.recordId, "chat", "messages"]).find((m, i) => m.get("id") == messageId);
        this.setIn(["records", catalogId, recordId, "chat", "newMessageToSend", "replyMessage"], Immutable.fromJS(message));
        this.changed();
    },

    getEditMessage(params) {
        const { catalogId, recordId, messageId } = params;
        const message = this.getIn(["records", params.catalogId, params.recordId, "chat", "messages"]).find((m, i) => m.get("id") == messageId);
        this.setIn(["records", catalogId, recordId, "chat", "editMessage"], Immutable.fromJS(message));
        this.changed();
    },

    async copyTextToClipboard(params, text) {
        const { catalogId, recordId, messageId } = params;
        const message = this.getIn(["records", catalogId, recordId, "chat", "messages"]).find((message) => message.get("id") === messageId);
        if (text !== "") {
            try {
                await navigator.clipboard.writeText(text);
            } catch (err) {
                console.log(err);
            }
        } else if (message.get("text")) {
            try {
                await navigator.clipboard.writeText(message.get("text"));
            } catch (err) {
                console.log(err);
            }
        }
    },

    _updateNewMessages(params) {
        const { catalogId, recordId } = params;

        // МЕНЯЕМ В СПИСКЕ ЧАТОВ ЧТО ПРОЧИТАЛИ СООБЩЕНИЕ И ОНО БОЛЬШЕ НЕ НОВОЕ
        const chatsItems = this.getIn(["chats", "items"])?.map((item) => {
            if (item.get("catalogId") == catalogId && item.get("recordId") == recordId) {
                item = item.setIn(["message", "newMessage"], false);
            }
            return item;
        });

        this.setIn(["chats", "items"], chatsItems);

        // МЕНЯЕМ В ЗАПИСИ ЧТО ПРОЧИТАЛИ СООБЩЕНИЕ И ОНО БОЛЬШЕ НЕ НОВОЕ
        let chatOptions = this.getIn(["records", catalogId, recordId, "chatOptions"]);
        // если по этой записи есть новые сообщения, то мы изменяем стейт
        if (chatOptions && chatOptions.get("newMessages")) {
            chatOptions = chatOptions.set("newMessages", false);

            this.setIn(["records", catalogId, recordId, "chatOptions"], chatOptions);
            // TODO ВЫНЕСТИ В ПОДПИСКИ ДЛЯ КАЖДОГО СЦЕНА, КАТАЛОГ, СЕКЦИЯ

            // МЕНЯЕМ В СЦЕНЕ СОСТОЯНИЕ ЗАПИСИ О ТОМ ЧТО ПОЛЬЗОВАТЕЛЬ ПРОЧИТАЛ СООБЩЕНИЯ
            const scenes = this.get("scenes");
            let sceneRecords = scenes.find((value, key) => (!value.getIn(["params", "recordId"]) && value.getIn("params", "catalogId")));
            const sceneId = sceneRecords.get("sceneId");
            if (sceneRecords && sceneRecords.get("records")) {
                let index;
                const newMessages = false;
                const records = sceneRecords.get("records").toJS();
                const prevRecordsCountNewMessages = sceneRecords.get("recordsCountNewMessages");
                let record = _.find(records, (value, key) => {
                    index = key;
                    return value.id == recordId;
                });
                record = Immutable.fromJS(record);
                record = record.setIn(["chatOptions", "newMessages"], newMessages);
                sceneRecords = sceneRecords.setIn(["records", index], record);
                sceneRecords = sceneRecords.set("recordsCountNewMessages", prevRecordsCountNewMessages == 0 ? 0 : prevRecordsCountNewMessages - 1);
                this.setIn(["scenes", sceneId], sceneRecords);
            };

            // МЕНЯЕМ В КАТАЛОГЕ КОЛИЧЕСТВО ЗАПИСЕЙ В КОТОРЫХ ЕСТЬ НОВЫЕ СООБЩЕНИЯ УБИРАЯ ЗАПИСЬ КОТОРУЮ ПРОЧИТАЛИ
            let catalog = this.getIn(["catalogs", catalogId]);
            const currentCountCatalog = catalog.getIn(["chat", "newChats"]);
            const newCountCatalog = currentCountCatalog <= 0 ? 0 : currentCountCatalog - 1;
            catalog = catalog.setIn(["chat", "newChats"], newCountCatalog);
            this.setIn(["catalogs", catalog.get("id")], catalog);

            // МЕНЯЕМ В СЕЦИИ КОЛИЧЕСТВО КАТАЛОГОВ В КОТОРЫХ ЕСТЬ НОВЫЕ СООБЩЕНИЯ ПО ЗАПИСЕ УБИРАЯ КАТАЛОГ ЕСЛИ ОН ПУСТ
            let section = this.getIn(["sections", catalog.get("sectionId")]);
            let chats = this.getIn(["chats"]);
            const currentCountSection = section.get("newMessages");
            if (currentCountSection) {
                const newCountSection = currentCountSection - 1;
                if (newCountSection <= 0) {
                    section = section.delete("newMessages");
                } else {
                    section = section.set("newMessages", newCountSection);
                };
            };
            this.setIn(["sections", section.get("id")], section);
            this.set("chats", chats);

            this.changed();
        }
    },

    resendMessage(message) {
        const catalogId = message.get("catalogId");
        const recordId = message.get("recordId");
        let messages = this.getIn(["records", catalogId, recordId, "chat", "messages"]).filter((m, i) => m.get("id") !== message.get("id"));
        this.setIn(["records", catalogId, recordId, "chat", "messages"], messages);
        const messageToResend = Immutable.fromJS({
            text: message.get("text"),
            attachments: message.get("attachments"),
            replyMessageId: message.get("replyMessageId"),
            mentions: message.get("mentions")
        });
        apiActions.createMessage({ catalogId, recordId }, messageToResend);
    },
    cancelResendMessage(message) {
        const catalogId = message.get("catalogId");
        const recordId = message.get("recordId");
        let messages = this.getIn(["records", catalogId, recordId, "chat", "messages"]).filter((m, i) => m.get("id") !== message.get("id"));
        this.setIn(["records", catalogId, recordId, "chat", "messages"], messages);
        this.changed();
    },

    setLoadingAttachment(params, flag) {
        const { catalogId, recordId } = params;
        this.setIn(["records", catalogId, recordId, "chat", "loadingAttachment"], flag);
        this.changed();
    },
    setBeingSentMessage(params, flag) {
        const { catalogId, recordId } = params;
        this.setIn(["records", catalogId, recordId, "chat", "isBeingSentMessage"], flag);
        this.changed();
    },
    setFirstLoadFlag(params, firstLoad) {
        const { catalogId, recordId } = params;
        this.setIn(["records", catalogId, recordId, "chat", "firstLoad"], firstLoad);
        this.changed();
    },

    setFlagisGetNewMessage(params, isGetNewMessage) {
        const { catalogId, recordId } = params;
        this.setIn(["records", catalogId, recordId, "chat", "isGetNewMessage"], isGetNewMessage);
        this.changed();
    },

    preparingToLoadingMessage(params) {
        const { catalogId, recordId } = params;
        this.setIn(["records", catalogId, recordId, "chat", "allLoadedMessages"], false);
        this.setIn(["records", catalogId, recordId, "chat", "startNewMessages"], 1);
        this.changed();
    },

    setMessageText(params, text) {
        const { catalogId, recordId } = params;
        this.setIn(["records", catalogId, recordId, "chat", "newMessageToSend", "text"], text);
        this.changed();
    },

    editMessageText(params, text) {
        const { catalogId, recordId } = params;
        this.setIn(["records", catalogId, recordId, "chat", "editMessage", "text"], text.get("text"));
        this.setIn(["records", catalogId, recordId, "chat", "editMessage", "mentions"], text.get("metions"));
        this.changed();
    },

    cancelEditMessage(params) {
        const { catalogId, recordId } = params;
        this.deleteIn(["records", catalogId, recordId, "chat", "editMessage"]);
        this.changed();
    },

    cancelReplyMessage(params) {
        const { catalogId, recordId } = params;
        this.deleteIn(["records", catalogId, recordId, "chat", "newMessageToSend", "replyMessage"]);
        this.changed();
    },

    setStartIndex(params, index = 0) {
        const { catalogId, recordId } = params;
        this.setIn(["records", catalogId, recordId, "chat", "startIndex"], index);
        this.changed();
    },

    setStartNewMessagesIndex(params, startNewMessages) {
        const { catalogId, recordId } = params;
        this.setIn(["records", catalogId, recordId, "chat", "startNewMessages"], startNewMessages + 2);
        this.changed();
    },

    setItemIndex(params, messagesToPrepend) {
        const { catalogId, recordId } = params;
        const startIndex = this.getIn(["records", catalogId, recordId, "chat", "startIndex"]);
        const firstItemIndex = this.getIn(["records", catalogId, recordId, "chat", "firstItemIndex"]) || startIndex;
        const nextFirstItemIndex = firstItemIndex - messagesToPrepend;
        this.setIn(["records", catalogId, recordId, "chat", "firstItemIndex"], nextFirstItemIndex);
        this.changed();
    },

    allMessageRead(params) {
        const { catalogId, recordId } = params;
        let messages = this.getIn(["records", catalogId, recordId, "chat", "messages"]);
        messages = messages.map((message, i) => {
            if (message.get("newMessage")) {
                message = message.set("newMessage", false);
            };
            return message;
        });
        this.setIn(["records", catalogId, recordId, "chat", "messages"], messages);
        this.changed();
    }
};