import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import apiActions from "../../../../actions/apiActions";
import Loading from "../../../common/Loading";
import LinkedCatalog from "./LinkedCatalog";
import { connect } from "../../../StateProvider";

import styles from "./linkedData.less";

class TabLinkedData extends React.PureComponent {
  loadLinkedData = () => {
    apiActions.getRelations({
      catalogId: this.props.catalogId,
      recordId: this.props.record.get("id")
    });
  };

  componentDidMount() {
    this.loadLinkedData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.record.get("id") !== this.props.record.get("id")) {
      this.loadLinkedData();
    }
  }

  render() {
    let linkedData = this.props.record.get("linkedData");
    return (
      <div className={styles.tabLinkedDataContainer}>
        {linkedData && linkedData.size ? (
          linkedData.map(catalogInfo => (
            <LinkedCatalog
              key={catalogInfo.get("id") + ":" + catalogInfo.get("fieldId")}
              catalogInfo={catalogInfo}
              catalogId={this.props.catalogId}
              sceneId={this.props.sceneId}
              record={this.props.record}
              loadLinkedData={this.loadLinkedData}
            />
          ))
        ) : !linkedData ? (
          <Loading fullHeight={true} />
        ) : (
          <div className={styles.noData}>
            {this.props.t("record.linkedData.noData")}
          </div>
        )}
      </div>
    );
  }
}

TabLinkedData.propTypes = {
  record: PropTypes.object.isRequired, // parent record
  catalogId: PropTypes.string.isRequired // parent catalogId
};

export default withTranslation()(
  connect(
    TabLinkedData,
    ["catalogs"],
    (props, { catalogs }) => {
      return {
        ...props,
        catalog: catalogs.getIn([props.catalogId]),
        catalogs
      };
    }
  )
);
