import React from "react";
import Draggable from "react-draggable";

import AxisFieldValue from "../Helpers/AxisFieldValue";

import styles from "./grid.less";

export default class GridHeader extends React.PureComponent {
  onDrag = (event, { deltaX }) => {
    this.props.onColumnResize(this.props.columnIndex, deltaX);
  };

  onStop = () => {
    this.props.onColumnResizeFinished(this.props.columnIndex);
  };


  render() {
    let { field, value, count } = this.props;

    return (
      <div className={styles.gridHeader}>
          {field ? (
            <AxisFieldValue field={field} value={value} />
          ) : (
            <strong
              className={styles.headerTitle}
            >
              {value}
            </strong>
          )}
        {count && count.size && <span className={styles.badgeCountRecords}>{count.size}</span>}
          <Draggable
            axis="x"
            defaultClassName={styles.gridColumnDragHandle}
            defaultClassNameDragging={styles.gridColumnDragHandleActive}
            onDrag={this.onDrag}
            onStop={this.onStop}
            position={{ x: 0 }}
            zIndex={999}
          >
            <span className={styles.gridHeaderDragHandleIcon} />
          </Draggable>
        </div>
    );
  }
}
