import React from "react";
import PropTypes from "prop-types";
import ProgressField from "../../common/dataTypes/ProgressField";
import ChangeDirection from "./ChangeDirection";

import styles from "./changes.less";

const ChangeProgress = props => {
  let fromObj = null;
  if (props.change.get("oldValue") != null && !props.isNewRecord) {
    fromObj = (
      <ProgressField
        value={props.change.get("oldValue")}
        config={props.config}
      />
    );
  }
  let toObj = null;
  if (props.change.get("newValue") != null) {
    toObj = (
      <ProgressField
        value={props.change.get("newValue")}
        config={props.config}
      />
    );
  }
  return (
    <ChangeDirection
      containerClass={styles.textWrapper}
      fromObj={fromObj}
      toObj={toObj}
    />
  );
};

ChangeProgress.propTypes = {
  change: PropTypes.object.isRequired,
  isNewRecord: PropTypes.bool
};

export default ChangeProgress;
