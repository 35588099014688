import appState from "../appState";
import { privilegePriorities } from "../configs/privilegeCodes";

export function getAllAccessOnObject(resource, object) {
  if (!object) return [];

  // support Immutable and plain objects
  let privilegeCode =
    (object.get && object.get("privilegeCode")) || object.privilegeCode;
  if (!appState.getIn(["privilegeCodesByResource", resource])) return [];

  let allPrivilegeCodes = appState
    .getIn(["privilegeCodesByResource", resource])
    .toJS();
  let inx = allPrivilegeCodes.indexOf(privilegeCode);

  return allPrivilegeCodes.slice(0, inx + 1);
}

export function checkAccessOnObject(resource, object, rights) {
  let _rights = getAllAccessOnObject(resource, object);

  return _rights.indexOf(rights) > -1;
}

function getPrivilegePriority(privilegeCode) {
  return privilegePriorities.indexOf(privilegeCode);
}

export function privilegeCodesComparator(privilegeCode1, privilegeCode2) {
  return (
    getPrivilegePriority(privilegeCode1) - getPrivilegePriority(privilegeCode2)
  );
}
