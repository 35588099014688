import * as AXIS_TYPES from "../../../../configs/reports/widget/axisTypes";
import FIELD_TYPES from "../../../../configs/fieldTypes";
import * as SORT_TYPES from "../../../../configs/reports/widget/sortTypes";

export default function mixDataSorting(query, widget, catalog) {
  query.sort =
    query.sort || widget.get("sort") || getDefaultSorting(widget, catalog);
  query.sortType =
    query.sortType ||
    widget.get("sortType") ||
    getDefaultSortingType(widget, catalog);
}

function getDefaultSorting(widget, catalog) {
  const axis = widget.get("axis");
  const fields = catalog.get("fields");

  switch (axis && axis.get("type")) {
    case AXIS_TYPES.FIELD:
      const fieldId = axis.get("value");
      const field = fields && fields.find(f => f.get("id") === fieldId);
      switch (field && field.get("type")) {
        case FIELD_TYPES.TEXT:
        case FIELD_TYPES.OBJECT:
        case FIELD_TYPES.USER:
          return SORT_TYPES.VALUE;
      }
  }

  return SORT_TYPES.AXIS;
}

function getDefaultSortingType(widget, catalog) {
  const axis = widget.get("axis");
  const fields = catalog.get("fields");

  switch (axis && axis.get("type")) {
    case AXIS_TYPES.FIELD:
      const fieldId = axis.get("value");
      const field = fields && fields.find(f => f.get("id") === fieldId);
      switch (field && field.get("type")) {
        case FIELD_TYPES.DATE:
          return SORT_TYPES.DESC;
        case FIELD_TYPES.TEXT:
        case FIELD_TYPES.OBJECT:
        case FIELD_TYPES.USER:
          return SORT_TYPES.DESC;
      }
  }

  return SORT_TYPES.ASC;
}
