import React from "react";
import _ from 'lodash';
import Immutable from "immutable";
import PropTypes from "prop-types";

import historyActions from "../../actions/historyActions";

import HistoryData from "./HistoryData";

class History extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fields: this.props.getFieldsToRender()
    };
  }

  loadData = _.debounce(force => {
    const viewId = this.props.viewId;
    const catalogId = this.props.catalog.get("id");
    const sceneId = this.props.sceneId;
    historyActions.loadHistory(sceneId, catalogId, null, { viewId }, force);
  }, 200);

  loadInitialHistory() {
    const sceneId = this.props.sceneId;
    const catalogId = this.props.catalog.get("id");
    const viewId = this.props.viewId;
    const fields = this.props
      .getFieldsToRender()
      .map(field => field.get("id"))
      .toJS();

    historyActions.setFilter({ fields }, { catalogId });
    historyActions.loadHistory(sceneId, catalogId, null, { viewId }, true);
  }

  componentDidUpdate(prevProps, prevState) {
    const viewId = this.props.viewId;
    const prevViewId = prevProps.viewId;

    const filters = this.props.scene.getIn(["views", viewId, "filters"]);
    const prevFilters = prevProps.scene.getIn(["views", prevViewId, "filters"]);

    const fields = this.state.fields;
    const prevFields = prevState.fields;
    if (
      prevViewId !== viewId ||
      (filters && !filters.equals(prevFilters)) ||
      (fields && !fields.equals(prevFields))
    ) {
      this.loadData(true);
    }
  }

  componentWillMount() {
    this.loadInitialHistory();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ fields: nextProps.getFieldsToRender() });
  }

  render() {
    return (
      <HistoryData
        fields={this.state.fields}
        catalog={this.props.catalog}
        getFieldsToRender={this.props.getFieldsToRender}
        loadData={this.loadData}
      />
    );
  }
}

History.propTypes = {
  catalog: PropTypes.object.isRequired
};

export default History;
