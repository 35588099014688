import React from 'react';

import styles from "./chatInput.less";

const CustomSuggestionsContainer = (Component) => {
    // ширина textarea
    return (
        <div style={{ width: Component.sizeContainer.width }} className={styles.msgerInputareaSuggestionsContainer}>
            {Component}
        </div>
    );
};

export default CustomSuggestionsContainer;