import { Image } from "antd";
import React from "react";
import cn from "classnames";

import ButtonClose from "../../../../../ButtonClose";
import i18n from "../../../../../../../../configs/i18n";

import imageStyles from "./image.less";

export const antImageViewer = "antImageViewer"

const AntImage = ({
  files,
  onRemove,
  containerClassName,
  wrapperClassName,
  imageClassName,
  imageCloseBtnClassName,
  width,
  height,
  shipped = false,
  calcSizes,
  isSmallImage
}) => {
  // In here we must let to this component option to small image manipulation and large sizes image
  // Which depends on different position: in preview variant and large variant
  return (
    <div
      style={{ margin: shipped && "0 -14px" }}
      className={cn(containerClassName)}
    >
      {files.length &&
        files.map(file => {
          return (
            <div className={cn(wrapperClassName)}>
              <Image
                onClick={e => {
                  e.stopPropagation();
                }}
                style={
                  calcSizes
                    ? calcSizes(files, file, shipped)
                    : {
                        width,
                        height
                      }
                }
                preview={{
                  src: file.url // put here original image size
                }}
                className={cn(imageClassName, imageStyles.imageSmall)}
                src={
                  isSmallImage
                    ? _.get(file, ["metadata", "thumbnail"])
                      ? file.metadata.thumbnail
                      : file.url
                    : file.url
                }
              />
              {onRemove && (
                <ButtonClose
                  title={i18n.t("record.fields.file.remove")}
                  onClick={() => onRemove(file)}
                  className={cn(imageCloseBtnClassName)}
                  small
                />
              )}
            </div>
          );
        })}
    </div>
  );
};

export default AntImage;
