import React from "react";
import tinycolor from "tinycolor2";
import cn from "classnames";

import styles from "./card.less";

import FieldValue from "../Helpers/FieldValue";
import { connect } from "../../StateProvider";

import LinkedRecordOpen from "../../Record/LinkedRecordOpen";
import { GRID } from "../../../configs/dndTargets";
import { DragSource } from "react-dnd";

const dragSource = DragSource(
  GRID,
  {
    canDrag(props) {
      return true;
    },

    beginDrag(props, monitor, component) {
      const { axisYkey, axisXkey, record } = props;
      return {
        recordId: record.get("id"),
        axisYkey,
        axisXkey
      };
    }
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  })
);

class Card extends React.PureComponent {
  static propTypes = {};

  state = {};

  onClick = () => {
    this.linkedRecordOpenLink.click();
  };

  render() {
    const {
      catalogId,
      sceneId,
      record,
      records,
      fieldsToRender,
      fields,
      fieldToColor,
      connectDragSource,
      isDragging,
      wrapperClassName,
      rowIndex,
      ...props
    } = this.props;

    if (!record) {
      return;
    }

    // color
    let background;
    const fieldIdToColor = fieldToColor && fieldToColor.get("id");
    if (fieldIdToColor) {
      const fieldToColorItems = fieldToColor.getIn(["config", "items"]);
      const colorValue = record.getIn(["values", fieldIdToColor, 0]);

      background =
        colorValue &&
        fieldToColorItems.find(item => item.get("id") === colorValue);
      background = background && background.get("color");
      background = background && tinycolor(background).setAlpha(0.5);
    }
    background = background || "white";

    const recordIdParams = {
      sectionId: props.sectionId,
      catalogId: catalogId,
      recordId: record.get("id")
    };

    const alert = record && record.getIn(["chatOptions", "newMessages"]);

    return connectDragSource(
      <div className={styles.cardContainer}>
        <div
          className={alert && styles.iconBadge}
          style={{ overflow: "hidden" }}
        />
        <div
          className={cn(styles.card, { [isDragging]: styles.cardDragging })}
          style={{ background, display: isDragging ? "none" : "block" }}
          onClick={e => {
            e.stopPropagation();
            this.onClick();
          }}
          {...props}
        >
          <LinkedRecordOpen
            linkProps={{
              children: <span ref={ref => (this.linkedRecordOpenLink = ref)} />
            }}
            params={recordIdParams}
            onDelete={this.props.onDeleteRecord}
          />

          {fieldsToRender && fieldsToRender.size ? (
            fieldsToRender.map(field => {
              const fieldId = field.get("id");
              const value = record && record.getIn(["values", fieldId]);

              return (
                <FieldValue
                  key={fieldId}
                  field={field}
                  value={value}
                  className={styles.field}
                  extended={true}
                />
              );
            })
          ) : (
            <span>{record && "№ " + record.get("id")}</span>
          )}
        </div>
      </div>
    );
  }
}

export default dragSource(
  connect(
    Card,
    {
      catalogs: ["catalogs"]
    },
    (props, { catalogs }) => {
      const sectionId = catalogs.getIn([props.catalogId, "sectionId"]);
      return {
        sectionId,
        ...props
      };
    }
  )
);
