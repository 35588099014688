import React, { Component } from "react";
import { Row } from "antd";

import apiActions from "../../../actions/apiActions";
import CompaniesMenu from "./CompaniesMenu";
import SectionsMenu from "./SectionsMenu";
import Settings from "./Settings";
import Widgets from "./Widgets";
import RecordsCountIndicator from "./RecordsCountIndicator";

import styles from "./appHeader.less";

class AppHeader extends Component {
  componentDidMount() {
    apiActions.getVendor();
    apiActions.getProfile();
    apiActions.getCompanies();
    // apiActions.getFilterKeys();
    apiActions.getPrivileges();
    apiActions.getCompanyInfo();
    apiActions.getLicense();
    apiActions.getUsers();
  }

  render() {
    return (
      <Row
        type="flex"
        justify="start"
        align="middle"
        className={styles.container}
      >
        <Row type="flex" align="middle" className={styles.logoContainer}>
          <CompaniesMenu />
        </Row>
        <RecordsCountIndicator />
        <SectionsMenu />
        <Row type="flex" align="middle" className={styles.containerSubmenu}>
          <Widgets />
          <Settings />
        </Row>
      </Row>
    );
  }
}

export default AppHeader;
