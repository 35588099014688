import React from "react";
import { useTranslation } from "react-i18next";

import Icon from '../../UI/Icon';

import styles from "./betta.less";

export default ({ name }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <Icon type="icon programing-5" />
      <span
        className={styles.title}
        title={`${name} ${t("buttons.betaTitle")}`}
      >
        {t("buttons.beta")}
      </span>
    </div>
  );
};
