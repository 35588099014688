import _ from "lodash";
import assignWith from "lodash.assignwith";
import Immutable from "immutable";

import apiActions from "../../../../actions/apiActions";
import reportsActions from "../../../../actions/reports";
import filtersUtils from "../../../../utils/filters";

import mixDataSorting from "./dataSorting";
import mixDataLimit from "./dataLimiter";

export function getQuery(catalog, widget) {
  const query = [
    "value",
    "valueFn",
    "axis",
    "split",
    "recordsType",
    "sort",
    "sortType"
  ].reduce(function (obj, key) {
    const widgetValue = widget.getIn([key]);
    obj[key] = _.isObject(widgetValue) ? widgetValue.toJS() : widgetValue;
    return obj;
  }, {});

  query.recordsFilter = getWidgetRecordsFilter(catalog, widget);
  return query;
}

export function getFilters(catalog, scene, viewId) {
  const catalogViews = catalog && catalog.get("views");
  const sceneViews = scene && scene.get("views");
  let allViews;

  if (catalogViews) {
    allViews = catalogViews.merge(sceneViews);
  } else {
    allViews = sceneViews;
  }

  let viewFilters = allViews && allViews.getIn([viewId, "filters"]);
  let sceneFilters = scene && scene.getIn(["data", "filters"]);

  if (viewFilters) {
    viewFilters = filtersUtils.getFiltersForRequest(
      viewFilters.toJS(),
      catalog.get("fields")
    );
  }

  if (sceneFilters) {
    sceneFilters = filtersUtils.getFiltersForRequest(
      sceneFilters.toJS(),
      catalog.get("fields")
    );
  }

  const filters = viewFilters
    ? sceneFilters
      ? viewFilters.concat(sceneFilters)
      : viewFilters
    : undefined;

  if (filters) {
    return { filters };
  }
}

export function getWidgetRecordsFilter(catalog, widget) {
  const recordsFilters = (widget.getIn(["recordsFilter", "filters"]));
  let filters = {}

  if (recordsFilters) {
    // convert to api format
    filters = filtersUtils.getFiltersForRequest(
      recordsFilters.toJS(),
      catalog.get("fields")
    );
  }

  return { filters };
}

export function mixRecordsFilter(query, catalog, scene, viewId) {
  const filters = getFilters(catalog, scene, viewId);
  if (!query.recordsFilter) {
    query.recordsFilter = {};
  }
  assignWith(query.recordsFilter, filters, function (value1, value2, key, obj) {
    if (_.isArray(value1)) {
      return value1.concat(value2);
    }

    if (_.isArray(value2)) {
      return value2.concat(value1);
    }

    if (key === "viewId") {
      if (value1 && value2) {
        obj.viewIds = [value1, value2];
      }
      return value1 || value2;
    }
  });
}

const getDataMixin = Component => {
  class DataMixin extends Component {
    UNSAFE_componentWillReceiveProps(props) {
      this.getData(props);
    }

    componentWillUnmount() {
      this.isUnMounted = true;
    }

    componentDidMount() {
      this.getData(this.props);
    }

    setVisible(isVisible) {
      this.isVisible = isVisible;
      if (isVisible && this.getDataAfterVisible) {
        this.getData(this.props);
      }
    }

    getDataDebounce(...args) {
      if (!this._getDataDebounce) {
        this._getDataDebounce = _.debounce(function ({ params, query, uid }) {
          if (this.isUnMounted) {
            return;
          }
          if (!params.boardId && !params.widget) {
            apiActions.getStatisticsValue(params, query, { uid });
            if (this.props.widget.get("axis")) {
              apiActions.getStatisticsTotal(params, query, { uid });
            }
          } else {
            apiActions.getValues(params, query, { uid });
            if (this.props.widget.get("axis")) {
              apiActions.getTotals(params, query, { uid });
            }
          }
        }, 200);
      }

      this._getDataDebounce(...args);
    }

    getData({ widget, board, catalog, scene, license, viewId, inEditMode }) {
      const catalogId = catalog.get("id")
      const boardId = board && board.get("id");
      const widgetId = widget.get("id");

      if (!license) {
        return;
      }

      /*if (!widget.get("axis")) {
        return;
      }*/
      
      if (!this.isVisible) {
        this.getDataAfterVisible = true;
        return;
      }
      this.getDataAfterVisible = false;
      let params;
      if (board && widget) {
        params = { boardId, widgetId: (!inEditMode && widgetId) || "new" };
      } else {
        params = { catalogId };
      }
      const dataQuery = getQuery(catalog, widget);
      const query = inEditMode || ! board ? dataQuery : {};

      mixRecordsFilter(query, catalog, scene, viewId);
      mixDataSorting(query, widget, catalog);
      mixDataLimit(query, widget, catalog);

      const newQuery = {
        params,
        query,
        dataQuery,
        boardUpdateTime: board && board.get("beginUpdate")
      };

      if (!_.isEqual(this._lastQuery, newQuery)) {
        const uid = widget.get("uid");
        if (
          this._lastQuery &&
          !_.isEqual(this._lastQuery.dataQuery, newQuery.dataQuery)
        ) {
          reportsActions.clearChartData(uid);
        }
        this._lastQuery = newQuery;
        this.getDataDebounce({ params, query, uid });
      }
    }
  }
  return DataMixin;
};

export default getDataMixin;
