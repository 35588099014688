import _ from "lodash";
import apiActions from "../actions/apiActions";

const TEN_MINUTES = 10 * 60 * 1001;

export default {
    timeoutId: null,
    nextUpdate: Date.now() + TEN_MINUTES,
    startTimerCheckNewMessages(nextUpdate = this.nextUpdate) {
        if (!nextUpdate) {
            return;
        };

        const duration = Math.max(nextUpdate - Date.now(), 0);
        clearTimeout(this.timeoutId);
        if (duration) {
            this.timeoutId = setTimeout(
                this.startTimerCheckNewMessages,
                Math.min(duration, 1000)
            );
        } else {
            this.chatsStatuses();
        };
    },
    prepareStartTimerCheckNewMessages() {
        this.set("lastCheckNewMessages", new Date());
        const nextUpdate = Date.now() + TEN_MINUTES;
        this.nextUpdate = nextUpdate;
        this.startTimerCheckNewMessages(nextUpdate);
        this.changed();
    },
    getCatalogsCompleted(res, params, data, query) {
        this.prepareStartTimerCheckNewMessages();
    },

    chatsStatuses() {
        const lastCheckNewMessages = this.getIn(["lastCheckNewMessages"]);
        return apiActions.getChatsStatuses({}, { lastCheckDate: lastCheckNewMessages.toJSON() })
    },
    getChatsStatuses(params, query) {

    },
    getChatsStatusesCompleted(newMessages, params, data, query, res, actionParams) {
        newMessages.forEach((newMessage) => {
            const { catalogId, newChats } = newMessage;
            let catalog = this.getIn(["catalogs", catalogId]);
            const prevCount = catalog.getIn(["chat", "newChats"])
            // добавляем count в каталоге
            catalog = catalog.setIn(["chat", "newChats"], Number(prevCount) + Number(newChats));
            this.setIn(["catalogs", catalogId], catalog);

            // добавляем count в секцию, если у нас в каталоге было до этого нуль, то мы добавляем в сецию
            const sectionId = catalog.get("sectionId");
            const prevCountSections = this.getIn(["sections", sectionId, "newMessages"]);
            this.setIn(["sections", sectionId, "newMessages"], Number(prevCountSections) + Number(newChats));
        });
        this.prepareStartTimerCheckNewMessages();
    },
    getChatsStatusesFailed(body, params, data, query, res, actionParams) {
        this.prepareStartTimerCheckNewMessages();
    }
};