import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import { Skeleton } from "antd";

import Masonry from "./Masonry";
import LoadMoreSuggestedRecords from "./LoadMoreSuggestedRecords";
import Loading from "../../../common/Loading";

import styles from "../../calendar.less";

const RecordsList = ({
  sceneId,
  recordsCount,
  recordsSize,
  records,
  fields,
  onDragStart,
  onClickRecord,
  loading,
  loadMoreSuggestedRecords,
  onDropFromCalendar,
  getEventStyle,
  convertRecordsToEvents
}) => {
  return (
    <div className={styles.suggestedRecordsList}>
      <Skeleton
        active
        title={false}
        paragraph={{ rows: 50 }}
        loading={!sceneId || _.isUndefined(recordsCount) || !records}
      >
        <Masonry
          records={records}
          fields={fields}
          onDragStart={onDragStart}
          onClickRecord={onClickRecord}
          onDropFromCalendar={onDropFromCalendar}
          getEventStyle={getEventStyle}
          convertRecordsToEvents={convertRecordsToEvents}
        />

        {!loading ? (
          +recordsCount > +recordsSize && (
            <LoadMoreSuggestedRecords
              loadMoreSuggestedRecords={loadMoreSuggestedRecords}
            />
          )
        ) : (
          <Loading />
        )}
      </Skeleton>
    </div>
  );
};

RecordsList.propTypes = {
  sceneId: PropTypes.string,
  records: ImmutablePropTypes.map,
  fields: ImmutablePropTypes.list,
  recordsCount: PropTypes.string,
  recordsSize: PropTypes.number,
  loading: PropTypes.bool,
  onDropFromCalendar: PropTypes.func,
  loadMoreSuggestedRecords: PropTypes.func,
  convertRecordsToEvents: PropTypes.func,

  onDragStart: PropTypes.func,
  onClickRecord: PropTypes.func,
  getEventStyle: PropTypes.func
};

export default React.memo(RecordsList);
