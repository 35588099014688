import _ from "lodash";
import moment from "moment";
import i18n from "../../../../../../../configs/i18n";

function formatDate(momentDuration, partKey) {
  const valuePart = momentDuration[partKey]();
  return valuePart + i18n.t("reports.shortDate." + partKey);
}

function formatTime(momentDuration, format) {
  return moment(
    new Date(
      momentDuration.hours() * 3600 * 1000 +
        momentDuration.minutes() * 60 * 1000 +
        momentDuration.seconds() * 1000
    )
  )
    .utc()
    .format(format);
}

export default function duration(value) {
  const d = moment.duration(value, "seconds");

  if (d.years()) {
    return formatDate(d, "years") + " " + formatDate(d, "months");
  } else if (d.months()) {
    return formatDate(d, "months") + " " + formatDate(d, "days");
  } else if (d.days()) {
    return formatDate(d, "days") + " " + formatDate(d, "hours");
  }

  return formatTime(d, "H:mm:ss");
}
