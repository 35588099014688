import React from "react";
import { Radio, Row } from "antd";
import { useTranslation } from "react-i18next";

import styles from "../config.less";

const SortType = props => {
  const { t } = useTranslation();
  const value = props.settings.get("sortType");
  return (
    <Row className={styles.controlRow}>
      <Radio.Group
        value={value}
        onChange={e => props.handler(e.target.value, "sortType")}
      >
        <Radio.Button value={1}>
          <span className={styles.radioValue}>
            {t("calendar.config.ascending")}
          </span>
        </Radio.Button>
        <Radio.Button value={-1}>
          <span className={styles.radioValue}>
            {t("calendar.config.descending")}
          </span>
        </Radio.Button>
      </Radio.Group>
    </Row>
  );
};

export default SortType;
