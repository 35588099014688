import { Col } from "antd";
import React from "react";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import LoadingSpin from "../../../../common/LoadingSpin";

import apiActions from "../../../../../actions/apiActions";
import styles from "./LoadMoreLinkedDataTabs.less";

class LoadMoreLinkedDataTabs extends React.Component {
  onClick = () => {
    const { recordId, catalogId, linkedDataItem } = this.props,
      fieldId = linkedDataItem.get("fieldId"),
      linkingCatalogId = linkedDataItem.get("id"),
      offset =
        linkedDataItem.get("records") && linkedDataItem.get("records").size,
      loadingSpin = linkedDataItem.get("loading");

    if (!loadingSpin) {
      apiActions.getRelations(
        { catalogId, recordId },
        { catalogId: linkingCatalogId, fieldId, offset }
      );
    }
  };

  render() {
    const { linkedDataItem } = this.props,
      loadedRecordsCount =
        linkedDataItem.get("records") && linkedDataItem.get("records").size,
      recordsTotal = linkedDataItem.get("recordsTotal"),
      loadingSpin = linkedDataItem.get("loading") ? (
        <LoadingSpin className={styles.spin} />
      ) : null,
      classes = classNames(styles.linkedRecordRow, styles.loadMore);

    if (loadedRecordsCount < recordsTotal) {
      return (
        <div className={styles.linkedRecordContainer}>
          <div className={classes} onClick={this.onClick}>
            <Col xs={20} className={styles.title}>
              {this.props.t("record.linkedData.loadMore")}
              {loadingSpin}
            </Col>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default withTranslation()(LoadMoreLinkedDataTabs);
