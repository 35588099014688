import React from "react";
import PropTypes from "prop-types";
import PureRenderMixin from "react-addons-pure-render-mixin";
import ImmutablePropTypes from "react-immutable-proptypes";

import DebouncedInput from "../../common/DebouncedInput";

const log = require("debug")("CRM:Component:Filter:AddressField");

class TextField extends React.PureComponent {
  onSave = value => {
    const { onSave, fieldId } = this.props;
    //this.setState(value);
    log("filter value", value);
    onSave(fieldId, value);
  };

  render() {
    return (
      <div>
        <DebouncedInput
          value={this.props.value}
          className="w100"
          onSave={this.onSave}
          readOnly={this.props.readOnly}
        />
      </div>
    );
  }
}

TextField.propTypes = {
  fieldId: PropTypes.string,
  value: PropTypes.string,
  config: ImmutablePropTypes.contains({
    type: PropTypes.string
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  readOnly: PropTypes.bool
};

export default TextField;
