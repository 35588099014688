import Immutable, { fromJS } from "immutable";
import AVAILABLE_FEILD_TYPES from "../availableFieldTypes";
import FIELD_TYPES from "../../../configs/fieldTypes";
import usActions from "../../../actions/userSettingsActions";

function settingIsValid(setting, settingName, fields) {
  const field = fields.find(f => f.get("id") === setting.get("key"));
  return (
    field && AVAILABLE_FEILD_TYPES[settingName].includes(field.get("type"))
  );
}

function prepareStartSettings(settings, fields) {
  const start = settings.get("start");
  if (start && settingIsValid(start, "start", fields)) {
    return settings;
  } else {
    const defaultStartField = fields.find(
      field => field.get("type") === FIELD_TYPES.DATE
    );

    if (defaultStartField) {
      const start = fromJS({
        text: defaultStartField.get("name"),
        key: defaultStartField.get("id")
      });

      settings = settings.set("start", start);
    }

    return settings;
  }
}

function prepareEndSettings(settings, fields) {
  const end = settings.get("end");
  if (end) {
    if (settingIsValid(end, "end", fields)) {
      return settings;
    } else {
      const end = fromJS({
        key: null,
        text: ""
      });
      return settings.set("end", end);
    }
  } else {
    return settings;
  }
}

function prepareSplitSettings(settings, fields) {
  const split = settings.get("split");

  if (split && settingIsValid(split, "split", fields)) {
    return settings;
  } else {
    const splitField =
      fields.find(f => f.get("type") === FIELD_TYPES.USER) ||
      fields.find(f => f.get("type") === FIELD_TYPES.OBJECT) ||
      fields.find(f => f.get("type") === FIELD_TYPES.RADIOBUTTON) ||
      fields.find(f => f.get("type") === FIELD_TYPES.CHECKBOXES) || 
      fields.find(f => f.get("type") === FIELD_TYPES.SWITCH);

    let split = splitField
      ? {
          key: splitField.get("id"),
          text: splitField.get("name")
        }
      : {
          key: null,
          text: ""
        };
    split = Immutable.Map(split);
    return settings.set("split", split);
  }
}

function prepareColorSettings(settings, fields) {
  const color = settings.get("color");
  if (color) {
    if (settingIsValid(color, "color", fields)) {
      return settings;
    } else {
      const color = fromJS({
        key: null,
        text: ""
      });
      return settings.set("color", color);
    }
  } else {
    return settings;
  }
}

function prepareSortFieldSettings(settings, fields) {
  const sortField = settings.get("sortField");
  if (sortField && settingIsValid(sortField, "sortField", fields)) {
    return settings;
  } else {
    const sortField = Immutable.Map({ text: "№ записи", key: "id" });
    return settings.set("sortField", sortField);
  }
}

function prepareSortTypeSettings(settings) {
  if (settings && settings.get("sortType")) {
    return settings;
  } else {
    return settings.set("sortType", 1);
  }
}

function prepareSuggestRecordsSettings(settings) {
  if (settings && settings.get("suggestRecords")) {
    return settings;
  } else {
    return settings.set("suggestRecords", false);
  }
}

export default function useSettings(settings, catalogId, fields) {
  const key = ["catalogs", catalogId, "viewMode", "calendar"];
  let calendarSettings = settings.getIn(key) || Immutable.Map();

  // userSettings прилетают быстрее, чем загружаются филды календаря
  if (fields && fields.size) {
    calendarSettings = prepareStartSettings(calendarSettings, fields);
    calendarSettings = prepareEndSettings(calendarSettings, fields);
    calendarSettings = prepareSplitSettings(calendarSettings, fields);
    calendarSettings = prepareColorSettings(calendarSettings, fields);
    calendarSettings = prepareSortTypeSettings(calendarSettings, fields);
    calendarSettings = prepareSortFieldSettings(calendarSettings, fields);
    
    calendarSettings = prepareSuggestRecordsSettings(calendarSettings, fields);
  }

  const userSettingsHandler = (newSettings, action) => {
    const settings = calendarSettings.set(action, fromJS(newSettings));
    return usActions.updateUserSettings(
      ["catalogs", catalogId, "viewMode", "calendar"],
      settings
    );
  };

  return { settings: calendarSettings, userSettingsHandler };
}
