import _ from "lodash";
import { withTranslation } from "react-i18next";
import React, { Component } from "react";
import PureRenderMixin from "react-addons-pure-render-mixin";

// import trs from "../../../../../getTranslations";
import i18n from "../../../../../configs/i18n";

import Immutable from "immutable";
import "chartsjs-plugin-data-labels";

import filtersUtils from "../../../../../utils/filters";
import formatAxis from "./utils/formatAxis";
import convertDataToDatasets from "./utils/convertDataToDatasets";
import getAxisColor from "./utils/getAxisColor";
import getBorderColor from "./utils/getBorderColor";
import getLabelColor from "./utils/getLabelColor";
import formatAxisValue from "./utils/formatValue/axis";
import formatTooltipValue from "./utils/formatValue/tooltip";
import formatLabelValue from "./utils/formatValue/label";
import getAxisDirection from "./utils/getAxisDirection";
import getAxisSortFn from "./utils/getAxisSortFn";
import * as SORT_TYPES from "../../../../../configs/reports/widget/sortTypes";
import getValue from "./utils/getValue";
import getAxis from "./utils/getAxis";
import getAxisFilter from "./utils/getAxisFilter";

class BaseChartJS extends Component {
  shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
  state = {
    redraw: false,
    size: {}
  };
  valuesAxis = "y";
  labelsAxis = "x";

  UNSAFE_componentWillReceiveProps(nextProps) {
    const prevSplit = this.props.widget.get("split");
    const newSplit = nextProps.widget.get("split");
    const splitIsEqual =
      (!prevSplit && !newSplit) || (prevSplit && prevSplit.equals(newSplit));

    const prevStacked = this.props.widget.get("stacked");
    const newStacked = nextProps.widget.get("stacked");
    const stackedIsEqual = prevStacked == newStacked;

    this.setState({
      redraw: !splitIsEqual || !stackedIsEqual // else legend not shown // else stacked not apply
    });
  }

  getOptions() {
    const { widget } = this.props;
    const split = widget.get("split");
    const stacked = !!widget.get("stacked");

    return {
      legend: {
        display: !!split,
        position: "bottom",
        labels: {
          boxWidth: 12
        }
      },
      maintainAspectRatio: false,
      tooltips: {
        mode: "index",
        filter: item => {
          // none exisiting values contain NaN as value
          return !(isNaN(item.x) || isNaN(item.y));
        },
        callbacks: {
          label: (item, data) => {
            return this.formatTooltipLabelItem(item, data);
          }
        }
      },
      scales: {
        [this.labelsAxis + "Axes"]: [
          {
            stacked,
            ticks: {
              display: true,
              autoSkipPadding: 40,
              maxRotation: 0,
              callback: (value, index, values) => {
                return this.formatAxisLabel(value);
              }
            },
            gridLines: {
              display: true,
              drawBorder: false,
              drawOnChartArea: false,
              lineWidth: 1,
              color: "rgba(0, 0, 0, 0.25)",
              drawTicks: true,
              tickMarkLength: 6
            }
          }
        ],
        [this.valuesAxis + "Axes"]: [
          {
            stacked,
            ticks: {
              beginAtZero: true,
              display: true,
              maxRotation: 0,
              callback: (value, index, values) => {
                return this.formatAxisValue(value, index, values);
              }
            }
          }
        ]
      },
      dataLabels: {
        fontSize: 11
      },
      animation: {
        duration: 0
      },
      onResize: this.onResize.bind(this)
    };
  }

  onResize(chart, size) {
    this.setState({ size });
  }

  truncate(text, length = 20) {
    return _.truncate(text, { length, omission: "\u2026" });
  }
  _formatAxis(axis, config, locale) {
    const fields = this.props.fields;
    return formatAxis(axis, config, fields, locale);
  }

  formatAxisLabel(axis, truncate = 20) {
    if (truncate) {
      axis = this.truncate(axis, truncate);
    }
    return axis;
  }

  formatAxis(axis, locale) {
    const config = this.props.widget.get("axis");
    return this._formatAxis(axis, config, locale);
  }

  formatAxisValue(value, index, values) {
    return formatAxisValue(
      { value, index, values },
      this.props.widget.get("value"),
      this.props.fields
    );
  }

  formatTooltipValue(value, label, truncate = 100) {
    value = formatTooltipValue(
      value,
      this.props.widget.get("value"),
      this.props.fields
    );
    if (truncate) {
      value = this.truncate(value, truncate);
    }
    return (label ? label + ": " : "") + value;
  }

  formatLabelValue = value => {
    return formatLabelValue(
      { value },
      this.props.widget.get("value"),
      this.props.fields
    );
  };

  formatTooltipLabelItem({ datasetIndex, index }, data) {
    const value = _.get(data, ["datasets", datasetIndex, "data", index]);
    const datasets = _.get(data, ["datasets"]);
    let label;
    if (datasets && datasets.length > 1) {
      label = _.get(data, ["datasets", datasetIndex, "label"]);
    }

    return this.formatTooltipValue(value, label);
  }

  formatSplit(split, locale) {
    const config = this.props.widget.get("split");
    if (!config) {
      return split;
    }
    return this._formatAxis(split, config, locale);
  }

  _getAxisColor(axis, index, config) {
    const widget = this.props.widget;
    const fields = this.props.fields;
    return getAxisColor(widget, axis, index, config, fields);
  }

  getAxisColor(axis, index) {
    const config = this.props.widget.get("axis");
    return this._getAxisColor(axis, index, config);
  }

  getSplitColor(split, index) {
    const config = this.props.widget.get("split");
    return this._getAxisColor(split, index, config);
  }

  getBorderColor(color) {
    return getBorderColor(color);
  }

  getLabelColor(color) {
    return getLabelColor(color);
  }

  getAxisSortFn() {
    const { widget, fields } = this.props;
    const sort = widget.get("sort");
    const sortType = widget.get("sortType");
    const subType = widget.getIn(["axis", "subType"]);

    return getAxisSortFn(widget.get("axis"), fields, sort, sortType, subType);
  }

  getSplitSortFn() {
    const { widget, fields } = this.props;
    const subType = widget.getIn(["split", "subType"]);

    return getAxisSortFn(
      widget.get("split"),
      fields,
      SORT_TYPES.AXIS,
      null,
      subType
    );
  }

  getReportData() {
    const { widget, fields } = this.props;
    let { chartData } = this.props;
    // chart data - y-значения

    // specific sorting of axis
    const sortFn = this.getAxisSortFn();
    if (sortFn) {
      chartData = chartData.sort(function(item1, item2) {
        return sortFn(item1.axis, item2.axis);
      });
    }

    // basic view direction
    chartData = getAxisDirection(chartData, widget.get("axis"), fields);

    // return as new object
    return chartData;
  }

  getLabels(locale) {
    return this.getReportData().map(item => this.formatAxis(item.axis, locale));
  }

  getDatasetsSortFn() {
    const sortFn = this.getSplitSortFn();
    return function(dataset1, dataset2) {
      return sortFn && sortFn(dataset1.split, dataset2.split);
    };
  }

  getDatasets() {
    const reportData = this.getReportData();
    const datasets = convertDataToDatasets(reportData).sort(
      this.getDatasetsSortFn()
    );
    // console.log("datasets1.5", datasets);

    datasets.forEach((dataset, index) => {
      const { split, chartDataset } = dataset;
      const color = this.getSplitColor(split, index);
      const borderColor = this.getBorderColor(color);
      const labelColor = this.getLabelColor(color);
      const label =
        this.formatSplit(split) ||
        i18n.t("reports.widget.modals.common.tabs.data.axis.emptyValue");
      _.assign(chartDataset, {
        initialData: split,
        label: label,
        borderWidth: 1.5,
        backgroundColor: color,
        borderColor: borderColor,
        pointBackgroundColor: borderColor,
        spanGaps: false,
        dataLabels: {
          color: labelColor,
          fontSize: 11,
          format: this.formatLabelValue
          // display: widget.get('labels')
        }
      });
    });

    return datasets;
  }

  getDatasetForCharts() {
    return this.getDatasets().map(({ chartDataset }) => {
      return chartDataset;
    });
  }

  getAxisInfo(param = "axis") {
    const { widget, fields } = this.props;

    const axis = getAxis(widget, fields, param);
    return axis;
  }

  getSplitInfo() {
    return this.getAxisInfo("split");
  }

  getValueInfo() {
    const { widget, fields } = this.props;
    const value = getValue(widget, fields);

    return value;
  }

  getData(locale) {
    return {
      labels: this.getLabels(locale),
      datasets: this.getDatasetForCharts(),
      axis: this.getAxisInfo(),
      split: this.getSplitInfo(),
      value: this.getValueInfo()
    };
  }

  // need define
  getChartComponent() {}

  // uniq onClick which will call method onClickArea that we can redefine
  onClick = (...args) => this.onClickArea(...args);

  onClickArea(event) {
    if (!event || !event.length) return;

    const datasetAxisIndex = event[0]._index;
    const datasetSplitIndex = event[0]._datasetIndex;

    let axisFilters = Immutable.Map({});
    let splitFilters = Immutable.Map({});

    if (datasetSplitIndex || datasetSplitIndex === 0) {
      const datasets = this.getDatasets() || {};
      let dataset = datasets && datasets[datasetSplitIndex];
      if (dataset) {
        const { split = null } = dataset;

        const splitInfo = this.getSplitInfo();
        splitFilters = getAxisFilter(split, splitInfo);
      }
    }

    if (datasetAxisIndex || datasetAxisIndex === 0) {
      const reportData = this.getReportData();
      let dataset = reportData ? reportData[datasetAxisIndex] : {};
      if (dataset) {
        const { axis = null } = dataset;

        const axisInfo = this.getAxisInfo();
        axisFilters = getAxisFilter(axis, axisInfo);
      }
    }

    const filters = filtersUtils.mergeFilters(axisFilters, splitFilters);

    const { onPieceClick } = this.props;
    onPieceClick && onPieceClick(Immutable.Map({ filters }));
  }

  render() {
    const ChartComponent = this.getChartComponent();

    const options = this.getOptions();
    const data = this.getData();
    const onClick = this.onClick;
    const size = this.state.size;
    const redraw = this.state.redraw;
    const catalog = this.props.catalog;
    const labelColumnWidth = this.props.labelColumnWidth;
    const chartRef = this.props.chartRef;

    return (
      <ChartComponent
        {...{
          data,
          options,
          size,
          redraw,
          onClick,
          catalog,
          labelColumnWidth,
          chartRef
        }}
      />
    ); // данные сюда идут
  }
}

export default BaseChartJS;
