import React, { useMemo, useCallback, useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import { MONTH_FORMAT } from "../formats";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import cn from "classnames";
import Immutable from "immutable";

import {
  toMinimizeDate,
  emptyResource,
  shouldRenderEmptyResource
} from "../helpers";

import FieldApi from "../../../models/FieldApi";

import useEvents from "../hooks/useEvents.hook";
import useResources from "../hooks/useResources.hook";

import BigCalendarEvent from "./customComponents/BigCalendarEvent";
import EventContainerWrapper from "./customComponents/EventContainerWrapper";
import MonthHeader from "./customComponents/MonthHeader";
import WeekHeader from "./customComponents/WeekHeader";
import EventWrapper from "./customComponents/EventWrapper";
import ResourseHeader from "./customComponents/ResourseHeader";
import MonthSlotHeader from "./customComponents/MonthSlotHeader";

import i18n from "../../../configs/i18n";

import styles from "../calendar.less";

const localizer = momentLocalizer(moment);
const TIME_STEP = 15;

const Calendar = props => {
  const {
    catalogId,
    sceneId,
    viewId,
    calendarView,
    fields,
    settings,
    withScheduling,
    adjustedRecords,
    suggestedRecords,
    filters = Immutable.List([]),
    getEventStyle
  } = props;

  const startId = settings.getIn(["start", "key"]);
  const endId = settings.getIn(["end", "key"]);
  const startField = fields.find(f => f.get("id") === startId);
  const endField = fields.find(f => f.get("id") === endId);

  const splitId = settings.getIn(["split", "key"]);
  const splitField = fields.find(f => f.get("id") === splitId);
  const splitType = splitField && splitField.get("type");

  const hasFieldsWithTime =
    (startField && startField.getIn(["config", "time"])) ||
    (endField && endField.getIn(["config", "time"]));

  const isWeek = props.calendarView === "week";
  const isDay = props.calendarView === "day";

  const {
    adjustedEvents,
    updateEvent,
    onDropEventFromOutside,
    onDropEventFromOutsideFailed,
    convertRecordToEvents
  } = useEvents(
    catalogId,
    sceneId,
    fields,
    settings,
    adjustedRecords,
    withScheduling,
    calendarView,
    props.fieldsOrder,
    props.visibleFields,
    props.getColorByItemId
  );

  const [draggedEvent, setDraggedEvent] = useState(null);

  useEffect(
    () => {
      if (!props.draggedRecord) return;

      const draggedEvent = convertRecordToEvents(props.draggedRecord, true)[0];

      setDraggedEvent(draggedEvent);
      return () => {
        setDraggedEvent(null);
      };
    },
    [props.draggedRecord]
  );

  const dragFromOutsideItem = useCallback(() => draggedEvent, [draggedEvent]);

  const prepareValues = (start, end, resourceId, prevValue, prevResourceId) => {
    const preparedValues = { [startId]: moment(start).toISOString() };

    if (endId) {
      preparedValues[endId] = moment(end).toISOString();
    }

    if (resourceId) {
      let value = prevValue || FieldApi.getEmptyValue(splitField);

      if (prevResourceId && prevResourceId !== emptyResource.id) {
        value = FieldApi.removeItem(splitField, value, prevResourceId);
      }

      if (resourceId !== emptyResource.id) {
        const newValue = FieldApi.convertIdToValue(
          splitField,
          resourceId,
          adjustedRecords,
          filters.get(splitId)
        );

        if (_.isObject(value)) {
          if (!value.some(v => FieldApi.compare(splitField, v, newValue))) {
            value = FieldApi.setValue(splitField, value, newValue);
          }
        } else {
          value = FieldApi.setValue(splitField, value, newValue);
        }
      }

      if (value) {
        value = value.toJS ? value.toJS() : value;
      }
      preparedValues[splitId] = value;
    }
    return preparedValues;
  };

  const updateRecord = (
    recordId,
    start,
    end,
    resourceId,
    prevResourceId,
    callbacks = {}
  ) => {
    const { catalogId, sceneId } = props;

    const record =
      (adjustedRecords &&
        adjustedRecords.find(r => r.get("id") === recordId)) ||
      (suggestedRecords &&
        suggestedRecords.find(r => r.get("id") === recordId));

    if (!record) return;

    const splitValue = record.getIn(["values", splitId]);

    const values = prepareValues(
      start,
      end,
      resourceId,
      splitValue,
      prevResourceId
    );

    props.onUpdateEvent &&
      props.onUpdateEvent(
        { catalogId, recordId },
        values,
        { sceneId },
        callbacks
      );
  };

  const { resources } = useResources(
    withScheduling,
    adjustedRecords,
    fields,
    settings,
    filters
  );

  if (shouldRenderEmptyResource(adjustedEvents, resources, splitType)) {
    resources.push(emptyResource);
  }

  const catalogHasManyDateFields = useMemo(
    () => {
      const filteredFields = fields.filter(f => f.get("type") === "date");
      return 1 < (filteredFields && filteredFields.size);
    },
    [fields]
  );

  const CalendarComponent = useMemo(
    () => {
      const startFieldEditable = startField && !startField.get("apiOnly");
      const endFieldEditable = endField && !endField.get("apiOnly");

      return startFieldEditable || endFieldEditable
        ? withDragAndDrop(BigCalendar)
        : BigCalendar;
    },
    [_.size(adjustedEvents), startField, endField, fields] // forceUpdate
  );

  const moveEventHandler = useCallback(
    e => {
      let { event, start, end, resourceId, isAllDay } = e;

      setDraggedEvent(null);

      if (!event || !event.droppable) {
        return;
      }

      if (isAllDay) {
        start = toMinimizeDate(start);
        end = toMinimizeDate(end);
      }

      updateEvent({ ...event, start, end, resourceId });
      updateRecord(event.recordId, start, end, resourceId, event.resourceId);
    },
    [updateRecord, updateEvent]
  );

  const onDropFromOutside = useCallback(
    e => {
      let { resource: resourceId, start, end, isAllDay } = e;

      if (!draggedEvent || !draggedEvent.droppable) {
        return null;
      }

      if (isAllDay) {
        start = toMinimizeDate(start);
        end = toMinimizeDate(end);
      }

      if (!draggedEvent || !props.draggedRecord) return null;

      const recordId = props.draggedRecord.get("id");
      const splitValue = props.draggedRecord.getIn(["values", splitId]);

      const values = prepareValues(start, end, resourceId);

      const callbacks = {
        onFailed: () => {
          onDropEventFromOutsideFailed(draggedEvent);
        }
      };

      onDropEventFromOutside({ ...draggedEvent, start, end, resourceId });
      setTimeout(() => {
        props.onDropFromOutside &&
          props.onDropFromOutside(
            { catalogId, recordId },
            values,
            { sceneId },
            callbacks
          );
      }, 1);
    },
    [
      draggedEvent,
      props.draggedRecord,
      props.onDropFromOutside,
      onDropEventFromOutside,
      onDropEventFromOutsideFailed
    ]
  );

  const customOnDragOver = useCallback(
    dragEvent => {
      if (draggedEvent && draggedEvent.droppable) {
        dragEvent.preventDefault();
        dragEvent.stopPropagation();
      }
    },
    [draggedEvent]
  );

  const onSelectSlot = ({ start, end, resourceId, action }) => {
    if (action === "doubleClick" || action === "select") {
      const preparedValues = prepareValues(start, end, resourceId);
      props.onCreateRecord &&
        props.onCreateRecord(preparedValues, props.loadData);
    }
  };

  const dayStyleGetter = date => {
    const backgroundColor =
      moment(date).format(MONTH_FORMAT) === moment().format(MONTH_FORMAT)
        ? "transparent"
        : moment(date).month() !== moment(props.date).month() && "#e5e8eb";

    return { style: { background: backgroundColor } };
  };

  const onSelectEvent = useCallback(
    ({ catalogId, recordId }) => {
      props.onClickRecord &&
        props.onClickRecord(
          { catalogId, recordId },
          {
            onDelete: props.loadData,
            onCreateRecord: props.loadData
          }
        );
    },
    [props.loadData]
  );

  const canDrag = ({ droppable }) => droppable;

  const onShowMore = (_events, date) => {
    props.onChangeUrl(date, "day");
    props.setUrlItemsToStore({ view: "day", date });
  };

  const scrollToTime = moment(props.date)
    .clone()
    .set({ hour: 6, minute: 0, second: 0 });

  const scrollToTimeToDate = new Date(scrollToTime); // made for prevent errors related to type matching

  const className = cn(styles.bigCalendar, {
    [styles.withoutTimeGrid]: (isWeek || isDay) && !hasFieldsWithTime,
    [styles.timeIndicator]: !withScheduling,
    [styles.withScheduling]: withScheduling,
    [styles.headerContentMargin]: isWeek,
    [styles.viewDay]: isDay,
    [styles.viewWeek]: isWeek,
  });

  return (
    <div className={className}>
      <CalendarComponent
        scrollToTime={scrollToTimeToDate}
        selectable={props.canCreate}
        onNavigate={props.switchDay}
        resizable={catalogHasManyDateFields}
        localizer={localizer}
        date={moment(props.date)._d}
        events={adjustedEvents}
        resources={withScheduling ? resources : null}
        onView={() => {}}
        step={TIME_STEP}
        view={calendarView}
        toolbar={false}
        showMultiDayTimes={true}
        onSelectEvent={onSelectEvent}
        onEventResize={moveEventHandler}
        draggableAccessor={canDrag}
        onDragOver={customOnDragOver}
        // dragFromOutsideItem={dragFromOutsideItem}
        onDropFromOutside={onDropFromOutside}
        onEventDrop={moveEventHandler}
        onSelectSlot={onSelectSlot}
        dayPropGetter={dayStyleGetter}
        eventPropGetter={getEventStyle}
        onShowMore={onShowMore}
        messages={{
          allDay: i18n.t("calendar.viewTabs.allDay"),
          showMore: total => (
            <span className={styles.totals}>+ {total} скрытых</span>
          )
        }}
        formats={{
          dateFormat: "D",
          eventTimeRangeFormat: ({ start, end }) => {
            return moment(start).format("dd hh mm") !==
              moment(end).format("dd hh mm")
              ? moment(start)
                  .format("HH mm")
                  .split(" ")
                  .join(":") +
                  " — " +
                  moment(end)
                    .format("HH mm")
                    .split(" ")
                    .join(":")
              : moment(start)
                  .format("HH mm")
                  .split(" ")
                  .join(":");
          }
        }}
        components={{
          event: BigCalendarEvent,
          eventWrapper: EventWrapper,
          eventContainerWrapper: EventContainerWrapper,
          resourceHeader: ResourseHeader,
          month: {
            header: MonthHeader,
            dateHeader: MonthSlotHeader
          },
          week: {
            header: WeekHeader
          }
        }}
      />
    </div>
  );
};

export default Calendar;
