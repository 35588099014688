import React from "react";
import PropTypes from "prop-types";
import PureRenderMixin from "react-addons-pure-render-mixin";
import DateField from "../../common/dataTypes/DateField";

import styles from "./changes.less";

const ChangeDate = props => {
  let oldClass = null;
  if (!props.change.get("newValue") && props.change.get("oldValue")) {
    oldClass = styles.removed;
  }
  return (
    <div className={styles.textWrapper}>
      <span className={oldClass}>
        <DateField value={props.change.get("oldValue")} config={props.config} />
      </span>
      {props.change.get("newValue") && props.change.get("oldValue") ? (
        <span
          className={
            "anticon-icon arrows-linear-big-2-01 " + styles.changeDirection
          }
        />
      ) : null}
      <DateField value={props.change.get("newValue")} config={props.config} />
    </div>
  );
};

ChangeDate.propTypes = {
  change: PropTypes.object.isRequired,
  isNewRecord: PropTypes.bool
};

export default ChangeDate;
