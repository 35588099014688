import React from "react";

import i18n from "../../../../../configs/i18n";

import styles from "../widget.less";

export default function({ title }) {
  return (
    <h3 className={styles.widgetViewTitleText}>
      {title || i18n.t("reports.widget.noName")}
    </h3>
  );
}
