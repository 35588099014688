import FIELD_TYPES from "../../configs/fieldTypes";

export default {
  start: [FIELD_TYPES.DATE],
  end: [FIELD_TYPES.DATE],
  color: [FIELD_TYPES.DROPDOWN],
  split: [
    FIELD_TYPES.CHECKBOXES,
    FIELD_TYPES.STARS,
    FIELD_TYPES.RADIOBUTTON,
    FIELD_TYPES.DROPDOWN,
    FIELD_TYPES.SWITCH,
    FIELD_TYPES.USER,
    FIELD_TYPES.OBJECT
  ],
  sortField: [
    FIELD_TYPES.TEXT,
    FIELD_TYPES.CODE,
    FIELD_TYPES.NUMBER,
    FIELD_TYPES.DATE,
    FIELD_TYPES.CONTACT,
    FIELD_TYPES.ADDRESS,

    FIELD_TYPES.DROPDOWN,
    FIELD_TYPES.SWITCH,
    FIELD_TYPES.CHECKBOXES,
    FIELD_TYPES.RADIOBUTTON,

    FIELD_TYPES.PROGRESS,
    FIELD_TYPES.STARS,

    FIELD_TYPES.USER,

    FIELD_TYPES.PAIR
  ],
  filterableFields: [
    FIELD_TYPES.GROUP,
    FIELD_TYPES.TEXT,
    FIELD_TYPES.CODE,
    FIELD_TYPES.NUMBER,
    FIELD_TYPES.CONTACT,
    FIELD_TYPES.ADDRESS,
    FIELD_TYPES.DROPDOWN,
    FIELD_TYPES.SWITCH,
    FIELD_TYPES.CHECKBOXES,
    FIELD_TYPES.RADIOBUTTON,
    FIELD_TYPES.PROGRESS,
    FIELD_TYPES.STARS,
    FIELD_TYPES.USER,
    FIELD_TYPES.OBJECT,
    FIELD_TYPES.FILE,
    FIELD_TYPES.PAIR
  ]
};
