import React from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import _ from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Category from "../../../../../common/UI/Category";
import i18n from "../../../../../../configs/i18n";
// import trs from "../../../../../../getTranslations";
import styles from "../../popup.less";

import CHART_ICONS from "../../../../../../configs/reports/widget/chartIcons";
import * as CHART_TYPES from "../../../../../../configs/reports/widget/chartTypes";
import cn from "classnames";

const ChartType = ({ type, selected, disabled, onClick }) => {
  const { t } = useTranslation();
  return (
    <li>
      <Category
        onClick={() => !disabled && onClick(type)}
        checked={selected}
        readOnly={disabled}
        className={styles.item}
        backgroundColor={"rgba(206, 209, 214, 0.5)"} // @BG_SELECTED
        title={
          <div className={cn({ [styles.disabledItem]: disabled })}>
            <span
              className={`anticon-icon ${CHART_ICONS[type]} ${styles.chartTypeIcon}`}
            />
            <span className={cn({ [styles.itemText]: selected })}>
              {t("reports.widget.modals.common.preview.types." + type)}
            </span>
          </div>
        }
      />
    </li>
  );
};

class SelectWidgetChartType extends React.PureComponent {
  getAvailableItems = () => {
    const axis = this.props.widget.getIn(["axis"]);
    const split = this.props.widget.getIn(["split"]);
    return [
      CHART_TYPES.NUMBER,
      CHART_TYPES.COLUMNS,
      CHART_TYPES.LINES,
      CHART_TYPES.BARS,
      CHART_TYPES.TABLE,
      CHART_TYPES.PIE,
      CHART_TYPES.RADAR,
    ].map(type => {
      let disabled = false;
      if (!axis) {
        disabled = type !== CHART_TYPES.NUMBER;
      }
      if (split) {
        disabled = type === CHART_TYPES.PIE;
      }

      return {
        type,
        disabled
      };
    });
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillReceiveProps({ widget }) {
    /*if (this.props.widget.getIn(["split"]) !== widget.getIn(["split"])) {
      this.setState(
        {
          availableItems: this.getAvailableItems(widget)
        },
        () => {
          this.selectDefaultValue();
        }
      );
    }*/
  }

  onChange = type => {
    this.props.onChange({ chartType: type });
  };

  getDefaultValue = () => {
    const availableItems = this.getAvailableItems();
    const allowed = _.filter(availableItems, {
      disabled: false
    });

    if (allowed.length === 1) {
      return allowed[0].type;
    } else if (allowed.length > 1) {
      return allowed[1].type;
    };
  };
  selectDefaultValue = () => {
    const selectedType = this.props.widget.get("chartType");
    const availableItems = this.getAvailableIte;
    const allowed = !!_.find(availableItems, {
      type: selectedType,
      disabled: false
    });

    /* do not set default chart type (to be able to auto select on axis change)
    if (!allowed) {
      this.onChange(availableItems[0].type);
    }*/
  };

  UNSAFE_componentWillMount() {
    this.selectDefaultValue();
  }

  render() {
    const { widget, className } = this.props;
    const availableItems = this.getAvailableItems();

    const selectedType = widget.getIn(["chartType"]) || this.getDefaultValue();
    return (
      <div className={styles.chartTypeContainer}>
          <strong className={cn({ [className]: className }, styles.typeText)}>
            {i18n.t("reports.widget.modals.common.preview.type")}
          </strong>
          <ul className={styles.chartTypeList}>
            {availableItems &&
              availableItems.map(({ type, disabled }) => (
                <ChartType
                  key={type}
                  type={type}
                  disabled={disabled}
                  selected={type == selectedType}
                  onClick={this.onChange}
                />
              ))}
          </ul>
        </div>
    );
  }
}

SelectWidgetChartType.propTypes = {
  widget: ImmutablePropTypes.map.isRequired,
  onChange: PropTypes.func.isRequired
};

export default SelectWidgetChartType;
