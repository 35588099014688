import * as TYPES from "./chartTypes";

export default {
  [TYPES.BARS]: "text-38",
  [TYPES.COLUMNS]: "business-79",
  [TYPES.LINES]: "setting-16",
  [TYPES.NUMBER]: "number",
  [TYPES.PIE]: "business-77",
  [TYPES.RADAR]: "edition-67",
  [TYPES.TABLE]: "design-26"
};
