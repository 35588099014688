import i18n from "../../i18n";
import * as SORT_TYPES from "./sortTypes";

const availableValues = [
  {
    title: i18n.t("reports.widget.modals.common.tabs.data.sort.types.axis"),
    value: SORT_TYPES.AXIS
  },
  {
    title: i18n.t("reports.widget.modals.common.tabs.data.sort.types.value"),
    value: SORT_TYPES.VALUE
  }
];

export default availableValues;
