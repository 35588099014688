import React from "react";
import { Row } from "antd";
import PropTypes from "prop-types";
import cn from "classnames";
import { withTranslation } from "react-i18next";

import HistoryItemChange from "./HistoryItemChange";
import { formatDate, formatTime } from "../../utils/formatDate";
import LinkedItem from "../common/UI/LinkedItem";
import Comment from "./Comment";

import styles from "./history.less";

class HistoryItem extends React.PureComponent {
  linkedItem(item) {
    const { recordId, catalogId, recordTitle } = item.toJS();
    const { sectionId, catalogIcon } = this.props;
    return (
      <LinkedItem
        item={{
          icon: catalogIcon,
          text: recordTitle
        }}
        classNameItem={styles.recordTitle}
        params={{
          sectionId,
          catalogId,
          recordId
        }}
      />
    );
  }

  render() {
    const { item, i18n: { language } } = this.props;
    let content = null;
    let contentChanges = null;
    switch (item.get("actionType")) {
      case "COMMENT":
        content = <Comment message={item.getIn(["payload", "message"])} />;
        break;
      case "REMOVED":
        content = (
          <span className={styles.titleText}>
            {this.props.t("record.history.type.delete")}
          </span>
        );
        break;
      case "CREATE":
        content = (
          <span className={styles.titleText}>
            {this.props.t("record.history.type.create")}
          </span>
        );
      case "UPDATE":
        let fields = this.props.fields;
        if (!fields) {
          break;
        }
        contentChanges = fields.map(field => {
          const change = item.getIn(["payload", field.get("id")]);
          if (change) {
            return (
              <HistoryItemChange
                key={field.get("id")}
                field={field}
                change={change}
                isNewRecord={item.get("actionType") === "CREATE"}
              />
            );
          }
          return null;
        });

        break;
      default:
        break;
    }

    let date = new Date(item.get("date"));
    let showTime = date.getFullYear() === new Date().getFullYear();

    return (
      <Row
        type="flex"
        justify="space-between"
        key={item.get("id")}
        className={cn(styles.itemContainer, {
          [styles.itemContainerBig]: this.props.withRecordTitle
        })}
      >
        <Row type="flex" className={styles.userfield}>
          <div className={styles.fieldHeader}>
            {this.props.withRecordTitle && (
              <div className={styles.recordHeaderWrapper}>
                <div
                  title={formatDate(date, language, true)}
                  className={styles.userfieldDate}
                >
                  {formatTime(date)}
                </div>
              </div>
            )}
          </div>
          <div className={styles.fieldBody}>
            <div className={styles.recordHeaderWrapper}>
              {this.props.withRecordTitle && (
                <div className={styles.recordTitleWrapper}>
                  {this.linkedItem(item)}
                </div>
              )}
              {!this.props.withRecordTitle && (
                <div
                  title={formatDate(date, language, true)}
                  className={styles.userfieldDateNonTitle}
                >
                  <span className={styles.actionDate}>
                    <small>{formatDate(date, language)}</small>
                  </span>
                  &nbsp;
                  <small>{showTime && formatTime(date)}</small>
                </div>
              )}
              <span className={styles.userName}>
                {item.getIn(["user", "title"])}
              </span>
            </div>
          </div>
        </Row>
        {content && (
          <Row type="flex" className={styles.userfield}>
            <div className={styles.fieldHeader} />
            <div className={styles.fieldBody}>{content}</div>
          </Row>
        )}
        {contentChanges}
      </Row>
    );
  }
}

HistoryItem.ropTypes = {
  item: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  sectionId: PropTypes.string,
  catalogIcon: PropTypes.string
};

export default withTranslation()(HistoryItem);
