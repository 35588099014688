import React, { useEffect, useState } from 'react';
import ButtonClose from '../../../../../common/UI/ButtonClose';
import { withTranslation } from 'react-i18next';
import messageActions from '../../../../../../actions/messageActions';
import Icon from '../../../../../common/UI/Icon';
import TextMessage from '../../Messages/TextMessage';

import styles from "./chatEditMessageHeader.less";

const ChatEditMessageHeader = ({ t, params, text }) => {

    const [textView, setTextView] = useState("");

    useEffect(() => {
        setTextView(text)
    }, []);

    const onCancel = () => {
        messageActions.cancelEditMessage(params);
    };

    return (
        <div className={styles.headerContainer}>
            <div className={styles.headerMessage}>
                <Icon className={styles.headerIcon} type={"icon edition-32"} />
                <div className={styles.headerTitleAndText}>
                    <strong className={styles.headerTitle}>{t("record.chat.editMessage.header")}</strong>
                    <TextMessage text={textView} />
                </div>
            </div>
            <ButtonClose large shiftRight onClick={onCancel} />
        </div>
    );
};

export default withTranslation()(ChatEditMessageHeader);