import React from "react";
import _ from "lodash";
import Immutable from "immutable";

import FIELD_TYPES from "../../configs/fieldTypes";
import { VALUE_STATUSES } from "../../configs/import";

export default class Switch {
  static type = FIELD_TYPES.SWITCH;

  static components = {
    inline: require("../../components/common/dataTypes/SwitchField").default,
    control: require("../../components/common/UI/ControlList/controls/Switch").default
  };

  static getComponent = type => Switch.components[type];

  static parseValue = (value, field) => {
    // clean
    value = _.trim(value);

    if (Switch.validateValue(value, field)) {
      return { value: value, status: VALUE_STATUSES.VALID };
    }

    // try parse as empty value
    if (Switch.isEmpty(value)) {
      value = Switch.getEmptyValue();
      return { value, status: VALUE_STATUSES.VALID };
    }

    return { value, status: VALUE_STATUSES.INVALID };
  };

  static validateValue = (value, field) => {
    return typeof normalizeValue(value) === "boolean"; 
  };

  static getEmptyValue = () => {
    return false;
  };

  static isEmpty = value => value === null || value === undefined;

  static valueIs = (value, type) => {
    return typeof value === type;
  };

  static calcId = value => String(value);

  static setValue = (value, newValue) => newValue;

  static removeItem = () => {
    return null;
  };

  static convertIdToValue = (field, id) => normalizeValue(id);
  
  static receivePossibleItems = (field, records, filters) => {
    return filters ? filters : Immutable.List([true, false]);
  };

  static sortValues = (_, values) => {
    return values;
  };

  static compare = (value1, value2) => {
    return value1 === value2;
  };

  static hasItem = (__, value, id) => {
    return value;
  };

  static createComponent = (field, value, type) => {
    const Component = Switch.components[type];
    return ({ containerClassName }) => {
      if (Switch.isEmpty(value)) {
        return null;
      }
      return (
        <Component
          config={field.get("config")}
          value={value}
          containerClassName={containerClassName}
        />
      );
    };
  };
  static convertFilterToRecordValue = () => {
    return undefined;
  };
  static getDefaultValue = field => {
    const defaultValue = field.getIn(["config", "defaultEmptyValue"]);
    return defaultValue;
  };

  static validateRequired = (value) => {
    return false; 
  }

  static boardWitchColor = () => {
    return false;
  }

  static visibleRules = (value) => {
    let v = value;
    v = v === "true" ? true : v;

    // тут стоит пояснить почему массив [ false, "$empty" ]
    // проблема возникает когда по значению = false устанавливается усл. вид. для другого поля
    // при создании новой записи в поле переключателя еще нет никакого значения
    // если углубляться глубже и идти в функцию calcVisibleControls, которая использует в себе магический matcher
    // в matcher происходит сравнение массива правил И массива значений из записи
    // получается так, что в момент создания записи у переключателя нет никакого значения, а массив правил в себе имеет правило [false]
    // по этому нам нужно сравнивать не только на false, но еще и на пустоту. 
    v = v === "false" ? [ false, "$empty" ] : v;
    v = { $in: v };
    
    return v;
  }

  static sortRecordsInCards = (field, records) => {
    const fieldId = field.get("id");
    // sort by value
    return records.sortBy(r => r.getIn(["values", fieldId]));
  }

  static checkChangeYourself = (field, value) => {
    // тут свич кейсом опишем какие типы можно изменять вот например пока нельзя изменять емаил
    const type = field.getIn(["config", "type"]);
    switch (type) {
      default:
        return false
    }
  }
}


function normalizeValue(value) {
  if (_.lowerCase(value) === "true" || _.lowerCase(value) === "false") {
    return _.lowerCase(value) === "true" ? true : false;
  } 

  return value;
}