import React from "react";
import debug from "debug";
import PureRenderMixin from "react-addons-pure-render-mixin";
import ImmutablePropTypes from "react-immutable-proptypes";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";

import SelectValue from "./Select/Value";
import SelectValueFunction from "./Select/ValueFunction";
import SelectAxis from "./Select/Axis";
import SelectMulticolor from "./Select/Multicolor";
import SelectSplit from "./Select/Split";
import SelectSort from "./Select/Sort";
import getDefaultSort from "../../../Widget/chart/utils/getDefaultSort";
import SelectSortType from "./Select/SortType";
import getDefaultSortType from "../../../Widget/chart/utils/getDefaultSortType";
import SelectRecordsType from "./Select/RecordsType";
import SelectStacked from "./Select/Stacked";
import styles from "../../popup.less";

const log = debug("Widget:Config:Data:");

class WidgetData extends React.PureComponent {
  UNSAFE_componentWillReceiveProps({ widget, onChange }) {
    if (this.props.widget !== widget) {
      log("changed", widget.toJSON());
    }

    // default values
    let stacked = widget.getIn(["stacked"]);
    // undefined is for new widget
    if (typeof stacked === "undefined") {
      onChange({ stacked: true });
    }
    let multicolor = widget.getIn(["multicolor"]);
    if (typeof multicolor === "undefined") {
      onChange({ multicolor: true });
    }
    let recordsType = widget.getIn(["recordsType"]);
    if (typeof recordsType === "undefined") {
      onChange({ recordsType: "all" });
    }
  }

  render() {
    const { catalog, widget, onChange, t } = this.props;
    const fields = catalog.get("fields");

    const value = widget.getIn(["value"]);
    const valueFn = widget.getIn(["valueFn"]);
    const axis = widget.getIn(["axis"]);
    const split = widget.getIn(["split"]);
    const sort = widget.getIn(["sort"]);
    const sortType = widget.getIn(["sortType"]);
    const stacked = widget.getIn(["stacked"]);
    const multicolor = widget.getIn(["multicolor"]);

    let accesstype = widget.getIn(["recordsType"]);
    accesstype = typeof accesstype === "undefined" ? "all" : accesstype;


    return (
      <div className={styles.dataContainer}>
        <Row type="flex" align="middle" className={styles.dataRow}>
          <Col xs={4} className={styles.dataColumn}>
            <strong>
              {t("reports.widget.modals.common.tabs.data.value.label")}
            </strong>
          </Col>
          <Col xs={10} className={styles.dataColumn}>
            <SelectValue
              showSearch
              fields={fields}
              value={value}
              onChange={value => onChange({ value, valueFn: null })}
            />
          </Col>
          <Col xs={10} className={styles.dataColumn}>
            <Row type="flex" align="middle">
              <Col xs={2}>
                {valueFn ? <span className={styles.valueFnText}>f</span> : null}
              </Col>
              <Col xs={22}>{
                value ? (
                  <SelectValueFunction
                    fields={fields}
                    value={value}
                    selectedFn={valueFn}
                    onChange={value => onChange({ valueFn: value })}
                  />
                ) : null
              }</Col>
            </Row>
          </Col>
        </Row>

        <Row type="flex" align="middle" className={styles.dataRow}>
          <Col xs={4} className={styles.dataColumn}>
            <strong>
              {t("reports.widget.modals.common.tabs.data.axis.label")}
            </strong>
          </Col>
          <Col xs={10} className={styles.dataColumn}>
            <SelectAxis
              showSearch
              fields={fields}
              value={axis}
              onChange={value => onChange({ axis: value })}
            />
          </Col>
          <Col xs={10} className={styles.dataColumn}>
            <SelectMulticolor
              fields={fields}
              axis={axis}
              value={multicolor}
              onChange={value => onChange({ multicolor: value })}
            />
          </Col>
        </Row>

        <Row type="flex" align="middle" className={styles.dataRow}>
          <Col xs={4} className={styles.dataColumn}>
            <span className={styles.labelLight}>
              {t("reports.widget.modals.common.tabs.data.split.label")}
            </span>
          </Col>
          <Col xs={10} className={styles.dataColumn}>
            <SelectSplit
              showSearch
              fields={fields}
              value={split}
              onChange={value => onChange({ split: value })}
            />
          </Col>
          <Col xs={10} className={styles.dataColumn}>
            <SelectStacked
              value={stacked}
              onChange={value => onChange({ stacked: value })}
            />
          </Col>
        </Row>

        <Row type="flex" align="middle" className={styles.dataRow}>
          <Col xs={4} className={styles.dataColumn}>
            <strong>
              {t("reports.widget.modals.common.tabs.data.sort.label")}
            </strong>
          </Col>
          <Col xs={10} className={styles.dataColumn}>
            <SelectSort
              value={sort || getDefaultSort(axis, fields)}
              onChange={value => onChange({ sort: value })}
            />
          </Col>
          <Col xs={4} className={styles.dataColumn}>
            <SelectSortType
              value={sortType || getDefaultSortType(axis, fields)}
              onChange={value => onChange({ sortType: value })}
            />
          </Col>
        </Row>

        <Row type="flex" align="middle" className={styles.dataRow}>
          <Col xs={4} className={styles.dataColumn}>
            <strong>
              {t("reports.widget.modals.common.tabs.data.records.label")}
            </strong>
          </Col>
          <Col xs={20} className={styles.dataColumn}>
            <SelectRecordsType
              catalog={catalog}
              value={accesstype}
              onChange={value => onChange({ recordsType: value })}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

WidgetData.propTypes = {
  catalog: ImmutablePropTypes.map.isRequired,
  widget: ImmutablePropTypes.map.isRequired,
  onChange: PropTypes.func.isRequired
};

export default withTranslation()(WidgetData);
