import React from "react";
import { EventEmitter } from "events";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import cn from "classnames";

import Icon from '../common/UI/Icon';
import ButtonClose from "../common/UI/ButtonClose";

import fieldTypeIcons from "../../configs/fieldTypeIcons";
import FIELD_TYPES from "../../configs/fieldTypes";

import styles from "./filter.less";

class FilterItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      opened: !!this.props.value //this.props.opened
    };
  }

  openFilter = () => {
    this.setState({ opened: true }, () => {
      this.props.onOpen && this.props.onOpen();
    });
  };

  closeFilter = () => {
    if (!this.props.readOnly) {
      this.setState({ opened: false }, () => {
        this.props.onDrop && this.props.onDrop();
      });
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    let opened;

    // if provided a Owner of filter list
    // if Owner changed, all filter should collapse
    const openedDependOnOwner = !!nextProps.ownerId;
    if (openedDependOnOwner && nextProps.ownerId !== this.props.ownerId) {
      opened = false;
    }

    // if value change, need to change Open state
    if (nextProps.value !== this.props.value) {
      // nextProps.value == null => not set value
      // nextProps.value == udefined => setted value to nothing, but filter is opened
      if (nextProps.value === null) {
        // value is set to nothing
        if (!openedDependOnOwner) {
          opened = false;
        }
      } else {
        opened = !!nextProps.value || this.state.opened;
      }
    }

    // is open strate is changed
    if (opened !== undefined && opened !== this.state.opened) {
      this.setState({ opened });
    }
  }

  render() {
    const { name, type, filterInPopover, className, readOnly } = this.props;
    const fieldIcon = fieldTypeIcons[type];

    if (type == FIELD_TYPES.GROUP) {
      return <div className={styles.filterItemGroup}>{name}</div>;
    }

    if (!this.state.opened) {
      return (
        <div className={cn(styles.filterItem, className)}>
          <div
            className={cn(styles.filterHeader)}
            onClick={this.openFilter}
            title={name}
          >
            <Icon
              className={styles.filterHeaderIcon}
              type={"icon " + fieldIcon}
            />
            <span className={styles.filterHeaderLabel}>
              {name}
              <Icon
                className={styles.filterHeaderExpandIcon}
                type={"icon arrows-chevron-medium-thin-4-01"}
              />
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={cn(
            styles.filterItem,
            styles.filterItemOpened,
            className,
            filterInPopover ? styles.filterItemOpenedInPopover : null
          )}
        >
          <div
            className={cn(styles.filterHeader)}
            onClick={this.closeFilter}
            title={this.props.t("filter.close")}
          >
            <Icon
              className={styles.filterHeaderIcon}
              type={"icon " + fieldIcon}
            />
            <span className={styles.filterHeaderLabel}>{name}</span>
            {!readOnly && (
              <ButtonClose
                onClick={this.closeFilter}
                className={styles.filterClose}
                small
              />
            )}
          </div>

          <div className={styles.filterBody}>{this.props.children}</div>
        </div>
      );
    }
  }
}

FilterItem.propTypes = {
  ownerId: PropTypes.string, // to know when to collapse all filters
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onOpen: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  readOnly: PropTypes.bool
};

export default withTranslation()(FilterItem);
