import React from "react";
import moment from "moment";

import { compareTimeCount } from "../../helpers";
import { connect } from "../../../StateProvider";
import EventContent from "./EventContent";

import styles from "../../calendar.less";

const BigCalendarEvent = ({ start, end, values, fields, title }) => {
  if (!values) {
    return title;
  }

  const eventTimeZero =
    moment(start).format("HH:mm") === "00:00" &&
    moment(start).format("HH:mm") === "00:00";

  if (compareTimeCount(start, end, 30) && !eventTimeZero) {
    return <span className={styles.eventTitle}>{title}</span>;
  }

  return (
    <EventContent
      title={title}
      values={values}
      fields={fields}
      className={styles.eventContent}
    />
  );
};

export default connect(
  BigCalendarEvent,
  ["catalogs"],
  (props, { catalogs }) => {
    const { catalogId, title, values, start, end } = props.event;
    const fields = catalogs.getIn([catalogId, "fields"]);

    return {
      start,
      end,
      title,
      values,
      fields
    };
  }
);
