import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import { Row } from "antd";
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';

import { formatDate } from "../../utils/formatDate";
import HistoryItem from "./HistoryItem";
import { connect } from "../StateProvider";
import Loading from "../common/Loading";

import styles from "./history.less";

class HistoryData extends React.PureComponent {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.list = React.createRef();
    this.state = {
      historyList: [],
      scroll: null,
      frontier: null
    };
  }

  onScroll = () => {
    const scroll = $(ReactDOM.findDOMNode(this.node.current)).scrollTop();
    this.setState({ scroll });
  };

  componentDidUpdate(prevProps, prevState) {
    const listHeight = $(this.list.current).innerHeight();
    const scroll = this.node.current.scrollTop;

    const catalogId = this.props.catalog.get("id");
    const historyLoading = this.props.catalogsHistory.getIn([
      catalogId,
      "loading"
    ]);
    if (
      listHeight &&
      scroll >= listHeight - window.innerHeight &&
      !historyLoading
    ) {
      this.props.loadData(false);
    }
  }

  componentDidMount() {
    $(this.node.current).on("scroll", this.onScroll);
  }

  componentWillUnmount() {
    $(this.node.current).off("scroll", this.onScroll);
  }

  render() {
    const { catalog, fields, i18n: { language } } = this.props;
    const catalogId = catalog.get("id");
    const history = this.props.catalogsHistory.get(catalogId);
    const historyLoading = history && history.get("loading");
    const historyItems = history && history.get("items");
    let date,
      historyList = [];
    if (historyItems) {
      historyItems.forEach(h => {
        const dateItem = new Date(h.get("date"));
        const dayItem = dateItem.getDate();

        if (!date || date !== dayItem) {
          date = dayItem;
          historyList.push(
            <Row
              type="flex"
              align="middle"
              key={h.get("id") - 0.5}
              className={styles.dateRow}
            >
              {formatDate(dateItem, language)}
            </Row>
          );
        }

        historyList.push(
          <HistoryItem
            key={h.get("id")}
            item={h}
            fields={fields}
            withRecordTitle={true}
            sectionId={catalog.get("sectionId")}
            catalogIcon={catalog.get("icon")}
          />
        );
      });
    }
    let dataLoading = null;
    if (historyLoading) {
      dataLoading = <Loading className={styles.loading} />;
    }
    return (
      <div ref={this.node} className={styles.historyContainer}>
        <div ref={this.list}>{historyList}</div>
        {dataLoading}
      </div>
    );
  }
}

HistoryData.propTypes = {
  catalog: PropTypes.object.isRequired
};

export default withTranslation()(connect(
  HistoryData,
  ["catalogsHistory"]
));
