import React from "react";
import { Col } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { formatDate } from "../../../../utils/formatDate";
import LinkedRecordOpen from "../../LinkedRecordOpen";
import styles from "./linkedData.less";

const LinkedRecord = ({ record, catalogId, sectionId, sceneId }) => {
  const { i18n } = useTranslation();
  return (
    <LinkedRecordOpen
      sceneId={sceneId}
      wrapperClassName={styles.linkedRecordContainer}
      params={{
        sectionid: sectionId,
        catalogId: catalogId,
        recordId: record.get("id")
      }}
      linkProps={{
        className: styles.linkedRecordRow,
        children: [
          <Col key="title" xs={20} className={styles.linkedRecordTitle}>
            <span className={styles.linkedRecordId}>{record.get("id")}</span>
            <span>{record.get("title")}</span>
          </Col>,
          <Col key="date" xs={4} className={styles.linkedRecordDate}>
            <span>
              <small>{formatDate(record.get("created"), i18n.language)}</small>
            </span>
          </Col>
        ]
      }}
    />
  );
};

LinkedRecord.propTypes = {
  record: PropTypes.object.isRequired,
  catalogId: PropTypes.string
};

export default LinkedRecord;
