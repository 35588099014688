import React, { useMemo } from "react";
import { Popover } from "antd";
import cn from "classnames";
import FIELD_TYPES from "../../../../configs/fieldTypes";

import FieldApi from "../../../../models/FieldApi";

import styles from "./table.less";

const classNames = {
  [FIELD_TYPES.TEXT]: styles.textControlWrapper,
  [FIELD_TYPES.PROGRESS]: styles.progressControlWrapper,
  [FIELD_TYPES.ADDRESS]: styles.addressControlWrapper,
  [FIELD_TYPES.CONTACT]: styles.contactControlWrapper,
  [FIELD_TYPES.FILE]: styles.fileControlWrapper
};

const FieldPopover = props => {
  const EditableField = useMemo(
    () => FieldApi.getComponent(props.field, "control"),
    [props.field]
  );
  const InlineField = useMemo(
    () => FieldApi.getComponent(props.field, "inline"),
    [props.field]
  );

  const getPopoverContent = () => {
    const { field, value, controlConfig } = props;

    return (
      <div
        onClick={e => e.stopPropagation()}
        onKeyDown={e => e.stopPropagation()}
        className={styles.fieldPopover}
      >
        <EditableField
          value={value}
          editable={true}
          autoFocus={true}
          onChange={props.onChange}
          config={field.get("config")}
          fieldId={field.get("id")}
          fieldType={field.get("type")}
          controlConfig={controlConfig}
          style={{ minWidth: 250 }}
          wrapperClassName={classNames[field.get("type")]}
        />
      </div>
    );
  };

  return (
    <Popover
      placement={"bottomLeft"}
      content={getPopoverContent()}
      defaultOpen={true}
      trigger={"click"}
      // getPopupContainer
      onOpenChange={visible => !visible && props.onEndEditing()}
    >
      <InlineField
        fieldId={props.field.get("id")}
        config={props.field.get("config")}
        value={props.originalValue}
        fieldType={props.field.get("type")}
        containerClassName={cn(props.fieldClassName, styles.cellField)}
      />
    </Popover>
  );
};

export default FieldPopover;
