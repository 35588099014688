import Reflux from "reflux";

import makeApiRequest from "../utils/makeApiRequest";
import createAsyncAction from "../helpers/createAsyncAction";

const actions = {
  loadBatchRecords: Reflux.createAction({ sync: true }),

  startBatchUpdateRecords: Reflux.createAction({ sync: false }),
  startBatchDeleteRecords: Reflux.createAction({ sync: false }),
  pauseBatchRecords: Reflux.createAction({ sync: false }),

  changefieldsEditableStatus: Reflux.createAction({ sync: false }),
  changeBatchUpdateValueActions: Reflux.createAction({ sync: false }),

  updateBatchUpdateRecordValues: Reflux.createAction({ sync: false }),
  clearBatch: Reflux.createAction({ sync: false }),
  changeBatchStatus: Reflux.createAction({ sync: false })
};

actions.getBatchRecords = createAsyncAction(function(
  params,
  query,
  actionParams
) {
  return makeApiRequest(
    "catalogs/" + params.catalogId + "/records/",
    {
      query
    },
    true
  )
    .then(response => {
      this.completed &&
        this.completed(response.body, params, query, response, actionParams);
      return response;
    })
    .catch(err => {
      this.failed && this.failed(err, params, query, actionParams);
      throw err;
    });
});

export default actions;
