import React, { useState } from "react";
import _ from "lodash";
import { Empty } from "antd";
import { withTranslation } from "react-i18next";
import { Virtuoso } from "react-virtuoso";
import { Image } from "antd";

import Message from "./Message/Message";
import apiActions from "../../../../../actions/apiActions";
import messageActions from "../../../../../actions/messageActions";
import ChatStickScrollToBottom from "../ChatStickScrollToBottom/ChatStickScrollToBottom";
import Immutable from "immutable";
import MessageDeleted from "./Message/MessageDeleted";
import { formatDate } from "../../../../../utils/formatDate";

import styles from "./messages.less";

const INITIAL_ITEM_COUNT = 50;

const Messages = ({
  total,
  catalogId,
  recordId,
  messages,
  firstLoad,
  countNewMessages,
  lastScrollPosition,
  firstItemIndex,
  user,
  loadingMessages,
  msgerRef,
  allLoadedMessages,
  startNewMessages,
  t,
  scrollToBottom,
  openRecord,
  inputFocus
}) => {
  const [isShowScrollBtn, setIsScrollBtn] = useState(false);

  // понятно уж что тут будет id
  const removeMessage = id => {
    apiActions.deleteMessage({ catalogId, recordId, messageId: id });
  };

  const getReplyMessage = id => {
    inputFocus();
    messageActions.getReplyMessage({ catalogId, recordId, messageId: id });
  };

  const editMessage = id => {
    messageActions.getEditMessage({ catalogId, recordId, messageId: id });
  };

  const copyText = (id, text) => {
    messageActions.copyTextToClipboard(
      { catalogId, recordId, messageId: id },
      text
    );
  };

  const resendMessage = message => {
    messageActions.resendMessage(message);
  };

  const cancelResendMessage = message => {
    messageActions.cancelResendMessage(message);
  };

  const loading = () => {
    return loadingMessages;
  };

  const scroll = () => {
    scrollToBottom("smooth");
  };

  const fethMoreMessagesDebounce = _.debounce(() => {
    messageActions.loadMessages({ catalogId, recordId }, false, 50);
  }, 300);

  const fetchMoreMessages = () => {
    if (!loading() && !allLoadedMessages) {
      fethMoreMessagesDebounce();
    }
  };

  let isNewMessages = false;
  const itemContent = (i, message) => {
    message = Immutable.fromJS(message);
    const index = messages.indexOf(message);
    let lastMessage = false;

    if (index == messages.size - 1) {
      lastMessage = true;
    }

    let isTitleDate =
      formatDate(message.get("createdDate")) >
      formatDate(messages.getIn([index - 1, "createdDate"]));

    if (index == 0) {
      isTitleDate = formatDate(message.get("createdDate"));
    }

    const isPreviousMessageSameUser = message => {
      if (!Immutable.List.isList(messages)) {
        return false;
      }
      // второй варик
      /* 
            получаем message получаем его индекс у массива выше, 
            далее нам нужно вычесть индекс - 1 для того чтобы достать пред сообщение,  
            */

      const index = messages.indexOf(message);
      const prevIndex = index - 1;
      const prevMessage = messages.get(prevIndex);
      if (!_.isObject(prevMessage)) {
        return false;
      }
      if ((!prevMessage && !prevMessage.size) || prevIndex == -1) {
        return false;
      }
      const currentUserId = message.getIn(["author", "recordId"]);
      const prevUserId = prevMessage.getIn(["author", "recordId"]);
      return !isTitleDate && currentUserId == prevUserId;
    };

    if (
      !isNewMessages &&
      message.get("newMessage") !== messages.getIn([index - 1, "newMessage"])
    ) {
      isNewMessages = message.get("newMessage");
    } else {
      isNewMessages = false;
    }

    return (
      <div key={i}>
        {isNewMessages && (
          <div className={styles.msgerChatUnreadMessage}>
            {t("record.chat.message.unreadMessage")}
          </div>
        )}
        {isTitleDate && (
          <div
            title={formatDate(message.get("createdDate"))}
            className={styles.msgerChatDateMessagesWrapper}
          >
            <strong className={styles.msgerChatDateMessagesText}>
              {formatDate(message.get("createdDate"))}
            </strong>
          </div>
        )}
        {!message.get("deleted") ? (
          <Image.PreviewGroup>
            <Message
              key={message.get("id")} // id message
              message={message}
              isMe={
                user.get("recordId") == message.getIn(["author", "recordId"])
              }
              id={message.get("id")}
              lastMessage={lastMessage}
              removeMessage={removeMessage}
              getReplyMessage={getReplyMessage}
              editMessage={editMessage}
              isPreviousMessageSameUser={isPreviousMessageSameUser}
              resendMessage={resendMessage}
              cancelResendMessage={cancelResendMessage}
              copyText={copyText}
              openRecord={openRecord}
            />
          </Image.PreviewGroup>
        ) : (
          <MessageDeleted
            key={message.get("id")} // id message
            message={message}
            isMe={user.get("recordId") == message.getIn(["author", "recordId"])}
            id={message.get("id")}
            isPreviousMessageSameUser={isPreviousMessageSameUser}
            lastMessage={lastMessage}
          />
        )}
      </div>
    );
  };

  return (
    <div id="scrollableDiv" className={styles.msgerChat}>
      {messages ? (
        <React.Fragment>
          {loadingMessages === false && messages.size == 0 ? (
            <div className={styles.msgerChatEmpty}>
              <Empty />
            </div>
          ) : (
            <React.Fragment>
              <Virtuoso
                style={{ height: "100%", width: "100%" }}
                firstItemIndex={firstItemIndex}
                initialTopMostItemIndex={INITIAL_ITEM_COUNT - startNewMessages}
                data={messages.toJS()}
                itemContent={itemContent}
                ref={msgerRef}
                atBottomThreshold={500}
                atTopThreshold={1000}
                atTopStateChange={top => {
                  top && fetchMoreMessages();
                }}
                atBottomStateChange={bottom => {
                  setIsScrollBtn(!bottom);
                }}
              />
              <ChatStickScrollToBottom
                isShowScrollBtn={isShowScrollBtn}
                scroll={scroll}
                countNewMessages={countNewMessages}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default withTranslation()(Messages);
