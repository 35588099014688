export default t => ({
  element: "bpmn:EndEvent",
  service: "",
  title: t("script.controlls.endEvent.title"),
  helplink: "",
  class: "event",
  color: "#869d49",
  border: 4,
  icon: "anticon-icon status-9",
  icon_scale: 1.2,
  offset_x: 8,
  offset_y: -24,
  toolbar_offset_x: 5,
  toolbar_offset_y: -18,
  priority: 1,
  config: [
    {
      id: "commonSection",
      name: t("script.common.general"),
      type: "group"
    },
    {
      id: "name",
      name: t("script.common.general"),
      type: "text",
      readOnly: false,
      config: {
        defaultEmptyValue: t("script.controlls.endEvent.title"),
        type: "text",
        valueType: "const",
        map: "input"
      }
    },
    {
      id: "description",
      name: t("script.common.description"),
      valueType: "const",
      type: "text",
      readOnly: false,
      config: {
        type: "multiline",
        valueType: "const",
        map: "input"
      }
    }
  ],
  exits: []
});
