import React, { useState } from "react";
import { Dropdown } from "antd";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import Icon from '../../../common/UI/Icon';
import FieldConfig from "../../../common/FieldConfig";
import ButtonTransparent from "../../../common/UI/ButtonTransparent";
import Overlay from "./Overlay";

import availableFieldTypes from "../../availableFieldTypes";

import styles from "./config.less";

const Config = ({
  view,
  settings,
  catalogId,
  fields,
  userSettingsHandler,
  suggestRecords
}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const fieldsforFilter = fields.filter(field =>
    availableFieldTypes.filterableFields.includes(field.get("type"))
  );

  return (
    <div className={styles.config}>
      <ButtonTransparent
        className={styles.buttonConfig}
        title={t("calendar.config.suggestRecords")}
        onClick={() => userSettingsHandler(!suggestRecords, "suggestRecords")}
      >
        <Icon
          type="icon text-52"
          className={cn(styles.suggestRecordsIcon, {
            [styles.activeIcon]: suggestRecords
          })}
        />
      </ButtonTransparent>
      <div title={t("calendar.config.groupingOptions")}>
        <Dropdown 
          dropdownRender={() => (
            <Overlay
              settings={settings}
              fields={fields}
              view={view}
              catalogId={catalogId}
              userSettingsHandler={userSettingsHandler}
            />
            )
          }
          trigger={["click"]}
          placement="bottomRight"
          onOpenChange={() => setVisible(!visible)}
          open={visible}
        >
          <ButtonTransparent className={styles.buttonConfig}>
            <Icon type="icon setting-10" />
          </ButtonTransparent>
        </Dropdown>
      </div>
      <FieldConfig
        catalogId={catalogId}
        fields={fieldsforFilter}
        viewMode={"calendar"}
        defaultVisible={false}
      />
    </div>
  );
};

export default Config;
