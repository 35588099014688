import React from "react";
import { Row } from "antd";
import { useTranslation } from "react-i18next";

import Dropdown from "../../../../common/Dropdown";

import { getAvailableSortFields } from "../../../helpers";
import styles from "../config.less";

const SortField = props => {
  const { t } = useTranslation();
  const value = props.settings.getIn(["sortField", "key"]);
  const items = getAvailableSortFields(props.fields);
  return items ? (
    <Row className={styles.controlRow}>
      <label className={styles.controlLabel}>
        {t("calendar.config.sortField")}
      </label>
      <Dropdown
        disabled={!items.length}
        items={[...items, { text: "№ записи", key: "id" }]}
        value={value}
        autocomplete={true}
        withButton={true}
        multiselect={false}
        onSelectItems={([item]) => props.handler(item, "sortField")}
      />
    </Row>
  ) : null;
};

export default SortField;
