import _ from "lodash";
import React, { Component } from "react";
import Timer from "../../common/Timer";
import reportsActions from "../../../actions/reports";

import LoadingSpin from "../../common/LoadingSpin";

import styles from "./header.less";

const updateInterval = 10 * 60 * 1000; //10 minutes

export default class ReportsHeaderTimer extends Component {
  state = {
    nextUpdate: null
  };
  componentDidMount() {
    this.startUpdate();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { board } = this.props;
    const { board: nextBoard } = nextProps;
    if ((board && board.get("id")) !== (nextBoard && nextBoard.get("id"))) {
      this.startUpdate(nextBoard);
    }
  }

  startUpdate(board = this.props.board) {
    this.setState({ nextUpdate: Date.now() + updateInterval });
    /*board && reportsActions.updateBoardSystem(board.get("id"), {
        nextUpdate: Date.now() + updateInterval
      });*/
  }

  update() {
    const { board } = this.props;
    if (board) {
      this.startUpdate();
      reportsActions.getBoardWithWidgets(board.get("id"));
    }
  }

  updateThrottled = _.throttle(() => this.update(), 2000, {
    leading: true,
    trailing: false
  });

  render() {
    return (
      <li className={styles.listItem} onClick={this.updateThrottled}>
        <LoadingSpin spin={false} className={styles.listItemIcon} />
        <Timer
          nextTime={this.state.nextUpdate}
          onAlarm={this.updateThrottled}
        />
      </li>
    );
  }
}
