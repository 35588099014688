import { DragSource, DropTarget } from "react-dnd";
import dndTargets from "../../../../../configs/dndTargets";
import dragAndDropActions from "../../../../../actions/dragAndDropActions";

export const dragSource = DragSource(
  dndTargets.SIDEBAR_ITEM,
  {
    beginDrag(props) {
      const item = {
        key: props.item.key,
        dragType: props.dragType
      };
      return item;
    },
    endDrag(props) {
      dragAndDropActions.endDrag();
      props.onDragEnd(props.item.key);
    },
    canDrag(props, monitor) {
      if (props.canDrag) {
        return props.canDrag(props.item.key);
      }
    }
  },
  function collect(connect, monitor) {
    return {
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging()
    };
  }
);

export const dropTarget = DropTarget(
  dndTargets.SIDEBAR_ITEM,
  {
    hover(props, monitor) {
      if (monitor.canDrop()) {
        const item = monitor.getItem();
        props.onMoveItem(item.key, props.item.key);
      }
    },
    canDrop(props, monitor) {
      return props.dragType === monitor.getItem().dragType;
    }
  },
  function collect(connect, monitor) {
    return {
      connectDropTarget: connect.dropTarget()
    };
  }
);
