import React from "react";
import cn from "classnames";

import { compareTimeCount } from "../../helpers";

import styles from "../../calendar.less";
import { DragSource } from "react-dnd";

import DND_TARGETS from "../../../../configs/dndTargets";

const dragSource = DragSource(
  DND_TARGETS.CALENDAR_EVENT,
  {
    canDrag(props) {
      return true;
    },

    beginDrag(props, monitor, component) {
      const { event } = props;
      return {
        recordId: event.recordId
      };
    }
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  })
);

const EventWrapper = props => {
  const { end, start } = props.event;
  const className = cn({
    [styles.floatLabel]: compareTimeCount(start, end, 30)
  });

  return props.connectDragSource(
    <div className={className} style={{ opacity: props.isDragging ? 0.4 : 1 }}>
      {props.children}
    </div>
  );
};

export default dragSource(EventWrapper);
