import _ from "lodash";
import availableValues from "../../../../../../../configs/reports/widget/availableSortValues";

import BasicSelect from "./Basic";

class SelectSort extends BasicSelect {
  getAvailableValues() {
    return availableValues;
  }

  getDefaultValue() {
    // need to be, but be empty
  }

  onChange = item => {
    const returnValue = item && item.value;
    this.props.onChange(returnValue);
  };
}

export default SelectSort;
