import Reflux from "reflux";
import makeApiRequest from "../utils/makeApiRequest";
import createAsyncAction from "../helpers/createAsyncAction";

const actions = {
  changeRecordValue: Reflux.createAction({ sync: true }),
  changeColumnField: Reflux.createAction({ sync: true }),
  parseExcel: Reflux.createAction({ sync: true }),

  importData: Reflux.createAction({ sync: true }),
  stopImport: Reflux.createAction({ sync: true }),
  removeRecord: Reflux.createAction({ sync: true }),
  restoreRecord: Reflux.createAction({ sync: true }),

  filterRecords: Reflux.createAction({ sync: true }),
  setQueryParams: Reflux.createAction({ sync: true }),
  setImportFilters: Reflux.createAction({ sync: true }),
  setImportSearchText: Reflux.createAction({ sync: true }),

  clearImportData: Reflux.createAction({ sync: true })
};

actions.loadUsers = createAsyncAction(function(params) {
  const query = {};
  if (params.title) {
    query.title = params.title;
  }
  return makeApiRequest("users", { query }, true)
    .then(res => {
      const users = res.body.map(o => {
        return {
          key: o.id,
          text: o.title,
          icon: "users-1",
          alwaysVisible: o.alwaysVisible,
          sort: ~~o.sort
        };
      });

      this.completed(params, users);
      return users;
    })
    .catch(err => {
      return err;
    });
});

actions.loadLinkedItems = createAsyncAction(function(params) {
  const query = {};
  if (params.title) {
    query.title = params.title;
  }
  return makeApiRequest(
    "catalogs/" +
      params.catalogId +
      "/fields/" +
      params.fieldId +
      "/availableRecords",
    { query },
    true
  )
    .then(res => {
      const items = res.body.map(o => {
        return {
          key: o.catalogId + ":" + o.recordId,
          text: o.recordTitle,
          icon: o.catalogIcon,
          item: o
        };
      });

      this.completed(params, items);
      return items;
    })
    .catch(err => {
      return err;
    });
});

export default actions;
