import React from "react";
import PropTypes from "prop-types";
import BaseNumberField from "../NumberField";

const NumberField = props => (
  <BaseNumberField
    value={props.value}
    config={props.config}
    withUnit={true}
    {...props}
  />
);

NumberField.propTypes = { config: PropTypes.object.isRequired };

export default NumberField;
