import _ from "lodash";
import React from "react";
import Immutable from "immutable";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import RecordDropdown from "../../common/UI/ControlList/controls/RecordDropdown";
import availableLinkedRecordActions from "../../../actions/availableLinkedRecordActions";
import { objectFilters, userFilters } from "../../../configs/filterKeys";
import AppState from "../../../appState";
import { connect } from "../../StateProvider";

import Filters from "../";
import styles from "../filter.less";

class ObjectField extends React.PureComponent {
  inMapper = item => {
    return {
      key:
        item.get("catalogId") +
        (item.get("recordId") ? ":" + item.get("recordId") : ""),
      text: item.get("recordTitle"),
      icon: item.get("catalogIcon"),
      item: item
    };
  };

  outMapper = ({ key, text, item }) => {
    let d = key.split(":");
    const result = {
      catalogId: d[0],
      recordTitle: text
    };

    // record can be undefined (will be converted to "undefined")
    if (d[1]) {
      result.recordId = d[1];
    }
    if (item && item.catalogIcon) {
      result.catalogIcon = item.catalogIcon;
    }

    return result;
  };

  filterMapper = ({ key }) => {
    let d = key.split(":");
    const result = { catalogId: d[0] };
    if (d[1]) {
      result.recordId = d[1];
    }
    return result;
  };

  getSelectedItems = () => {
    const { value } = this.props;
    return value && value.filter(i => !i.get("filters"));
  };
  getSelectedItemsPlain = () => {
    const items = this.getSelectedItems();
    return items ? items.toJS() : [];
  };

  getExtendedFilterItems = () => {
    const { value } = this.props;
    return value && value.filter(i => i.get("filters"));
  };
  getExtendedFilterItemsPlain = () => {
    const items = this.getExtendedFilterItems();
    return items ? items.toJS() : [];
  };

  onChange = items => {
    items = items.map(this.outMapper);
    this.onSave(items, null);
  };

  onChangeExtended = (catalogId, fieldId, value) => {
    // get cyrrent filters by extended fields
    let items = this.getExtendedFilterItemsPlain();
    items = _.keyBy(items, "catalogId");

    // create item for this extended catalog
    if (!items[catalogId]) {
      items[catalogId] = {
        catalogId,
        filters: {}
      };
    }

    // create filter for this extended catalog field
    if (!_.isEmpty(value) || value === 0) {
      items[catalogId].filters[fieldId] = [{ fieldId, value }]; // filter is array
    } else {
      delete items[catalogId].filters[fieldId];
    }

    // remove filters if all extended filters be catalog are removed
    items = _.pickBy(items, i => !_.isEmpty(i.filters));

    // set & save
    items = _.values(items);
    this.onSave(null, items);
  };

  onSave = (selectedItems = null, extendedFilterItems = null) => {
    const { onSave, fieldId } = this.props;

    // combine selected items & extendede filters
    selectedItems =
      selectedItems !== null ? selectedItems : this.getSelectedItemsPlain();
    extendedFilterItems =
      extendedFilterItems !== null
        ? extendedFilterItems
        : this.getExtendedFilterItemsPlain();
    const items = _.concat(selectedItems, extendedFilterItems);

    onSave && onSave(fieldId, items);
  };

  sortFn = items => {
    if (_.isArray(items)) {
      let orderedItems = _.sortBy(items, "recordTitle");
      let systemItems = [];
      let dynamicItems = [];
      let otherItems = [];

      items.forEach(item => {
        if (item.catalogId === "$EMPTY") {
          systemItems.push(item);
        } else if (item.catalogId === "USER_FIELD") {
          dynamicItems.push(item);
        } else {
          otherItems.push(item);
        }
      });

      return systemItems.concat(dynamicItems.concat(otherItems));
    }

    return items;
  };

  getExtraItems() {
    const { t } = this.props;
    const items = [];

    let config = this.props.config || Immutable.Map({});

    // any record from catalog
    let catalogs = config.get("catalogs").filter((i) => i !== "*").map(i => i.get("id"));
    catalogs = catalogs.concat(
      config.get("views").map(i => i.get("catalogId"))
    );
    catalogs = catalogs ? catalogs.toJS() : [];
    catalogs = _.uniq(catalogs);
    if (catalogs.length === 1) {
      items.push({
        key: catalogs[0],
        text: t("filter.keys.$HAS"),
        sort: -1
      });
    } else {
      catalogs.forEach(i => {
        const catalog = AppState.getIn(["catalogs"]).find(
          c => c.get("id") == i
        );
        if (catalog) {
          items.push({
            key: catalog.get("id"),
            text:
              "[" +
              t("filter.keys.has_from") +
              " «" +
              catalog.get("name") +
              "»]",
            sort: -1
          });
        }
      });
    }

    // if USER catalog is set as link, add USER special filters
    let hasUserCatalog = !!config
      .get("catalogs")
      .filter(c => c !== "*")
      .find(
        c => String(c.get("id")) === "3" || String(c.get("id")) === "$users"
      );
    hasUserCatalog =
      hasUserCatalog ||
      !!config
        .get("views")
        .find(
          c =>
            String(c.get("catalogId")) === "3" ||
            String(c.get("catalogId")) === "$users"
        );
    if (hasUserCatalog) {
      userFilters.forEach(f => {
        items.push({
          key: "$users:" + f.key,
          text: f.value,
          sort: -1
        });
      });
    }

    return items;
  }

  render() {
    const { fieldId, readOnly, catalogs, withExtendedFilters } = this.props;

    // mutate config for correct work of filter componment
    let config = this.props.config || Immutable.Map({});
    config = config.set("multiselect", true);
    config = config.set("enableSelect", true);
    config = config.set("enableCreate", false);

    // additional filter items
    let additionalItems = [];
    if (objectFilters) {
      objectFilters.forEach(f => {
        additionalItems.push({
          key: f.key,
          text: f.value,
          sort: -1
        });
      });
    }
    // extra items for USER catalog
    additionalItems = additionalItems.concat(this.getExtraItems());
    additionalItems = _.uniqBy(additionalItems, "text"); // to delete double $EMPTY

    // parse value into selected items & extendede filters
    const selectedItems = this.getSelectedItems();
    let extendedFilters = this.getExtendedFilterItems();
    extendedFilters =
      extendedFilters &&
      Immutable.Map(
        extendedFilters.map(v => [v.get("catalogId"), v.get("filters")])
      ); // transform:[{catalogId, filters}, ] => { catalogId: filters, }

    return (
      <div>
        <RecordDropdown
          /* посмотреть используются ли параметры */
          requestParams={{
            fieldId: fieldId,
            catalogId: this.props.catalogId,
            filters: this.props.filters
          }}
          remoteGroup="linkedObjectsWithFilters"
          value={selectedItems && selectedItems.map(this.inMapper)}
          additionalItems={additionalItems}
          config={config}
          readOnly={readOnly}
          apiOnly={false}
          sortFn={this.sortFn}
          onChange={this.onChange}
          loadAvailableItems={availableLinkedRecordActions.loadAvailableItems}
          clearAvailableItems={availableLinkedRecordActions.clearAvailableItems}
        />

        {withExtendedFilters
          ? config
              .get("fields")
              .entrySeq()
              .map(([catalogId, fields]) => {
                let catalogHeader = null;

                // create catalog header
                if (true || config.get("fields").length > 1) {
                  const catalogName = catalogs.getIn([catalogId, "name"]);
                  catalogHeader = catalogName ? (
                    <div className={styles.filterItemGroup}>{catalogName}</div>
                  ) : null;
                }

                // create filters for extendede fields
                if (fields) {
                  // get filters for this catalog
                  let filters =
                    extendedFilters && extendedFilters.get(catalogId);

                  return (
                    <div
                      className={styles.filterExtendedFields}
                      key={catalogId}
                    >
                      {catalogHeader}
                      <Filters
                        catalogId={catalogId}
                        fields={fields}
                        filters={filters} // format Immutable {filterId: [{value}], }
                        readOnly={readOnly}
                        onSave={(filterId, fieldId, value) =>
                          this.onChangeExtended(catalogId, fieldId, value)
                        }
                        withExtendedFilters={false}
                      />
                    </div>
                  );
                }
              })
          : null}
      </div>
    );
  }
}

ObjectField.propTypes = {
  value: PropTypes.object,
  config: PropTypes.object,
  fieldId: PropTypes.string.isRequired,
  catalogId: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  withExtendedFilters: PropTypes.bool
};

export default withTranslation()(
  connect(
    ObjectField,
    {
      catalogs: ["catalogs"]
    }
  )
);
