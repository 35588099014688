import _ from 'lodash';
import _components from "../components";

export default function(businessObject, t) {
  const components = _components.map(c => c(t));

  const { $type } = businessObject;

  switch ($type) {
    case "bpmn:ServiceTask":
      const serviceId = _.chain(businessObject)
        .get("extensionElements.values")
        .find(c => _.get(c, "$type") === "camunda:Connector")
        .get("connectorId")
        .value();

      return (
        serviceId &&
        components.find(c => c.element === $type && c.service === serviceId)
      );

    case "bpmn:BoundaryEvent":
      const boundaryType = _.get(businessObject, "eventDefinitions.0.$type");
      return boundaryType && components.find(c => c.element === boundaryType);
  }

  return components.find(c => c.element === $type);
}
