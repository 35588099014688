import React from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";
import _ from "lodash";
import { matchPath, withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { HistoryOutlined } from "@ant-design/icons";

import apiActions from "../../../actions/apiActions";
import modalsActions from "../../../actions/modalsActions";
import Icon from "../../common/UI/Icon";
import { confirm } from "../../common/Modal";
import getLink from "../../common/router/getLink";
import routes from "../../../routes";

import PRIVILEGE_CODES from "../../../configs/privilegeCodes";
import RESOURCE_TYPES from "../../../configs/resourceTypes";
import { checkAccessOnObject } from "../../../utils/rights";

import Header from "../Header";

import styles from "./recordHeader.less";

const TABS_IDS = {
  MAIN: "main",
  LINKS: "links",
  CHAT: "chat",
  HISTORY: "history"
};

class RecordHeader extends React.Component {
  static propTypes = {
    record: PropTypes.object.isRequired,
    catalog: PropTypes.object.isRequired,
    scene: PropTypes.object.isRequired,
    hasBeenEdit: PropTypes.bool.isRequired,
    onRefresh: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    onCreateRecord: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
    isWebForm: PropTypes.bool,
    isNew: PropTypes.bool,
    isLoading: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  getExtParams = (props = this.props) => {
    const { record, catalog } = props;
    return {
      sectionId: catalog.get("sectionId"),
      catalogId: catalog.get("id"),
      recordId: record.get("isNew") ? "$new" : record.get("id")
    };
  };

  getComponentForTabs = (idTabs, t, params = {}) => {
    const iconChat = params.messagesCount > 0 ? "icon communication-91" : "icon communication-87";
    switch (idTabs) {
      case TABS_IDS.MAIN:
        return t("record.tabs.main")
      case TABS_IDS.LINKS:
        return t("record.tabs.links")
      case TABS_IDS.CHAT:
        return (
          <div className={params.newMessages && styles.iconBadge}>
            <Icon className={styles.iconChatTabs} type={iconChat} />
            {params.messagesCount > 0 && 
            <span className={styles.messageCount}>
              {params.messagesCount}
            </span>}
          </div>
        )
      case TABS_IDS.HISTORY:
        return <HistoryOutlined className={styles.iconTabs} />
      default:
        return <span />
    }
  };

  onClickAccess = () => {
    let recordId = this.props.record.get("id");
    let catalogId = this.props.catalog.get("id");
    if (catalogId && recordId) {
      let isAdmin = checkAccessOnObject(
        RESOURCE_TYPES.RECORD,
        this.props.record,
        PRIVILEGE_CODES.ADMIN
      );
      let readOnly = !checkAccessOnObject(
        RESOURCE_TYPES.RECORD,
        this.props.record,
        PRIVILEGE_CODES.ACCESS
      );
      let object = { catalogId, recordId };
      let parents = [
        {
          sectionId: this.props.catalog.get("sectionId")
        },
        {
          catalogId
        }
      ];
      modalsActions.openAccessModal(
        { object, parents },
        RESOURCE_TYPES.RECORD,
        { readOnly, isAdmin }
      );
    }
  };

  onRemove = () => {
    const { scene, t, userId } = this.props;
    const sceneId = scene && scene.get("sceneId");
    const isYourself = userId === this.props.record.get("id");

    // эта проверка означает когда в каталоге мы пытаемся удалить себя

    confirm({
      headerText: isYourself ? t("modals.removeYourselfConfirm.header") : t("modals.removeRecordConfirm.header"),
      text: isYourself ? t("modals.removeYourselfConfirm.text") : t("modals.removeRecordConfirm.text"),
      okText: isYourself ? t("modals.removeYourselfConfirm.okText") : t("modals.removeRecordConfirm.okText"),
      cancelText: isYourself ? t("modals.removeYourselfConfirm.cancelText") : t("modals.removeRecordConfirm.cancelText"),
      onOk: () => {
        apiActions
          .deleteRecord(
            {
              catalogId: this.props.catalog.get("id"),
              recordId: this.props.record.get("id")
            },
            {
              sceneId,
              viewId: this.props.match && this.props.match.params.viewId
            }
          )
          .then(() => {
            this.props.onDelete && this.props.onDelete(sceneId);
          });
      }
    });
  };

  onClose = () => {
    const { scene } = this.props;
    const sceneId = scene && scene.get("sceneId");

    this.props.onClose && this.props.onClose(sceneId);
  };

  componentDidMount() {
    const extParams = this.getExtParams && this.getExtParams();

    const defaultTab = this.props.scene && this.props.scene.getIn(["params", "defaultTab"]);

    const tab = defaultTab ? `record${defaultTab}` : "recordMain";

    const match =
      matchPath(this.props.location.pathname, {
        path: routes.record.path,
        exact: true,
        strict: false
      }) ||
      matchPath(this.props.location.pathname, {
        // For WebFrom and Modals
        path: "/",
        exact: true,
        strict: false
      });
    // If not select one of tab
    if (match) {
      this.props.history.push(
        getLink(this.props.location, routes[tab], extParams)
      );
    }
  }

  componentDidUpdate(prevProps) {
    const extParams = this.getExtParams && this.getExtParams();

    const defaultTab = this.props.scene && this.props.scene.getIn(["params", "defaultTab"]);

    const tab = defaultTab ? `record${defaultTab}` : "recordMain";

    const newRecordId = this.props.record.get("id");
    const prevRecordId = prevProps.record.get("id");
    if (
      newRecordId !== prevRecordId ||
      matchPath(this.props.location.pathname, {
        // if was click repeated on selected record
        path: routes.record.path,
        exact: true,
        strict: false
      })
    ) {
      this.props.history.push(
        getLink(this.props.location, routes[tab], extParams)
      );
    }
  }

  render() {
    const {
      record,
      isWebForm,
      scene,
      catalog,
      match,
      history,
      hasBeenEdit,
      onRefresh,
      onCreateRecord,
      onSave,
      isLoading,
      t
    } = this.props;

    const messagesCount = record.getIn(["chatOptions", "messagesCount"]);
    const newMessages = record.getIn(["chatOptions", "newMessages"]);
    const isNew = record.get("isNew");
    const headerText = isNew ? t("record.newRecord") : record.get("title");

    // tabs
    let tabs = Immutable.List([
      Immutable.Map({
        id: TABS_IDS.MAIN,
        name: this.getComponentForTabs(TABS_IDS.MAIN, t),
        route: routes.recordMain
      }),
      Immutable.Map({
        id: TABS_IDS.LINKS,
        name: this.getComponentForTabs(TABS_IDS.LINKS, t),
        route: routes.recordLinks
      }),
      Immutable.Map({
        id: TABS_IDS.CHAT,
        name: this.getComponentForTabs(TABS_IDS.CHAT, t, { newMessages, messagesCount }),
        route: routes.recordChat
      }),
      Immutable.Map({
        id: TABS_IDS.HISTORY,
        name: this.getComponentForTabs(TABS_IDS.HISTORY, t),
        route: routes.recordHistory
      })
    ]);
    return (
      <Header
        tabs={tabs}
        record={record}
        catalog={catalog}
        scene={scene}
        viewId={match && match.params.viewId}
        hasBeenEdit={hasBeenEdit}
        withTabsMenu={!isNew}
        isWebForm={isWebForm}
        headerText={headerText}
        getExtParams={this.getExtParams}
        onClose={this.onClose}
        //
        history={history}
        onSave={onSave}
        isLoading={isLoading}
        onRefresh={onRefresh}
        onRemove={this.onRemove}
        onClickCreate={onCreateRecord}
        onClickAccess={this.onClickAccess}
        onClear={this.props.onClear}
      />
    );
  }
}

export default withTranslation()(withRouter(RecordHeader));
