import React, { useRef } from "react";
import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";

import SelectValue from "../../Reports/Board/popup/data/data/Select/Value";
import SelectValueFunction from "../../Reports/Board/popup/data/data/Select/ValueFunction";
import SelectAxis from "../../Reports/Board/popup/data/data/Select/Axis";
import SelectMulticolor from "../../Reports/Board/popup/data/data/Select/Multicolor";
import SelectSplit from "../../Reports/Board/popup/data/data/Select/Split";
import SelectSort from "../../Reports/Board/popup/data/data/Select/Sort";
import getDefaultSort from "../../Reports/Board/Widget/chart/utils/getDefaultSort";
import SelectSortType from "../../Reports/Board/popup/data/data/Select/SortType";
import getDefaultSortType from "../../Reports/Board/Widget/chart/utils/getDefaultSortType";
import SelectStacked from "../../Reports/Board/popup/data/data/Select/Stacked";
import styles from "../StatisticsData.less";
import _ from "lodash";
import { useEffect } from "react";

const StatisticData = (props) => {
    const { catalog, widget, onChange, t } = props;

    const fields = catalog.get("fields");
    const value = widget.getIn(["value"]);
    const valueFn = widget.getIn(["valueFn"]);
    const axis = widget.getIn(["axis"]);
    const split = widget.getIn(["split"]);
    const sort = widget.getIn(["sort"]);
    const sortType = widget.getIn(["sortType"]);
    const stacked = widget.getIn(["stacked"]);
    const multicolor = widget.getIn(["multicolor"]);

    const prevWidget = useRef(widget);

    useEffect(() => {
        if (!widget.equals(prevWidget.current)) {
            // default values
            let stacked = widget.getIn(["stacked"]);
            // undefined is for new widget
            if (typeof stacked === "undefined") {
                onChange({ stacked: true });
            };
            let multicolor = widget.getIn(["multicolor"]);
            if (typeof multicolor === "undefined") {
                onChange({ multicolor: true });
            };
            let recordsType = widget.getIn(["recordsType"]);
            if (typeof recordsType === "undefined") {
                onChange({ recordsType: "all" });
            };
        };
        // нужно для того чтобы сравнивать предыдущие значения, аналог метода жизненного цикла componentDidUpdate
        prevWidget.current = widget;
    }, [props.widget]);

    return (
        <div className={styles.dataContainerCommon}>
            <Row gutter={[30, 10]}>
                <Col xs={12} xl={6}>
                    <span className={styles.labelLight}>
                        {t("reports.widget.modals.common.tabs.data.value.label")}
                    </span>
                    <SelectValue
                        className={styles.selectMargin}
                        showSearch
                        fields={fields}
                        value={value}
                        onChange={value => onChange({ value, valueFn: null })}
                    />
                    {
                        value ? (
                            <SelectValueFunction
                                fields={fields}
                                value={value}
                                selectedFn={valueFn}
                                onChange={value => onChange({ valueFn: value })}
                            />
                        ) : null
                    }
                </Col>
                <Col xs={12} xl={6}>
                    <span className={styles.labelLight}>
                        {t("reports.widget.modals.common.tabs.data.axis.label")}
                    </span>
                    <SelectAxis
                        className={styles.selectMargin}
                        showSearch
                        fields={fields}
                        value={axis}
                        onChange={value => onChange({ axis: value })}
                    />
                    <SelectMulticolor
                        fields={fields}
                        axis={axis}
                        value={multicolor}
                        onChange={value => onChange({ multicolor: value })}
                    />
                </Col>
                <Col xs={12} xl={6}>
                    <span className={styles.labelLight}>
                        {t("reports.widget.modals.common.tabs.data.split.label")}
                    </span>
                    <SelectSplit
                        className={styles.selectMargin}
                        showSearch
                        fields={fields}
                        value={split}
                        onChange={value => onChange({ split: value })}
                    />
                    {split && <SelectStacked
                        value={stacked}
                        onChange={value => onChange({ stacked: value })}
                    />}
                </Col>
                <Col xs={12} xl={6}>
                    <span className={styles.labelLight}>
                        {t("reports.widget.modals.common.tabs.data.sort.label")}
                    </span>
                    <Row gutter={[4, 4]}>
                        <Col xs={12}>
                            <SelectSort
                                className={styles.selectMargin}
                                value={sort || getDefaultSort(axis, fields)}
                                onChange={value => onChange({ sort: value })}
                            />
                        </Col>
                        <Col xs={12}>
                            <SelectSortType
                                className={styles.selectMargin}
                                value={sortType || getDefaultSortType(axis, fields)}
                                onChange={value => onChange({ sortType: value })}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};


export default withTranslation()(StatisticData);
