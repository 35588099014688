import React from "react";

import Horizontal from "./Horizontal";
import Vertical from "./Vertical";

export default ({ direction = "row", ...props }) => {
  switch (direction) {
    case "row":
    case "row-reverse":
      return <Horizontal direction={direction} {...props} />;
    case "column":
    case "column-reverse":
      return <Vertical direction={direction} {...props} />;
    default:
      return <Horizontal direction="row" {...props} />;
  }
};
