import { Button } from 'antd';
import PropTypes from "prop-types";
import React, { useState } from 'react';
import tinycolor from 'tinycolor2';

import styles from "./ButtonItem.less";
import Icon from '../../../Icon';
import classNames from 'classnames';

const ButtonItem = (props) => {
    const { disabled, children, onClick, icon } = props;
    let { backgroundColor } = props;
    const [isHover, setIsHover] = useState(false);
    let textColor, borderColor, iconColor, shadowColor;
    if (!isHover) {
        backgroundColor = tinycolor(backgroundColor)
            .lighten(6)
            .toString();
        borderColor = tinycolor(backgroundColor)
            .darken(12)
            .desaturate(60)
            .toString();
        iconColor = tinycolor(backgroundColor)
            .darken(40)
            .desaturate(40)
            .toString();
        textColor = tinycolor(backgroundColor)
            .darken(70)
            .desaturate(70)
            .toString();
        shadowColor = tinycolor(backgroundColor)
            .setAlpha(0.4)
            .toString();
    } else {
        backgroundColor = tinycolor(backgroundColor)
            .lighten(2)
            .toString();
        borderColor = tinycolor(backgroundColor)
            .darken(20)
            .desaturate(40)
            .toString();
        iconColor = tinycolor(backgroundColor)
            .darken(50)
            .toString();
        textColor = tinycolor(backgroundColor)
            .darken(80)
            .desaturate(70)
            .toString();
        shadowColor = tinycolor(backgroundColor)
            .setAlpha(0.5)
            .toString();
    }
    const toggleHover = () => {
        setIsHover(!isHover);
    };

    return (
        <Button
            className={classNames(styles.button, {
                [props.className]: !!props.className
            })}
            disabled={disabled}
            title={children}
            icon={icon && <Icon className={styles.icon} style={{ color: !disabled && iconColor }} type={`icon ${icon}`} />}
            style={{
                backgroundColor: !disabled && backgroundColor,
                borderColor: !disabled && borderColor,
                color: !disabled && textColor,
                boxShadow: `${shadowColor} 0px 5px 10px`
            }}
            onClick={onClick}
            onMouseLeave={toggleHover}
            onMouseEnter={toggleHover}
        >
            <span className={styles.text}>
                {children}
            </span>
        </Button>
    );
};
ButtonItem.propTypes = {
    onClick: PropTypes.func,
    backgroundColor: PropTypes.string,
    disabled: PropTypes.bool
};

export default ButtonItem;