import React from "react";
import { Row } from "antd";
import PropTypes from "prop-types";
import FIELD_TYPES from "../../configs/fieldTypes";

import styles from "./history.less";

const changeComponents = {
  [FIELD_TYPES.TEXT]: require("./change/ChangeText").default,
  [FIELD_TYPES.NUMBER]: require("./change/ChangeNumber").default,
  [FIELD_TYPES.DATE]: require("./change/ChangeDate").default,
  [FIELD_TYPES.DROPDOWN]: require("./change/ChangeCategory").default,
  [FIELD_TYPES.SWITCH]: require("./change/ChangeSwitch").default,
  [FIELD_TYPES.BUTTON]: require("./change/ChangeButton").default,
  [FIELD_TYPES.CHECKBOXES]: require("./change/ChangeCheckboxes").default,
  [FIELD_TYPES.RADIOBUTTON]: require("./change/ChangeRadiobutton").default,
  [FIELD_TYPES.PROGRESS]: require("./change/ChangeProgress").default,
  [FIELD_TYPES.STARS]: require("./change/ChangeStars").default,
  [FIELD_TYPES.USER]: require("./change/ChangeUser").default,
  [FIELD_TYPES.OBJECT]: require("./change/ChangeObject").default,
  [FIELD_TYPES.FILE]: require("./change/ChangeFile").default,
  [FIELD_TYPES.CONTACT]: require("./change/ChangeContact").default,
  [FIELD_TYPES.ADDRESS]: require("./change/ChangeAddress").default
};

const HistoryItemChange = ({ field, change, isNewRecord }) => {
  const fieldTitle = field.get("name");
  const fieldType = field.get("type");
  const fieldConfig = field.get("config");
  const Comp = changeComponents[fieldType];

  if (!Comp) {
    return <div>NOT FOUND: {fieldType}</div>;
  }

  return (
    <Row type="flex" className={styles.userfield}>
      <div className={styles.fieldHeader}>
        <span className={styles.fieldHeaderTitle}>{fieldTitle}</span>
      </div>
      <div className={styles.fieldBody}>
        <Comp change={change} config={fieldConfig} isNewRecord={isNewRecord} />
      </div>
    </Row>
  );
};

HistoryItemChange.propTypes = {
  field: PropTypes.object.isRequired,
  change: PropTypes.object.isRequired,
  isNewRecord: PropTypes.bool
};

export default HistoryItemChange;
