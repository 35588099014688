import React, { Component } from "react";
import { Dropdown } from "antd";
import _ from "lodash";
import PropTypes from "prop-types";

import Menu from "./Menu";
import ButtonTransparent from "../../../UI/ButtonTransparent";
class OverlayDropdown extends Component {
  static props = {
    vertical: PropTypes.object,
    items: PropTypes.array,
    filterFn: PropTypes.func
  };
  state = {
    visible: false
  };

  handleVisibleChange = flag => {
    this.setState({ visible: flag });
  };

  render() {
    const {
      props: { vertical, buttonMoreProps }
    } = this;

    return (
      <Dropdown
        dropdownRender={() => <Menu {...this.props} />}
        placement="bottomRight"
        trigger={["click"]}
        onOpenChange={this.props.onVisibleChange}
      >
        <ButtonTransparent {...buttonMoreProps}>...</ButtonTransparent>
      </Dropdown>
    );
  }
}

export default OverlayDropdown;
