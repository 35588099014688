import React, { useState } from "react";
import PropTypes from "prop-types";
import TextInput from "./common/TextInput";

const TextControl = ({
  onEndEditing,
  eventable,
  updateProcess,
  editable,
  config,
  value,
  htmlId,
  onChange,
  autoFocus,
  onKeyPress,
  style,
  wrapperClassName,
  className,
}) => {
  const isMultiLine = config.get("type") === "multiline";
  const isScript = config.get("type") === "script";

  return (
    <TextInput
      id={htmlId}
      wrapperClassName={wrapperClassName}
      className={className}
      style={style}
      value={value}
      theme={config.get("theme")}
      onChange={onChange}
      onEndEditing={onEndEditing}
      readOnly={!editable}
      autoFocus={autoFocus}
      multiline={isMultiLine}
      allowTabs={isMultiLine}
      script={isScript}
      mask={config.get("mask")}
      eventable={eventable}
      updateProcess={updateProcess}
      minRows={isMultiLine && 2}
      placeholder={config.get("placeholder")}
      subType={config.get("subType")}
      onKeyPress={onKeyPress}
      config={config}
    />
  );
};

TextControl.propTypes = {
  controlConfig: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  editable: PropTypes.bool,
  eventable: PropTypes.bool,
  config: PropTypes.object,
  onChange: PropTypes.func,
  onEndEditing: PropTypes.func,
  updateProcess: PropTypes.object,
  error: PropTypes.string,
  placeholder: PropTypes.string
};

export default TextControl;
