import React, { useContext, Fragment } from "react";
import moment from "moment";
import { withRouter } from "react-router";

import { DataContext } from "../../DataProvider";
import DateHeader from "./DateHeader";

import { TOTALS_FORMAT } from "../../formats";
import routes from "../../../../routes";
import getLink from "../../../common/router/getLink";

import { prepareFilters, prepareDateForUrl } from "../../helpers";

import styles from "../../calendar.less";

const MonthSlotHeader = props => {
  const date = moment(props.date).format(TOTALS_FORMAT);
  const { summary, setUrlItemsToStore } = useContext(DataContext);
  const eventsSum = summary.get(date);

  // const onClick = () => {
  //   userSettingsActions.setUserSettingsToAppState(
  //     ["catalogs", catalogId, "options", "defaultViewMode", "value"],
  //     "table"
  //   );
  //   return sceneActions.openCatalog(
  //     { catalogId, viewId },
  //     { filters: prepareFilters(date, startId) }
  //   );
  // };

  const onClick = (...args) => {
    // props.onDrillDown(...args);
    const link = getLink(props.location, routes.calendarSettings, {
      date: prepareDateForUrl(date),
      type: "day"
    });
    props.history.push(link);
    setUrlItemsToStore({ view: "day", date: prepareDateForUrl(date) });
  };

  return (
    <Fragment>
      {/* {eventsSum > 3 && (
        <span className={styles.totals} onClick={onClick}>
          Всегo {eventsSum}
        </span>
      )} */}
      <div className={styles.monthSlotHeader}>
        <DateHeader
          onClick={onClick}
          onDrillDown={props.onDrillDown}
          label={props.label}
          date={props.date}
        />
      </div>
    </Fragment>
  );
};

export default withRouter(MonthSlotHeader);
