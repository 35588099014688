import React from "react";

import { confirm } from "./index";
import sceneActions from "../../../actions/sceneActions";

export default function getConfirmation(
  message,
  callback,
  state,
  confirmationText = {},
  t
) {
  const {
    text,
    headerText,
    okText,
    cancelText
  } = confirmationText;

  function onOk() {
    sceneActions.deleteScene(message);
    callback && callback(true);
  }

  function onCancel() {
    sceneActions.cancelDeleteScene();
    callback && callback(false);
  }

  confirm({
    onOk,
    onCancel,
    headerText: headerText || t("modals.closeEditor.headerText"),
    text: (
      <span
        dangerouslySetInnerHTML={{
          __html: text || t("modals.closeEditor.text")
        }}
      />
    ),
    okText: okText || t("modals.closeEditor.okText"),
    cancelText: cancelText || t("modals.closeEditor.cancelText")
  });
}
