import React, { useMemo, useState, useEffect } from "react";
import Immutable from "immutable";
import { withRouter } from "react-router";
import { message } from "antd";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import userSettingsActions from "../../../../../../../actions/userSettingsActions";
import { connect } from "../../../../../../StateProvider";

import LinkedRecordsTable from "./LinkedRecordsTable";
import LinkedRecordsInListMode from "../LinkedRecordsInListMode";

import styles from "../../controls.less";

const LinkedRecordsInTableMode = props => {
  const { t } = useTranslation();
  const { additionalItems, RecordSelect, ...otherProps } = props;
  const { linkedCatalogsSettings, controlConfig, items } = otherProps;

  const updateUserSettings = (settingPath, data) => {
    const catalogId = props.catalogId;
    userSettingsActions.updateUserSettings(
      ["catalogs", catalogId, "linkedCatalogs", ...settingPath],
      data
    );
  };

  const onRefuseEditing = () => {
    message.error(t("modals.denied.record.update.headerText"));
  };

  const onResize = (linkedCatalogId, width, fieldId) => {
    const path = [linkedCatalogId, "fields", fieldId, "width"];

    updateUserSettings(path, { width });
  };

  const onReorder = (linkedCatalogId, fieldsOrder) => {
    const path = [linkedCatalogId, "fieldsOrder"];
    updateUserSettings(path, { fieldsOrder: fieldsOrder.toJS() });
  };

  /*   const onSortChange = (linkedCatalogId, sortField, sortType) => {
    const path = [linkedCatalogId, "sortingRecords"];
    const sorting = { sortField, sortType };

    updateUserSettings(path, sorting);
    message.warning("Сортировка пока не работает");
  }; */

  const onChangeTableVisible = () => {
    updateUserSettings(["visible"], { visible: !isVisible() });
  };

  const [itemsByCatalogs, setItemsByCatalogs] = useState(Immutable.List());
  useEffect(
    () => {
      const { items } = props;
      const itemsByCatalogs = {};
      const catalogs = controlConfig.getIn(["config", "catalogs"]);
      const views = controlConfig.getIn(["config", "views"]);

      catalogs.forEach(
        catalog => (itemsByCatalogs[catalog.get("id")] = Immutable.List())
      );

      views &&
        views.forEach(
          view => (itemsByCatalogs[view.get("catalogId")] = Immutable.List())
        );

      items &&
        items.forEach(item => {
          const catalogId = item.getIn(["item", "catalogId"]);
          let items = itemsByCatalogs[catalogId] || Immutable.List();
          items = items.push(item);
          itemsByCatalogs[catalogId] = items;
        });

      setItemsByCatalogs(itemsByCatalogs);
    },
    [props.items]
  );

  /* _TODO_ разделение Опций в выпадалке по каталогам */
  /*   const additionalItemsByCatalog = useMemo(() => {
    const { additionalItems } = props;
    const additionalItemsByCatalog = {};

    additionalItems && additionalItems.forEach(additionalItem => {
      const catalogId = additionalItem.key;
      const items = additionalItemsByCatalog[catalogId] || [];
      items.push(additionalItem);
      additionalItemsByCatalog[catalogId] = items;
    });

    return additionalItemsByCatalog;
  }, [props.additionalItems]) */

  const linkedCatalogs = controlConfig.getIn(["config", "catalogs"]);

  if (items && items.isEmpty()) {
    return RecordSelect ? (
      <RecordSelect.type
        {...RecordSelect.props}
        additionalItems={additionalItems}
        wrapperClassName={styles.dropdownWrapper}
      />
    ) : null;
  } else {
    return (
      // <div className={cn({ [styles.linkedRecordTablesBordered]: isVisible() })}>
      _.map(itemsByCatalogs, (itemsByCatalog, catalogId) => {
        const userSettings =
          linkedCatalogsSettings.get(catalogId) || Immutable.Map();
        const extendedFields = props.extendedFields.get(catalogId);

        return (
          itemsByCatalog &&
          itemsByCatalog.size > 0 && (
            <React.Fragment key={catalogId}>
              {extendedFields && extendedFields.size > 0 ? (
                <LinkedRecordsTable
                  key={catalogId}
                  catalogId={catalogId}
                  sceneId={props.sceneId}
                  items={itemsByCatalog}
                  controlConfig={controlConfig}
                  readOnly={props.readOnly}
                  apiOnly={props.apiOnly}
                  extendedFields={extendedFields}
                  userSettings={userSettings}
                  showTitle={linkedCatalogs.size > 1}
                  onRemoveItem={props.onRemoveItem}
                  onRestoreItem={props.onRestoreItem}
                  onRefuseEditing={onRefuseEditing}
                  onResize={onResize}
                  onReorder={onReorder}
                  // onSortChange={onSortChange}
                  onChangeTableVisible={onChangeTableVisible}
                />
              ) : (
                <LinkedRecordsInListMode
                  {...otherProps}
                  items={itemsByCatalog} /* текущие записи (уже выбранные) */
                  extendedFields={extendedFields}
                />
              )}
              {RecordSelect ? (
                <RecordSelect.type
                  {...RecordSelect.props}
                  additionalItems={additionalItems}
                  wrapperClassName={styles.dropdownWrapper}
                  key={`selector:${catalogId}`}
                />
              ) : null}
            </React.Fragment>
          )
        );
      })
      // </div>
    );
  }
};

LinkedRecordsInTableMode.defaultProps = {
  linkedCatalogsSettings: Immutable.Map()
};

export default withRouter(
  connect(
    LinkedRecordsInTableMode,
    { userSettings: ["userSettings", "catalogs"] },
    ({ match, ...props }, { userSettings }) => {
      const catalogId = match.params.catalogId;
      const linkedCatalogsSettings = userSettings.getIn([
        catalogId,
        "linkedCatalogs"
      ]);
      return {
        linkedCatalogsSettings,
        catalogId,
        ...props
      };
    }
  )
);
