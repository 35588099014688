import { Input } from "antd";
import classNames from "classnames";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { DragSource, DropTarget } from "react-dnd";
import { withTranslation } from "react-i18next";

import dragAndDropActions from "../../../actions/dragAndDropActions";
import dndTargets from "../../../configs/dndTargets";
import IconsModal from "../../common/IconsModal";
import { confirm, renderModaltoBody } from "../../common/Modal";
import ButtonTransparent from "../../common/UI/ButtonTransparent";
import Icon from '../../common/UI/Icon';
import ItemListEditorColorPicker from "./ItemListEditorColorPicker";

import styles from "./fields.less";

const log = require("debug")("CRM:Component:ItemListEditorItem");

const dragSource = DragSource(
  dndTargets.DROPDOWN_ITEM,
  {
    beginDrag(props) {
      let item = {
        id: props.itemId,
        index: props.itemIndex,
        name: props.name
      };
      log("beginDrag", item.index);
      dragAndDropActions.beginDrag(dndTargets.DROPDOWN_ITEM, item);
      return item;
    },
    endDrag(props) {
      dragAndDropActions.endDrag();
      props.onDragEnd();
    }
  },
  function collect(connect, monitor) {
    return {
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging()
    };
  }
);

const dropTarget = DropTarget(
  dndTargets.DROPDOWN_ITEM,
  {
    hover(props, monitor) {
      const item = monitor.getItem();
      props.moveItem(item.name, props.name);
    }
  },
  function collect(connect, monitor) {
    return {
      connectDropTarget: connect.dropTarget()
    };
  }
);

class ItemListEditorItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      iconHovered: false,
      value: this.props.name
    };
  }

  onSelectColor = color => {
    this.props.onChangeColor(this.props.itemIndex, color);
  };

  onClickRemove = () => {
    if (this.props.itemId) {
      const { t } = this.props;
      confirm({
        headerText: t("modals.removeDropdownItemConfirm.header"),
        text: t("modals.removeDropdownItemConfirm.text"),
        okText: t("modals.removeDropdownItemConfirm.okText"),
        cancelText: t("modals.removeDropdownItemConfirm.cancelText"),
        onOk: () => {
          this.props.onRemove(this.props.itemIndex);
        }
      });
    } else {
      this.props.onRemove(this.props.itemIndex);
    }
  };

  onMouseEnterIcon = () => this.setState({ iconHovered: true });

  onMouseLeaveIcon = () => this.setState({ iconHovered: false });

  onChangeName = e => {
    let val = e.target.value;
    this.setState({ value: val });
  };

  onChangeIcon = () => {
    const { name, itemIndex, icon } = this.props;
    renderModaltoBody(IconsModal, {
      header: name,
      currentIcon: `icon ${icon}`,
      onOk: _.bind(this.props.onChangeItemIcon, this, itemIndex),
      onRemove: _.bind(this.props.onRemoveItemIcon, this, itemIndex)
    });
  };

  onBlur = e => {
    if (typeof this.props.onChangeName === "function") {
      this.props.onChangeName(this.props.itemIndex, e.target.value);
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.name });
  }

  render() {
    const {
      connectDragSource,
      connectDragPreview,
      connectDropTarget,
      isDragging,
      isChangeIcon,
      icon
    } = this.props;

    let classes = classNames({
      [styles.list]: true,
      dragging: isDragging,
      "items-list__item--colors": this.props.withColor
    });

    return _.flow(
      connectDragPreview,
      connectDropTarget
    )(
      <div
        className={classes}
        style={{ backgroundColor: "#" + this.props.color }}
        title={this.props.name}
      >
        {connectDragSource(
          <div
            className={classNames(styles.dragIcon, "anticon-icon interface-42")}
            onMouseEnter={this.onMouseEnterIcon}
            onMouseLeave={this.onMouseLeaveIcon}
          />
        )}
        {isChangeIcon && <div
          onClick={this.onChangeIcon}
          className={classNames(styles.btnChangeIcon, {
            [styles.btnChangeIconEmpty]: !icon
          })}
        >
          <Icon className={styles.icon} type={`icon ${icon || "interface-72"}`} />
        </div>}
        <Input
          disabled={this.props.disabled}
          type="text"
          value={this.state.value}
          ref="nameInput"
          onBlur={this.onBlur}
          placeholder={this.props.t("catalogEditor.field.list.emptyName.enterName")}
          onChange={this.onChangeName}
          className={styles.inputTags}
        />
        {this.props.itemId ? (
          <small className={styles.apiKey}>
            API:&nbsp;
            {this.props.itemId}
          </small>
        ) : null}
        {this.props.withColor && !this.props.disabled ? (
          <ItemListEditorColorPicker
            disabled={this.props.disabled}
            onSelect={this.onSelectColor}
            currentColor={this.props.color}
          />
        ) : null}
        {!this.props.disabled ? (
          <ButtonTransparent
            className={styles.inputTagsDelete}
            onClick={this.onClickRemove}
            title={this.props.t("catalogEditor.field.list.item.remove")}
          >
            <Icon type="icon interface-74" />
          </ButtonTransparent>
        ) : null}
      </div>
    );
  }
}

ItemListEditorItem.propTypes = {
  itemId: PropTypes.string,
  itemIndex: PropTypes.number.isRequired,
  name: PropTypes.string,
  color: PropTypes.string,
  withColor: PropTypes.bool,
  onChangeColor: PropTypes.func,
  onChangeName: PropTypes.func,
  onRemove: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  moveItem: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default _.flow(
  dropTarget,
  dragSource
)(withTranslation()(ItemListEditorItem));
