import React from "react";
import PureRenderMixin from "react-addons-pure-render-mixin";
import PropTypes from "prop-types";

import LinkedItem from "../../common/UI/LinkedItem";

class ChangeObject extends React.PureComponent {
  linkedItems = (item, removed) => {
    const params = {
      sectionId: String(item.get("sectionId")),
      catalogId: String(item.get("catalogId")),
      recordId: String(item.get("recordId"))
    };
    return (
      <LinkedItem
        key={params.catalogId + params.recordId}
        item={{
          icon: item.get("catalogIcon"),
          text: item.get("recordTitle")
        }}
        removed={removed}
        linkProps={{
          style: { cursor: "pointer" }
        }}
        params={params}
      />
    );
  };

  render() {
    const oldObjects = this.props.change.get("oldValue");
    const newObjects = this.props.change.get("newValue");

    //Фильтрация дубликатов
    const newObjectsList = newObjects.filter(object => {
      return !oldObjects.find(
        testObject => object.get("recordId") === testObject.get("recordId")
      );
    });
    const oldObjectsList = oldObjects.filter(object => {
      return !newObjects.find(
        testObject => object.get("recordId") === testObject.get("recordId")
      );
    });

    return (
      <div>
        {oldObjectsList.map((item, i) => {
          return this.linkedItems(item, true);
        })}
        {newObjectsList.map((item, i) => {
          return this.linkedItems(item);
        })}
      </div>
    );
  }
}

ChangeObject.propTypes = {
  change: PropTypes.object.isRequired,
  isNewRecord: PropTypes.bool
};

export default ChangeObject;
