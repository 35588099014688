import React, { PureComponent } from "react";
import Immutable, { fromJS } from "immutable";
import _ from "lodash";
import cn from "classnames";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import FIELD_TYPES from "../../../configs/fieldTypes";
import userSettingsActions from "../../../actions/userSettingsActions";
import dndContext from "../../../services/dndContext";
import { connect } from "../../StateProvider";
import FieldConfigItem from "./FieldConfigItem";
import Icon from '../UI/Icon';
import ButtonTransparent from "../UI/ButtonTransparent";

import styles from "./fieldConfig.less";

function getOrderArray(fields, fieldOrdersFromSettings = []) {
  // need sorting by
  fieldOrdersFromSettings = _.uniq(fieldOrdersFromSettings);
  const fieldIds = fields.map(col => col.get("id")).toArray();
  const notSetOrderOnField = _.difference(fieldIds, fieldOrdersFromSettings);

  return (fieldOrdersFromSettings || [])
    .filter(id => fieldIds.indexOf(id) !== -1)
    .concat(notSetOrderOnField);
}

class FieldConfigMenu extends PureComponent {
  static propTypes = {
    fields: PropTypes.object.isRequired,
    catalogId: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      fieldsOrder: getOrderArray(
        this.props.fields,
        this.props.fieldsOrder.toJS()
      ),
      visibleFields: this.getVisibleFields(),
      defaultVisible:
        this.props.defaultVisible === undefined
          ? true
          : this.props.defaultVisible
    };
  }

  getVisibleFields = (props = this.props) => {
    const defaultVisible =
      this.props.defaultVisible === undefined
        ? true
        : this.props.defaultVisible;

    const { fields, userSettingsfields } = props;

    if (!userSettingsfields) {
      return fields;
    }
    const res = fields.filter(field => {
      let visible =
        userSettingsfields &&
        userSettingsfields.getIn([field.get("id"), "visible", "visible"]);

      return visible === undefined ? defaultVisible : visible;
    });
    return res;
  };

  onChangeVisibility(fieldId, visible) {
    userSettingsActions.setFieldVisibility({
      catalogId: this.props.catalogId,
      viewMode: this.props.viewMode,
      fieldId,
      visible
    });
  }

  moveItem = (fieldId, afterFieldId) => {
    if (this.field !== fieldId || this.afterFieldId !== afterFieldId) {
      this.field = fieldId;
      this.afterFieldId = afterFieldId;

      const fieldIndex = this.state.fieldsOrder.indexOf(fieldId),
        newFieldIndex = this.state.fieldsOrder.indexOf(afterFieldId);

      const newOrder = this.state.fieldsOrder.slice();
      newOrder.splice(fieldIndex, 1);
      newOrder.splice(newFieldIndex, 0, fieldId);

      this.setState({
        fieldsOrder: newOrder
      });
    }
  };

  isFiltered = () => {
    return !this.props.fields
      .filter(field => field.get("type") !== "group")
      .equals(
        this.state.visibleFields.filter(field => field.get("type") !== "group")
      );
  };

  hasVisibleFields = () => {
    const visibleFields = this.state.visibleFields;
    return visibleFields && visibleFields.size > 1;
  };

  removeAllFilters = () => {
    const { fields } = this.props;
    let settings = Immutable.Map();

    fields.forEach(field => {
      if (field.get("type") !== "group") {
        settings = settings.set(
          field.get("id"),
          fromJS({
            visible: {
              visible: true
            }
          })
        );
      }
    });

    userSettingsActions.updateUserSettings(
      [
        "catalogs",
        this.props.catalogId,
        "viewMode",
        this.props.viewMode,
        "fields"
      ],
      settings
    );
  };

  setAllFilters = () => {
    const { fields } = this.props;
    let settings = Immutable.Map();

    fields.forEach(field => {
      if (field.get("type") !== "group") {
        settings = settings.set(
          field.get("id"),
          fromJS({
            visible: {
              visible: false
            }
          })
        );
      }
    });

    userSettingsActions.updateUserSettings(
      [
        "catalogs",
        this.props.catalogId,
        "viewMode",
        this.props.viewMode,
        "fields"
      ],
      settings
    );
  };

  componentWillReceiveProps(nextProps) {
    const fieldsOrder = this.state.fieldsOrder;
    const nextFieldsOrder = nextProps.fieldsOrder;
    this.setState({ visibleFields: this.getVisibleFields(nextProps) });
    if (nextFieldsOrder && nextFieldsOrder.toJS() !== fieldsOrder) {
      this.setState({
        fieldsOrder: getOrderArray(nextProps.fields, nextFieldsOrder.toJS())
      });
    }
  }

  onDragEnd = () => {
    userSettingsActions.setFieldsOrder({
      catalogId: this.props.catalogId,
      viewMode: this.props.viewMode,
      fieldsOrder: this.state.fieldsOrder
    });
  };

  render() {
    const fields = this.props.fields
      .map(col => {
        const index = this.state.fieldsOrder.indexOf(col.get("id"));
        return col.set(
          "_order",
          index === -1 ? Number.MAX_SAFE_INTEGER : index
        );
      })
      .sort((c1, c2) => {
        return c1.get("_order") - c2.get("_order");
      });

    const { userSettingsfields, viewMode, t } = this.props;

    const defaultVisible =
      this.props.defaultVisible === undefined
        ? true
        : this.props.defaultVisible;

    return (
      <ul
        className={cn(
          "ant-dropdown-menu ant-dropdown-menu-vertical",
          styles.menu
        )}
      >
        {fields
          .filter(col => col.get("type") !== FIELD_TYPES.GROUP && col.get("type") !== FIELD_TYPES.BUTTON)
          .filter(col => col.get("hidden") !== true)
          .map(col => {
            const colId = col.get("id");
            // get setting for current FieldConfigItem, from UserSetting store.
            const visible =
              userSettingsfields &&
              userSettingsfields.getIn([colId, "visible", "visible"]);
            return (
              <FieldConfigItem
                viewMode={viewMode}
                key={colId}
                visible={visible === undefined ? defaultVisible : visible}
                onChange={e => this.onChangeVisibility(colId, e.target.checked)}
                field={col}
                onDragEnd={this.onDragEnd}
                moveItem={this.moveItem}
              />
            );
          })}
        {this.hasVisibleFields() && (
          <li className={cn("ant-dropdown-menu-item", styles.showItem)}>
            <ButtonTransparent
              className={styles.showBtn}
              title={t("record.filter.removeTitle")}
              onClick={this.setAllFilters}
            >
              <Icon type="icon edition-39" />
              <span>{t("record.filter.hide")}</span>
            </ButtonTransparent>
          </li>
        )}
        {this.isFiltered() && (
          <li className={cn("ant-dropdown-menu-item", styles.showItem)}>
            <ButtonTransparent
              className={styles.showBtn}
              title={t("record.filter.removeTitle")}
              onClick={this.removeAllFilters}
            >
              <Icon type="icon edition-24" />
              <span>{t("record.filter.show")}</span>
            </ButtonTransparent>
          </li>
        )}
      </ul>
    );
  }
}

export default withTranslation()(
  dndContext(
    connect(
      FieldConfigMenu,
      {
        userSettings: ["userSettings", "catalogs"]
      },
      (props, { userSettings }) => {
        const { catalogId } = props;
        return {
          ...props,
          fieldsOrder:
            userSettings.getIn([
              catalogId,
              "viewMode",
              props.viewMode,
              "fieldsOrder",
              "fieldsOrder"
            ]) || Immutable.fromJS([]),
          userSettingsfields: userSettings.getIn([
            catalogId,
            "viewMode",
            props.viewMode,
            "fields"
          ])
        };
      }
    )
  )
);
