import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import _ from "lodash";

import recordActions from "../../../../actions/recordActions";
import DEFAULTS from "../../../../configs/records";
import filtersUtil from "../../../../utils/filters";
import { connect } from "../../../StateProvider";

import RecordsList from "./RecordsList";
import Header from "./Header";

import styles from "../../calendar.less";

const SuggestedRecordsContainer = props => {
  const onClickRecord = useCallback(
    recordId => {
      props.onClickRecord &&
        props.onClickRecord({ catalogId: props.catalogId, recordId });
    },
    [props.catalogId]
  );

  const getFiltersForSuggestionRecords = () => {
    let filters = [];
    let parentSceneFilters = props.parentSceneFilters;

    if (props.endId && props.startId) {
      /* большой вопрос */
      filters = [
        {
          fieldId: props.startId,
          value: "NULL_DATE"
        },
        {
          fieldId: props.endId,
          value: "NULL_DATE"
        }
      ];
    } else if (props.startId) {
      filters = [
        {
          fieldId: props.startId,
          value: "NULL_DATE"
        }
      ];
    }

    if (!parentSceneFilters) {
      return filters;
    } else {
      parentSceneFilters =
        parentSceneFilters && parentSceneFilters.toJS()
          ? parentSceneFilters.toJS()
          : parentSceneFilters;
      parentSceneFilters = filtersUtil.getFiltersForRequest(
        parentSceneFilters,
        props.fields
      );
      return [...parentSceneFilters, ...filters];
    }
  };

  const loadSuggestionRecords = (offset = 0) => {
    if (!props.startId || !props.sceneId || !props.catalogId) {
      return;
    }

    const limit = DEFAULTS.RECORDS_LIMIT["table"];
    const request = { limit, offset };

    recordActions.requestForRecordsImmediate(
      props.catalogId,
      props.sceneId,
      request,
      "table",
      getFiltersForSuggestionRecords(),
      { sortField: props.sortField, sortType: props.sortType },
      undefined,
      false
    );
  };

  const loadSuggestionRecordsDebounced = _.debounce(loadSuggestionRecords, 400);

  useEffect(() => {
    loadSuggestionRecords();
  }, []);

  useEffect(
    () => {
      props.shouldReload && loadSuggestionRecords(0);
    },
    [props.shouldReload]
  );

  useEffect(
    () => {
      loadSuggestionRecordsDebounced();
    },
    [
      props.parentSceneFilters,
      props.startId,
      props.endId,
      props.sortField,
      props.sortType
    ]
  );

  useEffect(
    () => {
      loadSuggestionRecords();
    },
    [props.sceneId, props.catalogId]
  );

  const loadMoreSuggestedRecords = () => {
    const offset = props.recordsSize || 0;
    loadSuggestionRecords(offset);
  };

  return (
    <div className={styles.suggestedRecordsContainer}>
      <Header
        recordsCount={props.recordsCount}
        className={styles.suggestedRecordsHeader}
      />

      <RecordsList
        sceneId={props.sceneId}
        records={props.records}
        fields={props.fields}
        recordsCount={props.recordsCount}
        recordsSize={props.recordsSize}
        loading={props.loading}
        onDragStart={props.onDragStart}
        onDropFromCalendar={props.onDropFromCalendar}
        getEventStyle={props.getEventStyle}
        convertRecordsToEvents={props.convertRecordsToEvents}
        loadMoreSuggestedRecords={loadMoreSuggestedRecords}
        onClickRecord={onClickRecord}
      />
    </div>
  );
};

SuggestedRecordsContainer.propTypes = {
  sceneId: PropTypes.string,
  sortField: PropTypes.string,
  sortType: PropTypes.number,
  startId: PropTypes.string,
  endId: PropTypes.string,
  shouldReload: PropTypes.bool,
  records: ImmutablePropTypes.map,
  parentSceneFilters: ImmutablePropTypes.map,
  fields: ImmutablePropTypes.list,
  onDragStart: PropTypes.func,
  onClickRecord: PropTypes.func,
  onDropFromCalendar: PropTypes.func,
  getEventStyle: PropTypes.func,
  convertRecordsToEvents: PropTypes.func,

  recordsCount: PropTypes.string,
  recordsSize: PropTypes.number,
  loading: PropTypes.bool,
  filtersChanged: PropTypes.bool
};

export default connect(
  SuggestedRecordsContainer,
  ["scenes"],
  (props, { scenes }) => {
    const { sceneId, records } = props;
    const scene = scenes && scenes.get(sceneId);

    let loading;
    let recordsCount;
    let recordsSize;

    if (scene) {
      loading = scene.get("loading");
      recordsCount = scene.get("recordsCount");
    }

    if (records) {
      recordsSize = records.size;
    }

    return {
      ...props,
      loading,
      recordsCount,
      recordsSize
    };
  }
);
