import React, { useCallback, useState } from "react";
import _ from "lodash";
import tinycolor from "tinycolor2";
import cn from "classnames";
import moment from "moment";
import Immutable from "immutable";

import BigCalendar from "./BigCalendar";
import YearCalendar from "./YearCalendar";
import SuggestedRecords from "./SuggestedRecords";

import apiActions from "../../../actions/apiActions";
import calendarActions from "../../../actions/calendarActions";

import PRIVILEGE_CODES from "../../../configs/privilegeCodes";
import RESOURCE_TYPES from "../../../configs/resourceTypes";
import fieldTypes from "../../../configs/fieldTypes";

import FieldApi from "../../../models/FieldApi";
import { shouldRenderScheduling } from "../helpers";
import { checkAccessOnObject } from "../../../utils/rights";
import { connect } from "../../StateProvider";
import dndContext from "../../../services/dndContext";

import useEvents from "../hooks/useEvents.hook";

import sceneActions from "../../../actions/sceneActions";

import styles from "../calendar.less";

const Body = props => {
  const startFieldId = props.settings.getIn(["start", "key"]);

  const endFieldId =
    props.settings.getIn(["end", "key"]) ||
    props.settings.getIn(["start", "key"]);

  const startField = props.fields.find(
    field => field.get("id") === startFieldId
  );
  const endField = props.fields.find(field => field.get("id") === endFieldId);

  const sortType = props.settings.get("sortType");
  const sortField = props.settings.getIn(["sortField", "key"]);
  const colorFieldId = props.settings.getIn(["color", "key"]);
  const colorField = props.fields.find(
    field => field.get("id") === colorFieldId
  );

  const isYear = props.calendarView === "year";

  const getColorByItemId = values => {
    const catalogHasStatusField = props.fields.some(
      f => f.get("type") === fieldTypes.DROPDOWN
      );

    const itemId = values.toJS ? values.getIn([colorFieldId, "0"]) : null;
    
    const eventAccentColor = "rgb(255, 255, 255)";
    const eventDefaultColor = "rgb(206, 209, 214)";

    const statusFieldItem =
      colorField && FieldApi.receivePossibleItemById(colorField, itemId);
    const statusFieldColor = statusFieldItem && statusFieldItem.get("color");
    let color =
      catalogHasStatusField && colorFieldId
        ? statusFieldColor
          ? statusFieldColor
          : eventDefaultColor
        : eventAccentColor;

    if (color.toLowerCase() === "ffffff") {
      return "#f8f8f8";
    } else {
      let { r, g, b } = tinycolor(color).toRgb();
      const alpha = 0.75;
      r = r * alpha + (1 - alpha) * 255;
      g = g * alpha + (1 - alpha) * 255;
      b = b * alpha + (1 - alpha) * 255;

      return tinycolor({ r, g, b }).toString();
    }
  };

  const getEventStyle = useCallback(
    ({ end, start, color, droppable }) => {
      const opacity =
        moment(start).month() !== moment(props.date).month() &&
        moment(end).month() !== moment(props.date).month() &&
        props.calendarView === "month"
          ? 0.5
          : null;

      const style = {
        opacity,
        color: "black",
        background: color
      };

      const className = droppable ? "isDraggable" : "nonDraggable";

      return { style, className };
    },
    [props.date, props.calendarView]
  );

  const withScheduling = shouldRenderScheduling(
    props.calendarView,
    props.settings
  );

  const { initEvents: convertRecordsToEvents } = useEvents(
    props.catalogId,
    props.sceneId,
    props.fields,
    props.settings,
    undefined,
    withScheduling,
    undefined,
    props.fieldsOrder,
    props.visibleFields,
    getColorByItemId
  );

  const [draggedRecord, setDraggedRecord] = useState(null);

  const updateRecord = (params = {}, values, actionParams, callbacks) => {
    const { onCompleted, onFailed } = callbacks;

    apiActions
      .updateRecord(params, { values }, actionParams)
      .then(() => {
        onCompleted && onCompleted();
        return;
      })
      .catch(() => {
        onFailed && onFailed();
        return props.loadData();
      });
  };

  const onDropFromOutside = (
    params,
    values,
    actionParams,
    { onFailed, onCompleted } = {}
  ) => {
    const record = _.cloneDeep(draggedRecord);
    setDraggedRecord(null);

    /* 
      1. перенести
      2. вызвать апдейт
      3. дождаться выполнения апдейта
      4. если выполнился успешно, то удалить из сцены из которой переносили запись, 
      в противном случае удалить из сцены в которую переносили

    */

    const callback = {
      onCompleted: (...args) => {
        onCompleted && onCompleted(...args);
        calendarActions.deleteRecordFromScene(
          props.suggestRecordsSceneId,
          params.recordId
        );
      },
      onFailed: (...args) => {
        onFailed && onFailed(...args);
        calendarActions.updateRecordBeforeMakeRequest(
          params,
          undefined,
          props.sceneId
        ); // if undefined, then return to origin values
        calendarActions.moveRecordFromTo(
          props.sceneId,
          props.suggestRecordsSceneId,
          record
        );
        calendarActions.deleteRecordFromScene(props.sceneId, params.recordId);
      }
    };

    calendarActions.updateRecordBeforeMakeRequest(
      params,
      values,
      props.suggestRecordsSceneId
    );
    calendarActions.moveRecordFromTo(
      props.suggestRecordsSceneId,
      props.sceneId,
      record
    );

    updateRecord(params, values, actionParams, callback);
  };

  const onDragStart = useCallback(
    (e, recordId) => {
      if (!props.suggestedRecords) return;

      const record = props.suggestedRecords.find(
        record => record.get("id") === recordId
      );

      if (!record) {
        e.preventDefault();
        return;
      }

      /* check apiOnly */
      let editable;

      if (!startField) {
        editable = false;
      } else if (!endField) {
        editable = !startField.get("apiOnly");
      } else {
        editable = !startField.get("apiOnly") && !endField.get("apiOnly");
      }

      /* check access */
      let canEdit = checkAccessOnObject(
        RESOURCE_TYPES.RECORD,
        record,
        PRIVILEGE_CODES.EDIT
      );
      canEdit = true;

      if (canEdit && editable) {
        e.dataTransfer.setData(["text/plain"], recordId);
        setDraggedRecord(record);
      } else {
        e.preventDefault();
      }
    },
    [props.suggestedRecords, startField, endField]
  );

  const onClickRecord = (params = {}, callbacks = {}) => {
    sceneActions.openRecord(params, callbacks);
  };

  const onCreateRecord = (values, onRefresh) => {
    sceneActions.openNewRecord(
      {
        catalogId: props.catalogId,
        viewId: props.viewId,
        parentSceneId: props.sceneId
      },
      values,
      {
        onCreateRecord: onRefresh,
        onDelete: onRefresh
      }
    );
  };

  return (
    <div
      className={cn(styles.body, {
        [styles.suggestRecords]: props.suggestRecords,
        [styles.bodyCreater]: props.canCreate
      })}
    >
      <div className={styles.calendar}>
        {isYear ? (
          <YearCalendar sceneId={props.sceneId} />
        ) : (
          <BigCalendar
            setUrlItemsToStore={props.setUrlItemsToStore}
            onChangeUrl={props.onChangeUrl}
            loadData={props.loadData}
            draggedRecord={draggedRecord}
            onUpdateEvent={updateRecord}
            onDropFromOutside={onDropFromOutside}
            onClickRecord={onClickRecord}
            onCreateRecord={onCreateRecord}
            getColorByItemId={getColorByItemId}
            getEventStyle={getEventStyle}
            withScheduling={withScheduling}
            calendarView={props.calendarView}
            date={props.date}
            catalogId={props.catalogId}
            sceneId={props.sceneId}
            viewId={props.viewId}
            fields={props.fields}
            filters={props.filters}
            adjustedRecords={props.adjustedRecords}
            settings={props.settings}
            switchDay={props.switchDay}
            canCreate={props.canCreate}
            fieldsOrder={props.fieldsOrder}
            visibleFields={props.visibleFields}
            summary={props.summary}
            suggestRecords={props.suggestRecords}
            shouldReload={props.shouldReload}
          />
        )}
      </div>

      {props.suggestRecords ? (
        <SuggestedRecords
          sceneId={props.suggestRecordsSceneId}
          catalogId={props.catalogId}
          parentSceneFilters={props.filters}
          sortField={sortField}
          sortType={sortType}
          startId={startFieldId}
          endId={endFieldId}
          records={props.suggestedRecords}
          fields={props.fields}
          shouldReload={props.shouldReload}
          filtersChanged={props.filtersChanged}
          onDragStart={onDragStart}
          onClickRecord={onClickRecord}
          getEventStyle={getEventStyle}
          convertRecordsToEvents={convertRecordsToEvents}
          onDropFromCalendar={props.onDropFromCalendar}
        />
      ) : null}
    </div>
  );
};

export default connect(
  dndContext(Body),
  ["scenes"],
  (props, { scenes }) => {
    const suggestedRecords =
      scenes && props.suggestRecordsSceneId
        ? scenes.getIn([props.suggestRecordsSceneId, "records"])
        : null;
    return {
      ...props,
      suggestedRecords
    };
  }
);
