import React from "react";
import PropTypes from "prop-types";

import BaseFileField from "../FileField";

const FileField = props => {
  return (
    <BaseFileField
      value={props.value}
      config={props.config}
      inContainers={true}
      {...props}
    />
  );
};

FileField.propTypes = {
  value: PropTypes.object,
  config: PropTypes.object.isRequired
};

export default FileField;
