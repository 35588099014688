import React, { useState } from "react";
import moment from "moment";
import cn from "classnames";
import PropTypes from "prop-types";
import { DatePicker, Input } from "antd";
import { useTranslation } from "react-i18next";

import styles from "./selectors.less";

const DATA_PICKER_FORMAT_WITH_TIME = "DD.MM.YYYY HH:mm";
const DATA_PICKER_FORMAT = "DD.MM.YYYY";
const API_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";
const MOMENT_TIME_FORMAT = "HH:mm";

const DateSelector = props => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(props.autoFocus);

  const onChange = value => {
    value = value !== null ? moment(value).format(API_FORMAT) : value;
    props.onChange && props.onChange(value);
  };

  const onOpenChange = open => {
    setOpen(open);
    props.onEndEditing();
  };

  const withTime = props.config.get("time");
  const { value } = props;
  const momentValue = moment(value || "");

  let pasteTime = moment(value, API_FORMAT).fromNow();
  const formattedDateValue = momentValue.isValid()
    ? momentValue.format("L")
    : value;

  // hide pastTime for current day if without time
  if (!withTime && formattedDateValue === moment().format("L")) {
    pasteTime = "";
  }

  return (
    <DatePicker
      className={cn(styles.datePicker, props.className)}
      popupClassName={styles.datePickerPopup}
      open={open}
      autoFocus={props.autoFocus}
      allowClear={false}
      value={momentValue.isValid() ? momentValue : null}
      format={withTime ? DATA_PICKER_FORMAT_WITH_TIME : DATA_PICKER_FORMAT}
      placeholder={t("fieldTypes.date.name")}
      showTime={withTime && { format: MOMENT_TIME_FORMAT }}
      onChange={onChange}
      onSelect={onChange}
      onOpenChange={onOpenChange}
    />
  );
};

DateSelector.propTypes = {
  value: PropTypes.string,
  error: PropTypes.string,
  config: PropTypes.object,
  onChange: PropTypes.func,
  onEndEditing: PropTypes.func,
  editable: PropTypes.bool
};

export default DateSelector;
