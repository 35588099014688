import React from "react";
import { Row } from "antd";
import { useTranslation } from "react-i18next";

import Icon from '../../common/UI/Icon';

import styles from "./header.less";

export default props => {
  const { t } = useTranslation();
  return (
    <li className={styles.boardCreater} onClick={props.onClick}>
      <Row type="flex" justify="space-between" align="middle">
        <Icon type="icon interface-72" className={styles.listItemIcon} />
        <span>{t("reports.boards.create")}</span>
      </Row>
    </li>
  );
};
