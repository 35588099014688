import React from "react";
import _ from "lodash";
import { Pie } from "react-chartjs-2";
import tinyColor from "tinycolor2";

import Base from "./base";

const PieComponent = (props) => {
  return (
    <Pie
      getElementAtEvent={props.onClick}
      ref={props.chartRef}
      {...props}
    />
  )
}

class WidgetChartPie extends Base {
  getChartComponent() {
    return PieComponent;
  }

  getAxisColor(...args) {
    const color = tinyColor(super.getAxisColor(...args));
    return color.setAlpha(1).toRgbString();
  }

  getDatasets() {
    const datasets = super.getDatasets();

    datasets.forEach(({ chartDataset }) => {
      const data = this.getReportData();

      const color = data.map(({ axis }, index) => {
        return this.getAxisColor(axis, index);
      });

      const labelColors = color.map(this.getLabelColor);

      // return default format fn
      delete chartDataset.dataLabels.format;

      _.assign(
        chartDataset,
        _.defaultsDeep(
          {
            backgroundColor: color,
            borderColor: "#fff",
            dataLabels: {
              colors: labelColors
            }
          },
          chartDataset
        )
      );
    });

    return datasets;
  }

  /*getReportData() {
    return super.getAxisSortFn()
      ? super.getReportData()
      : [...super.getReportData()].reverse();
  }*/

  formatTooltipLabelItem({ datasetIndex, index }, data) {
    const value = _.get(data, ["datasets", datasetIndex, "data", index]);
    const axislabel = _.get(data, ["labels", index]);
    return super.formatTooltipValue(value, axislabel);
  }

  getLabels(locale) {
    let labels = super.getLabels(locale);
    labels = _.map(labels, axis => super.formatAxisLabel(axis));
    return labels;
  }

  getOptions() {
    let options = super.getOptions();
    options = {
      ...options,
      scales: {},
      legend: {
        ...options.legend,
        display: true,
        align: "start",
        position: "right"
      }
    };
    return options;
  }
}

export default WidgetChartPie;
