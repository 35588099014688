import tinyColor from "tinycolor2";

import colorText from "../../../../../../utils/colorFromString";
import CHARTS_COLORS from "../../../../../../configs/chartsColors";
import * as AXIS_TYPES from "../../../../../../configs/reports/widget/axisTypes";
import * as AXIS_SUB_TYPES from "../../../../../../configs/reports/widget/axisSubTypes";
import FIELD_TYPES from "../../../../../../configs/fieldTypes";

const COLORS = CHARTS_COLORS;
const COLORS_COUNT = COLORS.length;

function getRandomColor(index) {
  let color = "#" + COLORS[index % COLORS_COUNT];
  if (index >= COLORS_COUNT) {
    color = tinyColor(color)
      .darken(Math.floor(index / COLORS_COUNT) * 6)
      .toHexString();
  }
  return color;
}

function getRandomColorByText(value) {
  let color = colorText.colorFromString(value);
  color = tinyColor(color).toHsl();

  let colorNice = {};
  colorNice.h = color.h; // hue
  colorNice.s = 0.85 + (color.s - 0.5) * 0.1; // saturation
  colorNice.l = 0.82; // lightness

  colorNice = tinyColor(colorNice).toHexString();
  return colorNice;
}

function getEmptyColor() {
  let color = "#f8f8f8";
  return color;
}

export function getRealColor(widget, axis, config, fields) {
  let color;

  const multicolor = widget.get("multicolor");

  switch (config && config.get("type")) {
    case AXIS_TYPES.FIELD:
      const field = fields.find(f => f.get("id") === config.get("value"));
      const fieldType = field && field.get("type");

      switch (fieldType) {
        case FIELD_TYPES.DROPDOWN:
          const fieldItems = field.getIn(["config", "items"]) || [];
          const fieldItem = fieldItems.find(i => i.get("id") == axis);
          color = fieldItem && fieldItem.get("color");
          if (color) {
            color = "#" + color;
          }
          break;
        case FIELD_TYPES.TEXT:
          if (multicolor && axis) {
            color = getRandomColorByText(axis);
          }
          break;
        case FIELD_TYPES.OBJECT:
          if (multicolor && axis) {
            color = getRandomColorByText(axis.recordTitle);
          }
          break;
        case FIELD_TYPES.USER:
          if (multicolor && axis) {
            color = getRandomColorByText(axis.title);
          }
          break;
      }
  }

  return color;
}

export default function getAxisColor(widget, axis, axisNumber, config, fields) {
  // specific color for axis
  let color = getRealColor(widget, axis, config, fields);

  // default color for multi axises
  if (!color) {
    if (axis === null) {
      color = getEmptyColor();
    } else {
      color = getRandomColor(axisNumber);
    }
  }

  // style color
  color = tinyColor(color)
    .setAlpha(0.66)
    .darken(8);

  return color.toRgbString();
}
