import React from "react";
import { Dropdown } from "antd";

import Icon from '../../common/UI/Icon';
import ButtonTransparent from "../../common/UI/ButtonTransparent";
import Item from "./Item";

import styles from "./header.less";
import cn from "classnames";


const Board = props => {
  const className = cn(styles, styles.listItem, {
    [styles.listItemSelected]: props.isActive
  });
  const boardButtonWrapper = cn(styles.boardButtonWrapper, {
    [styles.boardButtonWrapperFocus]: props.isActive
  });
  const boardButton = cn(styles.boardButton, {
    [styles.boardButtonFocus]: props.isActive
  });

  const onClick = (event) => props.onClick(event.key, props.boardId);

  const items = props.opportunities.map(opportunity => {
    const listItem = cn({ [styles.listItemDanger]: opportunity.danger });

    return {
      label: <Item listItem={listItem} opportunity={opportunity} />, key: opportunity.key
    };
  })
  return (
    <div className={className}>
      {props.children}
      {!props.readOnly && (
        <div className={boardButtonWrapper}>
          <Dropdown
            menu={{ items, onClick, className: styles.boardMenu }}
            trigger={["click"]}
            overlayClassName={styles.boardDropDown}
            placement={"bottomRight"}
          >
            <ButtonTransparent className={boardButton}>
              <Icon type="icon setting-10" />
            </ButtonTransparent>
          </Dropdown>
        </div>
      )}
    </div>
  );
};

export default Board;
