export function getTotalCount(datasets, labels, split) {

    /* подсчет общего количества по колонке нам не нужно считать если у нас одно разложение */
    const totalRow = [];
    datasets.forEach((dataRecord, index) => {
        // пушим в массив сумму всех колонок
        totalRow.push(dataRecord.data.reduce((a, b) => a + b, 0));
    });
    const newlabels = [...labels, "Итого"];

    if (!split) {
        return { datasets, labels: newlabels, totalColumn: [], totalRow };
    };

    /* подсчет общего количества по строке */
    const totalColumn = [];
    // проходимся по массиву для получения индекса лейбла
    labels.forEach((label, index) => {
        // создаем объект для записи по ключу индекса лейбла массива всех значений строки
        const allByRow = {};
        // далее проходимся по массиву данных количества записей
        datasets.forEach((dataRecord, indexRecord) => {
            // смотрим есть ли у нас уже знаечение по этому ключу 
            if (!allByRow[index]) {
                // создаем новый ключ значение в объекте значением будет являтся массив количества записей по стркое
                allByRow[index] = dataRecord.data[index];
            } else {
                // если у нас уже существует, нам необходимо сохранить предыдущие значения и добавить в конец новые
                allByRow[index] = allByRow[index] + dataRecord.data[index];
            };
        })
        // далее мы получаем сумму из массива
        totalColumn.push(allByRow[index]);
    });


    /* считаем общеее количество по перекрекстку колонок и строк, тобишь общее количесво записей) */
    totalRow.push(totalRow.reduce((a, b) => a + b, 0));

    /* применение общего количества в datasets и формирование нового лейбла */
    const newDatasets = [...datasets, {
        label: "Итого",
        data: totalColumn,
        backgroundColor: "#cccc"
    }];

    /* вернуть результат подсчета по строке колонке новые datasets, labels */
    return { labels: newlabels, datasets: newDatasets, totalColumn, totalRow };
};