import tinycolor from "tinycolor2";

//  log - function for oppacity
const log = (val) => {
    val = Math.abs(val * 100);
    return Math.log(val + 1) / Math.log(10) / 2.05;
};

// s-Shape - function for oppacity
const sFunction = (x) => {
    const minOpacity = 0.1,
        skew = 10;

    return (
        (1 - minOpacity) / (1 + Math.E ** (skew * (-Math.abs(x) + 0.5))) +
        minOpacity
    );
};

export function getCellColor({ value, maxValue, minValue, colorProps }) {
    const hasPositiveArray = maxValue >= 0;
    const hasNegativeArray = minValue <= 0;

    const gren = "rgba(180, 249, 153, 1)",
        red = "rgba(245, 157, 157, 1)";
    // color by props or default ?
    const color = value >= 0 ? gren : red;

    let cellColor, oppacity;

    if (value) {
        // if positive and negative values in selection
        if (hasPositiveArray && hasNegativeArray) {
            // finding comparative interval and value
            const relativeInterval = maxValue - minValue,
                relativeValue = value - minValue;

            // calculate opacity by sFunction
            oppacity =
                maxValue === minValue
                    ? 1
                    : sFunction(relativeValue / relativeInterval);
        }

        // if positive or negative values in selection
        else if (hasPositiveArray || hasNegativeArray) {
            // finding new min/max vales cause of old values outdated
            const newMinValue = hasNegativeArray ? maxValue : minValue,
                newMaxValue = hasNegativeArray ? minValue : maxValue;

            // finding comparative interval and value
            const relativeInterval = newMaxValue - newMinValue,
                relativeValue = value - newMinValue;

            // calculate opacity by sFunction
            oppacity =
                newMaxValue === newMinValue
                    ? 1
                    : sFunction(relativeValue / relativeInterval);
        }

        // set the opacity to color
        cellColor =
            oppacity && tinycolor(colorProps || color).setAlpha(oppacity);
    };
    return cellColor;
};