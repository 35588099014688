import React, { useState } from "react";
import { Input } from "antd";
import { useTranslation } from "react-i18next";

import styles from "../widget.less";

export default function({ board, widget, onChange, title }) {
  const [state, setState] = useState({ focused: false });
  const { t } = useTranslation();

  return (
    <Input
      value={state.focused ? widget.get("name") || title : widget.get("name")}
      maxLength={255}
      onChange={e => {
        onChange({ name: e.target.value });
        setState({ focused: false });
      }}
      onFocus={e => setState({ focused: true })}
      onBlur={e => setState({ focused: false })}
      placeholder={
        title || t("reports.widget.modals.common.preview.namePlaceHolder")
      }
      className={styles.inputEditable}
    />
  );
}
