import * as SORT_TYPES from "../../../../../../configs/reports/widget/sortTypes";
import * as AXIS_TYPES from "../../../../../../configs/reports/widget/axisTypes";
import * as AXIS_SUB_TYPES from "../../../../../../configs/reports/widget/axisSubTypes";

import getDefaultSort from "../utils/getDefaultSort";
import getDefaultSortType from "../utils/getDefaultSortType";

import FIELD_TYPES from "../../../../../../configs/fieldTypes";

function getSortType(sortType, defaultSortType) {
  const sort = sortType || defaultSortType || SORT_TYPES.ASC;
  if (sort === SORT_TYPES.DESC) {
    return -1;
  } else if (sort === SORT_TYPES.ASC) {
    return 1;
  }
}

export default function getAxisSortFn(
  axisConfig /* axis or split from widget */,
  fields,
  sort,
  sortType,
  subType
) {
  let sortIndex;

  if (sort === null || !sort) {
    sort = getDefaultSort(axisConfig, fields).get("value");
    sortType = getDefaultSortType(axisConfig, fields).get("value");
  }

  if (sort === SORT_TYPES.VALUE) {
    return;
  }

  switch (axisConfig && axisConfig.get("type")) {
    case AXIS_TYPES.FIELD:
      const field = fields.find(f => f.get("id") === axisConfig.get("value"));
      const fieldType = field && field.get("type");

      switch (fieldType) {
        case FIELD_TYPES.DROPDOWN:
        case FIELD_TYPES.CHECKBOXES:
        case FIELD_TYPES.RADIOBUTTON:
          // sort by order in catalog field config items order
          const fieldConfig = field.get("config");
          const items = (fieldConfig && fieldConfig.get("items")) || [];

          return function(axis1, axis2) {
            const axis1Index = items.findIndex(i => i.get("id") == axis1);

            if (axis1Index === -1) {
              return Infinity;
            }

            const axis2Index = items.findIndex(i => i.get("id") == axis2);

            if (axis2Index === -1) {
              return -Infinity;
            }

            return axis1Index - axis2Index;
          };

        // sort by int int
        case FIELD_TYPES.PROGRESS:
        case FIELD_TYPES.NUMBER:
        case FIELD_TYPES.STARS:
          sortIndex = getSortType(sortType, SORT_TYPES.ASC);
          return function(axis1, axis2) {
            if (axis1 === null) {
              return 1;
            } else if (axis2 === null) {
              return -1;
            } else if (+axis1 > +axis2) {
              return 1 * sortIndex;
            } else {
              return -1 * sortIndex;
            }
          };

        // sort by str str, and if element empty - put it in the end of sort
        case FIELD_TYPES.OBJECT:
          sortIndex = getSortType(sortType, SORT_TYPES.ASC);
          return function(axis1, axis2) {
            if (axis1.recordTitle === null) {
              return 1;
            } else if (axis2.recordTitle === null) {
              return -1;
            } else if (axis1.recordTitle > axis2.recordTitle) {
              return 1 * sortIndex;
            } else {
              return -1 * sortIndex;
            }
          };

        case FIELD_TYPES.USER:
          sortIndex = getSortType(sortType, SORT_TYPES.ASC);
          return function(axis1, axis2) {
            if (axis1.title === null) {
              return 1;
            } else if (axis2.title === null) {
              return -1;
            } else if (axis1.title > axis2.title) {
              return 1 * sortIndex;
            } else {
              return -1 * sortIndex;
            }
          };

        // special sort for date, cause of sort direction and sub_type format
        case FIELD_TYPES.DATE:
          sortIndex = getSortType(sortType, SORT_TYPES.DESC);

          switch (subType) {
            case AXIS_SUB_TYPES.DAY_OF_WEEK:
              return function(axis1, axis2) {
                // С сервера значение для вс приходит 1, для пн - 2
                axis1 = axis1 == 1 ? 10 : axis1;
                axis2 = axis2 == 1 ? 10 : axis2;
                return +axis1 > +axis2 ? 1 * sortIndex : -1 * sortIndex;
              };

            default:
              return function(axis1, axis2) {
                return axis1 > axis2 ? 1 * sortIndex : -1 * sortIndex;
              };
          }

        // default sort for other types
        default:
          return function(axis1, axis2) {
            if (axis1 === null) {
              return 1;
            } else if (axis2 === null) {
              return -1;
            } else if (axis1 > axis2) {
              return 1 * sortIndex;
            } else {
              return -1 * sortIndex;
            }
          };
      }

    case AXIS_TYPES.CREATED:
      sortIndex = getSortType(sortType, SORT_TYPES.DESC);
      switch (subType) {
        case AXIS_SUB_TYPES.HOUR:
          return function(axis1, axis2) {
            return axis1 > axis2 ? 1 * sortIndex : -1 * sortIndex;
          };

        case AXIS_SUB_TYPES.HOUR_OF_DAY:
          return function(axis1, axis2) {
            return +axis1 > +axis2 ? 1 * sortIndex : -1 * sortIndex;
          };

        // for security
        case AXIS_SUB_TYPES.DAY_OF_WEEK:
          return function(axis1, axis2) {
            // С сервера значение для вс приходит 1, для пн - 2
            axis1 = axis1 == 1 ? 10 : axis1;
            axis2 = axis2 == 1 ? 10 : axis2;
            return +axis1 > +axis2 ? 1 * sortIndex : -1 * sortIndex;
          };

        default:
          return function(axis1, axis2) {
            return axis1 > axis2 ? 1 * sortIndex : -1 * sortIndex;
          };
      }
  }
}
