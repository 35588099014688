import React from "react";
import PureRenderMixin from "react-addons-pure-render-mixin";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import LinkedItem from "../../common/UI/LinkedItem";
import ChangeDirection from "./ChangeDirection";

import styles from "./changes.less";

const ChangeUser = props => {
  const { t } = useTranslation();
  let oldUsers = props.change.get("oldValue");
  let newUsers = props.change.get("newValue");
  //Фильтрация дубликатов
  let newUsersList = newUsers.filter(user => {
    return !oldUsers.find(testUser => user.get("id") == testUser.get("id"));
  });
  let oldUsersList = oldUsers.filter(user => {
    return !newUsers.find(testUser => user.get("id") == testUser.get("id"));
  });

  const isSingle =
    (oldUsers && oldUsers.size) === 1 && (newUsers && newUsers.size) === 1;

  if (isSingle) {
    const fromUser = oldUsers.first() ? (
      <LinkedItem
        item={{
          icon: "users-1",
          text: oldUsers.first().get("title") || t("emptyUser")
        }}
        linkProps={{
          style: { cursor: "pointer" }
        }}
        params={{
          // sectionId: "",
          catalogId: "3", // hardcode :(
          recordId: oldUsers.first().get("id")
        }}
      />
    ) : null;

    const toUser = newUsers.first() ? (
      <LinkedItem
        item={{
          icon: "users-1",
          text: newUsers.first().get("title") || t("emptyUser")
        }}
        linkProps={{
          style: { cursor: "pointer" }
        }}
        params={{
          // sectionId: "",
          catalogId: "3", // hardcode :(
          recordId: newUsers.first().get("id")
        }}
      />
    ) : null;

    return (
      <ChangeDirection
        containerClass={styles.flexContainer}
        fromObj={fromUser}
        toObj={toUser}
      />
    );
  } else {
    return (
      <div>
        {newUsersList.map((item, i) => {
          return (
            <LinkedItem
              key={i}
              item={{
                icon: "users-1",
                text: item.get("title") || t("emptyUser")
              }}
              linkProps={{
                style: { cursor: "pointer" }
              }}
              params={{
                // sectionId: "",
                catalogId: "3", // hardcode :(
                recordId: item.get("id")
              }}
            />
          );
        })}
        {oldUsersList.map((item, i) => {
          return (
            <LinkedItem
              key={i}
              item={{
                icon: "users-1",
                text: item.get("title") || t("emptyUser")
              }}
              removed
            />
          );
        })}
      </div>
    );
  }
};

ChangeUser.propTypes = {
  change: PropTypes.object.isRequired,
  isNewRecord: PropTypes.bool
};

export default ChangeUser;
