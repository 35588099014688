import React from "react";
import { Row } from "antd";
import { useTranslation } from "react-i18next";

import Dropdown from "../../../../common/Dropdown";
import { getFieldsByType } from "../../../helpers";

import FIELD_TYPES from "../.../../../../../../configs/fieldTypes";

import styles from "../config.less";

const DateSetting = props => {
  const { t } = useTranslation();
  const value = props.settings.getIn([props.name, "key"]) || null;
  let items = getFieldsByType(props.fields, FIELD_TYPES.DATE);

  if (props.name === "end") {
    const startFieldId = props.settings.getIn(["start", "key"]);
    items = items.filter(item => item.key !== startFieldId);
  }
  return items ? (
    <Row className={styles.controlRow}>
      <label className={styles.controlLabel}>
        {t(`calendar.config.${props.name}`)}
      </label>
      <Dropdown
        disabled={!items.length}
        items={
          props.name === "end"
            ? [{ key: null, text: "", sort: -1 }, ...items]
            : items
        }
        value={value}
        autocomplete={true}
        withButton={true}
        multiselect={false}
        onSelectItems={([item]) => props.handler(item, props.name)}
      />
    </Row>
  ) : null;
};

export default DateSetting;
