import { fromJS } from "immutable";
import _ from "lodash";

export default {
  // Нельзя задавать у записи атрибуты, в которых есть предустановленные
  // значения свойств, отвечающие за состояние записи, которое может отличаться
  // у экземпляров записей,
  // тк это может привести к коллизии состояний,
  // при получении записи происходит мердж полученной записи
  // и записи, которая уже находится в стейте
  // например при открытии записи, которая была загружена
  // в другой записи, через связи

  /* 
    Например, если бы мы создавали новую запись и при этом 
    задали бы значение updateProcesses в дефолтное {
      should: false,
      count: 0,
      fields: {}
    };

    То было бы непонятно какому значению верить. Значению из
    существующей, где count: 1 или новополученной, где count: 0
  */
  create(data = {}) {
    let record = {};
    record.__name = "Record";
    record._createTime = Date.now();

    record.id = data.id;
    record.catalogId = data.catalogId;
    record.isNew = data.isNew;
    record.chatOptions = data.chat;
    record.isDefaultReceived = !record.isNew;
    record.index = data.index;
    record.title = data.title;
    record.values = data.values || {};
    record.originValues = !data.isNew ? record.values : {}; // to this values will be able to save
    record.privilegeCode = !data.isNew ? data.privilegeCode : "edit";
    // record.fields = data.fields;
    record.fieldPrivilegeCodes = data.fieldPrivilegeCodes;
    record.history = {};
    record.errors = {};
    record.loading = false; // is there pending request for catalog records
    record.hasBeenEdit = false;

    record.parents = data.parents || [];
    /* 
    record.updateProcesses = {
      should: false,
      count: 0,
      fields: {}
    };
    */
    record.updateProcesses = {};
    return fromJS(record);
  }
};
