import React from "react";
import Immutable, { fromJS } from "immutable";
import cn from "classnames";
import { withTranslation } from "react-i18next";

import { AutoSizer, MultiGrid } from "react-virtualized";

import GridItem from "./GridItem";
import GridHeader from "./GridHeader";
import GridCell from "./GridCell";
import GridFixedColumn from "./GridFixedColumn";

import FIELD_TYPES from "../../../configs/fieldTypes";
import RESOURCE_TYPES from "../../../configs/resourceTypes";
import PRIVILEGE_CODES from "../../../configs/privilegeCodes";
import userSettingsActions from "../../../actions/userSettingsActions";
import sortRecords from "../Helpers/sortRecords";
import formatAxisValues from "../Helpers/formatAxisValues";
import {
  _getAxisByDateSubTypeKey,
  _parseRecordValueAsAxisValues
} from "../Helpers/formatAxisValues";

import * as AXIS_SUB_TYPES from "../../../configs/grid/axisSubTypes";

import styles from "./grid.less";
import _ from "lodash";
import dateCalculator from "../Helpers/dateCalculator";
import apiActions from "../../../actions/apiActions";
import sceneActions from "../../../actions/sceneActions";
import FieldApi from "../../../models/FieldApi";
import { checkAccessOnObject } from "../../../utils/rights";

let gridRef;
const defaultColumnWidth = 200;
const defaultRowHeight = 135;
const minRowHeight = 100;

const NullAxisValue = "__null";

class GridBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnWidth: {},
      columnWidthFixed: defaultColumnWidth,
      columnWidthData: defaultColumnWidth,

      rowHeight: {},
      rowHeightData: defaultRowHeight,

      axisX: Immutable.fromJS({}),
      axisY: Immutable.fromJS({}),
      axisXValues: [],
      axisYValues: [],

      records: Immutable.fromJS({}),
      fields: Immutable.fromJS([]),
      fieldsToRender: Immutable.fromJS([]),
      fieldToColor: Immutable.fromJS([]),
      sorting: Immutable.fromJS({})
    };
  }

  componentDidMount() {
    let fields = this.props.catalog.get("fields");
    fields = fields.filter(field => !field.get("hidden"));
    this.setState({ fields });
  }

  componentDidUpdate(prevProps, prevState) {
    const view = this.props.scene.getIn(["views", this.props.viewId]);

    const isAccessCreateRecordAtCatalog = checkAccessOnObject(
      RESOURCE_TYPES.CATALOG,
      this.props.catalog,
      PRIVILEGE_CODES.CREATE
    );

    let isAccessCreateRecordAtViews = true;

    if (view) {
      isAccessCreateRecordAtViews = checkAccessOnObject(
        RESOURCE_TYPES.VIEW,
        view,
        PRIVILEGE_CODES.CREATE
      );
    }
    const canCreate =
      isAccessCreateRecordAtCatalog || isAccessCreateRecordAtViews;

    canCreate !== this.state.canCreate && this.setState({ canCreate });
    isAccessCreateRecordAtCatalog !==
      this.state.isAccessCreateRecordAtCatalog &&
      this.setState({ isAccessCreateRecordAtCatalog });

    // HACK to rerender GRID after render & measure
    if (prevState !== this.state) {
      this.updateGrid();
    }

    const prevFields = prevProps.catalog && prevProps.catalog.get("fields");
    const fields = this.props.catalog && this.props.catalog.get("fields");

    if (!Immutable.is(prevFields, fields)) {
      this.setState({ fields: this.filterFieldsByHidden(fields) });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.catalog !== nextProps.catalog ||
      this.props.viewId !== nextProps.viewId ||
      this.props.records !== nextProps.scene.get("records") ||
      JSON.stringify(this.props.settings) !== JSON.stringify(nextProps.settings)
    ) {
      this.updateProps(nextProps);
      this.updateGrid();
    }
  }

  filterFieldsByHidden = (fields = Immutable.List()) => {
    return fields.filter(field => !field.get("hidden"));
  };

  updateProps = props => {
    let state = {};
    let stateUpdate;

    // calc axis & axis split values
    stateUpdate = this.refreshAxises(props);
    state = _.assign(state, stateUpdate);

    // parse field properties to render cards
    stateUpdate = this.setFieldSettings(props);
    state = _.assign(state, stateUpdate);

    // get & set column sizes from settings
    stateUpdate = this.setColumnWidthFromSettings(props);
    state = _.assign(state, stateUpdate);

    // get & set row sizes from settings
    stateUpdate = this.setRowHeightFromSettings(props);
    state = _.assign(state, stateUpdate);

    // parse records list into grid cells by axis
    stateUpdate = this.parseRecords(props);
    state = _.assign(state, stateUpdate);

    // save state
    this.setState(state);
  };

  _updatePropsDeboune = () => {
    _.debounce(props => this.updateProps(props), 100);
  };

  /* AXIS */

  refreshAxises = props => {
    const self = this;
    const {
      i18n: { language }
    } = this.props;

    // X
    const axisX = self._getAxisX(props);
    const axisXValues = axisX.get("field")
      ? formatAxisValues(axisX, props.scene, props.viewId, undefined, language)
      : [];

    // Y
    const axisY = self._getAxisY(props);
    const axisYValues = axisY.get("field")
      ? formatAxisValues(axisY, props.scene, props.viewId, undefined, language)
      : [];

    // save to state
    return {
      axisX,
      axisXValues,
      axisY,
      axisYValues
    };
  };

  _getAxisX = props => {
    const fields = this.state.fields;
    const settings = props.settings;

    // X axis
    let axisX =
      settings && settings.getIn(["options", "axisX", "value", "value"]);
    axisX = axisX && fields.find(field => field.get("id") === axisX);
    // default axis
    axisX =
      axisX || fields.find(field => field.get("type") === FIELD_TYPES.DROPDOWN);
    axisX =
      axisX || fields.find(field => field.get("type") === FIELD_TYPES.USER);
    axisX =
      axisX || fields.find(field => field.get("type") === FIELD_TYPES.OBJECT);
    axisX =
      axisX ||
      fields.find(field => field.get("type") === FIELD_TYPES.RADIOBUTTON);
    axisX =
      axisX || fields.find(field => field.get("type") === FIELD_TYPES.STARS);
    axisX =
      axisX ||
      fields.find(field => field.get("type") === FIELD_TYPES.CHECKBOXES);

    // subtype
    let subType =
      settings && settings.getIn(["options", "axisX", "value", "subType"]);

    return Immutable.fromJS({ field: axisX, subType });
  };

  _getAxisY = props => {
    const fields = this.state.fields;
    const settings = props.settings;

    let axisY =
      settings && settings.getIn(["options", "axisY", "value", "value"]);
    axisY = axisY && fields.find(field => field.get("id") === axisY);

    // subtype
    let subType =
      settings && settings.getIn(["options", "axisY", "value", "subType"]);

    return Immutable.fromJS({ field: axisY, subType });
  };

  _hasAxisX(state) {
    state = state || this.state;
    return !!state.axisX.get("field");
  }

  _hasAxisY(state) {
    state = state || this.state;
    return !!state.axisY.get("field");
  }

  /* RECORDS */

  parseRecords = props => {
    const records = props.scene && props.scene.get("records");
    const fields = this.state.fields;

    // axis
    // could NOT get from State because it is set Async :(
    const axisX = this._getAxisX(props);
    const axisY = this._getAxisY(props);
    const axisXid = axisX.getIn(["field", "id"]);
    const axisYid = axisY.getIn(["field", "id"]);

    // sorting params
    // could NOT get from State because it is set Async :(
    const sorting = this._setSortingField(props);

    let recordsGrid = {};
    let countValueY = {};
    let countValueX = {};

    if (records) {
      // pack grid by [X,Y] values
      records.forEach(r => {
        let valuesX = _parseRecordValueAsAxisValues(
          r.getIn(["values", axisXid]),
          axisX
        );
        let valuesY = _parseRecordValueAsAxisValues(
          r.getIn(["values", axisYid]),
          axisY
        );

        valuesX.forEach(valueX => {
          valuesY.forEach(valueY => {
            if (!recordsGrid[valueX]) {
              recordsGrid[valueX] = {};
            }
            if (!recordsGrid[valueX][valueY]) {
              recordsGrid[valueX][valueY] = [];
            }
            recordsGrid[valueX][valueY].push(r);
          });
        });
        valuesY.forEach(valueY => {
          if (!countValueY[valueY]) {
            countValueY[valueY] = [];
          }
          countValueY[valueY].push(r);
        });
        valuesX.forEach(valueX => {
          if (!countValueX[valueX]) {
            countValueX[valueX] = [];
          }
          countValueX[valueX].push(r);
        });
      });

      // sorting
      Object.keys(recordsGrid).map(keyX => {
        Object.keys(recordsGrid[keyX]).map(keyY => {
          recordsGrid[keyX][keyY] = sortRecords(
            recordsGrid[keyX][keyY],
            fields,
            sorting.get("fieldId"),
            sorting.get("type")
          );
        });
      });
    }

    // convert to Immutable
    recordsGrid = Immutable.fromJS(recordsGrid);
    countValueY = Immutable.fromJS(countValueY);
    countValueX = Immutable.fromJS(countValueX);

    // save to state
    return {
      records: recordsGrid,
      countValueY,
      countValueX
    };
  };

  updateGrid() {
    // gridRef && gridRef.forceUpdateGrids(); // ??
    gridRef && gridRef.recomputeGridSize(); // recalculate cell sizes after row/col resize
    this.forceUpdate();
  }

  onResize = () => this.updateGrid();

  /* COLUMN SIZES */
  setColumnWidthFromSettings(props) {
    const settings = props.settings;

    if (!settings) {
      return;
    }

    let columnWidthFixed = settings.getIn([
      "options",
      "columnWidthFixed",
      "value"
    ]);
    let columnWidthData = settings.getIn([
      "options",
      "columnWidthData",
      "value"
    ]);

    return {
      columnWidthFixed,
      columnWidthData
    };
  }

  getColumnWidth = ({ index }) => {
    return this._calcColumnWith(this.state, index);
  };

  _calcColumnWith = (state, index) => {
    const hasAxisY = this._hasAxisY(state);

    // if is Y axis, so 1 column has unique with, all other has the same
    // but resizing column can has other with during resize
    let defaultWidth =
      hasAxisY && index == 0 ? state.columnWidthFixed : state.columnWidthData;

    let width =
      state.columnWidth[String(index)] || defaultWidth || defaultColumnWidth;

    // фикс значение, меньше него уменьшить невозможно, был баг в котором ширина могла быть отрицательным числом, соотвестсвенно её не видно.
    const minColumnWidth = 100;
    width = Math.max(width, minColumnWidth);
    return width;
  };

  onColumnResize = (columnIndex, deltaX) => {
    // set size to excect column during resize
    this.setState(prevState => {
      let newColumnWidth = prevState.columnWidth;
      newColumnWidth[String(columnIndex)] =
        this._calcColumnWith(prevState, columnIndex) + deltaX;
      return {
        columnWidth: newColumnWidth
      };
    });
  };
  onColumnResizeFinished = columnIndex => {
    const hasAxisY = this._hasAxisY();
    const hasAxisX = this._hasAxisX();

    // set 1 column it size if axis Y is exist
    // all other columns has the same size

    let columnWidth = this.state.columnWidth[String(columnIndex)];
    let columnWidthKey;

    if (columnIndex == 0 && hasAxisY) {
      columnWidthKey = "columnWidthFixed";
    } else {
      columnWidthKey = "columnWidthData";
    }

    this.setState({
      [columnWidthKey]: columnWidth,
      columnWidth: {}
    });

    userSettingsActions.setOption({
      catalogId: this.props.catalogId,
      viewMode: "cards",
      option: columnWidthKey,
      value: columnWidth
    });
  };

  /* ROW SIZES */
  setRowHeightFromSettings(props) {
    const settings = props.settings;

    if (!settings) {
      return;
    }
    let rowHeightData = settings.getIn(["options", "rowHeightData", "value"]);

    return {
      rowHeightData
    };
  }

  getRowHeight = ({ index }) => {
    return this._calcRowHeight(this.state, index);
  };

  _calcRowHeight = (state, index) => {
    const hasAxisY = this._hasAxisY(state);
    let height;

    if (index == 0) {
      // высота первой ячейки фиксирована, изменить её не можем
      height = 35;
    } else if (state.rowHeight[String(index)]) {
      // пока изменяем высоту ячейки остальные не меняются
      height = Math.max(state.rowHeight[String(index)], minRowHeight);
    } else if (!hasAxisY) {
      // если нет разложения по вертикали размещаем до конца элемента и вычитаем высоту скролла горизонтального и высоту первой ячейки
      height = gridRef && gridRef.props.height - 45;
    } else if (!state.rowHeightData) {
      // если при первом заходе в стейте ничего нет берем стандатный размер
      height = Math.max(defaultRowHeight, minRowHeight);
    } else {
      // изменяем высоту тоскаемого элемента
      height = Math.max(state.rowHeightData, minRowHeight);
    }
    return height;
  };

  onRowResize = (rowIndex, deltaY) => {
    // set size to excect column during resize
    this.setState(prevState => {
      let newRowHeight = prevState.rowHeight;
      newRowHeight[String(rowIndex)] =
        this._calcRowHeight(prevState, rowIndex) + deltaY;
      return {
        rowHeight: newRowHeight
      };
    });
  };
  onRowResizeFinished = rowIndex => {
    // set 1 column it size if axis Y is exist
    // all other columns has the same size
    let rowHeight = this.state.rowHeight[String(rowIndex)];
    let rowHeightKey = "rowHeightData";

    this.setState({
      [rowHeightKey]: rowHeight,
      rowHeight: {}
    });

    userSettingsActions.setOption({
      catalogId: this.props.catalogId,
      viewMode: "cards",
      option: rowHeightKey,
      value: rowHeight
    });
  };

  /* FIELD SETTINGS */
  setFieldSettings = props => {
    const fieldsToRender = this._setFieldsToRender(props);
    const fieldToColor = this._setColorField(props);
    const sorting = this._setSortingField(props);

    return {
      fieldsToRender,
      fieldToColor,
      sorting
    };
  };

  _setFieldsToRender = props => {
    const fields = this.state.fields;

    const settings = props.settings;
    const userSettingsfields = settings && settings.get("fields");

    // visible
    let fieldsToRender = fields
      .filter(col => col.get("hidden") !== true)
      .filter(col => {
        const colId = col.get("id");
        // get setting for current FieldConfigItem, from UserSetting store.
        const visible =
          userSettingsfields &&
          userSettingsfields.getIn([colId, "visible", "visible"]);
        return visible;
      });

    // order
    let fieldOrdersFromSettings = settings.getIn([
      "fieldsOrder",
      "fieldsOrder"
    ]);
    fieldOrdersFromSettings = fieldOrdersFromSettings
      ? fieldOrdersFromSettings.toJS()
      : [];
    const fieldIds = fieldsToRender.map(col => col.get("id")).toArray();
    const notSetOrderOnField = _.difference(fieldIds, fieldOrdersFromSettings);
    const fieldsOrder = (fieldOrdersFromSettings || [])
      .filter(id => fieldIds.indexOf(id) !== -1)
      .concat(notSetOrderOnField);

    fieldsToRender = fieldsToRender
      .map(col => {
        const index = fieldsOrder.indexOf(col.get("id"));
        return col.set(
          "_order",
          index === -1 ? Number.MAX_SAFE_INTEGER : index
        );
      })
      .sort((c1, c2) => {
        return c1.get("_order") - c2.get("_order");
      });

    return fieldsToRender;
  };

  _setSortingField = props => {
    const settings = props.settings;
    const sortingRecords = settings && settings.getIn(["sortingRecords"]);

    let fieldId = sortingRecords && sortingRecords.get("sortField");
    const type = (sortingRecords && sortingRecords.get("sortType")) || -1;

    return Immutable.fromJS({
      fieldId,
      type
    });
  };

  _setColorField = props => {
    const fields = this.state.fields;

    const settings = props.settings;
    if (!settings) {
      return;
    }

    // color
    const fieldIdToColor =
      settings && settings.getIn(["options", "color", "value", "value"]);
    let fieldToColor =
      fieldIdToColor && fields.find(f => f.get("id") === fieldIdToColor);
    fieldToColor = fieldToColor || Immutable.fromJS({});

    return fieldToColor;
  };

  createRecord = (axisFields, axisKeys) => {
    if (!this.state.canCreate) {
      return;
    }

    const xValue = this.getAxisXValueByIndex(axisKeys.x);
    const yValue = this.getAxisYValueByIndex(axisKeys.y);

    const values = this.prepareValues(null, { xValue, yValue }, axisFields);
    const { catalogId, viewId, sceneId } = this.props;

    sceneActions.openNewRecord(
      { catalogId, viewId, parentSceneId: sceneId },
      values,
      {
        onCreateRecord: this.props.loadData
      }
    );
  };

  /**
   * @method onDropCard
   * @param {axisX when card located earlier} dragAxisXkey
   * @param {axisY when card located earlier} dragAxisYkey
   * @param {recordId of dropped card} recordId
   * @param {axisX when card has been dropped } dropAxisYkey
   * @param {axisX when card has been dropped } dropAxisXkey
   * @param {field of cell when card has been dropped} axisXField
   * @param {field of cell when card has been dropped} axisYField
   */

  onDropCard = (
    dragAxisXkey,
    dragAxisYkey,
    recordId,
    dropAxisYkey,
    dropAxisXkey,
    xField,
    yField
  ) => {
    if (dragAxisXkey === dropAxisXkey && dragAxisYkey === dropAxisYkey) {
      return;
    }

    const [record] = this.findLocalRecord(dragAxisXkey, dragAxisYkey, recordId);
    const prevRecordValues = record.get("values");

    const xValue = this.getAxisXValueByIndex(dropAxisXkey);
    const yValue = this.getAxisYValueByIndex(dropAxisYkey);

    const prevXValue = this.getAxisXValueByIndex(dragAxisXkey);
    const prevYValue = this.getAxisYValueByIndex(dragAxisYkey);

    const values = this.prepareValues(
      { prevXValue, prevYValue },
      { xValue, yValue },
      { xField, yField },
      prevRecordValues
    );

    const fromAxis = { x: dragAxisXkey, y: dragAxisYkey };
    const toAxis = { x: dropAxisXkey, y: dropAxisYkey };

    this.updateLocalRecord(fromAxis, toAxis, recordId, values);

    this.updateGrid();

    const catalogId = this.props.catalogId;
    const sceneId = this.props.sceneId;

    apiActions
      .updateRecord(
        { catalogId, recordId },
        { values: values.toJS() },
        { sceneId }
      )
      .finally(this.props.loadData);
  };

  /**
   * @method getAxisYValueByIndex
   * @param {value index by YAxis} index
   */
  getAxisYValueByIndex = index => {
    if (this._hasAxisY(this.state)) {
      return this.state.axisYValues.find(
        object => object.key === String(index)
      );
    }
  };

  /**
   * @method getAxisXValueByIndex
   * @param {value index by XAxis} index
   */
  getAxisXValueByIndex = index => {
    if (this._hasAxisX(this.state)) {
      return this.state.axisXValues.find(
        object => object.key === String(index)
      );
    }
  };

  /**
   * @method prepareValues
   * @param {previous values by axis} prevAxisValues
   * @param {values by axis} axisValues
   * @param {fields by axis} axisFields
   * @param {prev values of dropped record} prevRecordValues
   */
  prepareValues = (
    prevAxisValues,
    axisValues,
    axisFields,
    prevRecordValues
  ) => {
    const { xField, yField } = axisFields;

    let { xValue, yValue } = axisValues;
    let { prevXValue, prevYValue } = prevAxisValues || {};
    let values = Immutable.Map();

    if (!_.isUndefined(xValue)) {
      const subType = this.state.axisX.get("subType");
      const xFieldId = xField.get("id");
      const prevRecordValue =
        prevRecordValues && prevRecordValues.get(xFieldId);

      const value = this.prepareValue(
        prevXValue,
        xValue,
        xField,
        subType,
        prevRecordValue
      );
      values = values.set(xFieldId, value);
    }

    if (!_.isUndefined(yValue) && yField) {
      const subType = this.state.axisY.get("subType");
      const yFieldId = yField.get("id");

      const prevRecordValue =
        xField.get("id") !== yField.get("id")
          ? prevRecordValues && prevRecordValues.get(yFieldId)
          : values.get(yFieldId);

      const value = this.prepareValue(
        prevYValue,
        yValue,
        yField,
        subType,
        prevRecordValue
      );

      values = values.set(yFieldId, value);
    }

    return values;
  };

  /**
   *
   * @param { axis value when card was located } prevAxisValue
   * @param { axis value when we have dropped card } axisValue
   * @param { field of axid } axisField
   * @param { subType }
   * @param { record value before dnd } prevRecordValues
   */
  prepareValue = (
    prevAxisValue,
    axisValue,
    axisField,
    subType,
    prevRecordValue
  ) => {
    const noPrevAxisValue =
      prevAxisValue && prevAxisValue.key === NullAxisValue;
    const axisValueIsEmpty = axisValue.key === NullAxisValue;

    const fieldType = axisField.get("type");
    // если и в prevRecordValue значение пустое то возвращем
    // либо результат выполнения calcNewDateValue либо axisValue.value
    if (!prevRecordValue) {
      return fieldType === FIELD_TYPES.DATE
        ? this.calcNewDateValue(subType, axisValue)
        : axisValue.value;
    }
    // если это дата то просто возвращаем результат выполнения calcNewDateValue
    if (fieldType === FIELD_TYPES.DATE) {
      return this.calcNewDateValue(subType, axisValue, prevRecordValue);
    }

    if (axisValueIsEmpty) {
      return FieldApi.getEmptyValue(axisField);
    }

    // из prevRecordValue удаляем prevAxisValue.key и вставляем axisValue.value
    let value = !noPrevAxisValue
      ? FieldApi.removeItem(axisField, prevRecordValue, prevAxisValue.key)
      : prevRecordValue;

    const newItemExistsAtValue = FieldApi.hasItem(
      axisField,
      value,
      axisValue.key
    );

    if (newItemExistsAtValue !== null || newItemExistsAtValue !== undefined) {
      const newItem = _.isObject(axisValue.value)
        ? axisValue.value.get(0)
        : axisValue.value;
      value = FieldApi.setValue(axisField, value, newItem);
    }

    return value;
  };

  /**
   * @method calcNewDateValue
   * @description create new date values in relations to type of date
   * @param { date subType } subType
   * @param { axisValue } axisValue
   */

  calcNewDateValue = (subType, axisValue, prevValue) => {
    if (axisValue.key === NullAxisValue) {
      return axisValue.value;
    }
    switch (subType) {
      case AXIS_SUB_TYPES.HOUR:
        return dateCalculator.calcHour(prevValue, axisValue.key);
      case AXIS_SUB_TYPES.HOUR_OF_DAY:
        return dateCalculator.calcHourOfDay(prevValue, axisValue.key);
      case AXIS_SUB_TYPES.DAY:
        return dateCalculator.calcDay(prevValue, axisValue.key);
      case AXIS_SUB_TYPES.DAY_OF_WEEK:
        return dateCalculator.calcDayOfWeek(prevValue, axisValue.key);
      case AXIS_SUB_TYPES.WEEK:
        return dateCalculator.calcWeek(prevValue, axisValue.key);
      case AXIS_SUB_TYPES.WEEK_OF_YEAR:
        return dateCalculator.calcWeekOfYear(prevValue, axisValue.key);
      case AXIS_SUB_TYPES.MONTH:
        return dateCalculator.calcMonth(prevValue, axisValue.key);
      case AXIS_SUB_TYPES.MONTH_OF_YEAR:
        return dateCalculator.calcMonthOfYear(prevValue, axisValue.key);
      case AXIS_SUB_TYPES.YEAR:
        return dateCalculator.calcYear(prevValue, axisValue.key);
      default:
        return axisValue.value;
    }
  };
  /**
   * @method updateLocalRecord
   * @description substitute new values and move record to another cell
   * @param {axis when need to move record from} fromAxis
   * @param {axis when need to move record to} fromAxis
   * @param {target record id} recordId
   * @param {completed value for substitution} values
   * @returns void
   */
  updateLocalRecord = (fromAxis, toAxis, recordId, values) => {
    const fields = this.state.fields;
    const sorting = this._setSortingField(this.props);

    let records = this.state.records;

    let [
      fromRecord,
      fromRecordIndex,
      fromCellWidthRecords
    ] = this.findLocalRecord(fromAxis.x, fromAxis.y, recordId);
    let [toRecord, toRecordIndex, toCellWidthRecords] = this.findLocalRecord(
      toAxis.x,
      toAxis.y,
      recordId
    );

    if (toRecord) {
      records = records.deleteIn([toAxis.x, toAxis.y, toRecordIndex]);
    }

    fromRecord = fromRecord.mergeIn(["values"], values);

    records = records.setIn(
      [fromAxis.x, fromAxis.y],
      fromCellWidthRecords.splice(fromRecordIndex, 1)
    );

    toCellWidthRecords = toCellWidthRecords || Immutable.List();
    if (!toCellWidthRecords.some(r => r.get("id") === fromRecord.get("id"))) {
      toCellWidthRecords = toCellWidthRecords.push(fromRecord);
    }
    toCellWidthRecords = sortRecords(
      toCellWidthRecords,
      fields,
      sorting.get("fieldId"),
      sorting.get("type")
    );

    records = records.setIn([toAxis.x, toAxis.y], toCellWidthRecords);

    records = fromJS(
      sortRecords(records, fields, sorting.get("fieldId"), sorting.get("type"))
    );

    this.setState({ records });
  };

  findLocalRecord = (axisXkey, axisYkey, recordId) => {
    // находим ячейку в которой лежит эта запись
    let cellWidthRecords = this.state.records.getIn([axisXkey, axisYkey]);

    // в этой ячейке находим запись
    let recordIndex;
    let record =
      cellWidthRecords &&
      cellWidthRecords.find((record, index) => {
        if (record.get("id") === recordId) {
          recordIndex = index;
          return true;
        }
      });

    return [record, recordIndex, cellWidthRecords];
  };

  render() {
    const self = this;

    const catalogId = this.props.catalogId;
    const sceneId = this.props.sceneId;
    const settings = this.props.settings;
    const options = this.props.options;

    const axisXField = this.state.axisX.get("field");
    const axisYField = this.state.axisY.get("field");

    const records = this.state.records;

    const { axisX, axisY, axisXValues, axisYValues } = this.state;

    const hasAxisX = this._hasAxisX();
    const hasAxisY = this._hasAxisY();

    const canCreate = this.state.canCreate;

    const axisYValue = options && options.getIn(["axisY", "value"]);

    const firstRowCellIndex = 1;
    const firstColumnCellIndex = axisYValue ? 1 : 0;

    const cellRenderer = ({
      columnIndex, // X (column) index of cell
      isScrolling, // The Grid is currently being scrolled
      isVisible, // This cell is visible within the grid (eg it is not an overscanned cell)
      key, // Unique key within array of cells
      parent, // Reference to the parent Grid (instance)
      rowIndex, // Y (row) index of cell
      style // Style object to be applied to cell (to position it);
      // This must be passed through to the rendered cell element.
    }) => {
      const axisXIndex = columnIndex - (hasAxisY ? 1 : 0);
      const axisYIndex = rowIndex - (hasAxisX ? 1 : 0);

      let content;

      // render cells with cards
      const axisXkey =
        (axisXValues[axisXIndex] && axisXValues[axisXIndex].key) ||
        NullAxisValue;
      const axisYkey =
        (axisYValues[axisYIndex] && axisYValues[axisYIndex].key) ||
        NullAxisValue;

      if (rowIndex == 0 && hasAxisX && columnIndex == 0 && hasAxisY) {
        // render 0 header with both axis
        const fieldName =
          axisY.getIn(["field", "name"]) +
          " / " +
          axisX.getIn(["field", "name"]);
        content = (
          <GridHeader
            columnIndex={columnIndex}
            value={fieldName}
            onColumnResize={self.onColumnResize}
            onColumnResizeFinished={self.onColumnResizeFinished}
          />
        );
      } else if (rowIndex == 0 && !hasAxisX) {
        // render header with NO axis
        content = (
          <GridHeader
            columnIndex={columnIndex}
            value={this.props.t("records.cards.axis.types.nothing")}
            onColumnResize={self.onColumnResize}
            onColumnResizeFinished={self.onColumnResizeFinished}
          />
        );
      } else if (rowIndex == 0 && hasAxisX) {
        // render header with axis
        if (axisXValues[axisXIndex]) {
          const axisKey = axisXValues[axisXIndex].key;
          const recordsToAxisY = this.state.countValueX.get(axisKey);
          content = (
            <GridHeader
              count={recordsToAxisY}
              columnIndex={columnIndex}
              field={axisX.get("field")}
              value={axisXValues[axisXIndex].value}
              onColumnResize={self.onColumnResize}
              onColumnResizeFinished={self.onColumnResizeFinished}
            />
          );
        }
      } else if (columnIndex == 0 && hasAxisY) {
        // render fixed column
        if (axisYValues[axisYIndex]) {
          const axisKey = axisYValues[axisYIndex].key;
          const recordsToAxisY = this.state.countValueY.get(axisKey);
          content = (
            <GridFixedColumn
              rowIndex={rowIndex}
              count={recordsToAxisY}
              field={axisY.get("field")}
              value={axisYValues[axisYIndex].value}
              onRowResize={self.onRowResize}
              onRowResizeFinished={self.onRowResizeFinished}
            />
          );
        }
      } else if (records) {
        const recordsToRender = records.getIn([axisXkey, axisYkey]);
        content = (
          <GridCell
            rowIndex={rowIndex}
            axisXkey={axisXkey}
            axisYkey={axisYkey}
            isAccessCreateRecordAtCatalog={
              this.state.isAccessCreateRecordAtCatalog
            }
            catalogId={catalogId}
            sceneId={sceneId}
            records={recordsToRender}
            settings={settings}
            fieldsToRender={self.state.fieldsToRender}
            fieldToColor={self.state.fieldToColor}
            onDeleteRecord={this.props.loadData}
            t={this.props.t}
          />
        );
      }

      // render grid cell
      const lastRow =
        hasAxisY && axisYValues.length
          ? rowIndex == axisYValues.length
          : rowIndex == 1;
      const lastCol =
        hasAxisX && axisXValues.length
          ? !hasAxisY
            ? columnIndex == axisXValues.length - 1
            : false
          : columnIndex == (hasAxisY ? 1 : 0);

      return (
        <GridItem
          onCreateRecord={this.createRecord}
          key={key}
          canDrop={
            rowIndex >= firstRowCellIndex && columnIndex >= firstColumnCellIndex
          }
          axisXkey={axisXkey}
          axisYkey={axisYkey}
          axisXField={axisXField}
          axisYField={axisYField}
          onDropCard={this.onDropCard}
          canCreate={canCreate}
          className={{
            [styles.lastRow]: lastRow,
            [styles.lastCol]: lastCol
          }}
          style={style}
        >
          {content}
        </GridItem>
      );
    };

    let columnCount = hasAxisY ? 1 : 0; // add column for fixed column
    columnCount += axisXValues.length || 1; // add columns equal to X split count

    //const hasRecords = this.props.records && this.props.records.size;
    const hasParsedRecords = records && records.size;
    let rowCount = 1; // add row for header
    if (hasParsedRecords) {
      // skip if now records that are parsed yet. it cause empty cells render
      rowCount += hasAxisY ? axisYValues.length : 1; // add rows equal to Y split count or 1 row if no split
    }

    return (
      <AutoSizer onResize={this.onResize}>
        {({ height, width }) => (
          <MultiGrid
            height={height}
            width={width}
            cellRenderer={cellRenderer}
            fixedColumnCount={hasAxisY ? 1 : 0}
            fixedRowCount={1}
            columnCount={columnCount}
            rowCount={rowCount}
            columnWidth={this.getColumnWidth}
            rowHeight={this.getRowHeight}
            overscanRowCount={2}
            enableFixedColumnScroll={true}
            className={styles.list}
            classNameTopRightGrid={styles.gridHead}
            classNameTopLeftGrid={cn(styles.gridHead, styles.gridFixedColumn)}
            classNameBottomLeftGrid={styles.gridFixedColumn}
            classNameBottomRightGrid={styles.gridData}
            ref={ref => (gridRef = ref)}
          />
        )}
      </AutoSizer>
    );
  }
}

export default withTranslation(undefined, { withRef: true })(GridBody);
