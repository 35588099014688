import _ from "lodash";
import Immutable from "immutable";
import { withTranslation } from "react-i18next";
import BasicSelect from "./Basic";

import FIELD_TYPES from "../../../../../../configs/fieldTypes";

import * as AXIS_TYPES from "../../../../../../configs/reports/widget/axisTypes";
import trs from "../../../../../../getTranslations";

class SelectAxis extends BasicSelect {
  getDefaultValue() {
    const { availableValues } = this.state;

    if (!availableValues.length) {
      return null;
    }

    return availableValues[0];
  }

  getAvailableValues(fields) {
    const t = this.props.t ? this.props.t : trs;
    const values = [];
    values.push({
      type: AXIS_TYPES.FIELD,
      value: "id",
      title: t("records.cards.axis.types.id")
    });

    fields.toArray().map(field => {
      const fieldId = field.get("id");
      const fieldName = field.get("name");

      if (field.get("type") !== FIELD_TYPES.GROUP) {
        values.push({
          type: AXIS_TYPES.FIELD,
          value: fieldId,
          title: fieldName
        });
      }
    });

    return values;
  }
}

export default withTranslation()(SelectAxis);
