import React, { useMemo } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import DropdownRemote from "../../../../DropdownRemote";
import { TYPES } from "../../../../../../actions/availableLinkedRecordActions";
import useDropdownBehaviour from "../../hooks/useDropdownBehavior.hook";

import styles from "../controls.less";

const LinkedRecordSelector = props => {
  const { t } = useTranslation();
  const enableSelect = props.config.get("enableSelect");
  const enableCreate = props.config.get("enableCreate");
  const placeholder = t("record.fields.object.add") || props.placeholder;

  const {
    dropdownClassName,
    visible,
    selectItem,
    visibleChange
  } = useDropdownBehaviour({
    className: styles.dropdownRemote,
    onSelectItem: props.onAddItem,
    onVisibleChange: props.onVisibleChange
  });

  if (props.type === TYPES.LINKED_OBJECTS) {
    if (!enableSelect && !enableCreate) {
      return null;
    }

    if (
      !enableSelect &&
      props.additionalItems &&
      props.additionalItems.length < 2
    ) {
      const item = props.additionalItems[0];

      return (
        <div className={styles.linkedItemCreate}>
          {item && item.children
            ? item.children({ linkProps: { children: item.text } })
            : null}
        </div>
      );
    }
  }

  return (
    <div className={cn(styles.dropdownContainer, props.wrapperClassName)}>
      <DropdownRemote
        placeholder={placeholder}
        className={dropdownClassName}
        showArrow={visible}
        open={visible || props.open}
        onVisibleChange={visibleChange}
        onSelectItems={selectItem}
        {...props}
      />
    </div>
  );
};

export default LinkedRecordSelector;
