import React from "react";

import FIELD_TYPES from "../../../configs/fieldTypes";

import styles from "../Grid/grid.less";

class AxisSimpleHeader extends React.PureComponent {
  render() {
    let value = this.props.value;

    if (!value) {
      return <span />; // для позиционирования количества записей, чтобы он всегда был в правом углу
    }

    if (value.length > 100) {
      value = value.substr(0, 100) + "...";
    }

    return <strong className={styles.headerTitle}>{value}</strong>;
  }
}

const fieldComponentsByType = {
  [FIELD_TYPES.TEXT]: require("../../common/dataTypes/TextField").default,
  [FIELD_TYPES.NUMBER]: require("../../common/dataTypes/fieldWrappers/NumberField")
    .default,
  [FIELD_TYPES.CONTACT]: require("../../common/dataTypes/ContactField").default,
  [FIELD_TYPES.ADDRESS]: require("../../common/dataTypes/AddressField").default,
  [FIELD_TYPES.DATE]: AxisSimpleHeader,
  [FIELD_TYPES.USER]: require("../../common/dataTypes/UserField").default,
  [FIELD_TYPES.DROPDOWN]: require("../../common/dataTypes/CategoryField")
    .default,
  [FIELD_TYPES.SWITCH]: require("../../common/dataTypes/SwitchField").default,
  [FIELD_TYPES.CHECKBOXES]: require("../../common/dataTypes/CheckboxesField")
    .default,
  [FIELD_TYPES.RADIOBUTTON]: require("../../common/dataTypes/RadiobuttonField")
    .default,
  [FIELD_TYPES.OBJECT]: require("../../common/dataTypes/ObjectField").default,
  [FIELD_TYPES.FILE]: require("../../common/dataTypes/fieldWrappers/FileField")
    .default,
  [FIELD_TYPES.PROGRESS]: require("../../common/dataTypes/ProgressField")
    .default,
  [FIELD_TYPES.STARS]: require("../../common/dataTypes/StarsField").default
};

export default class AxisFieldValue extends React.PureComponent {
  render() {
    const { field, value, className } = this.props;
    if (!field) {
      return null;
    }

    const fieldId = field.get("id");
    const fieldType = field.get("type");

    const FieldComponent = fieldComponentsByType[fieldType];
    if (!FieldComponent) {
      return null;
    }

    const fieldProps = {
      fieldId,
      fieldType,
      config: field.get("config"),
      value,
      containerClassName: className,
      extended: true
    };

    return <FieldComponent {...fieldProps} />;
  }
}
