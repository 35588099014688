import React from 'react';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

import AvatarUser from '../../../../../common/UI/AvatarUser';
import { formatDate, formatTime } from '../../../../../../utils/formatDate';

import styles from "./message.less";

const MessageDeleted = ({ id, t, isMe, key, message, isPreviousMessageSameUser, i18n, lastMessage }) => {
    const isSameUser = isPreviousMessageSameUser(message);
    const date = message.get("createdDate");
    const authorName = message.getIn(["author", "recordTitle"]);
    const sendingTime = formatTime(date);
    const { language } = i18n;

    return (
        <div key={key} className={classNames(styles.msg, {
            [styles.msgSameUser]: isSameUser,
            [styles.msgIsMe]: isMe,
            [styles.msgLastMessage]: lastMessage
        })}>
            {/* ну тут типо если предыдущее сообщение отправил он же условие не показывать аватар плюс если МЫ отправили */}
            {!isMe && (
                <React.Fragment>
                    {!isSameUser ? (
                        <AvatarUser
                            userName={authorName}
                            className={styles.avatar}
                        />
                    ) : (
                        <div className={styles.emptyAvatar} />
                    )}
                </React.Fragment>
            )}
            <div style={{ paddingTop: 8 }} className={styles.msgBubble}>
                <div className={styles.msgContent}>
                    <time title={formatDate(date, language, true)} className={styles.msgInfoTime}>{sendingTime}</time>
                    <span className={styles.msgTextDeleted}>{t("record.chat.message.messageDeleted")}</span>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(MessageDeleted);