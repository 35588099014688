import React, { useState, useEffect, useLayoutEffect } from "react";
import Immutable from "immutable";
import _ from "lodash";

export const useCalcPersent = (value, amount) => {
  // const persent = +value / +amount

  return `100%`;
  // return persent
};

export const usePrepareMessages = records => {
  const [items, setItems] = useState(null);

  useEffect(
    () => {
      const items = {};

      if (!records) {
        setItems(null);
      } else {
        records.forEach((record, recordKey) => {
          const recordId = record.get("id");
          let messages = record.getIn(["indicator", "messages"]);

          const isImmutableObject =
            Immutable.Map.isMap(messages) || Immutable.List.isList(messages);

          messages = isImmutableObject ? messages.toJS() : messages;

          const uniqMessages = _.chain(messages)
            .map(message => {
              if (_.isObject(message)) {
                // console.log("message", message);
                return message.text;
              }
              return message;
            })
            .uniq(messages)
            .value();

          messages = _.map(
            uniqMessages,
            (message, key) => {
              const repeats = _.chain(messages)
                .filter(_message => _.isEqual(_message, message))
                .size()
                .value();

              return repeats > 1 ? message + String(repeats) : message;
            },
            []
          );

          items[recordId] = messages;
        });

        setItems(items);
      }
    },
    [records]
  );

  return items;
};
