import React, { Component } from "react";
import { Button, Progress } from "antd";
import _ from "lodash";
import moment from "moment";
import { withTranslation } from 'react-i18next';

import { connect } from "../../../StateProvider";
import {
  IMPORT_STATUS,
  RECORD_STATUS_FIELD_ID,
  RECORD_STATUSES
} from "../../../../configs/import";
import LoadingSpin from "../../../common/LoadingSpin";

import styles from "../ImportStyles.less";

class ImportFooter extends Component {
  format = percent => {
    if (!percent) return;

    return Math.floor(percent) + "%";
  };

  render() {
    const {
      importStatus,
      loading,
      recordsCount,
      recordsCountByStatus,
      startSaving,
      stopSaving,
      waitingTime,
      showSpin,
      importing,
      t
    } = this.props;
    // const {  selectedColumnsSize } = this.state;

    const imported =
      +recordsCountByStatus.get(RECORD_STATUSES.IMPORT_COMPLETED) || 0;
    const failed =
      +recordsCountByStatus.get(RECORD_STATUSES.IMPORT_FAILED) || 0;
    const parsed =
      +recordsCountByStatus.get(RECORD_STATUSES.PARSE_COMPLETED) || 0;

    const parsedRecordsSize = imported + failed + parsed;

    const parsedRecordsPercent =
      ((imported + failed) / parsedRecordsSize) * 100;
    const importedRecordsPercent = (imported / parsedRecordsSize) * 100;

    let buttonClassName;
    let buttonOnClick;
    let butonChildren;
    let disabled;

    if (importStatus === IMPORT_STATUS.SAVING.STATUS) {
      buttonClassName = styles.buttonStop;
      buttonOnClick = stopSaving;
      disabled = loading;
      butonChildren = t("import.buttons.importStop");
    } else if (importStatus === IMPORT_STATUS.STOPED.STATUS) {
      buttonClassName = styles.buttonSave;
      buttonOnClick = startSaving;
      disabled = loading;
      butonChildren = t("import.buttons.importResume");
    } else if (importStatus === IMPORT_STATUS.LOADING.STATUS) {
      buttonClassName = styles.buttonSave;
      disabled = true;
      buttonOnClick = startSaving;
      butonChildren = t("import.buttons.importStart");
    } else {
      buttonClassName = styles.buttonSave;
      disabled = loading;
      buttonOnClick = startSaving;
      butonChildren = t("import.buttons.importStart");
    }

    let hint = recordsCount
      ? t("import.progress.indicator.all") + " " + recordsCount
      : "";

    if (parsedRecordsSize > 0) {
      hint =
        hint +
        " / " +
        t("import.progress.indicator.parsedRecords") +
        " " +
        parsedRecordsSize;
    }

    if (imported > 0) {
      hint =
        hint +
        " / " +
        t("import.progress.indicator.imported") +
        " " +
        imported;
    }

    if (failed > 0) {
      hint =
        hint + " / " + t("import.progress.indicator.failed") + " " + failed;
    }

    return (
      <div className={styles.footerWrapper}>
        <div className={styles.leftPart}>
          <Button
            key="saveData"
            type="primary"
            disabled={disabled}
            className={buttonClassName}
            onClick={buttonOnClick}
          >
            {butonChildren}
          </Button>

          <div className={styles.progressWrapper}>
            <div className={styles.progressHint}>{hint}</div>
            <Progress
              percent={parsedRecordsPercent}
              successPercent={importedRecordsPercent}
              className={styles.progressComponent}
              format={this.format}
            />
          </div>
        </div>

        <div className={styles.rightPart}>
          {showSpin && <LoadingSpin className={styles.spin} />}
          {waitingTime &&
            importing && (
              <div
                title={t("import.timer.waitingTime")}
                className={styles.timerWrapper}
              >
                {waitingTime}
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default connect(
  withTranslation()(ImportFooter),
  {
    importStatus: ["import", "importStatus"],
    loading: ["import", "loading"],
    records: ["import", "records"],
    waitingTime: ["import", "waitingTime"]
  },
  (props, { records, filteredRecords, waitingTime, ...state }) => {
    const { loading, importStatus } = state;

    const recordsCountByStatus =
      records &&
      records.countBy(r => r.getIn(["values", RECORD_STATUS_FIELD_ID, 0]));

    const recordsCount = records && records.size;
    const saving = importStatus === IMPORT_STATUS.LOADING.STATUS;
    const importing = importStatus === IMPORT_STATUS.SAVING.STATUS;
    const showSpin = loading || saving || importing;
    waitingTime = waitingTime && moment.utc(waitingTime).format("HH:mm:ss");

    return {
      ...state,
      ...props,
      recordsCount,
      recordsCountByStatus,
      showSpin,
      waitingTime,
      importing
    };
  }
);
