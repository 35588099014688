import _ from "lodash";
import React from "react";
import Immutable from "immutable";
import { Modal, Button, Row } from "antd";
import { withTranslation } from "react-i18next";

import { connect } from "../../../StateProvider";
import apiActions from "../../../../actions/apiActions";
import ButtonClose from "../../../common/UI/ButtonClose";
import HashMemoryRouter from "../../../common/router/HashMemoryRouter";
import Data from "./data";
import StatisticsData from "../../../Statistics";
import { confirm } from "../../../common/Modal";
import filtersUtils from "../../../../utils/filters";

import styles from "./popup.less";

const PriceLink = connect(
  function PriceLink({ priceUrl, t }) {
    return (
      <a
        className={`default ${styles.linkToLicense}`}
        href={priceUrl}
        target="_blank"
      >
        {t("reports.widget.common.messages.noLicenseToSave")}
      </a>
    );
  },
  {
    priceUrl: ["vendor", "priceUrl"]
  }
);

class WidgetCreate extends React.Component {
  constructor(props) {
    super(props);
    let { widget } = this.props;

    if (widget) {
      // new uid for new chart data not modify in widgets list
      widget = widget.merge({
        uid: _.uniqueId("edit_")
      });
    } else {
      widget = Immutable.Map({
        id: null,
        uid: _.uniqueId("new_")
      });
    }

    widget = widget.set("inEditMode", true);

    this.state = { widget }; 
  }

  onChange = (obj, newWidget = null) => {
    // throughout callback because this.state changed asynchronous after setState()
    // if setState calls in 2 times, second setState get this.state is very old
    this.setState(state => {
      if (newWidget) {
        return {
          widget: newWidget
        };
      }

      return {
        widget: state.widget.mergeDeep(obj)
      };
    });
  };

  onSave = () => {
    this.props.onOk();

    let { widget } = this.state;
    const widgetId = widget.get("id");
    const boardId = this.props.board.get("id");
    
    widget = widget["delete"]("inEditMode");

    let appWidget = widget;
    let requestWidget = filtersUtils.prepareWidgetFiltersForRequest(widget);

    if (widgetId) {
      requestWidget = requestWidget["delete"]("uid");
      appWidget = appWidget["delete"]("uid");
      apiActions.updateWidget({ boardId, widgetId }, requestWidget, { appWidget });
    } else {
      apiActions.createWidget({ boardId }, requestWidget, { appWidget });
    }
  };

  openRemoveModal = () => {
    const { t } = this.props;
    const modalData = {
      headerText: t("modals.removeWidget.header"),
      okText: t("modals.removeWidget.okText"),
      cancelText: t("modals.removeWidget.cancelText"),
      text: t("modals.removeWidget.text"),
      onOk: this.onRemove
    };

    confirm(modalData);
  };

  onRemove = () => {
    this.props.onCancel();

    const { widget } = this.state;
    const widgetId = widget.get("id");
    const boardId = this.props.board.get("id");

    apiActions.deleteWidget({ boardId, widgetId }, widget);
  };

  render() {
    const { license, t } = this.props;
    const { widget } = this.state;
    const widgetId = widget.get("id");
    const title = widgetId
      ? t("reports.widget.modals.edit.title")
      : t("reports.widget.modals.create.title");

    const saveTitle = widgetId
      ? t("reports.widget.modals.edit.buttons.ok")
      : t("reports.widget.modals.create.buttons.ok");

    let footer = [
      <Button
        key="submit"
        type="primary"
        disabled={!license}
        onClick={license && this.onSave}
      >
        {saveTitle}
      </Button>
    ];
    if (license) {
      footer = footer.concat([
        <Button key="back" type="default" onClick={this.props.onCancel}>
          {t("reports.widget.modals.common.buttons.cancel")}
        </Button>
      ]);
    } else {
      footer = footer.concat([<PriceLink key="price" t={t} />]);
    }

    if (widgetId && license) {
      footer = footer.concat([
        <Button
          key="remove"
          type="danger"
          style={{ float: "right" }}
          onClick={this.openRemoveModal}
        >
          {t("reports.widget.modals.common.buttons.remove")}
        </Button>
      ]);
    }

    return (
      <Modal
        open={true}
        maskClosable={false}
        closable={false}
        footer={footer}
        width="60%"
      >
        <Row type="flex" justify="space-between" className={styles.modalHeader}>
          <h2>{title}</h2>
          <ButtonClose large shiftRight onClick={this.props.onCancel} />
        </Row>
        <HashMemoryRouter>
          <Data {...this.props} widget={widget} onChange={this.onChange} />
        </HashMemoryRouter>
      </Modal>
    );
  }
}

export default withTranslation()(WidgetCreate);
