import Immutable from "immutable";
import _ from "lodash";

import apiActions from "../actions/apiActions";

export default {
    updateChatOption(params, query) {

    },
    updateChatOptionCompleted(body, params, data, query, res, actionParams) {
        const { catalogId, recordId } = params;
        const chatOptions = this.getIn(["records", catalogId, recordId, "chatOptions"]);
        const mergePrevOptions = chatOptions ? { ...chatOptions.toJS(), ...data } : data;
        this.setIn(
            ["records", catalogId, recordId, "chatOptions"],
            Immutable.fromJS(mergePrevOptions)
        );
        this.changed();
    },
    updateChatOptionFailed(body, params, data, query, res, actionParams) {

    },
    getChatOptionCompleted(body, params, data, query, res, actionParams) {
        const { catalogId, recordId } = params;
        body = Immutable.fromJS(body);
        this.setIn(
            ["records", catalogId, recordId, "chat", "options"],
            body
        );
        this.changed();
    },
};