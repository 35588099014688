import React, { useContext } from "react";
import cn from "classnames";

import styles from "./grid.less";
import Card from "../Card";
import ButtonTransparent from "../../common/UI/ButtonTransparent";
import { HandlerContext } from "./GridItem";
import Icon from "../../common/UI/Icon";

const GridCell = ({
  catalogId,
  sceneId,
  records,
  fieldsToRender,
  fieldToColor,
  axisXkey,
  axisYkey,
  onDeleteRecord,
  isAccessCreateRecordAtCatalog,
  rowIndex,
  t
}) => {
  const { onClickHandler, canDrop, isHovering } = useContext(HandlerContext);
  const isAxisY = records && axisYkey !== "__null";
  return (
    <div className={styles.gridCell}>
      <div
        className={cn(styles.containerGridItems, {
          [styles.containerGridItemsHover]: isHovering && canDrop
        })}
      >
        {records &&
          records.valueSeq().map(record => {
            const id = record.get("id");

            return (
              <>
                <Card
                  rowIndex={rowIndex}
                  axisXkey={axisXkey}
                  axisYkey={axisYkey}
                  key={id}
                  catalogId={catalogId}
                  sceneId={sceneId}
                  record={record}
                  records={records}
                  fieldsToRender={fieldsToRender}
                  fieldToColor={fieldToColor}
                  onDeleteRecord={onDeleteRecord}
                />
              </>
            );
          })}
      </div>
      {isAccessCreateRecordAtCatalog && (
        <div className={styles.containerBtnGruop}>
          <ButtonTransparent
            className={styles.gridBtnAdd}
            onClick={onClickHandler}
          >
            <Icon type="icon interface-72" />
            {t("buttons.add")}
          </ButtonTransparent>
          {isAxisY && (
            <span className={styles.badgeCountRecords}>{records.size}</span>
          )}
        </div>
      )}
    </div>
  );
};

export default GridCell;
