import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "../../calendar.less";

const LoadMoreSuggestedRecords = props => {
  const { t } = useTranslation()
  return (
    <div onClick={props.loadMoreSuggestedRecords} className={styles.loadMore}>
      {t("calendar.suggestRecords.loadMoreSuggestedRecords")}
    </div>
  );
};

LoadMoreSuggestedRecords.propTypes = {
  loadMoreSuggestedRecords: PropTypes.func
};

export default LoadMoreSuggestedRecords;
