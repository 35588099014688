import React from "react";
import PureRenderMixin from "react-addons-pure-render-mixin";
import ImmutablePropTypes from "react-immutable-proptypes";
import Immutable from "immutable";
import _ from "lodash";
import PropTypes from "prop-types";

import Filters from "../../../../Filters";

import styles from "../popup.less";

class WidgetRecordsFilters extends React.PureComponent {
  onSave = (filterId, fieldId, value) => {
    let widget = this.props.widget;

    const filterPath = ["recordsFilter", "filters", fieldId];
    const filter = Immutable.fromJS({
      value: value
    });

    if (_.isEmpty(value)) {
      if (widget.getIn([...filterPath, filterId])) {
        /* удаляем полностью фильтр иначе дальше нам придет пустой массив и его не отрисует */ 
        widget = widget.removeIn([...filterPath]);
      }
    } else {
      const filtersExistAndTheyAreArray =
        widget.getIn(filterPath) &&
        Immutable.List.isList(widget.getIn(filterPath));

      if (filtersExistAndTheyAreArray) {
        widget = widget.setIn([...filterPath, filterId], filter);
      } else {
        // let recordsFilters = widget.getIn(["recordsFilter", "filters"]) || Immutable.Map({})

        // const recordsFilter = Immutable.fromJS({
        //   filters: {
        //     [fieldId]: [filter]
        //   }
        // })

        if (!widget.get("recordsFilter")) {
          widget = widget.set("recordsFilter", Immutable.Map({}));
        }

        widget = widget.setIn(filterPath, Immutable.fromJS([filter]));
      }
    }
    return this.props.onChange(null, widget);
  };

  render() {
    const { catalog, widget } = this.props;

    return (
      <div className={styles.filterContainer}>
        <Filters
          ownerId={widget.get("id")}
          catalogId={catalog.get("id")}
          fields={catalog.get("fields")}
          filters={widget.getIn(["recordsFilter", "filters"])}
          onSave={this.onSave}
        />
      </div>
    );
  }
}

WidgetRecordsFilters.propTypes = {
  catalog: ImmutablePropTypes.map.isRequired,
  widget: ImmutablePropTypes.map.isRequired,
  onChange: PropTypes.func.isRequired
};

export default WidgetRecordsFilters;
