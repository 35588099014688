import React from "react";
import { NativeTypes } from "react-dnd-html5-backend";
import { DropTarget } from "react-dnd";
import cn from "classnames";

import Icon from '../../../Icon';

import styles from "./filedrop.less";

const { FILE } = NativeTypes;

const FileDrop = ({ canDrop, isOver, connectDropTarget, isDragging }) => {
  isOver = canDrop && isOver;
  isDragging = canDrop && isDragging;

  const className = cn(styles.container, {
    [styles.drag]: isDragging,
    [styles.over]: isOver
  });

  return connectDropTarget(
    <div className={className}>
      <Icon type={"icon transfers-5"} />
    </div>
  );
};

export default DropTarget(
  FILE,
  {
    drop({ onDrop, multiple }, monitor) {
      if (!onDrop) {
        return;
      }
      const files = monitor.getItem().files;
      if (multiple) {
        files.forEach(file => onDrop({ file }));
      } else {
        onDrop({ file: files[0] });
      }
    }
  },
  (connect, monitor) => {
    return {
      isDragging: !!monitor.getItem(),
      connectDropTarget: connect.dropTarget(),
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    };
  }
)(FileDrop);
