import React from "react";
import cn from "classnames";

import Field from "./Field";
import FieldApi from "../../../../models/FieldApi";
import styles from "../../calendar.less";

const Event = ({ title, values, fields, className }) => {
  if (!values) {
    return title;
  }

  const valuesExistAndNotEmpty = values.some((v, fId) => {
    const field = fields.find(field => field.get("id") === fId);
    return !FieldApi.isEmpty(field, v);
  });

  if (!values.size && !valuesExistAndNotEmpty) {
    return <span className={className}>{title}</span>;
  }

  const content = [];
  values.forEach((value, id) => {
    const field = fields.find(field => field.get("id") === id);
    // const containerClassName =
    //   typeof value === "string" ? stringValueClassname : objectValueClassname;

    content.push(
      <Field
        key={`${field.get("type")}:${field.get("id")}`}
        className={className}
        field={field}
        value={value}
        extended={false}
      />
    );
  });
  return <div className={styles.eventList}>{content}</div>;
};

export default React.memo(Event);
