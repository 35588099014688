import Reflux from "reflux";

const actions = Reflux.createActions({
  setUrlItems: { sync: true },
  moveRecordFromTo: { sync: true },
  updateRecordBeforeMakeRequest: { sync: true },
  deleteRecordFromScene: { sync: true }
});

export default actions;
