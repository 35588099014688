import Reflux from "reflux";
import ViewInputModal from "../components/views/ViewInputModal";
import modalsActions from "../actions/modalsActions";

import viewsActions from "../actions/viewActions";
import apiActions from "../actions/apiActions";
import i18n from "../configs/i18n";
import appState from "../appState";
import RESOURCE_TYPES from "../configs/resourceTypes";

const ModalStore = Reflux.createStore({
  listenables: [modalsActions],

  openAccessModal(
    { object, parents },
    resource,
    accessProps,
    hasAdminRule = false,
    onCloseCb
  ) {
    let RightsModal = require("../components/access/AccessModalController")
      .default;
    const renderModaltoBody = require("../components/common/Modal")
      .renderModaltoBody;
    const { readOnly, isAdmin } = accessProps;
    renderModaltoBody(RightsModal, {
      object,
      parents,
      resource,
      readOnly,
      isAdmin,
      hasAdminRule,
      onOk: onCloseCb
    });
  },

  openViewFieldRightsModal(
    rule,
    index,
    object,
    subjectPrivilege,
    basePrivilege,
    catalogId,
    fields,
    onOk,
    onCancel
  ) {
    let FieldRightsModal = require("../components/access/FieldRightsModal")
      .default;
    const renderModaltoBody = require("../components/common/Modal")
      .renderModaltoBody;
    renderModaltoBody(FieldRightsModal, {
      rule,
      index,
      object,
      subjectPrivilege,
      basePrivilege,
      catalogId,
      fields,
      onOk,
      onCancel
    });
  },

  openViewAccessModal(view, readOnly, onCloseCb) {
    const catalogId = view.get("catalogId");
    const object = {
      viewId: view.get("id")
    };

    let parents = [
      {
        sectionId: appState.getIn(["catalogs", catalogId, "sectionId"])
      },
      {
        catalogId: catalogId
      }
    ];

    this.openAccessModal(
      { object, parents },
      RESOURCE_TYPES.VIEW,
      { readOnly },
      false,
      onCloseCb
    );
  },

  /**
   * Open modal create related record
   * @param catalogId
   * @param linkedRecord {Object<catalog, record>} record to link with
   * @param options {Object}
   */

  openViewInputModal(sceneId, viewId, accessOnViewForRights, resolveFn) {
    const renderModaltoBody = require("../components/common/Modal")
      .renderModaltoBody;
    renderModaltoBody(ViewInputModal, {
      isNew: true,
      headerText: i18n.t("modals.createNewView.headerText"),
      onOk: params =>
        viewsActions.createNewView(sceneId, viewId, params, resolveFn),
      disabledChangeType: !accessOnViewForRights
    });
  },

  openViewInputModalEdit(currView, catalogId, sceneId) {
    const renderModaltoBody = require("../components/common/Modal")
      .renderModaltoBody;
    renderModaltoBody(ViewInputModal, {
      isNew: false,
      headerText: i18n.t("modals.editNewView.headerText"),
      name: currView.get("name"),
      originName: currView.get("originName"),
      rights: currView.get("forRights"),
      onOk: params => {
        apiActions.updateView(
          {
            catalogId,
            viewId: currView.get("id"),
            forRights: currView.get("forRights")
          },
          params,
          { sceneId }
        );
      }
    });
  }
});

export default ModalStore;
