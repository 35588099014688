import React, { useEffect, useRef, useState } from "react";
import _debounce from "lodash/debounce";

const wrapperStyle = {
  overflow: 'visible',
  height: 0,
  width: 0
};

const objStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  height: "100%",
  width: "100%",
  pointerEvents: "none",
  zIndex: -1,
  opacity: 0
};

const _defaultDimensions = {
  width: 0,
  height: 0
};

export default function Dimensions({ 
  debounce = 0, 
  style = wrapperStyle, 
  debounceOpts = {}, 
  defaultDimensions = _defaultDimensions,
  className 
}) {
  return ComposedComponent => {
    return function DimensionsHOC(props) {

      const [dimensions, setDimensions] = useState({...defaultDimensions});
      const objectRef = useRef(null);

      useEffect(() => {
        onLoad();

        return () => {
          const obj = objectRef.current;
          if (obj && obj.contentDocument && obj.contentDocument.defaultView) {
            obj.contentDocument.defaultView.removeEventListener("resize", onResize);
          }
        };
      }, []);

      const onLoad = () => {
        const obj = objectRef.current;
        if (obj && obj.contentDocument && obj.contentDocument.defaultView) {
          obj.contentDocument.defaultView.addEventListener("resize", onResize);
        } 

        onResize();
      };

      function onResize() {

        const updateDimensions =
          debounce === 0
            ? updateDimensionsImmediate
            : _debounce(updateDimensionsImmediate, debounce, debounceOpts);

        updateDimensions();
      }

      function updateDimensionsImmediate() {
        if (objectRef.current) {
          const elementRect = objectRef.current.getBoundingClientRect();
          const width = elementRect.width;
          const height = elementRect.height;
          setDimensions({ width, height });
        } 
      }

      return (
        <div style={style} className={className}>
          <object
            onLoad={onLoad}
            ref={objectRef}
            tabIndex={-1}
            type={"text/html"}
            data={"about:blank"}
            title={""}
            aria-label="dimensions-resizer"
            style={objStyle}
          />
          {props.children}
          <ComposedComponent 
            {...dimensions} 
            {...props} 
          />
        </div>
      );
    };
  };
}
