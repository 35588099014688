import React from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";
import _ from "lodash";
import { withTranslation } from "react-i18next";

import DebouncedInput from "../../common/DebouncedInput";

import styles from "./controls.less";

const MAX_SAFE_INTEGER = Number.MAX_SAFE_INTEGER || Math.pow(2, 53) - 1;
const MIN_SAFE_INTEGER = Number.MIN_SAFE_INTEGER || -MAX_SAFE_INTEGER;

class NumberRangeField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { value: this.props.value };
  }

  onSave = neededArgs => {
    const { onSave, fieldId } = this.props;

    onSave(
      fieldId,
      _.transform(neededArgs, function(res, v, k) {
        // как это работает
        if (v || v == 0) res[k] = v;
      })
    );
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.value });
  }

  parser(value) {
    value = value.replace(/,/g, ".");
    value = value.replace(/[^\d.-]*/g, "");
    return value;
  }

  prepareNumber(value) {
    if (value !== "") {
      const valueDot = String(value).replace(/,/g, ".");
      if (
        isNaN(parseFloat(valueDot)) ||
        !_.inRange(parseFloat(valueDot), MIN_SAFE_INTEGER, MAX_SAFE_INTEGER)
      ) {
        value = "";
      } else {
        value = parseFloat(valueDot);
      }
    }

    if (value === "") {
      value = null;
    }
    return value;
  }

  // refactor.
  onSaveFrom(pref, value) {
    value = this.prepareNumber(value);

    let $value = this.state.value || Immutable.Map();
    value = $value.set(pref, value);

    this.setState({ value }, () => {
      this.onSave(value.toJS());
    });
  }

  render() {
    let startNum, endNum;
    let value = this.state.value;

    if (value) {
      startNum = value.get("at");
      endNum = value.get("to");
    }

    return (
      <div className={styles.item}>
        <span className={styles.rangeInput}>
          <DebouncedInput
            size="default"
            type="number"
            value={startNum}
            placeholder={this.props.t("fieldTypes.number.fromText")}
            onSave={value => this.onSaveFrom("at", value)}
            parser={this.parser}
            readOnly={this.props.readOnly}
          />
        </span>
        <span className={styles.spanDash}>—</span>
        <span className={styles.rangeInput}>
          <DebouncedInput
            size="default"
            type="number"
            value={endNum}
            placeholder={this.props.t("fieldTypes.number.toText")}
            onSave={value => this.onSaveFrom("to", value)}
            parser={this.parser}
            readOnly={this.props.readOnly}
          />
        </span>

        <span className={styles.spanUnit}>{this.props.config.get("unit")}</span>
      </div>
    );
  }
}

NumberRangeField.propTypes = {
  value: PropTypes.object,
  config: PropTypes.object,
  onSave: PropTypes.func.isRequired
};

export default withTranslation()(NumberRangeField);
