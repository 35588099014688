// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".abstractMenu__container___RO3De {\n  overflow: hidden;\n  height: 47px;\n}\n.abstractMenu__overlayDropdown___fAIRU {\n  position: absolute;\n  top: 0px;\n  height: calc(100% - 47px);\n  overflow: hidden;\n  line-height: 47px;\n}\n.abstractMenu__wrapper___hoprO {\n  position: relative;\n}\n.abstractMenu__menuHorizontal___KV6HJ {\n  display: flex;\n  flex-wrap: wrap;\n}\n.abstractMenu__menuVertical___C0Wuj {\n  display: flex;\n  flex-wrap: nowrap;\n}\n.abstractMenu__menuItem___iVmNW:not(:last-child) {\n  margin-right: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/menu/AbstractMenu/abstractMenu.less"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,YAAA;AADF;AAIA;EACE,kBAAA;EACA,QAAA;EACA,yBAAA;EACA,gBAAA;EACA,iBAAA;AAFF;AAKA;EACE,kBAAA;AAHF;AAMA;EACE,aAAA;EACA,eAAA;AAJF;AAOA;EACE,aAAA;EACA,iBAAA;AALF;AASE;EACE,kBAAA;AAPJ","sourcesContent":["@import (reference) \"../../../../styles/helpers/index.less\";\n\n.container {\n  overflow: hidden;\n  height: @HEADER_BASE;\n}\n\n.overlayDropdown {\n  position: absolute;\n  top: 0px;\n  height: calc(~\"100% - 47px\"); //@HEADER_BASE\n  overflow: hidden;\n  line-height: @HEADER_BASE;\n}\n\n.wrapper {\n  position: relative;\n}\n\n.menuHorizontal {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.menuVertical {\n  display: flex;\n  flex-wrap: nowrap;\n}\n\n.menuItem {\n  &:not(:last-child) {\n    margin-right: @PADDING_BASE;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "abstractMenu__container___RO3De",
	"overlayDropdown": "abstractMenu__overlayDropdown___fAIRU",
	"wrapper": "abstractMenu__wrapper___hoprO",
	"menuHorizontal": "abstractMenu__menuHorizontal___KV6HJ",
	"menuVertical": "abstractMenu__menuVertical___C0Wuj",
	"menuItem": "abstractMenu__menuItem___iVmNW"
};
export default ___CSS_LOADER_EXPORT___;
