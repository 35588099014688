import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";
import ImmutablePropTypes from "react-immutable-proptypes";
// import trs from "../../../../../../../getTranslations";
import i18n from "../../../../../../../configs/i18n";

import Dropdown from "../../../../../../common/Dropdown";

const EMPTY_VALUE = Symbol("empty value");

function getValueKey({ type, subType, value }) {
  return (type || "") + (subType || "") + (value || "");
}

class BasicSelect extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      availableValues: this._getAvailableValues(this.props.fields)
    };
  }

  _getAvailableValues = fields => {
    const values = [];

    if (!this.getDefaultValue) {
      values.push({
        key: "null",
        type: EMPTY_VALUE,
        value: null,
        text: i18n.t("reports.widget.modals.common.tabs.data.axis.types.nothing"),
        sort: -1
      });
    }

    return values.concat(
      this.getAvailableValues(fields).map(value => {
        return {
          ...value,
          key: getValueKey(value),
          text: value.title
        };
      })
    );
  };

  onChange = item => {
    const returnValue =
      item && item.type !== EMPTY_VALUE
        ? Immutable.fromJS({
            type: item.type,
            subType: item.subType,
            value: item.value
          })
        : null;

    this.props.onChange(returnValue);
  };

  onChangeSelect = item => {
    // const valueKey = e.target.value;
    // const selectedAvailableValue = _.find(this.state.availableValues, {key: valueKey});
    this.onChange(item);
  };

  UNSAFE_componentWillReceiveProps({ fields }) {
    if (this.props.fields !== fields) {
      this.setState({
        availableValues: this._getAvailableValues(fields)
      });
    }
  }

  UNSAFE_componentWillMount() {
    if (this.getDefaultValue) {
      const { value } = this.props;

      if (!value) {
        this.onChange(this.getDefaultValue(this.props));
      }
    }
  }

  render() {
    const { value, showSearch, className } = this.props;
    const { availableValues } = this.state;

    const selectedValueKey =
      value && value.toJS ? getValueKey(value.toJS()) : value;

    return (
      <Dropdown
        showSearch={showSearch}
        items={availableValues}
        multiselect={false}
        value={selectedValueKey}
        autocomplete={true}
        withButton={true}
        className={className || ""}
        onSelectItems={([item]) => this.onChangeSelect(item)}
      />
    );
  }
}

BasicSelect.propTypes = {
  fields: ImmutablePropTypes.list,
  onChange: PropTypes.func.isRequired
  // value: ImmutablePropTypes.map
};

export default BasicSelect;
