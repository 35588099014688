// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".chatEditMessageHeader__headerContainer___pYNCH {\n  display: flex;\n  justify-content: space-between;\n  background-color: rgba(206, 209, 214, 0.5);\n  align-items: center;\n  padding: 5px 15px;\n  cursor: pointer;\n}\n.chatEditMessageHeader__headerTitle___SXDMy {\n  color: #5ca199;\n}\n.chatEditMessageHeader__headerMessage___F\\+V6\\+ {\n  display: flex;\n  align-items: center;\n}\n.chatEditMessageHeader__headerIcon___OzE17 {\n  margin-right: 10px;\n  font-size: 22px;\n  color: #5ca199;\n}\n.chatEditMessageHeader__headerTitleAndText___FUl7o {\n  display: flex;\n  flex-direction: column;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Record/RecordBody/chatTab/ChatEditMessage/ChatEditMessageHeader/chatEditMessageHeader.less"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,8BAAA;EACA,0CAAA;EACA,mBAAA;EACA,iBAAA;EACA,eAAA;AADJ;AAIA;EACI,cAAA;AAFJ;AAKA;EACI,aAAA;EACA,mBAAA;AAHJ;AAMA;EACI,kBAAA;EACA,eAAA;EACA,cAAA;AAJJ;AAOA;EACI,aAAA;EACA,sBAAA;AALJ","sourcesContent":["@import (reference) \"../../../../../../styles/helpers/index.less\";\n\n.headerContainer {\n    display: flex;\n    justify-content: space-between;\n    background-color: @BG_SELECTED;\n    align-items: center;\n    padding: @PADDING_BASE / 2 @PADDING_BASE * 1.5;\n    cursor: pointer;\n}\n\n.headerTitle {\n    color: @TEXT_ACCENT;\n}\n\n.headerMessage {\n    display: flex;\n    align-items: center;\n}\n\n.headerIcon {\n    margin-right: @PADDING_BASE;\n    font-size: @TEXT_SIZE_HEADER;\n    color: @TEXT_ACCENT;\n}\n\n.headerTitleAndText {\n    display: flex;\n    flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerContainer": "chatEditMessageHeader__headerContainer___pYNCH",
	"headerTitle": "chatEditMessageHeader__headerTitle___SXDMy",
	"headerMessage": "chatEditMessageHeader__headerMessage___F+V6+",
	"headerIcon": "chatEditMessageHeader__headerIcon___OzE17",
	"headerTitleAndText": "chatEditMessageHeader__headerTitleAndText___FUl7o"
};
export default ___CSS_LOADER_EXPORT___;
