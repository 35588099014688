import Reflux from "reflux";
import _ from "lodash";

const log = require("debug")("CRM:Action:editorActions");

const actions = Reflux.createActions({
  dropField: {},
  dropCollapsedSection: {},
  removeField: {},
  updateField: { sync: true },
  changeFieldConfig: { sync: true },
  setFieldName: {},
  setFieldRequired: {},
  setFieldApiOnly: {},
  setFieldHint: {},
  setCatalogIcon: {},
  setCatalogName: {},
  setCatalogSection: {}
});

_.forEach(actions, (act, name) => {
  act.preEmit = function preEmit(...args) {
    log(name, args);
  };
});

export default actions;
