import React, { useEffect, useState, useRef } from 'react';
import Immutable from 'immutable';

import ChatInput from '../ChatSendMessage/ChatInput/ChatInput';
import ChatEditMessageHeader from './ChatEditMessageHeader/ChatEditMessageHeader';
import messageActions from '../../../../../actions/messageActions';
import apiActions from "../../../../../actions/apiActions";
import keys from '../../../../../configs/keys';


const ChatEditMessage = ({
    text,
    attachments,
    author,
    catalogId,
    recordId,
    messageId,
    loadingAttachment,
    isBeingSentMessage,
    mentions,
    progressState,
    uploadFile,
    onClickRemoveFile,
    setAttachments
}) => {

    const [originalMentions, setOriginalMentions] = useState([]);

    useEffect(() => {
        if (text) {
            const regex = /(?<=@)[^;]+(?=;)/g;
            let mentions = [...new Set(text.match(regex))];
            mentions = mentions.map((mention) => {
                mention = mention.split(":");
                return {
                    catalogId: mention[0],
                    recordId: mention[1]
                }
            });
            setOriginalMentions(mentions);
        }
    }, [text]);

    const onChangeEditText = (value, metions) => {
        messageActions.editMessageText({ catalogId, recordId }, Immutable.fromJS({
            text: value,
            metions
        }));
    };

    const onKeyDown = (e) => {
        if (e.keyCode === keys.ENTER && !e.shiftKey) {
            e.preventDefault();
            updateMessage();
        };
    };

    const updateMessage = () => {
        const message = Immutable.fromJS({
            text,
            attachments: attachments,
            mentions: originalMentions
        });

        apiActions.updateMessage({ catalogId, recordId, messageId }, message);
        setAttachments(Immutable.List([]));
    };

    const handleFile = (e) => {
        const file = e.file;
        if (file) {
            uploadFile(file);
        };
    };

    return (
        <React.Fragment>
            <ChatEditMessageHeader
                params={{ catalogId, recordId }}
                text={text}
            />
            <ChatInput
                text={text}
                readyToShip={true}
                attachments={attachments}
                updateProgress={progressState}
                loadingAttachment={loadingAttachment}
                isBeingSentMessage={isBeingSentMessage}
                mentions={mentions}
                onChange={onChangeEditText}
                onKeyDown={onKeyDown}
                sendMessage={updateMessage}
                handleFile={handleFile}
                onClickRemoveFile={onClickRemoveFile}
                icon={"icon status-1"}
            />
        </React.Fragment>
    );
};

export default ChatEditMessage;