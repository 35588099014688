import React, { Component } from "react";
import cn from "classnames";

import ButtonClose from "../ButtonClose";
import LinkedRecordOpen from "../../../Record/LinkedRecordOpen";

import styles from "./linkedItem.less";

export default class LinkedItem extends Component {
  render() {
    const {
      item,
      titleOnRemove,
      onClick,
      onClickRemove,
      removable,
      removed,
      href,
      className,
      classNameItem,
      classNameColor,
      classNameItemColor,
      title,
      linkProps, // for subscribe event onClick Link
      subLinkProps, // for subscribe event onClick Link
      params, // for linked object
      simple,
      sceneId,
      small,
      file
    } = this.props;

    let { type } = this.props;

    let Icon;

    if (typeof item.icon === "object") {
      Icon = (
        <span key={"icon"} className={styles.linkItemIcon}>
          {item.icon}
        </span>
      );
    } else {
      Icon = item.icon || "content-11"; // default icon if not set
      const linkColor = classNameItemColor; // || styles.linkItemIconColor;
      Icon = (
        <span
          key={"icon"}
          className={cn("anticon-icon " + Icon, styles.linkItemIcon, linkColor)}
        />
      );
    }

    const subCN = styles.linkItemSubText;
    let textCN = [],
      wrapperCN = [],
      subText;

    /* Wrapper and Text class names */

    textCN.push(styles.linkItemText);
    if (file) {
      if (small) {
        wrapperCN.push(styles.linkItemFileForChat, classNameItem);
      } else {
        wrapperCN.push(styles.linkItemFile, classNameItem);
      }
    } else {
      wrapperCN.push(styles.linkItem, classNameItem);
    };
    wrapperCN.push(classNameColor || styles.linkColor);

    if (onClick || href || params || type === "link" || type === "modal") {
      if (!removed) {
        wrapperCN.push(styles.linkClickWrapper);
      }
    }

    // no extra design
    if (simple) {
      wrapperCN.push(styles.linkItemSimple);
    }

    if (removed) {
      wrapperCN.push(styles.linkItemRemoved);
      textCN.push(styles.removed);
    }

    wrapperCN = wrapperCN.join(" ");
    textCN = textCN.join(" ");
    const text = (
      <span key={"text"} className={textCN}>
        {item.text}
      </span>
    );

    /* Sub text class */

    if (item.subText) {
      subText = (
        <small key={"subText"} className={subCN}>
          {item.subText}
        </small>
      );
    }

    let content, subContent;
    if (type === undefined) {
      if (params) {
        type = "modal";
      }
      if (href) {
        type = "link";
      }
    }
    switch (type) {
      case "modal":
        content = !removed ? (
          <LinkedRecordOpen
            sceneId={sceneId}
            linkProps={{
              className: wrapperCN,
              children: [Icon, text],
              ...linkProps
            }}
            params={params}
            onClick={onClick}
          />
        ) : (
          <div className={wrapperCN}>{[Icon, text]}</div>
        );
        break;
      case "link":
        if (subLinkProps && subLinkProps.href) {
          content = (
            <a href={href} {...linkProps} className={wrapperCN}>
              {Icon}
              {text}
            </a>
          );
          subContent = (
            <a {...subLinkProps} style={{ marginTop: "-1px" }}>
              {subText}
            </a>
          );
        } else {
          content = (
            <a href={href} {...linkProps} className={wrapperCN}>
              {Icon}
              {text}
              {subText}
            </a>
          );
        }
        break;
      default:
        //  for deprecated code, which need a refactoring (i.e. specify type)
        if (onClick) {
          content = (
            <div className={wrapperCN} onClick={onClick}>
              {Icon}
              {text}
            </div>
          );
          subContent = subText;
        } else {
          content = (
            <div className={wrapperCN}>
              {Icon}
              {text}
              {subText}
            </div>
          );
        }
        break;
    }

    return (
      <div title={title} style={{ height: small && 40 }} className={cn(styles.linkItemRow, className)}>
          {content}
          {!small && subContent}
        {removable && onClickRemove && (
          <ButtonClose
            className={styles.removeButton}
            onClick={() => onClickRemove(item.key)}
            small
            title={titleOnRemove}
          />
        )}
      </div>
    );
  }
}
