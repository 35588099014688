import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

import apiActions from "../../actions/apiActions";
import Loading from "../common/Loading";
import fullScreen from "../common/fullScreen";
import { connect } from "../StateProvider";
import NavRoute from "../common/router/Route";
import routes from "../../routes";

import Header from "./Header";
import Board from "./Board";

import styles from "./reports.less";

class ReportsMainController extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      colsMode: "small"
    };
  }
  componentDidMount() {
    const { catalog } = this.props;
    const catalogId = catalog.get("id");
    apiActions.getBoards({}, { catalogId });
  }

  toggleColsWidthMode = mode => {
    this.setState({ colsMode: mode });
  };

  render() {
    const {
      catalog,
      scene,
      company,
      fullScreen,
      boards: boardsObj,
      toggleColsWidthMode
    } = this.props;
    const modules = company.get("modules");
    const catalogId = catalog.get("id");
    const loaded = boardsObj && boardsObj.getIn(["loaded", catalogId]);

    if (!loaded) {
      return (
        <div>
          <Loading />
        </div>
      );
    }
    const boards = boardsObj
      .get("list")
      .filter(b => String(b.get("catalogId")) === catalogId);

    return (
      <NavRoute route={routes.board}>
        {({ match }) => {
          const { boardId, viewId } = match ? match.params : {};

          const board = boards.find(b => String(b.get("id")) === boardId);
          return (
            <div className={styles.container}>
              <Header
                className={styles.header}
                {...{ catalog, boards, board, fullScreen, viewId, scene }}
              />
              {board && (
                <Board
                  className={styles.body}
                  colsMode={this.props.colsMode}
                  {...{ catalog, board, modules, fullScreen, viewId, scene }}
                />
              )}
            </div>
          );
        }}
      </NavRoute>
    );
  }
}

ReportsMainController.propTypes = {
  company: PropTypes.object,
  boards: PropTypes.object
};

export default connect(
  fullScreen(withRouter(ReportsMainController)),
  ["company", "boards", "scenes"],
  (props, { company, boards, scenes }) => {
    const scene = scenes.get(props.sceneId);
    return {
      ...props,
      company,
      boards,
      scene
    };
  }
);
