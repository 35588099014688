import React from "react";
import PureRenderMixin from "react-addons-pure-render-mixin";
import ImmutablePropTypes from "react-immutable-proptypes";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { checkAccessOnObject } from "../../../../../../../utils/rights";
import { Radio } from "antd";

import PRIVILEGE_CODES from "../../../../../../../configs/privilegeCodes";
import RESOURCE_TYPES from "../../../../../../../configs/resourceTypes";
import {
  ALL,
  AVAILABLE
} from "../../../../../../../configs/reports/widget/recordsTypes";

class SelectRecordsType extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      availableItems: this.getAvailableItems(this.props.catalog)
    };
  }
  getAvailableItems(catalog) {
    const items = [];

    if (
      checkAccessOnObject(
        RESOURCE_TYPES.CATALOG,
        catalog,
        PRIVILEGE_CODES.ADMIN
      )
    ) {
      items.push(ALL);
    }

    items.push(AVAILABLE);

    return items;
  }

  UNSAFE_componentWillReceiveProps({ catalog }) {
    if (this.props.catalog !== catalog) {
      this.setState({
        availableItems: this.getAvailableItems(catalog)
      });
    }
  }

  UNSAFE_componentWillMount() {
    const { value } = this.props;

    if (!value) {
      this.props.onChange(this.state.availableItems[0]);
    }
  }

  onChange = e => {
    this.props.onChange(e.target.value);
  };

  render() {
    const { value } = this.props;
    const { availableItems } = this.state;

    return (
      <Radio.Group onChange={this.onChange} value={value}>
        {availableItems.map(item => {
          return (
            <Radio.Button key={item} value={item}>
              {this.props.t(
                "reports.widget.modals.common.tabs.data.records.types." + item
              )}
            </Radio.Button>
          );
        })}
      </Radio.Group>
    );
  }
}

SelectRecordsType.propTypes = {
  catalog: ImmutablePropTypes.map.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};

export default withTranslation()(SelectRecordsType);
