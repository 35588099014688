import React from "react";
import PureRenderMixin from "react-addons-pure-render-mixin";
import PropTypes from "prop-types";
import {Switch, Row} from "antd";

import styles from "./changes.less";

const ChangeSwitch = props => {
  const newValue = props.change.get("newValue");
  const oldValue = props.change.get("oldValue");

  return (
    <div className={styles.switch}>
      <Switch defaultChecked={newValue} disabled/>
    </div>
  );
};

ChangeSwitch.propTypes = {
  change: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  isNewRecord: PropTypes.bool
};

export default ChangeSwitch;
