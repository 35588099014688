import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import Immutable from "immutable";

import { Row } from "antd";

import ButtonClose from "../../common/UI/ButtonClose";
import TabsMenu from "../../common/menu/TabsMenu";

import styles from "../script.less";

class SideBarHeader extends React.PureComponent {
  componentDidMount() {
   
  }

  render() {
    const {
      component,
      currentTab,
      onChangeTab,
      onClose,
      t
    } = this.props;

    // tabs
    const tabs = Immutable.List([
        Immutable.Map({
            id: "properties",
            name: t("script.sidebar.tabs.properties"),
            //route: routes.recordMain
        }),
        Immutable.Map({
            id: "sandbox",
            name: t("script.sidebar.tabs.sandbox"),
            //route: routes.recordLinks
        })
    ]);
    
    return (
        <Row
            type="flex"
            justify="space-between"
            align="middle"
            className={cn(styles.sideBarHeader, "bpmn-header")}
        >
            <h2 className="text-overflow-break">
                {component ? component.title : ""}
            </h2>

            <TabsMenu
                items={tabs}
                className={styles.tabsMenu}
                activeId={currentTab}
                onClick={onChangeTab}
            />
            
            <ButtonClose
                onClick={onClose}
                shiftRight
                type="interface-37"
            />
        </Row>
    );
  }
}

SideBarHeader.propTypes = {
  component: PropTypes.object,
  currentTab: PropTypes.string,
  onChangeTab: PropTypes.func,
  onClick: PropTypes.func,
  t: PropTypes.func
};

export default SideBarHeader;
