import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Immutable from "immutable";
import cn from "classnames";
import { withTranslation } from "react-i18next";
import { Select } from "antd";

import ProcessNotify from "../ProcessNotify";
import Group from "./controls/common/Group";
import FIELD_TYPES, { OBJECT_MODS } from "../../../../configs/fieldTypes";
import valueActionTypes from "../../../../configs/valueActionTypes";
import ControlItem, { HORISONTAL_VIEW, VERTICAL_VIEW } from "./ControlItem";
import calcVisibleControls from "../../../../utils/calcVisibleControls";
import Checkbox from "../Checkbox";

import styles from "./controlList.less";

// beacuse Symbol can't convert to String(error from React)
const virtualGroupId = "$virtualGroupId";

const fieldComponentsByType = {
  [FIELD_TYPES.TEXT]: require("./controls/Text").default,
  [FIELD_TYPES.PAIR]: require("./controls/Pair").default,
  [FIELD_TYPES.ADDRESS]: require("./controls/Address").default,
  [FIELD_TYPES.NUMBER]: require("./controls/Number").default,
  [FIELD_TYPES.DATE]: require("./controls/Date").default,

  [FIELD_TYPES.DROPDOWN]: require("./controls/CategoryList").default,
  [FIELD_TYPES.SWITCH]: require("./controls/Switch").default,
  [FIELD_TYPES.BUTTON]: require("./controls/Button").default,
  [FIELD_TYPES.CHECKBOXES]: require("./controls/CheckboxList").default,
  [FIELD_TYPES.RADIOBUTTON]: require("./controls/Radiobutton").default,

  [FIELD_TYPES.PROGRESS]: require("./controls/Progress").default,
  [FIELD_TYPES.STARS]: require("./controls/Stars").default,

  [FIELD_TYPES.OBJECT]: require("./controls/Object").default,
  [FIELD_TYPES.USER]: require("./controls/User").default,
  [FIELD_TYPES.FILE]: require("./controls/File").default
};

let idPrefix = 0;

class ControlList extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    onHiddenChange: PropTypes.func,
    onEndEditing: PropTypes.func,
    keyForStorage: PropTypes.string,
    fieldsEditableStatus: PropTypes.object
  };

  key = localStorage.getItem(`controlList.${this.props.keyForStorage}`);

  state = {
    closed: this.key ? JSON.parse(this.key) : {},
    sections: []
  };

  idPrefix = idPrefix++;

  setSections(configs, values) {
    let _curGroup;
    let sections = [];
    const closed = {};

    configs.forEach(config => {
      if (config.get("type") === FIELD_TYPES.GROUP) {
        _curGroup = {
          id: config.get("id"),
          section: config,
          configs: [],
          values: {}
        };
        sections.push(_curGroup);
      } else {
        if (!_curGroup) {
          _curGroup = {
            id: virtualGroupId,
            section: Immutable.fromJS({ name: "", type: FIELD_TYPES.GROUP }),
            configs: [],
            values: {}
          };
          sections.push(_curGroup);
        }
        if (config.get("error")) {
          closed[_curGroup.id] = false;
        }
        _curGroup.configs.push(config);
      }
    });
    // при выборе варианта конвертации Excel -> JSON убираем секцию параметров
    if (values && values.get("method") === "excel-json") {
      sections = sections.filter((section) => section.id !== "section_data");
    };
    this.setState({
      sections: sections,
      closed: {
        ...this.state.closed,
        ...closed
      }
    });
  };

  toggleList = sectionId => {
    const closedSections = {
      ...this.state.closed,
      [sectionId]: !this.state.closed[sectionId]
    };

    this.setState({
      closed: closedSections
    });

    if (this.props.keyForStorage) {
      localStorage.setItem(
        `controlList.${this.props.keyForStorage}`,
        JSON.stringify(closedSections)
      );
    }
  };

  componentDidUpdate() {
    if (this.errorControl && this.errorControl !== this.lastErrorControl) {
      this.errorControl.focus();
      this.lastErrorControl = this.errorControl;
    }
  };

  componentDidMount() {
    const configs = this.props.data.configs;
    const values = this.props.data.values;

    this.setSections(configs, values);
    this.setHiddenFields(configs, values);
  };

  componentWillReceiveProps(nextProps) {
    const newConfig = nextProps.data.configs;
    const configs = this.props.data.configs;

    const newValues = nextProps.data.values;
    const values = this.props.data.values;

    if (
      (newConfig && newConfig !== configs) ||
      (newValues && newValues !== values)
    ) {
      this.setSections(newConfig, newValues);
      this.setHiddenFields(newConfig, newValues);
    }
  };

  setHiddenFields(configs, values) {
    const visible = calcVisibleControls(values, configs);
    let hidden = {};
    _.mapKeys(visible, (v, fId) => {
      if (!v) {
        hidden[fId] = !v;
      }
    });
    const oldHidden = this.state.hidden;
    if (!_.isEqual(hidden, oldHidden)) {
      this.setState({ hidden });
      this.props.onHiddenChange && this.props.onHiddenChange(hidden);
    }
  };

  getControllVisibility = fieldId => {
    const { fieldsEditableStatus } = this.props;
    const fieldEditableStatus =
      fieldsEditableStatus && fieldsEditableStatus.get(fieldId);

    return _.isUndefined(fieldEditableStatus) ? false : fieldEditableStatus;
  };

  getOptionsForBatchUpdate = (config) => {
    const options = [];

    options.push({
      value: valueActionTypes.UPDATE,
      label: this.props.t("batchUpdateRecords.valueActions.set")
    });

    if (config.get("multiselect")) {
      options.push({
        value: valueActionTypes.ADD,
        label: this.props.t("batchUpdateRecords.valueActions.add")
      }, {
        value: valueActionTypes.EXCLUDE,
        label: this.props.t("batchUpdateRecords.valueActions.exclude")
      });
    };

    return options;
  };

  render() {
    const { props, state } = this;
    const {
      data,
      params,
      className,
      compact,
      sceneId,
      modeMassUpdate,
      labelOnTop,
      changeCheckboxValue,
      changeSelectValue,
      fieldsEditableStatus
    } = props;
    const { values, configs } = data;
    const { hidden } = state;

    const firstError = configs.find(f => f.get("error"));

    const updateProcess = !!configs.find(f =>
      f.getIn(["updateProcess", "inProcess"])
    );

    return (
      <div
        className={cn(styles.controlList, className, { [styles.labelOnTop]: labelOnTop })}
      >
        <ProcessNotify show={updateProcess} />

        {state.sections.map(section => {
          // hide hidden sections
          if (
            section.id &&
            section.id !== virtualGroupId &&
            hidden[section.id]
          ) {
            return null;
          }

          // hide empty sections
          const hasVisibleControls = !!section.configs.find(
            control => !hidden[control.get("id")]
          );
          if (!hasVisibleControls) {
            return null;
          }

          const sectionVirtual = section.id === virtualGroupId;
          const sectionClosed = state.closed[section.id];

          return (
            <div key={section.id}>
              {!(sectionVirtual && compact) ? (
                <Group
                  id={section.id}
                  title={section.section.get("name")}
                  closed={sectionClosed}
                  onClick={this.toggleList}
                  count={this.props.t(
                    "record.groupFields.count",
                    { count: section.configs.length }
                  )}
                />
              ) : null}

              <div
                className={cn(styles.sectionFields, {
                  [styles.sectionHidden]: sectionClosed
                })}
              >
                {section.configs.map(controlConfig => {
                  const config = controlConfig.get("config");
                  const allowMassEdit = modeMassUpdate &&
                    (controlConfig.get("type") !== FIELD_TYPES.FILE && controlConfig.get("type") !== FIELD_TYPES.BUTTON);
                  const controlId = controlConfig.get("id");
                  if (hidden[controlId]) {
                    return null;
                  }

                  const currentControlError =
                    firstError && firstError.get("id") === controlId;
                  const htmlId = this.idPrefix + controlId;

                  const editable = !(
                    controlConfig.get("apiOnly") ||
                    controlConfig.get("readOnly")
                  );

                  const value = values.get(controlId);

                  const hasValue =
                    value && value.get
                      ? !!value.size
                      : value !== undefined && value !== null && value !== "";

                  // const isTable =
                  //   controlConfig.getIn(["config", "mode"]) ===
                  //   OBJECT_MODS.TABLE;
                  const Control =
                    fieldComponentsByType[controlConfig.get("type")];
                  // const view = isTable ? VERTICAL_VIEW : HORISONTAL_VIEW;

                  const checked =
                    modeMassUpdate && fieldsEditableStatus
                      ? fieldsEditableStatus.get(controlId)
                      : false;

                  const controllVisibility = this.getControllVisibility(controlId);

                  const options = this.getOptionsForBatchUpdate(config);
                  return (
                    <ControlItem
                      // view={view}
                      labelRef={
                        currentControlError &&
                        (node => (this.errorControl = node))
                      }
                      htmlId={htmlId}
                      key={controlId}
                      error={controlConfig.get("error")}
                      name={controlConfig.get("name")}
                      fieldId={controlId}
                      required={controlConfig.get("required")}
                      hasValue={hasValue}
                      type={controlConfig.get("type")}
                      editable={editable}
                      apiOnly={controlConfig.get("apiOnly")}
                      readOnly={controlConfig.get("readOnly")}
                      hint={controlConfig.get("hint")}
                      compact={compact}
                      labelOnTop={labelOnTop}
                      updateProcess={controlConfig.get("updateProcess")}
                      sceneId={sceneId}
                    >
                      {
                        <div className={styles.controlWrapper}>
                          {modeMassUpdate && (
                            <React.Fragment>
                              <Checkbox
                                checked={checked}
                                disabled={!allowMassEdit}
                                onChange={() => changeCheckboxValue(controlId)}
                                readOnly={this.props.readOnly}
                                className={styles.checkbox}
                              />
                              {controllVisibility ? (
                                <Select
                                  defaultValue={valueActionTypes.UPDATE}
                                  onChange={(value) => changeSelectValue(value, controlId)}
                                  options={options}
                                  className={styles.massUpdateSelect}
                                />
                              )
                                :
                                allowMassEdit ? (
                                  <div
                                    onClick={e =>
                                      this.props.onPlaceHolderClick &&
                                      this.props.onPlaceHolderClick(controlId)
                                    }
                                    className={styles.placeHolder}
                                  >
                                    {this.props.placeHolder}
                                  </div>
                                ) : (
                                  <span className={styles.notChanged}>{this.props.notChangeTitle}</span>
                                )
                              }
                            </React.Fragment>
                          )}

                          {(!modeMassUpdate || controllVisibility) && (
                            <div className={styles.componentWrapper}>
                              <Control
                                id={controlId}
                                type={controlConfig.get("type")}
                                htmlId={htmlId}
                                value={values.get(controlId)}
                                controlConfig={controlConfig}
                                config={config}
                                hint={controlConfig.get("hint")}
                                eventable={controlConfig.get("eventable")}
                                editable={editable}
                                apiOnly={controlConfig.get("apiOnly")}
                                readOnly={controlConfig.get("readOnly")}
                                params={params}
                                updateProcess={controlConfig.get(
                                  "updateProcess"
                                )}
                                error={controlConfig.get("error")}
                                onChange={value =>
                                  this.props.onChange(controlId, value)
                                }
                                onEndEditing={value =>
                                  this.props.onEndEditing(controlId, value)
                                }
                                sceneId={sceneId}
                              />
                            </div>
                          )}
                        </div>
                      }
                    </ControlItem>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default withTranslation()(ControlList);
