import React from "react";
import { connect } from "../StateProvider";
import { withRouter } from "react-router-dom";
import routes from "../../routes";
import NavRoute from "../common/router/Route";
import NavRedirect from "../common/router/Redirect";

import DataProvider from "./DataProvider";

class Calendar extends React.PureComponent {
  render() {
    return (
      <NavRoute
        route={routes.calendarSettings}
        children={({ match }) => {
          if (!match) {
            return (
              <NavRedirect
                route={routes.calendarSettings}
                params={{ type: this.props.view, date: this.props.date }}
              />
            );
          }
          return this.props.scene && this.props.catalog ? (
            <DataProvider
              match={match}
              catalog={this.props.catalog}
              scene={this.props.scene}
              viewId={this.props.viewId}
            />
          ) : null;
        }}
      />
    );
  }
}

export default connect(
  withRouter(Calendar),
  {
    view: ["calendar", "view"],
    date: ["calendar", "date"]
  }
);
