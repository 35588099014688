import React from "react";
import { useTranslation } from "react-i18next";

import Checkbox from "../../../../../../common/UI/Checkbox";

export default function({ value, onChange }) {
  const { t } = useTranslation();
  return (
    <Checkbox
      checked={value}
      onChange={e => onChange(!!e.target.checked)}
      title={t("reports.widget.modals.common.tabs.data.split.stacked")}
    />
  );
}
