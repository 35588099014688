const getParams = (item, controlConfig, remoteGroup) => {
  const itemData = item.get("item");

  // params to make item clickable
  let params;
  if (controlConfig && controlConfig.get("type") === "user") {
    params = {
      sectionId: "",
      catalogId: "3", // hardcode :(
      recordId: item.get("key")
    };
  } else if (
    itemData &&
    (remoteGroup === "linkedObjects" || remoteGroup === "linkedRecords")
  ) {
    params = {
      sectionId: itemData.get("sectionId"),
      catalogId: itemData.get("catalogId"),
      recordId: itemData.get("recordId")
    };
  }
  return params;
};

export { getParams };
