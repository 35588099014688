import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import $ from "jquery";
import { withTranslation } from 'react-i18next';

import Icon from '../../common/UI/Icon';
import DROPDOWN_COLORS, {
  Labels as DROPDOWN_COLORS_LABELS
} from "../../../configs/dropdownColors";

import styles from "./fields.less";

class ItemListEditorColor extends React.PureComponent {
  select = () => {
    this.props.onSelect(this.props.color);
  };

  render() {
    return (
      <div
        onClick={this.select}
        title={this.props.label}
        style={{ backgroundColor: "#" + this.props.color }}
        className={styles.itemColor}
      >
        {this.props.selected ? <div className={styles.colorSelected} /> : null}
      </div>
    );
  }
}

class ItemListEditorColorPicker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.refArrow = React.createRef(null);
    this.reflist = React.createRef(null);
  }

  onSelectColor = color => {
    this.setState({
      isOpen: false
    });
    this.props.onSelect(color);
  };

  onClickArrow = () => {
    if (this.props.disabled) {
      return;
    }
    const isOpen = !this.state.isOpen;
    this.setState({
      isOpen: isOpen
    });
  };

  closeList = e => {
    let el = $(e.target);
    const arrow = this.refArrow.current;
    const list = this.reflist.current;

    if (
      e.target !== list &&
      el
        .parents("." + styles.colorList)
        .toArray()
        .indexOf(list) === -1 &&
      e.target !== arrow &&
      el
        .parents("." + styles.colorPickerArrow)
        .toArray()
        .indexOf(arrow) === -1
    ) {
      this.setState({
        isOpen: false
      });
    }
  };

  closeListEsc = e => {
    if (e.keyCode === 27) {
      this.setState({
        isOpen: false
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isOpen && !prevState.isOpen) {
      $("body").on("click", this.closeList);
      $("body").on("keydown", this.closeListEsc);
    } else if (!this.state.isOpen && prevState.isOpen) {
      $("body").off("click", this.closeList);
      $("body").off("keydown", this.closeListEsc);
    }
  }

  componentWillUnmount() {
    $("body").off("click", this.closeList);
    $("body").off("keydown", this.closeListEsc);
  }

  render() {
    let colors = DROPDOWN_COLORS.map((color, i) => (
      <ItemListEditorColor
        key={color}
        selected={color === this.props.currentColor}
        label={DROPDOWN_COLORS_LABELS(this.props.t)[i]}
        color={color}
        onSelect={this.onSelectColor}
      />
    ));

    return (
      <div className={styles.colorPicker}>
        <div
          className={styles.colorPickerArrow}
          ref={this.refArrow}
          onClick={this.onClickArrow}
        >
          <Icon
            className={this.state.isOpen ? "fa-rotate-180" : null}
            type="icon arrows-chevron-medium-fat-4-01"
          />
        </div>
        <div
          ref={this.reflist}
          className={styles.colorList}
          style={this.state.isOpen ? null : { display: "none" }}
          title=""
        >
          {colors}
        </div>
      </div>
    );
  }
}

ItemListEditorColor.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

ItemListEditorColorPicker.propTypes = {
  currentColor: PropTypes.string,
  onSelect: PropTypes.func.isRequired
};

export default withTranslation(undefined, { withRef: true })(
  ItemListEditorColorPicker
);
