import Reflux from "reflux";
import makeApiRequest from "../utils/makeApiRequest";
import us_const from "../configs/userSettings";
import us_util from "../utils/userSettings";
import createAsyncAction from "../helpers/createAsyncAction";
import _ from "lodash";

const actions = {
  /**
   * Common
   */
  setKey: createAsyncAction(function(key, value) {
    // key can be: "var", "var1.var2.var3", ["var1", "var2"]
    const keyString = _.isArray(key) ? key.join(us_util.delimiter) : key;

    return makeApiRequest(`userSettings/${keyString}`, {
      method: "patch",
      body: { value }
    }).then(
      res => {
        this.completed(key, res.body);
      },
      err => {
        this.failed(err);
      }
    );
  }),
  getKey: createAsyncAction(function(key) {
    return makeApiRequest(`userSettings`, {
      query: { search: key },
      method: "get"
    }).then(
      res => {
        this.completed(key, res.body);
      },
      err => {
        this.failed(err);
      }
    );
  }),

  /**
   * save visible setting field for the current catalog.
   */
  setFieldVisibility: createAsyncAction(function({
    catalogId,
    viewMode,
    fieldId,
    visible
  }) {
    let data = { catalogId, viewMode, fieldId, visible };
    let key = us_util.makeKey(data, us_const.VISIBLE);

    return makeApiRequest(`userSettings/${key}`, {
      method: "patch",
      body: { [us_const.VISIBLE]: visible }
    }).then(
      res => {
        this.completed(res.body);
      },
      err => {
        this.failed(err);
      }
    );
  }),

  /**
   * save width setting field for the current catalog.
   */
  setFieldWidth: createAsyncAction(function({
    catalogId,
    viewMode,
    fieldId,
    width
  }) {
    let data = { catalogId, viewMode, fieldId };
    let key = us_util.makeKey(data, us_const.WIDTH);

    return makeApiRequest(`userSettings/${key}`, {
      method: "patch",
      body: { [us_const.WIDTH]: width }
    }).then(
      res => {
        this.completed(res.body);
      },
      err => {
        this.failed(err);
      }
    );
  }),

  /**
   * save visible setting field for the current catalog.
   */
  setOption: createAsyncAction(function({
    catalogId,
    viewMode,
    option,
    value
  }) {
    let data = { catalogId, viewMode, option };
    let key = us_util.makeKey(data);

    return makeApiRequest(`userSettings/${key}`, {
      method: "patch",
      body: { value }
    }).then(
      res => {
        this.completed(res.body);
      },
      err => {
        this.failed(err);
      }
    );
  }),

  /**
   * get all settings for catalogId
   */
  getUserSettingsForCatalog: createAsyncAction(function({ catalogId }) {
    return makeApiRequest(`userSettings`, {
      query: { search: us_util.makeKeyForSearch({ catalogId }) },
      method: "get"
    }).then(
      res => {
        this.completed(res.body, { catalogId });
      },
      err => {
        this.failed(err);
      }
    );
  }),

  //
  setFieldsOrder: createAsyncAction(function({
    catalogId,
    viewMode,
    fieldsOrder
  }) {
    let data = { catalogId, viewMode, fieldsOrder };
    let key = us_util.makeKey(data, us_const.FIELDS_ORDER);

    return makeApiRequest(`userSettings/${key}`, {
      method: "patch",
      body: { [us_const.FIELDS_ORDER]: fieldsOrder }
    }).then(
      res => {
        this.completed(res.body, data);
      },
      err => {
        this.failed(err);
      }
    );
  }),

  setSortingRecords: createAsyncAction(function({ catalogId, viewMode }, data) {
    let params = { catalogId, viewMode };
    let key = us_util.makeKey(params, us_const.SORTING_RECORDS);

    return makeApiRequest(`userSettings/${key}`, {
      method: "patch",
      body: data
    }).then(
      res => {
        this.completed(res.body, params, data);
      },
      err => {
        this.failed(err);
      }
    );
  }),

  updateUserSettings: createAsyncAction(function(key, data) {
    actions.setUserSettingsToAppState(key, data);

    const serverKey = key.join(".");

    return makeApiRequest(`userSettings/${serverKey}`, {
      method: "patch",
      body: data
    }).then(
      res => {
        this.completed(res.body, key, data);
      },
      err => {
        this.failed(err);
      }
    );
  }),

  setCatalogUserSettingsToAppState: Reflux.createAction({ sync: false }),
  setUserSettingsToAppState: Reflux.createAction({ sync: false })
};

export default actions;
