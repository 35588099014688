import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Dropdown, Row } from 'antd';
import cn from 'classnames';

import ButtonTransparent from '../../../../common/UI/ButtonTransparent';
import Icon from '../../../../common/UI/Icon';
import i18n from '../../../../../configs/i18n';
import * as CHART_TYPES from "../../../../../configs/reports/widget/chartTypes";

import styles from "../widget.less";

const WidgetsActivities = (props) => {
    const { 
        widgetId, 
        chartType, 
        readonly, 
        isFull, 
        onDownloadChart, 
        onEdit, 
        onExportExcel, 
        addFullWidgetUid, 
        removeFullWidgetUid,
    } = props

    const [itemsDownloadsDropDown, setItemsDownloadsDropDown] = useState([]);
    
    const isCanDownloadImgChart = (chartType !== CHART_TYPES.TABLE && chartType !== CHART_TYPES.NUMBER) 

    //  TODO: как только появится возможность скачивания графиков в excel
    //  нужно скачивание картинки и скачивание excel перенести в Dropdown 
    // useEffect(() => {
    //     const items = [
    //         {
    //             key: 1,
    //             onClick: onExportExcel,
    //             label: (
    //                 <a onClick={onExportExcel} >
    //                     <Icon
    //                         type={"icon files-40"}
    //                         className={styles.settingIcon}
    //                     />
    //                     {i18n.t("reports.widget.buttons.options.toExcel")}
    //                 </a>
    //             )
    //         },
    //         {
    //             key: 2,
    //             onClick: onDownloadChart,
    //             label: (
    //                 <a onClick={onExportExcel} >
    //                     <Icon
    //                         type={"icon multimedia-13"}
    //                         className={styles.settingIcon}
    //                     />
    //                     {i18n.t("reports.widget.buttons.options.downloadWidget")}
    //                 </a>
    //             )
    //         },
    //         {
    //             key: 3,
    //             label: (
    //                 <a onClick={onEdit}>
    //                     <Icon
    //                         type={"icon multimedia-13"}
    //                         className={styles.settingIcon}
    //                     />
    //                     {i18n.t("reports.widget.buttons.options.edit")}
    //                 </a>
    //             )
    //         },
    //     ]

    //     setItemsDownloadsDropDown(items)
    // }, [])

    return (
        <React.Fragment>
            <Row className={styles.buttonsHidden}>
                <ButtonTransparent
                    className={styles.buttonActivities}
                    title={i18n.t("reports.widget.buttons.options.edit")}
                    onClick={onEdit}
                >
                    <Icon type="icon setting-10" />
                </ButtonTransparent>
                {
                    isCanDownloadImgChart && 
                    <ButtonTransparent
                        className={styles.buttonActivities}
                        title={i18n.t("reports.widget.buttons.options.downloadWidget")}
                        onClick={onDownloadChart}
                    >
                        <Icon type="icon transfers-5" />
                    </ButtonTransparent>
                }
                {
                    !isFull &&
                    <ButtonTransparent
                        className={cn(
                            styles.buttonActivities, 
                            styles.buttonExpand,
                        )}
                        title={i18n.t("reports.widget.buttons.expand")}
                        onClick={e => {
                            e.stopPropagation();
                            addFullWidgetUid(widgetId);
                        }}
                    >
                        <Icon type="icon edition-27" />
                    </ButtonTransparent>
                }
            </Row>
            <Row>
            {
                isFull &&
                <ButtonTransparent
                    className={cn(
                        styles.buttonActivities, 
                        styles.buttonCollapse,
                    )}
                    title={i18n.t("reports.widget.buttons.collapse")}
                    onClick={e => {
                        e.stopPropagation();
                        removeFullWidgetUid(widgetId);
                    }}
                >
                    <Icon type="icon interface-74" />
                </ButtonTransparent>
            }
            </Row>

            {/*  
                <Dropdown
                    menu={{ items: itemsDownloadsDropDown }}
                    trigger={["click"]}
                >
                    <ButtonTransparent
                        title={i18n.t("reports.widget.buttons.options.downloadWidget")}
                    >
                        <Icon type="icon transfers-5" />
                    </ButtonTransparent>
                </Dropdown> 
            */}  
        </React.Fragment>
    );
};

export default WidgetsActivities;

WidgetsActivities.propTypes = {
    widgetId: PropTypes.string,
    chartType: PropTypes.string,
    isFull: PropTypes.bool,
    readonly: PropTypes.bool,
    addFullWidgetUid: PropTypes.func,
    removeFullWidgetUid: PropTypes.func,
    onDownloadChart: PropTypes.func,
    btnEdit: PropTypes.func,
    onExportExcel: PropTypes.func
};