import React from "react";
import _ from "lodash";
import { isToday } from "../helpers";

import ViewTabs from "./ViewTabs";
import RangeController from "./RangeController";
import Config from "./Config/index.jsx";

import styles from "../calendar.less";

class Header extends React.PureComponent {
  render() {
    const { view, date, catalogId, fields } = this.props;
    return (
      <div className={styles.header}>
        <ViewTabs view={view} date={date} isToday={isToday(date, view)} />
        <RangeController
          setUrlItemsToStore={this.props.setUrlItemsToStore}
          onChangeUrl={this.props.onChangeUrl}
          date={date}
          view={view}
        />
        <Config
          catalogId={catalogId}
          fields={fields}
          suggestRecords={this.props.suggestRecords}
          view={this.props.view}
          settings={this.props.settings}
          userSettingsHandler={this.props.userSettingsHandler}
        />
      </div>
    );
  }
}

export default Header;
