import Immutable from "immutable";
import _ from "lodash";
import { checkAccessOnObject } from "../utils/rights";
import RESOURCE_TYPES from "../configs/resourceTypes";
import PRIVILEGE_CODES from "../configs/privilegeCodes";
import extensionsModules from "../configs/extensionsModules";

export default {
  getCompanyInfoCompleted(response) {
    const extensions = _.get(response, "body.extensions");
    const modules = _.get(response, "body.modules", []);
    const adminSection = this.get("sections").find(
      section => Number(section.get("id")) === 1
    );

    const isAdmin = checkAccessOnObject(
      RESOURCE_TYPES.SECTION,
      adminSection,
      PRIVILEGE_CODES.ADMIN
    );

    // взять текщие extensions
    let currentExtensions = this.getIn(["company", "extensions"]);
    currentExtensions = currentExtensions ? currentExtensions.toJS() : [];

    // найти новые extensions по разнице текщих extensions с полученными extensions по code
    let newExtensions = _.differenceBy(extensions, currentExtensions, "code");
    // remove some trash from extensions
    newExtensions = newExtensions
      .map(item => {
        if (!isAdmin && item.code === extensionsModules.HELP_CHAT) {
          return;
        }
        return {
          code: item.code,
          title: item.title,
          icon: item.icon,
          css: item.css,
          js: item.js
        };
      })
      .filter(item => !!item);

    // concat new extensions to already loaded
    _.set(response, "body.extensions", currentExtensions.concat(newExtensions));

    // remove some trash from modules
    _.set(
      response,
      "body.modules",
      modules
        .map(item => {
          if (!isAdmin && item.code === extensionsModules.HELP_CHAT) {
            return;
          }
          return item.code;
        })
        .filter(item => !!item)
    );

    // set modify response.body to appState
    this.set("company", Immutable.fromJS(response.body));

    this.changed();
  },

  getCompaniesCompleted(response) {
    this.set("companies", Immutable.fromJS(response.body));
    this.changed();
  },

  getLicenseCompleted(response) {
    this.set("license", Immutable.fromJS(response.body.params || {}));
    this.set(
      "activationCode",
      Immutable.fromJS(response.body.activationCode || null)
    );
    this.changed();
  },

  getVendorCompleted(response) {
    this.set("vendor", Immutable.fromJS(response.body));
    this.changed();
  }
};
