import Immutable from "immutable";

import FIELD_TYPES from "../../../../../../configs/fieldTypes";
import availableValues from "../../../../../../configs/reports/widget/availableSortTypeValues";

export default function getDefaultSortType(axis, fields) {
  const field =
    axis &&
    axis.get("type") == "field" &&
    fields.find(i => i.get("id") == axis.get("value"));
  const type = field ? field.get("type") : "";

  switch (type) {
    case FIELD_TYPES.USER:
    case FIELD_TYPES.OBJECT:
    case FIELD_TYPES.TEXT:
      return Immutable.fromJS(availableValues[1]);

    case FIELD_TYPES.DATE:
      return Immutable.fromJS(availableValues[1]);

    case FIELD_TYPES.NUMBER:
    case FIELD_TYPES.DROPDOWN:
    case FIELD_TYPES.CHECKBOXES:
    case FIELD_TYPES.RADIOBUTTON:
    case FIELD_TYPES.STARS:
    case FIELD_TYPES.PROGRESS:
    default:
      return Immutable.fromJS(availableValues[0]);
  }
}
