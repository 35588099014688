import React from "react";
import _ from "lodash";
import { withRouter } from "react-router";
import Immutable, { fromJS } from "immutable";
import { message } from "antd";
import Dimensions from "../../Dimensions";
import guid from "guid";

import apiActions from "../../../actions/apiActions";
import recordActions from "../../../actions/recordActions";
import sceneActions from "../../../actions/sceneActions";
import userSettingsActions from "../../../actions/userSettingsActions";
import { connect } from "../../StateProvider";
import { withTranslation } from "react-i18next";
import getLink from "../../common/router/getLink";
import getRouteParams from "../../common/router/getRouteParams";
import routes from "../../../routes";
import { confirm } from "../../common/Modal";
import { checkAccessOnObject } from "../../../utils/rights";
import RESOURCE_TYPES from "../../../configs/resourceTypes";
import PRIVILEGE_CODES from "../../../configs/privilegeCodes";
import FIELD_TYPES from "../../../configs/fieldTypes";

import styles from "../records.less";

import RecordsTable from "../../common/UI/RecordsTable";
import UrlParamSync from "../../UrlParamSync";
import { ACTION_RECORD_OPEN } from "../../../configs/routes";

const Table = Dimensions({ debounce: 100 })(RecordsTable);

class RecordsBody extends React.Component {
  state = {
    editingRecordId: null,
    fieldsWidth: null
  };

  fetchRecords = (offset = 0, limit = 50) => {
    recordActions.requestForRecords(this.props.catalogId, this.props.sceneId, {
      offset,
      limit,
      viewId: this.props.viewId
    });
  };

  getRecord = recordId => {
    const catalogId = this.props.catalogId;
    const sceneId = this.props.sceneId;
    return apiActions
      .getRecord(
        { recordId, catalogId },
        { withFieldsAdditional: true },
        { sceneId }
      )
      ["catch"](error => console.error(error));
  };

  saveRecord = (recordId, soft = false) => {
    const { catalogId, sceneId } = this.props;

    recordActions.validateAndSaveRecord(
      {
        catalogId,
        recordId
      },
      {
        sceneId
      },
      () => {
        if (soft) return;
        recordActions.clearRecord(catalogId, recordId);
      },
      error => {
        error && message.error(error);
        sceneActions.openRecord({ catalogId, recordId });
      }
    );
  };

  canEditValue = (record, field) => {
    const fieldId = field && field.get("id");
    const apiOnly = field && field.get("apiOnly");

    if (!record) {
      return undefined;
    }

    const recordBasePrivilege = record.get("privilegeCode");
    const recordFieldsPrivilegeCodes = record.get("fieldPrivilegeCodes");

    if (!recordBasePrivilege || !recordFieldsPrivilegeCodes) {
      return undefined;
    }

    const hiddenFields = record.get("hiddenFields");
    const fieldPrivilegeCode = record.getIn(["fieldPrivilegeCodes", fieldId]);

    const recordEditable = checkAccessOnObject(
      RESOURCE_TYPES.RECORD,
      record,
      PRIVILEGE_CODES.EDIT
    );

    const privilegiesIsOk = recordEditable
      ? fieldPrivilegeCode === "edit" || _.isUndefined(fieldPrivilegeCode)
      : fieldPrivilegeCode === "edit";
    const isHidden = hiddenFields && hiddenFields.get(fieldId);

    return privilegiesIsOk && !isHidden && !apiOnly;
  };

  isEditableField = field => field && !field.get("apiOnly");

  setWidthByFields = () => {
    const { userSettingsfields } = this.props;

    if (!userSettingsfields) {
      return;
    }
    const fieldsWidth = userSettingsfields.map(setting =>
      setting.getIn(["width", "width"])
    );
    this.setState({ fieldsWidth });
  };

  onRefuseEditing = (recordId, fieldId) => {
    message.error(this.props.t("modals.denied.record.update.headerText"));
  };

  onStartRecordEditing = record => {
    const fields = this.props.catalog.get("fields");
    const records = this.props.records;

    const recordId = record.get("id");
    const catalogId = record.get("catalogId");

    const recordExist = records && records.getIn([catalogId, recordId]);

    this.setState({ editingRecordId: record.get("id") });

    const params = { catalogId, recordId };
    const data = {
      id: record.get("id"),
      isNew: false,
      title: record.get("title"),
      values: record.get("values"),
      fields: fields && fields.toJS()
    };

    if (!recordExist) {
      recordActions.generateRecord(params, data);
      return this.getRecord(record.get("id"));
    }
  };

  onRecordClick = record => {
    const { openRecordsInModal, catalogId, isWebForm } = this.props;
    const recordId = record.get("id");

    if (isWebForm) {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set("record", recordId);
      urlParams.set("action", ACTION_RECORD_OPEN);
      const queryString = urlParams.toString();

      // Получаем текущий хост и путь без параметров запроса
      const baseUrl = `${window.location.protocol}//${window.location.host}${
        window.location.pathname
      }`;

      // Формируем новую URL с обновленными параметрами запроса
      const newUrl = `${baseUrl}?${queryString}`;

      // Переходим на новую URL
      window.location.href = newUrl;
    } else if (openRecordsInModal) {
      sceneActions.openRecord({ catalogId, recordId });
    } else {
      const link = getLink(this.props.location, routes.record, { recordId });
      this.props.history.push(link);
    }
  };

  onChangeRecordValue = (recordId, fieldId, value) => {
    const catalogId = this.props.catalogId;
    const values = fromJS({ [fieldId]: value });

    recordActions.updateValues(catalogId, recordId, values);
    recordActions.calcRecordHiddenFields(catalogId, recordId);
  };

  onEndValueEditing = (recordId, fieldId, value) => {
    const catalogId = this.props.catalogId;
    const values = fromJS({ [fieldId]: value });

    recordActions.raiseChanges(catalogId, recordId, values, () => {
      if (this.state.editingRecordId !== recordId) {
        this.saveRecord(recordId, true); // soft applying changes
      }
    });
  };

  onEndRecordEditing = (recordId, soft) => {
    const catalogId = this.props.catalogId;
    const record = this.props.records.getIn([catalogId, recordId]);

    this.setState({ editingRecordId: null });

    if (record && !record.getIn(["updateProcesses", "count"])) {
      this.saveRecord(recordId, soft);
    }
  };

  onSortFieldChange = sortField => {
    const { sortingRecordSetting, catalog } = this.props;
    const fields = catalog.get("fields");
    const isValid =
      sortField === "id" || fields.some(field => field.get("id") === sortField);

    const sortType =
      sortingRecordSetting && sortingRecordSetting.get("sortType");
    if (!isValid) {
      sortField = "id";
    }
    this.onSortChange(sortField, sortType);
  };

  onSortTypeChange = sortType => {
    const { sortingRecordSetting } = this.props;
    const sortField =
      sortingRecordSetting && sortingRecordSetting.get("sortField");
    sortType = Number(sortType) < 0 ? -1 : 1;

    if (sortType && sortField) {
      this.onSortChange(sortField, sortType);
    }
  };

  onColorChange = (colorField, key = "color") => {
    const { catalog } = this.props;
    const catalogId = catalog && catalog.get("id");
    const fields = catalog && catalog.get("fields");
    const isValid =
      fields &&
      fields
        .filter(field => field.get("type") === FIELD_TYPES.DROPDOWN)
        .some(field => field.get("id") === colorField.value);
    if (!isValid) colorField = null;
    // field.get("type") FIELD_TYPES.DROPDOWN

    userSettingsActions.setOption({
      catalogId,
      viewMode: "table",
      option: key,
      value: colorField
    });
  };

  onSortChange = (sortField, sortType) => {
    const { catalogId, sceneId } = this.props;
    sortType = Number(sortType) === 1 ? 1 : -1;
    userSettingsActions.setSortingRecords(
      { catalogId, viewMode: "table" },
      { sortField, sortType }
    );

    recordActions.clearRecordsCollection(sceneId);
  };

  onReorder = fieldsOrder => {
    userSettingsActions.setFieldsOrder({
      catalogId: this.props.catalogId,
      viewMode: "table",
      fieldsOrder
    });
  };

  onResize = (width, fieldId) => {
    userSettingsActions.setFieldWidth({
      catalogId: this.props.catalog.get("id"),
      viewMode: "table",
      fieldId,
      width
    });
  };

  onDeleteRecord = record => {
    const { catalogId, viewId, t, userId } = this.props;
    const recordId = record.get("id");
    const isYourself = userId === recordId;

    // эта проверка означает когда в каталоге мы пытаемся удалить себя

    confirm({
      headerText: isYourself
        ? t("modals.removeYourselfConfirm.header")
        : t("modals.removeRecordConfirm.header"),
      text: isYourself
        ? t("modals.removeYourselfConfirm.text")
        : t("modals.removeRecordConfirm.text"),
      okText: isYourself
        ? t("modals.removeYourselfConfirm.okText")
        : t("modals.removeRecordConfirm.okText"),
      cancelText: isYourself
        ? t("modals.removeYourselfConfirm.cancelText")
        : t("modals.removeRecordConfirm.cancelText"),
      onOk: () => {
        apiActions
          .deleteRecord({ catalogId, recordId }, { viewId })
          .then(this.fetchRecords);
      }
    });
  };

  componentDidUpdate(prevProps) {
    const userSettingsfields = this.props.userSettingsfields;

    if (!Immutable.is(prevProps.userSettingsfields, userSettingsfields)) {
      this.setWidthByFields();
    }
  }

  componentDidMount() {
    this.setWidthByFields();
  }

  render() {
    const {
      scene,
      catalog,
      catalogId,
      sceneId,
      viewId,
      fieldsToRender,
      allFields,
      fieldsOrder,
      colorField
    } = this.props;

    const sortingRecordSetting = this.props.sortingRecordSetting;
    const routeParams = getRouteParams(this.props.location);
    const selectedRecordId = routeParams
      ? routeParams.match.params.recordId
      : 0;

    const sortType =
      sortingRecordSetting && sortingRecordSetting.get("sortType");
    const sortField =
      sortingRecordSetting && sortingRecordSetting.get("sortField");

    return (
      <div className={styles.recordsDataContainer}>
        <Table
          sectionId={catalog.get("sectionId")}
          catalogId={catalogId}
          sceneId={sceneId}
          scene={scene}
          viewId={viewId}
          selectedRecordId={selectedRecordId}
          fieldsToRender={fieldsToRender}
          allFields={allFields}
          records={scene.get("records")}
          loading={scene.get("loading")}
          shouldReload={scene.get("shouldReload")}
          rowsCount={scene.get("recordsCount")}
          sortField={sortField}
          sortType={sortType}
          fieldsWidth={this.state.fieldsWidth}
          fieldsOrder={fieldsOrder}
          fetchRecords={this.fetchRecords}
          onChangeRecordValue={this.onChangeRecordValue}
          onRecordClick={this.onRecordClick}
          onSortChange={this.onSortChange}
          onStartRowEditing={this.onStartRecordEditing}
          onEndRowEditing={this.onEndRecordEditing}
          onEndCellEditing={this.onEndValueEditing}
          onRefuseEditing={this.onRefuseEditing}
          onReorder={this.onReorder}
          onResize={this.onResize}
          onDeleteRow={this.onDeleteRecord}
          isEditableCell={this.isEditableField}
          canEditRecordValue={this.canEditValue}
          colorField={colorField}
        />
        <UrlParamSync
          name="sortField"
          value={sortField}
          onChange={this.onSortFieldChange}
        />
        <UrlParamSync
          name="sortType"
          value={sortType}
          onChange={this.onSortTypeChange}
        />
        <UrlParamSync
          name="colorField"
          value={colorField}
          onChange={this.onColorChange}
        />
      </div>
    );
  }
}

export default withTranslation(undefined, { withRef: true })(
  withRouter(
    connect(
      RecordsBody,
      {
        records: ["records"],
        catalogs: ["catalogs"],
        userSettingsCatalogs: ["userSettings", "catalogs"],
        user: ["user"]
      },
      (props, { records, catalogs, userSettingsCatalogs, user }) => {
        const { catalogId } = props;
        const catalog = catalogs.get(catalogId);
        const settings = userSettingsCatalogs.getIn([
          catalogId,
          "viewMode",
          "table"
        ]);
        const colorField =
          settings && settings.getIn(["options", "color", "value"]);
        const userId = user && user.get("id");

        return {
          records,
          catalog,
          colorField,
          userId,
          ...props
        };
      }
    )
  )
);
