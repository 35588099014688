import React, { useLayoutEffect } from "react";
import PropTypes from "prop-types";

import TabChat from "./TabChat";
import messageActions from "../../../../actions/messageActions";

const TabChatController = ({
  catalog,
  record,
  sceneId,
  visibleChatPopup,
  openChatInModal,
  isPopupOpen
}) => {
  const recordId = record.get("id");
  const chat = record.get("chat");
  const catalogId = catalog.get("id");

  useLayoutEffect(() => {
    if (!chat) {
      messageActions.loadMessages(
        { catalogId, recordId, sceneId },
        true,
        50,
        true
      );
    }
  }, []);

  return recordId && chat ? (
    <TabChat
      {...{
        catalog,
        sceneId,
        catalogId,
        chat,
        record,
        recordId,
        visibleChatPopup,
        openChatInModal,
        isPopupOpen
      }}
    />
  ) : null;
};

TabChatController.propTypes = {
  catalog: PropTypes.object.isRequired,
  record: PropTypes.object
};

export default TabChatController;
