import React from "react";
import { Row } from "antd";
import { useTranslation } from "react-i18next";

import Dropdown from "../../../../common/Dropdown";
import { getAvailableLayoutFields } from "../../../helpers";

import styles from "../config.less";

const Split = props => {
  const { t } = useTranslation();
  const value = props.settings.getIn(["split", "key"]);
  const items = getAvailableLayoutFields(props.fields);
  return items && props.view === "day" ? (
    <Row className={styles.controlRow}>
      <label className={styles.controlLabel}>
        {t("calendar.config.layout")}
      </label>
      <Dropdown
        disabled={!items.length}
        items={[{ key: null, text: "" }, ...items]}
        value={value}
        autocomplete={true}
        withButton={true}
        multiselect={false}
        onSelectItems={([item]) => props.handler(item, "split")}
      />
    </Row>
  ) : null;
};

export default Split;
