import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import getLink from "../../../common/router/getLink";
import routes from "../../../../routes";
import { COMPARE_FORMAT } from "../../formats";
import moment from "moment";
import cn from "classnames";
import { DataContext } from "../../DataProvider";

import styles from "../../calendar.less";
import { prepareDateForUrl } from "../../helpers";

const DateHeader = ({ onClick, date, label }) => {
  const { setUrlItemsToStore, date: currentDate } = useContext(DataContext);

  const dateSub = moment(date).month() !== moment(currentDate).month();
  const dateNow =
    moment(date).format(COMPARE_FORMAT) === moment().format(COMPARE_FORMAT);
  return (
    <span
      onClick={onClick}
      className={cn(styles.monthDate, {
        [styles.monthDateSub]: dateSub,
        [styles.monthDateNow]: dateNow
      })}
    >
      {label}
    </span>
  );
};

export default DateHeader;
