import React from "react";
import _ from "lodash";
import InputMask from "inputmask-core";

import FIELD_TYPES from "../../configs/fieldTypes";
import { VALUE_STATUSES } from "../../configs/import";
import i18n from "../../configs/i18n";
import { formatCharacters } from "../../configs/maskFormatCharacters";

const MAX_LENGTH = 255;

export default class Text {
  static type = FIELD_TYPES.TEXT;

  static components = {
    inline: require("../../components/common/dataTypes/TextField").default,
    control: require("../../components/common/UI/ControlList/controls/Text")
      .default
  };

  static getComponent = type => Text.components[type];

  static parseValue = (value, field) => {
    // clean
    value = _.trim(value);

    // try parse as empty value
    if (Text.isEmpty(value)) {
      value = Text.getEmptyValue();
      return { value, status: VALUE_STATUSES.VALID };
    }

    // try parse as string
    if (_.isString(value)) {
      if (Text.validateValue(value, field)) {
        return { value, status: VALUE_STATUSES.VALID };
      }
    }

    /* при превышении лимита по символам обрезать текст */
    // if (value && value.length > 100) {
    //   value = value.substr(0, 100) + "...";
    // }
    return { value, status: VALUE_STATUSES.INVALID };
  };

  static validateValue = (value, field, returnError = false) => {
    if (!_.isString(value)) {
      return false;
    }

    if (!field) {
      return false;
    }

    const mask = field.getIn(["config", "mask"]);
    const multiline = field.getIn(["config", "type"]) == "multiline"; //TEXT_TYPES.MULTILINE

    let isValid = true;
    let error = "";

    // size checking
    if (!multiline) {
      isValid = isValid && MAX_LENGTH > _.size(value);
    }

    // masked input checking
    if (mask && value) {
      let maskValue1;
      let maskValue2;
      try {
        maskValue1 = InputMask({
          formatCharacters,
          pattern: mask,
          value: value
        });

        maskValue1 = maskValue1.getValue();
        maskValue2 = InputMask({
          formatCharacters,
          pattern: mask,
          value: value,
          placeholderChar: "`"
        });
        maskValue2 = maskValue2.getValue();

        isValid = isValid && (maskValue1 == maskValue2);
      } catch (e) {
        error = i18n.t("fieldTypes.text.mask.incorrectMask");
      }

      if (maskValue1 != maskValue2) {
        error = i18n.t("fieldTypes.text.mask.error");
      }
    }

    return returnError ? error : isValid;
  };

  static getEmptyValue = () => "";

  static createApi = () => new this();

  static isEmpty = value => !value;

  static getDefaultValues = (value1, value2) => value1 === value2;

  static createComponent = (field, value, type) => {
    const Component = Text.components[type];
    return ({ containerClassName }) => {
      if (Text.isEmpty(value)) {
        return null;
      }
      return (
        <Component
          config={field.get("config")}
          value={value}
          containerClassName={containerClassName}
        />
      );
    };
  };
  static convertFilterToRecordValue = () => {
    return undefined;
  };
  static getDefaultValue = field => {
    const defaultValue = field.getIn(["config", "defaultEmptyValue"]);
    return defaultValue;
  };

  /**
   * Проверка поля на пустоту
   * @param {string} value
   * @returns {boolean}
   */
  static validateRequired = (value) => {
    const empty = _.isNull(value) || value === "" || value === undefined;
    return empty;
  }

  static boardWitchColor = () => {
    return true;
  }

  static visibleRules = (value) => {
    return;
  }

  static sortRecordsInCards = (field, records) => {
    const fieldId = field.get("id");
    // sort by value
    return records.sortBy(r => r.getIn(["values", fieldId]));
  }

  static checkChangeYourself = (field, value) => {
    // тут свич кейсом опишем какие типы можно изменять вот например пока нельзя изменять емаил
    const type = field.getIn(["config", "type"]);
    switch (type) {
      case "mail":
        return true;
      default:
        return false
    }
  }
}