import React from "react";
import Mime from "mime-types";
import Immutable from "immutable";
import _ from "lodash";
import raf from "raf";
import { Row, Upload } from "antd";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import dndContext from "../../../../../services/dndContext";
import apiActions from "../../../../../actions/apiActions";
import AttachFile from "./AttachFileModal";

import FileDrop from "./FileDrop";
import FileViewer from "./fileViewer";
import { getViewerByMimeType } from "./fileViewer/getViewerType";

import styles from "./controls.less";

const log = require("debug")("CRM:Component:Record:FileField");

class FileField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.xhrs = {};
    this.state = {
      imagesRendered: false,
      updateProgress: Immutable.Map(),
      uploadListeners: {
        progress: [],
        load: [],
        error: []
      }
    };
  }

  pushUploadListeners = (event, value) => {
    const uploadListeners = { ...this.state.uploadListeners };
    let uploadListenersArr = uploadListeners[event];

    if (value) {
      uploadListenersArr.push(value);
    } else {
      uploadListenersArr = [];
    }
    uploadListeners[event] = uploadListenersArr;

    this.setState({ uploadListeners });
  };

  handleFile = e => {
    const file = e.file;
    if (file) {
      this.uploadFile(file);
    }
  };

  uploadFile = (file, value = this.props.value) => {
    const uploadId = Math.random();

    // add file in state
    log("start uploading file", uploadId);
    let params = {
      name: file.name,
      size: file.size,
      mimeType: file.type || Mime.lookup(file.title), // sometimes browsers cant identify the file mimi-type
      typeStorage: "remoteStorage"
    };

    this.onChange(
      (value || Immutable.List()).push(
        Immutable.fromJS({
          id: uploadId,
          title: file.name,
          loading: true,
          size: file.size,
          error: false,
          mimeType: file.type
        })
      )
    );

    return apiActions
      .uploadFileRecord(
        this.props.controlConfig.get("requestParams") || {},
        params,
        uploadId
      )
      .then(data => {
        const fileId = data.fileId;
        // make request on s3
        // todo: make configure by type storage.
        log("Link upload file", uploadId, data);
        let fd = new FormData();

        fd.append("key", data.fileKey);
        fd.append("acl", data.acl);
        // fd.append('success_action_redirect', data.redirect);

        fd.append("Content-Type", file.type ? file.type : "");
        fd.append("AWSAccessKeyId", data.AWSAccessKeyId);
        fd.append("Policy", data.police);
        fd.append("Signature", data.signature);

        fd.append("file", file);

        // todo: defer abort xhr.abort()
        var xhr = new XMLHttpRequest();
        this.xhrs[uploadId] = xhr;
        let progressListener = progress => {
          log("progress file", uploadId, progress);
          this.updateProgress(progress, uploadId);
        };
        xhr.upload.addEventListener("progress", progressListener, false);
        this.pushUploadListeners("progress", progressListener);
        let _data = data;
        let loadListener = res => {
          let data = {
            name: file.name,
            size: file.size,
            mimeType: file.type,
            url: _data.fileKey
          };
          apiActions.updateFileRecord({ fileId }, data).then((res, ...args) => {
            let newFile = Immutable.Map({
              id: res.id,
              title: res.title,
              mimeType: res.mimeType,
              size: file.size,
              url: res.url,
              loading: false
            });
            this.completeUpload(uploadId, newFile);
          });
        };
        xhr.addEventListener("load", loadListener, false);
        this.pushUploadListeners("load", loadListener);
        let errorListener = data => {
          this.setErrorOnFile(uploadId);
        };
        xhr.addEventListener("error", errorListener, false);
        this.pushUploadListeners("error", errorListener);
        xhr.addEventListener(
          "abort",
          function uploadCanceled(data) {
            log("abort", arguments);
            // this.removeUploadingFile({id: uploadId});
          },
          false
        );

        xhr.open("POST", data.uploadUrl, true);
        xhr.send(fd);
      })
      .catch(() => {
        // remove if failed create.
        this.removeUploadingFile({ id: uploadId });
      });
  };

  uploadContent = (content, { type, title }, value = this.props.value) => {
    const file = new Blob([content], { type });
    file.name = title;
    this.uploadFile(file, value);
  };

  replaceFile = (file, newContent) => {
    const newValue = this.onClickRemoveFile(file);

    // raf will call after props will came again
    // it is needed because onClickRemoveFile call onEndEdit that is also called in raf
    raf(() => {
      this.uploadContent(newContent, file, newValue);
    });
  };

  onEndEditing = newValue => {
    this.props.onEndEditing && this.props.onEndEditing(newValue);
  };

  onChange = newValue => {
    this.props.onChange && this.props.onChange(newValue);
  };

  completeUpload = (uploadId, newFile) => {
    log("upload file complete", uploadId);
    const index = this.props.value.findIndex(f => f.get("id") === uploadId);

    if (index !== -1) {
      this.onChange(this.props.value.set(index, newFile));
      this.onEndEditing(this.props.value.set(index, newFile));
    }
  };

  updateProgress = (progress, uploadId) => {
    this.setState({
      updateProgress: this.state.updateProgress.set(
        uploadId,
        ((progress.loaded / progress.total) * 100).toFixed(2)
      )
    });
  };

  setErrorOnFile = uploadId => {
    this.onChange(
      this.props.value.map(f => {
        if (f.get("id") === uploadId) {
          f = f.set("error", true).set("loading", false);
        }
        return f;
      })
    );
  };

  removeUploadingFile = file => {
    const index = this.props.value.findIndex(f => f.get("id") === file.id);
    if (index !== -1) {
      this.onChange(this.props.value.delete(index));
    }
  };

  onClickRemoveFile = file => {
    const { value } = this.props;
    const index = value.findIndex(
      f =>
        (f.get("id") && f.get("id") === file.id) ||
        (f.get("temporaryId") && f.get("temporaryId") === file.temporaryId)
    );
    const xhr = this.xhrs[file.id];

    if (xhr) {
      xhr.abort();
      delete this.xhrs[file.id];
    }

    if (index !== -1) {
      const newValue = value.delete(index);
      this.onChange(newValue);
      this.onEndEditing(newValue);
      //!file.loading && apiActions.removeFileRecord({ fileId: file.id });
      return newValue;
    }

    return value;
  };

  componentWillUnmount() {
    _.forOwn(this.xhrs, xhr => {
      xhr.abort();
      //Очистка всех листенеров
      _.forOwn(this.state.uploadListeners, (listeneres, event) => {
        _.forEach(listeneres, listener => {
          xhr.removeEventListener(event, listener);
        });
        this.pushUploadListeners(event);
      });
    });

    const { value } = this.props;

    if (value && value.find(val => val.get("loading"))) {
      let newValue = Immutable.List();
      value.forEach(val => {
        if (!val.get("loading")) {
          newValue = newValue.push(val);
        }
      });
      this.onChange(newValue);
    }
  }

  render() {
    const { params } = this.props;
    const values = (this.props.value && this.props.value.toJS()) || [];
    const mimeType = this.props.config.get("mimeType") || "";
    const editable = this.props.editable;
    const showUploadButton =
      editable && (this.props.config.get("multiselect") || values.length === 0);
    const mimeTypes = mimeType.split(",").map(t => t.trim());

    return (
      <React.Fragment>
        {showUploadButton && (
          <FileDrop
            onDrop={this.handleFile}
            multiple={this.props.config.get("multiselect")}
          />
        )}

        <div
          className={this.props.wrapperClassName}
          style={{ position: "relative" }}
        >
          <FileViewer
            files={values}
            readOnly={!editable}
            removeFn={this.onClickRemoveFile}
            saveFn={this.replaceFile}
            updateProgress={this.state.updateProgress}
            params={params}
          />
          {this.props.editable ? (
            <Row
              type="flex"
              style={!showUploadButton ? { display: "none" } : null}
            >
              {editable &&
                mimeTypes.map((mimeType, idx) => {
                  const Viewer = getViewerByMimeType(mimeType);
                  return (
                    Viewer && (
                      <React.Fragment>
                        <div className={styles.viewerWrapper} key={idx}>
                          <Viewer
                            params={params}
                            key={mimeType}
                            saveFn={
                              editable &&
                              (({ title }, content) =>
                                this.uploadContent(content, {
                                  title,
                                  type: mimeType
                                }))
                            }
                          />
                        </div>
                      </React.Fragment>
                    )
                  );
                })}
              <div
                title={this.props.t("record.fields.file.drop")}
                className={styles.uploadWrapper}
              >
                <Upload
                  className={styles.upload}
                  customRequest={this.handleFile}
                  showUploadList={false}
                  multiple={this.props.config.get("multiselect")}
                  accept={mimeType}
                >
                  {this.props.t("record.fields.file.upload")}
                </Upload>
                <AttachFile
                  onChange={this.onChange}
                  onEndEditing={this.onEndEditing}
                  value={this.props.value}
                />
              </div>
            </Row>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

FileField.propTypes = {
  value: PropTypes.object,
  config: PropTypes.object,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
  onEndEditing: PropTypes.func
};

export default withTranslation()(dndContext(FileField));
