import React, { Component } from "react";
import _ from "lodash";
import cn from "classnames";
import PropTypes from "prop-types";
import { dragSource, dropTarget } from "./dndSourceTarget";

import styles from "./abstractMenu.less";

class MenuItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    onDragEnd: PropTypes.func.isRequired
  };

  state = {
    iconHovered: false
  };

  onMouseEnter = e => {
    this.setState({
      iconHovered: true
    });
  };

  onMouseLeave = e => {
    this.setState({
      iconHovered: false
    });
  };

  render() {
    const {
      connectDragSource,
      connectDragPreview,
      connectDropTarget,
      isDragging,
      item,
      className
    } = this.props;
    return _.flow(
      connectDragSource,
      connectDragPreview,
      connectDropTarget
    )(
      <li
        className={cn(className, { dragging: isDragging })}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        style={isDragging ? { opacity: "0.1" } : null}
      >
        {item}
      </li>
    );
  }
}

export default _.flow(
  dragSource,
  dropTarget
)(MenuItem);
