import React from "react";
import cn from "classnames";
import moment from "moment";

import useRange from "../hooks/useRange.hook";
import { RANGE_FORMAT } from "../formats";

import Icon from '../../common/UI/Icon';
import ButtonTransparent from "../../common/UI/ButtonTransparent";

import styles from "../calendar.less";
import i18n from "../../../configs/i18n";

class RangeController extends React.Component {
  navigate(action, prevDate = this.props.date) {
    const date = moment(prevDate)
      [action](1, this.props.view)
      .format(RANGE_FORMAT);

    this.props.onChangeUrl(date);
    this.props.setUrlItemsToStore({ date, view: this.props.view });
  }

  dateParce = () => {
    const { view, date } = this.props;

    let value;
    switch (view) {
      case "year":
        value = moment(date).format("YYYY");
        break;
      case "month":
        value = `${moment(date).format("MMMM")}, ${moment(date).format(
          "YYYY"
        )}`;
        break;
      case "week":
        const [mon, sun] = useRange(view, date, RANGE_FORMAT);
        value = `${moment(mon).format("D MMM")} — ${moment(sun).format(
          "D MMM"
        )}`;
        break;
      case "day":
        value = `${moment(date)
          .locale(i18n.language)
          .format("LL")}`;
        break;
    }

    const el = (
      <div className={styles.dateTabsValue}>
        {value[0].toUpperCase() + value.slice(1)}
        {view === "day" && (
          <span className={styles.week}>
            ,{" "}
            {moment(date)
              .locale(i18n.language)
              .format("ddd")
              .toUpperCase()}
          </span>
        )}
      </div>
    );
    return el;
  };

  render() {
    return (
      <div className={styles.dateTabs}>
        <ButtonTransparent onClick={() => this.navigate("subtract")}>
          <Icon
            type={"icon arrows-chevron-big-3-01"}
            className={styles.dateTabsIcon}
          />
        </ButtonTransparent>
        {this.dateParce()}
        <ButtonTransparent onClick={() => this.navigate("add")}>
          <Icon
            type={"icon arrows-chevron-big-4-01"}
            className={styles.dateTabsIcon}
          />
        </ButtonTransparent>
      </div>
    );
  }
}

export default RangeController;
