import React, { PureComponent } from "react";
import cn from "classnames";
import _ from "lodash";
import PropTypes from "prop-types";
import Immutable from "immutable";

import MenuItem from "./MenuItem";

import styles from "./abstractMenu.less";

const EmptyList = Immutable.List();

class BaseMenuList extends PureComponent {
  static propTypes = {
    items: PropTypes.object,
    onDragEnd: PropTypes.func
  };

  state = {
    order: this.props.items.toList(),
    dropdownMenuItems: EmptyList
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!Immutable.is(nextProps.items, this.props.items)) {
      this.setState({
        order: nextProps.items.toList()
      });
    }
  }

  onDragEnd = item => {
    this.props.onDragEnd(this.state.order.map(o => o.key), item);
  };

  onMoveItem = (itemId, afterItemId) => {
    if (itemId !== this.itemId || afterItemId !== this.afterItemId) {
      const findIndex = id => this.state.order.findIndex(o => o.key === id);

      const arr = this.state.order
        .delete(findIndex(itemId))
        .insert(
          findIndex(afterItemId),
          this.state.order.get(findIndex(itemId))
        );
      this.setState({
        order: arr
      });

      this.itemId = itemId;
      this.afterItemId = afterItemId;
    }
  };

  renderList(listClassName, listStyle) {
    const { dragType, canDrag } = this.props;
    const { order } = this.state;

    return (
      <ul
        className={cn(listClassName && listClassName.menu, styles.menu)}
        style={listStyle}
        ref={node => (this.ul = node)}
      >
        {order.map((item, i) => (
          <MenuItem
            key={item.key}
            item={item}
            dragType={dragType}
            onDragEnd={this.onDragEnd}
            onMoveItem={this.onMoveItem}
            className={listClassName && listClassName.item}
            canDrag={canDrag}
          />
        ))}
      </ul>
    );
  }
}

export default BaseMenuList;
