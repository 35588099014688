import React, { useContext } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { prepareDateForUrl } from "../../helpers";
import NavLink from "../../../common/router/Link";
import routes from "../../../../routes";
import { DataContext } from "../../DataProvider";

import styles from "../../calendar.less";

const WeekHeader = props => {
  const { t } = useTranslation();
  const { setUrlItemsToStore } = useContext(DataContext);
  return (
    <NavLink
      route={routes.calendarSettings}
      params={{ type: "day", date: prepareDateForUrl(props.date) }}
      render={({ link }) => {
        return (
          <Link
            onClick={() => {
              setUrlItemsToStore({
                date: prepareDateForUrl(props.date),
                view: "day"
              });
            }}
            to={link}
          >
            <div className={styles.weekHeader}>
              <span
                className={cn(styles.weekHeaderDate, {
                  [styles.dateNow]:
                    moment(props.date).date() === moment().date()
                })}
              >
                {`${moment(props.date).date()} ${t(
                  `calendar.week.months.${moment(props.date).month()}`
                )}`}
              </span>
              <span className={styles.weekHeaderDayOfWeek}>
                {" "}
                {t(`calendar.week.weekDays.${moment(props.date).day()}`)}
              </span>
            </div>
          </Link>
        );
      }}
    />
  );
};

export default WeekHeader;
