import React from "react";
import cn from "classnames";
import { Row } from "antd";
import { useTranslation } from "react-i18next";

import formatTooltipValue from "../chart/utils/formatValue/tooltip";

import Icon from '../../../../common/UI/Icon';
import ButtonTransparent from "../../../../common/UI/ButtonTransparent";

import styles from "../widget.less";

function stopPropogate(e) {
  e.stopPropagation();
}

export default function({ inEditMode, data, widget, buttonSettings }) {
  const { t } = useTranslation();

  if (!data) {
    // https://github.com/facebook/react/issues/5355
    return <noscript />;
  }

  return (
    <div
      onTouchStart={stopPropogate}
      onMouseDown={stopPropogate}
      onDrag={stopPropogate}
    >
      <span className={styles.widgetTotals}>
        <span className={styles.widgetTotalsData}>
          {formatTooltipValue(data.sum, widget.get("value"))}
        </span>
        <span className={styles.widgetTotalLabel}>
          {t("reports.widget.modals.common.totals.sum")}
        </span>
      </span>

      <span className={styles.widgetTotals}>
        <span className={styles.widgetTotalsData}>
          {formatTooltipValue(data.avg, widget.get("value"))}
        </span>
        <span className={styles.widgetTotalLabel}>
          {t("reports.widget.modals.common.totals.avg")}
        </span>
      </span>
    </div>
  );
}
