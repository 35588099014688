import React from "react";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import _ from "lodash";
import { DropTarget } from "react-dnd";

import SuggestedItem from "../customComponents/SuggestedItem";
import DND_TARGETS from "../../../../configs/dndTargets";

const dropTarget = DropTarget(
  DND_TARGETS.CALENDAR_EVENT,
  {
    drop(props, monitor) {
      const { recordId } = monitor.getItem();
      props.onDropRecord(recordId);
    },
    canDrop(props) {
      return true;
    }
  },
  function collect(connect, monitor) {
    return {
      isHovering: monitor.isOver(),
      connectDropTarget: connect.dropTarget()
    };
  }
);

export const Masonry = props => {
  const events = props.convertRecordsToEvents(props.records);

  return props.connectDropTarget(
    <div>
      {events.map((event, key) => {
        const recordId = event.recordId;
        const title = event.title;

        const { style } = props.getEventStyle && props.getEventStyle(event);
        const values = event.values;

        return (
          <SuggestedItem
            key={recordId}
            recordId={recordId}
            title={title}
            values={values}
            fields={props.fields}
            style={style}
            onClickRecord={() => recordId && props.onClickRecord(recordId)}
            onDragStart={e => recordId && props.onDragStart(e, recordId)}
          />
        );
      })}
    </div>
  );
};

Masonry.propTypes = {
  records: ImmutablePropTypes.map,
  fields: ImmutablePropTypes.list,
  onDragStart: PropTypes.func,
  onClickRecord: PropTypes.func,
  onDropFromCalendar: PropTypes.func,
  convertRecordToEvents: PropTypes.func,
  getEventStyle: PropTypes.func
};

export default React.memo(dropTarget(Masonry));
