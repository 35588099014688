import React from "react";
import _ from "lodash";
import { Table as FixedDataTable, Column, Cell } from "fixed-data-table-2";
import Dimensions from "../../../../Dimensions";
import cn from "classnames";
import formatTooltipValue from "./utils/formatValue/tooltip";
import Base from "./base";
import style from "./table.less";

import FIELD_TYPES from "../../../../../configs/fieldTypes";
import { connect } from "../../../../StateProvider";
import userSettingsActions from "../../../../../actions/userSettingsActions";
import { getTotalCount } from "./utils/getTotalCount";
import { getCellColor } from "./utils/getCellColor";

const highlightingFieldTypes = [FIELD_TYPES.DROPDOWN]; //статус

class TextCell extends React.Component {
  onClick = (event) => {
    const { rowIndex, columnKey } = this.props;

    event.stopPropagation();
    this.props.onClick({ rowIndex, columnKey });
  }

  render() {
    const {
      isTotalRow,
      valueConfig,
      value,
      color,
      ...props
    } = this.props;

    let style;

    if (isTotalRow) { // total row
      style = {
        fontWeight: "bold",
      };
    } else if (color) {
      style = {
        backgroundColor: color
      };
    };

    return (
      <Cell
        height={props.height}
        width={props.width}
        style={style}
        {...props}
        onClick={this.onClick}
      >
        {formatTooltipValue(value, valueConfig)}
      </Cell>
    );
  }
}

class Table extends React.Component {
  state = {
    labelColumnWidth: this.props.labelColumnWidth || 150,
    dataColumnWidth: null,
    totalColumn: [],
    labels: [],
    datasets: [],
    totalRow: []
  };

  componentDidMount() {
    const { data } = this.props;

    const { labels, datasets, totalColumn, totalRow } = getTotalCount(data.datasets, data.labels, data.split);
    // сетаем в стейт все данные которые будем юзать в компоненте  
    this.setState({ labels, datasets, totalColumn, totalRow });
  };

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    const { datasets, labels } = data;

    const { data: prevData } = prevProps;
    const { datasets: prevDatasets, labels: prevLabels } = prevData;

    if (!_.isEqual(datasets, prevDatasets) || !_.isEqual(labels, prevLabels)) {
      /* данные динамические соответсвенно нужно и тоталы менять */
      const { labels: newLabels, datasets: newDatasets, totalColumn, totalRow } = getTotalCount(datasets, labels, data.split);
      this.setState({ totalRow, totalColumn, datasets: newDatasets, labels: newLabels });
    };
  };

  // resizing all columns by one column
  _onColumnResizeEndCallback = async (newColumnWidth, columnKey) => {
    const { catalog } = this.props;
    const catalogId = catalog.get("id");
    if (columnKey === "labels") {
      await userSettingsActions.setOption({
        catalogId: catalogId,
        viewMode: undefined,
        option: "labelColumnWidth",
        value: newColumnWidth
      });
    };
    this.setState(() => {
      if (columnKey === "labels") {
        return { labelColumnWidth: newColumnWidth };
      } else {
        return { dataColumnWidth: newColumnWidth };
      }
    });
  };

  stopPropogate = e => {
    e.stopPropagation();
  };

  onClick = ({ rowIndex, columnKey }) => {
    const event = {};

    if (rowIndex || rowIndex === 0) {
      event._index = rowIndex;
    }
    if (columnKey || columnKey === 0) {
      event._datasetIndex = columnKey;
    }

    this.props.onClick([event]);
  }

  render() {
    const { data, width, height } = this.props;
    const { labelColumnWidth, dataColumnWidth, totalRow, labels, datasets, totalColumn } = this.state;

    const minWidth = 70;
    const yScrollWidth = 15;

    const axisCount = labels.length || 0,
      splitCount = datasets.length || 0,
      axisName = data.axis && data.axis.get("title"),
      splitName = data.split && data.split.get("title"),
      splitFieldType = data.split && data.split.getIn(["field", "type"]),
      valueName = data.value && data.value.get("title"),
      highlightingFlag = highlightingFieldTypes.includes(splitFieldType),
      currentWidth =
        (width - labelColumnWidth - yScrollWidth) / splitCount,
      calculatedColumnWidth = Math.max(currentWidth, minWidth),
      tableMaxValue = _.max(data.datasets.map(item => _.max(item.data))),
      tableMinValue = _.min(data.datasets.map(item => _.min(item.data)));


    const colorOption = {};
    // let maxValue, minValue, hasPositiveArray, hasNegativeArray, color;

    if (!highlightingFlag) {
      colorOption.maxValue = tableMaxValue;
      colorOption.minValue = tableMinValue;
    };

    return (
      <div
        style={{ height: "100%", position: "relative" }}
        // prevent drag & drop while scrolling
        onTouchStart={this.stopPropogate}
        onMouseDown={this.stopPropogate}
        onDrag={this.stopPropogate}
      >
        <FixedDataTable
          rowsCount={axisCount}
          columnCount={splitCount}
          firstColumnWidth={labelColumnWidth}
          rowHeight={35} // @RECORD_DATA_HEADER
          headerHeight={35} // @RECORD_DATA_HEADER
          onColumnResizeEndCallback={this._onColumnResizeEndCallback}
          width={width}
          height={height}
          // onColumnReorderEndCallback={this._onColumnReorderEndCallback}
          isColumnResizing={false}
          isColumnReordering={false}
          touchScrollEnabled={true}
          keyboardScrollEnabled={true}
          className={style.table}
          onRowClick={(event, rowIndex) => this.onClick({ rowIndex })}
        >
          <Column
            key={"labels"}
            columnKey={"labels"}
            width={this.state.labelColumnWidth}
            minWidth={minWidth}
            fixed={true}
            isResizable={true}
            allowCellsRecycling={true}
            pureRendering={false}
            header={
              <Cell className={style.labelsColumnCell}>
                {axisName && splitName
                  ? `${axisName}/${splitName}`
                  : axisName || splitName}
              </Cell>
            }
            cell={({ rowIndex, ...props }) => {
              return data.axis ? (
                <Cell
                  {...props}
                  className={cn(
                    "fixedDataTable_fixedColumn",
                    style.labelsColumnCell,
                    { [style.labelTotal]: totalRow.length && rowIndex == labels.length - 1 }
                  )}
                >
                  {labels[rowIndex]}
                </Cell>
              ) : (
                <Cell
                  {...props}
                  className={cn(
                    "fixedDataTable_fixedColumn",
                    style.labelsColumnCell
                  )}
                >
                  {valueName}
                </Cell>
              );
            }}
          />

          {datasets.map((dataRecord, index) => {
            // добавляем в конец total count записей по колонке
            const addTotal = [...dataRecord.data, totalRow[index]];
            const rowCount = addTotal.length;
            const columnCount = datasets.length;
            const isTotalColumn = totalColumn.length && index == columnCount - 1;

            return (
              <Column
                key={index}
                columnKey={index} //columnKey={dataRecord.label}
                width={dataColumnWidth || calculatedColumnWidth}
                minWidth={minWidth}
                allowCellsRecycling={true}
                pureRendering={false}
                isResizable={true}
                isReorderable={true}
                header={
                  <Cell
                    className={cn({ [style.labelTotal]: isTotalColumn })}
                    onClick={() => this.onClick({ columnKey: index })}
                  >
                    {data.split
                      ? dataRecord.label
                        ? dataRecord.label
                        : null
                      : valueName}
                  </Cell>
                }
                cell={({ rowIndex, ...props }) => {
                  const isTotalRow = totalRow.length && rowIndex == rowCount - 1;
                  // checking for arrays content
                  let cellColorOptions = {};
                  if (highlightingFlag || isTotalColumn) {
                    cellColorOptions.maxValue = _.max(dataRecord.data);
                    cellColorOptions.minValue = _.min(dataRecord.data);
                    cellColorOptions.colorProps = dataRecord.backgroundColor;
                  } else {
                    cellColorOptions = colorOption;
                  };

                  cellColorOptions.value = addTotal[rowIndex];

                  const colorCell = getCellColor(cellColorOptions);

                  return <TextCell
                    {...props}
                    value={addTotal[rowIndex]}
                    rowIndex={rowIndex}
                    isTotalRow={isTotalRow}
                    valueConfig={data.value}
                    onClick={this.onClick}
                    color={colorCell}
                  />
                }
                }
              />
            );
          })}
        </FixedDataTable>
      </div>
    );
  }
}

// demension for transfer containerWidth, containerHeight to props
const TableDimensions = Dimensions({ debounce: 50 })(Table);
class WidgetChartTableComponent extends React.Component {
  state = {
    componentJustChanged: true
  };

  componentDidMount() {
    // fix table resizing after changing widget when opening report
    setTimeout(() => {
      this.setState({ componentJustChanged: false });
    }, 1);
  }

  render() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        {!this.state.componentJustChanged ? (
          // null
          <TableDimensions {...this.props} />
        ) : null}
      </div>
    );
  }
}

class WidgetChartTable extends Base {
  formatAxisLabel(axis) {
    return super.formatAxisLabel(axis);
  }

  getChartComponent() {
    return WidgetChartTableComponent;
  }

  /*   getReportData() {  
      // сортировка которая в окне конфигурации
      const reportData = super.getReportData();
      if (this.getAxisSortFn()) {
        return reportData;
      }
  
      // basic view direction
      const { widget, fields } = this.props;
      return getAxisDirection(reportData, widget.get("axis"), fields);
    } */
}

export default connect(WidgetChartTable,
  ["userSettings"],
  (props, state) => {
    const catalogId = props.catalog && props.catalog.get("id");
    const labelColumnWidth = state.userSettings && state.userSettings.getIn(["catalogs", catalogId, "options", "labelColumnWidth", "value"]);
    return {
      labelColumnWidth,
      ...props,
      ...state
    };
  });;
