import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Mention, MentionsInput } from "react-mentions";
import ResizeObserver from "resize-observer-polyfill";
import { withRouter } from "react-router";
import { connect } from "../../../../../../StateProvider";
import SCENE_CONTAINER from "../../../../../../../configs/sceneContainer";

import ButtonTransparent from "../../../../../../common/UI/ButtonTransparent";

const MentionsInputContainer = props => {
  const inputRef = useRef(null);

  const refContainer = props.refContainer;

  const [value, setValue] = useState("");
  const [size, setSize] = useState({});

  const minRows = 1;
  const maxRows = 20;

  const setSizeDebounce = _.debounce((width, height) => {
    setSize({ width, height });
  }, 300);

  const handleResize = entries => {
    for (const entry of entries) {
      const { width, height } = entry.contentRect;
      setSizeDebounce(width, height);
    }
  };

  useEffect(() => {
    inputRef.current.addEventListener("paste", e => {
      const files = e.clipboardData.files;
      const items = [].slice.call(files).filter(function(item) {
        // Filter the image items only
        return item.type.indexOf("image") !== -1;
      });
      if (items.length === 0) {
        return;
      }

      let file = items[0];
      if (file) {
        // remane file for uniq
        file = new File([file], `image_${new Date().toISOString()}.png`);
        props.handleFile({ file });
      }
    });
  }, []);

  useEffect(() => {
    inputRef.current.focus();
    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(refContainer.current);
    setValue(props.value || "");
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(
    () => {
      if (props.value === "" || !props.value) {
        setValue("");
      }
    },
    [props.value]
  );

  useEffect(
    () => {
      inputRef.current.focus();
    },
    [props.shouldFocusInput]
  );

  const onChangeDebounce = _.debounce(
    value => {
      props.onChange(value);
    },
    100,
    { maxWait: 300 }
  );

  const onChange = e => {
    const value = e.target.value;
    setValue(value);
    onChangeDebounce(value);
  };

  const clickMentions = e => {
    setValue(prevValue => {
      if (prevValue == "") {
        return prevValue + "@";
      } else if (!prevValue) {
        return "@";
      } else {
        return prevValue + " @";
      }
    });
    inputRef.current.focus();
  };

  const onKeyDown = e => {
    props.onKeyDown && props.onKeyDown(e);

    if (e.key === "Tab" && !e.shiftKey) {
      e.preventDefault();
      document.execCommand("insertText", false, "\t");
      return false;
    }
  };

  const {
    customSuggestionsContainer,
    renderSuggestion,
    mentions,
    placeholder,
    classNames,
    wrapperClassName,
    shouldFocusInput
  } = props;
  const suggestions = args => {
    const addArgs = {
      ...args,
      sizeContainer: size
    };
    return customSuggestionsContainer(addArgs);
  };

  return (
    <React.Fragment>
      <ButtonTransparent
        className={props.msgerInputareaMentionsButtonClassName}
        onClick={clickMentions}
      >
        <strong>@</strong>
      </ButtonTransparent>
      <div className={wrapperClassName}>
        <MentionsInput
          autoFocus={false}
          inputRef={inputRef}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          customSuggestionsContainer={suggestions}
          maxRows={maxRows}
          minRows={minRows}
          classNames={classNames}
          suggestionsPortalHost={props.suggestionsPortalHost}
        >
          <Mention
            markup={"<@__id__;__display__/@>"}
            data={mentions}
            appendSpaceOnAdd
            renderSuggestion={renderSuggestion}
            className={classNames.mentions__mention}
          />
        </MentionsInput>
      </div>
    </React.Fragment>
  );
};

export default MentionsInputContainer;
