import React from "react";
import Immutable from "immutable";
import PropTypes from "prop-types";
import { Checkbox, Empty } from "antd";
import { withTranslation } from "react-i18next";

import ObjectItem from "./ObjectItem";
import ObjectModeTabs from "./ObjectModeTabs";
import ObjectItemNew from "./ObjectItemNew";

import { OBJECT_MODS } from "../../../../configs/fieldTypes";

import fieldsStyles from "../fields.less";
import styles from "./ObjectField.less";

class ObjectField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isNew: !(this.props.items && this.props.items.size) };
  }

  itemsHaveFields = items => {
    const haveFields = items.reduce((previousValue, currentValue) => {
      const hasFields =
        currentValue &&
        currentValue.get("fields") &&
        currentValue.get("fields").size;
      return previousValue || !!hasFields;
    }, false);
    return haveFields;
  };

  componentDidUpdate(prevProps) {
    const { items, view } = this.props;
    const { items: prevItems } = prevProps;

    // автовыбор типа отображения полей связанного каталога
    // в зависимости от количества расширенных полей
    if (prevItems && prevItems !== items) {
      const haveFields = this.itemsHaveFields(items);
      const hadFields = prevItems && this.itemsHaveFields(prevItems);

      if (!hadFields && haveFields && view == OBJECT_MODS.LIST) {
        this.props.onChange({ mode: OBJECT_MODS.CARDS });
      }

      if (hadFields && !haveFields) {
        this.props.onChange({ mode: OBJECT_MODS.LIST });
      }
    }
  }

  onChangeCheckbox = event => {
    const value = event.target.checked;
    const name = event.target.name;
    this.props.onChange({
      [name]: value
    });
  };

  onChangeMode = event => {
    const value = event.target.value;
    const name = event.target.name;
    this.props.onChange({ [name]: value });
    if (value === OBJECT_MODS.LIST) {
      const newItems = this.props.items.map(item => {
        return item.set("fields", Immutable.List());
      });
      this.props.onChangeItems(newItems);
    }
  };

  render() {
    const { editingCatalog, multiselect, disabled, onChangeItems, isAllCatalogs } = this.props;
    let { items, view } = this.props;

    const list = items.map((item, index) => {
      return (
        <ObjectItem
          key={index}
          editingCatalog={editingCatalog}
          item={item}
          view={view}
          disabled={disabled}
          onChange={newItem => {
            const newItems = items.setIn([index], newItem);
            onChangeItems(newItems);
          }}
          onRemove={() => {
            const newItems = items.splice([index], 1);
            if (!(newItems && newItems.size)) {
              this.setState({ isNew: true });
            }
            onChangeItems(newItems);
          }}
        />
      );
    });

    let newItem = !disabled ? (
      this.state.isNew ? (
        <ObjectItem
          key={"newItem"}
          autoFocus={false}
          disabled={disabled}
          onChange={newItem => {
            const newItems = items.push(newItem);
            this.setState({ isNew: false });
            onChangeItems(newItems);
          }}
          onRemove={() => {
            this.setState({ isNew: false });
          }}
        />
      ) : (
        <ObjectItemNew
          key={"newItem"}
          onClick={() => {
            this.setState({ isNew: true });
          }}
        />
      )
    ) : null;

    const allCatalogs = (
      <label className={styles.itemsLabel}>
        {this.props.t("fieldTypes.object.item.cannotChanged")}
      </label>
    );

    return (
      <div>
        <ObjectModeTabs view={view} onChange={this.onChangeMode} />
        <label className={styles.itemsLabel}>
          {this.props.t("fieldTypes.object.item.label")}
        </label>
        {list}
        {!isAllCatalogs ? newItem : allCatalogs}

        <div className={styles.extraOptoins}>
          <div>
            <Checkbox
              className={fieldsStyles.checkboxInline}
              disabled={disabled}
              name="multiselect"
              checked={multiselect}
              onChange={this.onChangeCheckbox}
            >
              {this.props.t("fieldTypes.object.multiselect")}
            </Checkbox>
          </div>
        </div>
      </div>
    );
  }
}

ObjectField.propTypes = {
  editingCatalog: PropTypes.object,
  items: PropTypes.object,
  accessOnly: PropTypes.bool,
  multiselect: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default withTranslation()(ObjectField);
