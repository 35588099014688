import React from "react";
import cn from "classnames";
import { Modal, Row } from "antd";

import Icon from '../../Icon';
import ButtonTransparent from "../../ButtonTransparent";
import ButtonClose from "../../ButtonClose";
import ReactResizeDetector from "react-resize-detector";
import { withTranslation, useTranslation } from "react-i18next";

import { ControlledEditor, monaco } from "@monaco-editor/react";

import styles from "./controls.less";




function createConfigIfThereIsNoInternet() {
  const online = window.navigator.onLine;
  if (!online) {
    monaco.config({
      paths: {
        vs: "vs",
      },
    });
  };
};


async function createMonacoTheme() {
  return await monaco.init().then(monaco => {
    // https://github.com/microsoft/monaco-editor/blob/master/test/playground.generated/customizing-the-appearence-exposed-colors.html
    monaco.editor.defineTheme("bpium", {
      base: "vs",
      inherit: true,
      rules: [],
      colors: {
        "editorLineNumber.foreground": "#b6b8ba"
        /*'editorGutter.background': '#f7f8fa',*/
      }
    });

    return monaco;
  });
};

class EditorConfig {
  constructor(config) {
    this.language = "javascript";
    this.options = {
      fontSize: 13,
      colors: {
        "editor.background": "#EDF9FA",
        "editorLineNumber.foreground": "#aaaaaa"
      },
      minimap: {
        enabled: config.minimap
      },
      scrollbar: {
        horizontalScrollbarSize: 5,
        verticalScrollbarSize: 5
      },
      mouseWheelZoom: true,
      renderLineHighlight: config.renderLineHighlight || "all",
      editorClassName: styles.editor
    };
    if (config.lineNumbersMinChars) {
      this.options.lineNumbersMinChars = config.lineNumbersMinChars;
    }
  }
}

const fullEditorConfig = new EditorConfig({
  name: "full",
  renderLineHighlight: "line"
});

const controlEditorConfig = new EditorConfig({
  name: "control",
  renderLineHighlight: "none",
  minimap: false,
  lineNumbersMinChars: 3
});

const EditorHeader = ({ functional, onCancel }) => {
  const { t } = useTranslation();
  return (
    <Row
      type="flex"
      justify="space-between"
      align="middle"
      className={styles.codeEditorModalHeader}
    >
      <h2>{t("record.fields.code.editorTitle")}</h2>
      <div>
        {functional.map(button => {
          return (
            <ButtonTransparent
              onClick={button.method}
              title={button.title}
              className={styles.dashboardButton}
            >
              <Icon type={button.icon} />
            </ButtonTransparent>
          );
        })}
      </div>
      <ButtonClose onClick={onCancel} className={styles.codeEditorModalClose} />
    </Row>
  );
};

class CodeEditor extends React.Component {
  constructor(props) {
    super(props);
    this.dashboard = [];
    this.state = {
      value: this.props.value,
      focus: false,
      modalVisible: false,
      width: null
    };
  }

  toggleModal = () => this.setState({ modalVisible: !this.state.modalVisible });

  focusHandler = b => this.setState({ focus: b });

  onCancel = () => {
    this.toggleModal();
  };

  onChange = value => {
    this.setState({ value }, () => this.props.onChange(this.state.value));
  };

  editorDidMount = (_, monaco) => {
    this.monaco = monaco;
    monaco.onDidFocusEditorText(() => this.focusHandler(true));
    monaco.onDidBlurEditorText(() => this.focusHandler(false));
  };

  async componentDidMount() {
    createConfigIfThereIsNoInternet();
    await createMonacoTheme();
    this.setState({ monacoThemeReady: true });
  }

  render() {
    const controlClassName = cn(
      { [styles.focus]: this.state.focus },
      styles.controlEditorWrapper
    );

    if (!this.state.monacoThemeReady) {
      return null;
    }

    const subType = this.props.subType;

    const value = this.state.value != this.props.value ? this.props.value : this.state.value;

    return (
      <React.Fragment>
        <ReactResizeDetector
          skipOnMount={true}
          handleWidth={true}
          handleHeight={true}
          onResize={(width, height) => this.monaco.layout({ width, height })}
        >
          <div
            className={controlClassName}
            style={this.props.rows ? { height: this.props.rows * 18 } : {}}
          >
            {!this.state.modalVisible && (
              <ControlledEditor
                width={"100%"}
                options={{
                  ...controlEditorConfig.options,
                  readOnly: this.props.readOnly
                }}
                editorDidMount={this.editorDidMount}
                value={value}
                onChange={(_, value) => this.onChange(value)}
                theme="bpium"
                language={subType || controlEditorConfig.language}
              />
            )}
            <ButtonTransparent
              title={this.props.t("record.fields.code.open")}
              onClick={this.toggleModal}
              className={styles.codeEditorButton}
            >
              <Icon type={"icon interface-22"} />
            </ButtonTransparent>
          </div>
        </ReactResizeDetector>
        <Modal
          open={this.state.modalVisible}
          maskClosable={true}
          closable={false}
          width={"auto"}
          onCancel={this.onCancel}
          wrapClassName="large-modal-bpium"
          footer={null}
        >
          <div className={styles.codeEditorModal}>
            <EditorHeader
              functional={this.dashboard}
              onCancel={this.onCancel}
            />
            <div className={styles.codeEditorModalBody}>
              <ControlledEditor
                width={"100%"}
                options={{
                  ...fullEditorConfig.options,
                  readOnly: this.props.readOnly
                }}
                value={value}
                onChange={(_, value) => this.onChange(value)}
                theme="bpium"
                language={subType || controlEditorConfig.language}
              />
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withTranslation(
  undefined, { withRef: true }
)(CodeEditor);
