import React from 'react';

import UserAvatarMenu from '.';
import { connect } from '../../../../StateProvider';
import calculateRemainsLicense from '../../../../../utils/calculateRemainsLicense';
import calculateRemainsRecords from '../../../../../utils/calculateRemainsRecords';


const UserAvatarContainer = (props) => {
    const recordsLimit = props.licenseInfo.get("records");
    const licenseDate = props.licenseInfo.get("expires")

    const recordsCount = props.companyInfo.get("recordsCount");

    const remainsRecords = calculateRemainsRecords(recordsLimit, recordsCount);
    const remainsLicense = calculateRemainsLicense(licenseDate);

    const { user } = props;

    const userName = user && user.get("title");
    const styleUserMenuBadge = {
        boxShadow: "none",
        background: "rgb(255 0 0 / 71%)",
        width: "10px",
        height: "10px",
        top: "2px",
        right: "5px"
    };

    if (!userName) {
        return null;
    };

    return <UserAvatarMenu
        styleUserMenuBadge={styleUserMenuBadge}
        userName={userName}
        recordsEnds={remainsRecords.redTitlePercentage}
        licenseEnds={remainsLicense.redTitleDate}
    />
};

export default connect(
    UserAvatarContainer,
    {
        user: ["user"],
        companyInfo: ["company"],
        licenseInfo: ["license"]
    }
);