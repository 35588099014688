import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "antd";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import _ from "lodash";

import FileViewer from "../../../../../common/UI/ControlList/controls/fileViewer";
import ChatStatusMessage from "../../ChatStatusMessage";
import Icon from "../../../../../common/UI/Icon";
import colorFromString from "../../../../../../utils/colorFromString";
import AvatarUser from "../../../../../common/UI/AvatarUser";
import { formatDate, formatTime } from "../../../../../../utils/formatDate";
import ReplyMessage from "../../ReplyMessage";
import { antImageViewer } from "../../../../../common/UI/ControlList/controls/fileViewer/viewers/Image/AntImage";
import TextMessage from "../TextMessage";

import styles from "./message.less";

const Message = ({
  id,
  t,
  isMe,
  key,
  message,
  removeMessage,
  getReplyMessage,
  editMessage,
  lastMessage,
  isPreviousMessageSameUser,
  i18n,
  resendMessage,
  cancelResendMessage,
  copyText,
  openRecord
}) => {
  const [items, setItems] = useState([]);
  const [isHover, setIsHover] = useState(false);
  const [titleTime, setTitleTime] = useState();

  const { language } = i18n;

  const authorName = message.getIn(["author", "recordTitle"]);
  const catalogTitle = message.getIn(["author", "catalogTitle"]);
  const icon = (
    <Icon type={`icon ${message.getIn(["author", "catalogIcon"])}`} />
  );
  const text = message.get("text");
  const date = message.get("createdDate");
  const reply = message.get("reply");
  const sending = message.get("sending");
  const updatedDate = message.get("updatedDate");

  const attachments = message.get("attachments").toJS();
  const isSameUser = isPreviousMessageSameUser(message);
  const sendingTime = formatTime(date);
  const highlightedText = useRef(null);

  const onClickReplyMessage = e => {
    return getReplyMessage(id);
  };

  const onClickEditMessage = () => {
    return editMessage(id);
  };

  const onClickRemoveMessage = () => {
    return removeMessage(id);
  };

  const onClickCopyMessageText = () => {
    return copyText(id, highlightedText.current);
  };

  useEffect(() => {
    const saveSelection = () => {
      setTimeout(() => {
        highlightedText.current = window.getSelection().toString();
      }, 100);
    };
    document.addEventListener("mouseup", saveSelection);
    return () => document.removeEventListener("mouseup", saveSelection);
  }, []);

  useEffect(
    () => {
      const items = [];
      items.push({
        label: (
          <span className={styles.msgInfoOptionsMessage}>
            <Icon
              type={"icon interface-2"}
              className={styles.msgInfoOptionsMessageIcon}
            />
            {t("record.chat.message.reply")}
          </span>
        ),
        key: 0,
        onClick: onClickReplyMessage
      });

      if (text) {
        items.push({
          label: (
            <span className={styles.msgInfoOptionsMessage}>
              <Icon
                type={"icon files-28"}
                className={styles.msgInfoOptionsMessageIcon}
              />
              {t("record.chat.message.copy")}
            </span>
          ),
          key: 1,
          onClick: onClickCopyMessageText
        });
      }

      if (isMe) {
        items.push({
          label: (
            <span className={styles.msgInfoOptionsMessage}>
              <Icon
                type={"icon edition-30"}
                className={styles.msgInfoOptionsMessageIcon}
              />
              {t("record.chat.message.edit")}
            </span>
          ),
          key: 2,
          onClick: onClickEditMessage
        });
        items.push({
          label: (
            <span className={styles.msgInfoOptionsMessage}>
              <Icon
                type={"icon edition-41"}
                className={styles.msgInfoOptionsMessageIcon}
              />
              {t("record.chat.message.remove")}
            </span>
          ),
          key: 3,
          onClick: onClickRemoveMessage
        });
      }
      setItems(items);
    },
    [isMe]
  );

  useEffect(
    () => {
      let title = `${formatDate(date, language, true)}`;
      if (updatedDate) {
        title = title.concat(
          `\n${t("record.chat.message.edited")}: ${formatDate(
            updatedDate,
            language,
            true
          )}`
        );
      }
      setTitleTime(title);
    },
    [date, updatedDate]
  );

  const HoverOn = () => {
    if (!isMe) {
      setIsHover(true);
    }
  };
  const HoverOff = () => {
    if (!isMe) {
      setIsHover(false);
    }
  };

  const onClickAuthor = () => {
    const authorParamsLink = {
      catalogId: message.getIn(["author", "catalogId"]),
      recordId: message.getIn(["author", "recordId"])
    };
    openRecord(authorParamsLink);
  };

  const calcSizes = (files, file, shipped) => {
    const separator = files.length > 2 ? 4 : 2;

    let widthOriginal = file.metadata?.size?.width;
    let heightOriginal = file.metadata?.size?.height;

    const size = {
      width: widthOriginal / separator,
      height: heightOriginal / (separator * 1.5)
    };

    if (files.length > 2) {
      size.width = widthOriginal / (separator * 1.3);
      size.height = heightOriginal / (separator * 1.3);
    }

    if (widthOriginal <= 440 && heightOriginal <= 400) {
      size.width = widthOriginal;
      size.height = heightOriginal;
    }

    if (files.length > 4) {
      size.width = 100;
      size.height = 100;
    }

    if (!shipped) {
      size.width = 50;
      size.height = 50;
    }
    return size;
  };

  return (
    <div
      onMouseLeave={HoverOff}
      onMouseEnter={HoverOn}
      key={key}
      className={classNames(styles.msg, {
        [styles.msgSameUser]: isSameUser,
        [styles.msgIsMe]: isMe,
        [styles.msgLastMessage]: lastMessage
      })}
    >
      {/* ну тут типо если предыдущее сообщение отправил он же условие не показывать аватар плюс если МЫ отправили */}
      {!isMe && (
        <React.Fragment>
          {!isSameUser ? (
            <React.Fragment>
              {authorName ? (
                <AvatarUser
                  onClick={onClickAuthor}
                  icon={catalogTitle !== "Сотрудники" && icon}
                  userName={authorName}
                  className={styles.avatar}
                />
              ) : (
                <Icon className={styles.avatarScript} type="icon content-36" />
              )}
            </React.Fragment>
          ) : (
            <div className={styles.emptyAvatar} />
          )}
        </React.Fragment>
      )}
      <Dropdown
        menu={{
          items
        }}
        trigger={["contextMenu"]}
      >
        <div className={styles.msgBubble}>
          <div className={isMe ? styles.msgContentMe : styles.msgContent}>
            {(sending == "error" || sending == false) && (
              <ChatStatusMessage
                message={message}
                t={t}
                resendMessage={resendMessage}
                cancelResendMessage={cancelResendMessage}
              />
            )}
            {updatedDate && (
              <span className={styles.msgInfoEdited}>
                {t("record.chat.message.edited")}
              </span>
            )}
            <time title={titleTime} className={styles.msgInfoTime}>
              {sendingTime}
            </time>
            {!isSameUser &&
              !isMe && (
                <div className={styles.msgInfoName}>
                  {authorName && (
                    <strong
                      onClick={onClickAuthor}
                      style={{
                        color: colorFromString.colorUserIcon(authorName)
                      }}
                    >
                      {authorName}
                    </strong>
                  )}
                </div>
              )}
            {reply &&
              !!reply.size && (
                <ReplyMessage
                  author={reply.get("author")}
                  text={
                    !reply.get("deleted")
                      ? reply.get("text")
                      : t("record.chat.message.messageDeleted")
                  }
                  attachments={reply.get("attachments")}
                  forMessage={true}
                />
              )}
            {text ? (
              <TextMessage
                text={text}
                updatedDate={updatedDate}
                openRecord={openRecord}
              />
            ) : null}
            {attachments.length > 0 && (
              <div
                className={
                  isMe ? styles.msgInfoAttachmentMe : styles.msgInfoAttachment
                }
              >
                <FileViewer
                  files={attachments}
                  calcSizes={calcSizes}
                  componentType={antImageViewer}
                  shipped={true}
                  containerClassName={
                    isMe
                      ? styles.attachmentImagesContainerMe
                      : styles.attachmentImagesContainer
                  }
                  wrapperClassName={styles.attachmentImagesWrapper}
                  imageCloseBtnClassName={styles.fileImageRemoveBtn}
                  imageClassName={styles.antImageStyle}
                />
              </div>
            )}
          </div>
        </div>
      </Dropdown>
      {isHover &&
        !isMe && (
          <a onClick={onClickReplyMessage} className={styles.msgInfoReply}>
            <Icon className={styles.replyArrow} type="icon communication-48" />
          </a>
        )}
    </div>
  );
};

export default withTranslation()(Message);
