import { Button, Checkbox, Dropdown, Popover, Upload } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';
import { useEffect } from 'react';
import recordActions from '../../../actions/recordActions';
import useStartTimer from '../../../utils/hooks/useStartTimer.hook';
import { connect } from '../../StateProvider';

const AutoRefreshButton = ({ timeUntilTimerEnd, btnRefresh, userSettingsTimer, sceneId }) => {

    useEffect(() => {
        userSettingsTimer && recordActions.setTimeUntilTimerEnd(sceneId, Date.now() + 10 * 60 * 1001);
    }, []);

    useEffect(() => {
        const dataNow = userSettingsTimer ? Date.now() + 10 * 60 * 1001 : null;
        recordActions.setTimeUntilTimerEnd(sceneId, dataNow);
    }, [userSettingsTimer]);

    useStartTimer(timeUntilTimerEnd, userSettingsTimer, btnRefresh);
    return null;
};

export default connect(AutoRefreshButton, {
    userSettings: ["userSettings", "catalogs"],
}, (props, { userSettings }) => {
    const timeUntilTimerEnd = props.scene && props.scene.get("timeUntilTimerEnd");
    const sceneId = props.scene && props.scene.get("sceneId");
    return {
        userSettingsTimer: userSettings.getIn([
            props.catalogId,
            "viewMode",
            "table",
            "options",
            "timer",
            "value"
        ]),
        sceneId,
        timeUntilTimerEnd,
        ...props
    }
});