/* import getRouteParams from "./getRouteParams";

export default function link(location, route, params = {}) {
  const { match, route: _route } = getRouteParams(location) || {
    match: { params: {}, route: { path: "" } }
  };
  route = route || _route;
  
  return route.path
    .split("/")
    .map(path => {
      if (path[0] === ":") {
        const paramName = path.slice(1);

        if ( params[paramName] ) {
          return params[paramName];
        } else if ( match.params[paramName] && match.params[paramName][0] !== ":" ) {
          return match.params[paramName];
        } else {
          return route.defaults[paramName];
        }
      }
      return path;
    })
    .join("/");
} */

import _ from "lodash";
import getRouteParams from "./getRouteParams";

export default function link(location, route, params = {}) {
  const { match, route: _route } = getRouteParams(location) || {
    match: { params: {}, route: { path: "" } }
  };

  const curentCatalogId = _.get(match, ["params", "catalogId"]);
  const targetCatalogId =
    _.get(params, ["catalogId"]) ||
    _.get(match, ["params", "catalogId"]) ||
    _.get(route, ["defaults", "catalogId"]);

  const sameCatalog =
    curentCatalogId && targetCatalogId && curentCatalogId == targetCatalogId;
  const addQueryParams = sameCatalog;

  let search = location && location.search;

  route = route || _route;
  const link = route.path
    .split("/")
    .map(path => {
      if (path[0] === ":") {
        const paramName = path.slice(1);
        return (
          params[paramName] ||
          match.params[paramName] ||
          route.defaults[paramName]
        );
      }
      return path;
    })
    .join("/");

  return addQueryParams
    ? {
        pathname: link,
        search: search
      }
    : { pathname: link };
}
