import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import Immutable from "immutable";
import React, { Component } from "react";
import { matchPath, withRouter } from "react-router";

import batchRecordsActions from "../../../actions/batchRecordsActions";
import apiActions from "../../../actions/apiActions";
import routes from "../../../routes";
import getLink from "../../common/router/getLink";
import RecordsBatchUpdateRecord from "./RecordsBatchUpdateForm";
import { alert } from "../../common/Modal";
import { connect } from "../../StateProvider";

class RecordsBatchUpdateControler extends Component {
  static propTypes = {
    viewId: PropTypes.string,
    catalogId: PropTypes.string.isRequired,
    catalog: ImmutablePropTypes.map.isRequired,
    filter: ImmutablePropTypes.map.isRequired,
    sectionId: PropTypes.string,
    scene: ImmutablePropTypes.map.isRequired,
    sceneId: PropTypes.string.isRequired,
    batchUpdateRecord: ImmutablePropTypes.map.isRequired,
    openRecordsInModal: PropTypes.bool,
    onClose: PropTypes.func
  };

  onStartUpdate = () => {
    const { catalogId, scene, sceneId, viewId, t } = this.props;

    const recordsCount = scene && scene.get("recordsCount");

    const alreadyOnResultTab = matchPath(this.props.location, {
      path: routes.batchUpdateResult.path
    });

    alert({
      headerText: t("batchUpdateRecords.confirmation.headerText"),
      text:
        t("batchUpdateRecords.confirmation.text") +
        t("record.groupRecords.count", { count: +recordsCount }) +
        "?",
      okText: t("batchUpdateRecords.confirmation.okText"),
      onOk: () => {
        batchRecordsActions.startBatchUpdateRecords(sceneId, catalogId, viewId);

        if (!alreadyOnResultTab) {
          this.props.history.push(
            getLink(this.props.location, routes.batchUpdateResult)
          );
        }
      }
    });
  };

  onResumeUpdate = () => {
    const { catalogId, sceneId, viewId } = this.props;
    batchRecordsActions.startBatchUpdateRecords(sceneId, catalogId, viewId);
  };

  onPauseUpdate = () => {
    const { sceneId } = this.props;
    batchRecordsActions.pauseBatchRecords(sceneId);
  };

  getRecords = () => {
    /* создать флаг о загрузке айди записей и блокировать элементы в этот момент */
    const { catalogId, sceneId, viewId } = this.props;
    batchRecordsActions.loadBatchRecords(sceneId, catalogId, viewId);
  };

  getRecordTab = () => {
    if (
      matchPath(this.props.location.pathname, {
        path: routes.changeForm.path,
        exact: true,
        strict: false
      })
    ) {
      return routes.changeForm;
    } else if (
      matchPath(this.props.location.pathname, {
        path: routes.resultTrack.path,
        exact: true,
        strict: false
      })
    ) {
      return routes.resultTrack;
    }
  };

  componentDidMount() {
    this.getRecords();
  }

  componentWillUnmount() {
    const { catalogId, sceneId } = this.props;
    batchRecordsActions.clearBatch(catalogId, sceneId);
    apiActions.getCatalog({ catalogId });
  }

  componentDidUpdate(prevProps) {
    const { scene, filter } = this.props;
    const { scene: prevScene, filter: prevFilter } = prevProps;

    /* в случаях удаления записи должен быть произведен редирект, тк сам компонент записи недоступен */
    if (!scene && prevScene) {
      this.props.history.push(getLink(this.props.location, routes.records));
    }

    if (prevFilter && prevFilter !== filter) {
      this.getRecords();
    }
  }

  render() {
    const {
      batchUpdateRecord,
      scene,
      sceneId,
      catalog,
      catalogId,
      onClose
    } = this.props;

    return batchUpdateRecord ? (
      <RecordsBatchUpdateRecord
        sceneId={sceneId}
        catalogId={catalogId}
        scene={scene}
        catalog={catalog}
        batchUpdateRecord={batchUpdateRecord}
        onStartUpdate={this.onStartUpdate}
        onPauseUpdate={this.onPauseUpdate}
        onResumeUpdate={this.onResumeUpdate}
        onClose={onClose}
      />
    ) : null;
  }
}

export default connect(
  withRouter(RecordsBatchUpdateControler),
  {
    scenes: ["scenes"],
    catalogs: ["catalogs"],
    records: ["records"]
  },
  (props, { scenes, records, catalogs }) => {
    const { sceneId, catalogId } = props;

    const scene = scenes.get(sceneId);
    const batchUpdateRecordId = scene.getIn(["params", "recordId"]);
    const filter = scene.getIn(["data", "filters"]);

    const catalog = catalogs.get(catalogId);
    const batchUpdateRecord = records.getIn([catalogId, batchUpdateRecordId]);

    return {
      ...props,
      scene,
      catalog,
      batchUpdateRecord,
      filter
    };
  }
);
