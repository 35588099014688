import React, { Component } from "react";
import { Input } from "antd";
import cn from "classnames";
import _ from "lodash";
import { withTranslation } from "react-i18next";

import Item from "./Item";

import styles from "./OverlayDropdown.less";

class Menu extends Component {
  state = {
    filterData: this.props.items
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.items && nextProps.items !== this.props.items) {
      this.setState({
        filterData: nextProps.items
      });
    }
  }

  search = e => {
    if (this.props.filterFn) {
      const filteredArrId = this.props.filterFn(
        this.props.items.map(i => i.key),
        e.target.value
      );
      let arr = [];
      this.props.items.forEach(item => {
        filteredArrId.forEach(id => {
          if (item.key === id) {
            arr.push(item);
          }
        });
      });

      this.setState({
        filterData: arr
      });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <ul
        className={cn(
          this.props.vertical.menu,
          styles.menu,
          "ant-dropdown-menu ant-dropdown-menu-vertical"
        )}
      >
        <li className={styles.itemSearch}>
          <Input
            type="search"
            className={styles.input}
            placeholder={t("section.search")}
            onChange={this.search}
            onClick={e => e.stopPropagation()}
          />
        </li>
        {this.state.filterData.map(item => {
          return (
            <Item
              key={item.key}
              item={item}
              vertical={this.props.vertical}
              route={this.props.route}
              canDrag={this.props.canDrag}
              onMoveItem={this.props.onMoveItem}
              onDragEnd={this.props.onDragEnd}
              dragType={this.props.dragType}
              params={this.props.params}
            />
          );
        })}
      </ul>
    );
  }
}

export default withTranslation()(Menu);
