import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import React, { useState } from "react";
import { Row } from "antd";
import _ from "lodash";
import { useTranslation } from 'react-i18next';

import Icon from '../../../common/UI/Icon';
import sceneActions from "../../../../actions/sceneActions";
import { connect } from "../../../StateProvider";
import { usePrepareMessages } from "../../services/hooks";

import styles from "../../styles/styles.less";

const ResultList = ({
  errorRecords,
  wrapperClassname,
  catalogIcon,
  catalogId,
  ...props
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
  const messages = usePrepareMessages(errorRecords);

  const openRecord = recordId =>
    sceneActions.openRecord({ catalogId, recordId });

  return (
    messages && (
      <div className={wrapperClassname}>
        <Row
          type="flex"
          align="middle"
          className={styles.resultListTitle}
          onClick={() => setIsOpen(i => !i)}
        >
          <div className={styles.sectionToggle}>
            <Icon className={styles.headerIcon} type={"icon " + catalogIcon} />
            <span className={styles.headerText}>
              {t("batchUpdateRecords.result.errors.header")}
            </span>
            <span className={styles.subHeaderText}>
              {props.errorRecordsCount}
            </span>
          </div>
        </Row>
        {isOpen && (
          <div className={styles.itemsWrapper}>
            {_.map(messages, (messages, recordId) => (
              <div
                key={recordId}
                className={styles.resultItem}
                onClick={() => openRecord(recordId)}
              >
                <span className={styles.idColumn}>{recordId}</span>
                <span className={styles.messageColumn}>
                  {_.map(messages, (message, key) => message)}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
    )
  );
};

ResultList.propTypes = {
  records: ImmutablePropTypes.map,
  catalogId: PropTypes.string.isRequired,
  sectionId: PropTypes.string.isRequired,
  sceneId: PropTypes.string.isRequired,
  fields: ImmutablePropTypes.list,
  fieldsEditableStatus: ImmutablePropTypes.map,
  catalogIcon: PropTypes.string
};

export default connect(
  ResultList,
  {
    scenes: ["scenes"],
    catalogs: ["catalogs"]
  },
  (props, { scenes, catalogs }) => {
    let { sceneId, catalogId } = props;

    const errorRecords = scenes.getIn([sceneId, "data", "errorRecords"]);
    const catalogIcon = catalogs.getIn([catalogId, "icon"]);
    const errorRecordsCount =
      (errorRecords && parseInt(errorRecords.size, 10)) || 0;

    return {
      ...props,
      errorRecords,
      catalogIcon,
      errorRecordsCount
    };
  }
);
