import React from "react";
import { useTranslation } from "react-i18next";

import Checkbox from "../../../../../../common/UI/Checkbox";
import FIELD_TYPES from "../../../../../../../configs/fieldTypes";
import FieldApi from "../../../../../../../models/FieldApi";

export default function({ axis, fields, value, onChange }) {
  const { t } = useTranslation();
  const field =
    axis &&
    axis.get("type") == "field" &&
    fields.find(i => i.get("id") == axis.get("value"));

  if (!field) {
    return null;
  }
    
  const witchColor = FieldApi.boardWitchColor(field);

  if (witchColor) {
    return (
      <Checkbox
        checked={value}
        onChange={e => onChange(!!e.target.checked)}
        title={t("reports.widget.modals.common.tabs.data.axis.multicolor")}
      />
    );
  } 

  return null;
}
