import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import styles from "./dataTypes.less";

const RadiobuttonField = ({ value, config, containerClassName }) => {
  if (!value) {
    return <span />; // для позиционирования количества записей, чтобы он всегда был в правом углу
  }

  if (!!value.get) {
    value = value.get("id");
  }
  const item = config.get("items").find(item => item.get("id") === value);
  return (
    <span className={cn(containerClassName, styles.simpleField)}>
      {item.get("name")}
    </span>
  );
};

RadiobuttonField.propTypes = {
  value: PropTypes.string || PropTypes.object || PropTypes.func,
  config: PropTypes.object
};

export default RadiobuttonField;
