import React from 'react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

import TextMessage from '../Messages/TextMessage';
import colorFromString from '../../../../../utils/colorFromString';

import styles from "./replyMessage.less";

const ReplyMessage = ({ t, author, text, attachments, forMessage = false }) => {
    const authorName = author ? author.get("recordTitle") : t("record.chat.message.authorSystem");
    const isExistAttachments = attachments && attachments.size > 0;
    const colorForName = colorFromString.colorUserIcon(authorName);
    const backgroundColor = _.cloneDeep(colorForName);

    return (
        <div style={{ borderColor: colorForName, backgroundColor: forMessage && backgroundColor.setAlpha(0.1) }} className={styles.replyMessageContainer}>
            {isExistAttachments && <div className={styles.replyMessageAttachment}>
                {attachments.size}
                <br />
                {t("record.chat.message.attachments.count", { count: attachments.size || 0 })}
            </div>}
            <div className={styles.replyMessageInfo}>
                <strong style={{ color: colorForName }}>{authorName}</strong>
                <TextMessage
                    text={text}
                    reply={true}
                />
            </div>
        </div>
    );
};

export default withTranslation()(ReplyMessage);