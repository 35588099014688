import React from "react";
import PureRenderMixin from "react-addons-pure-render-mixin";
import { withTranslation } from "react-i18next";

import styles from "./board.less";

const AddWidget = props => (
  <div className={styles.addWidget} onClick={props.onClick}>
    <span className="anticon-icon interface-69" />
    <span>{props.t("reports.buttons.addWidget")}</span>
  </div>
);

export default withTranslation()(AddWidget);
