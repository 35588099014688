import Immutable from "immutable";
import _ from "lodash";

import i18n from "../../../../../../configs/i18n";
import * as AXIS_TYPES from "../../../../../../configs/reports/widget/axisTypes";
import * as AXIS_SUB_TYPES from "../../../../../../configs/reports/widget/axisSubTypes";
import FIELD_TYPES from "../../../../../../configs/fieldTypes";
import moment from "moment";

const formatAxisValue = (axis, fieldType, fieldSubType) => {
  const emptyValue = i18n.t(
    "reports.widget.modals.common.tabs.data.axis.emptyValue"
  );
  const emptyId = "$EMPTY";
  const valid = _.isNull(axis) || axis === 0 || axis === "0" || axis;

  if (!valid) {
    return false;
  }

  switch (fieldType) {
    case FIELD_TYPES.USER:
      const id = axis.id;

      if (!id) {
        return [
          {
            id: emptyId,
            title: emptyValue
          }
        ];
      } else {
        return [axis];
      }
    case FIELD_TYPES.OBJECT:
      const catalogId = axis.catalogId;

      if (!catalogId) {
        return [
          {
            catalogId: emptyId,
            recordTitle: emptyValue
          }
        ];
      } else {
        return [axis];
      }

    case FIELD_TYPES.DROPDOWN:
    case FIELD_TYPES.CHECKBOXES:
    case FIELD_TYPES.RADIOBUTTON:
      if (!axis) {
        return [emptyId];
      } else {
        return [axis];
      }

    case FIELD_TYPES.NUMBER:
      return Immutable.Map({ at: axis });

    case FIELD_TYPES.DATE:
      let date = moment(axis);

      if (!date.isValid()) {
        return;
      }

      let at = _.cloneDeep(date);
      let to = _.cloneDeep(date);

      switch (fieldSubType) {
        case AXIS_SUB_TYPES.HOUR:
          at = at.startOf("hour");
          to = to.endOf("hour");
          return Immutable.Map({ at: at.toISOString(), to: to.toISOString() });

        case AXIS_SUB_TYPES.DAY:
          at = at.startOf("day");
          to = to.endOf("day");
          return Immutable.Map({ at: at.toISOString(), to: to.toISOString() });

        case AXIS_SUB_TYPES.WEEK:
          to = to.add(1, "w").subtract(1, "d");

          return Immutable.Map({ at: at.toISOString(), to: to.toISOString() });

        case AXIS_SUB_TYPES.MONTH:
          to = to.add(1, "M").subtract(1, "d");

          return Immutable.Map({ at: at.toISOString(), to: to.toISOString() });

        case AXIS_SUB_TYPES.YEAR:
          to = to.add(1, "y").subtract(1, "d");

          return Immutable.Map({ at: at.toISOString(), to: to.toISOString() });

        default:
          return;
      }
    case FIELD_TYPES.STARS:
      return [axis];
    default:
      return axis;
  }
};

export default function(axis, axisInfo) {
  if (!axisInfo) return;

  const axisType = axisInfo.get("type");
  const fieldType = axisInfo.getIn(["field", "type"]);
  const fieldSubType =
    axisInfo.getIn(["field", "subType"]) || axisInfo.get("subType");
  const fieldValue = axisInfo.get("value");
  const fieldId = axisInfo.getIn(["field", "id"]);

  axis = Immutable.fromJS(formatAxisValue(axis, fieldType, fieldSubType));

  switch (axisType) {
    case AXIS_TYPES.FIELD:
      const filters = Immutable.fromJS({
        [fieldId]: [
          {
            value: axis
          }
        ]
      });
      return filters;

    case AXIS_TYPES.CREATED:
      return;

    default:
      break;
  }
}
