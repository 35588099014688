import { useState, useEffect, useCallback } from "react";
import Immutable from "immutable";
import _ from "lodash";

import FieldApi from "../../../models/FieldApi";

const useResourses = (
  withScheduling,
  records,
  fields,
  settings,
  filters = Immutable.List([])
) => {
  const [resources, setResources] = useState(null);

  const initResources = useCallback(
    () => {
      const splitId = settings.getIn(["split", "key"]);
      if (!withScheduling) {
        return setResources(null);
      }

      const field = fields.find(f => f.get("id") === splitId);

      if (!field) {
        return;
      }

      let allPossibleItems = FieldApi.receivePossibleItems(
        field,
        records,
        filters.get(splitId)
      );
      let possibleItems = Immutable.List([]);
      for (let i = 0; i < (allPossibleItems && allPossibleItems.size); i++) {
        if (i >= 100) {
          break;
        }
        possibleItems = possibleItems.push(allPossibleItems.get(i));
      }
      possibleItems = FieldApi.sortValues(field, possibleItems);

      let resources = possibleItems.map(item => {
        return {
          id: FieldApi.calcId(field, item),
          header: FieldApi.createComponent(field, item, "inline")
        };
      });
      if (resources) {
        resources = resources.toJS ? resources.toJS() : resources;
      } else {
        resources = null;
      }

      return setResources(resources);
    },
    [fields, records, settings]
  );
  useEffect(
    () => {
      initResources();
    },
    [initResources]
  );
  return { resources };
};

export default useResourses;
