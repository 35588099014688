export default t => ({
  element: "bpmn:ServiceTask",
  service: "bpiumAdd",
  title: t("script.controlls.bpiumAdd.title"),
  helplink: "",
  class: "bpium",
  color: "#7cab9e",
  border: 2,
  icon: "anticon-icon interface-63",
  icon_scale: 0.8,
  offset_x: -6,
  offset_y: -15,
  priority: 1,
  config: [
    {
      id: "commonSection",
      name: t("script.common.general"),
      type: "group"
    },
    {
      id: "name",
      name: t("script.common.title"),
      type: "text",
      readOnly: false,
      config: {
        defaultEmptyValue: t("script.controlls.bpiumAdd.title"),
        type: "text",
        valueType: "const",
        map: "input"
      }
    },
    {
      id: "description",
      name: t("script.common.description"),
      type: "text",
      readOnly: false,
      config: {
        type: "multiline",
        valueType: "const",
        map: "input"
      }
    },
    {
      id: "paramsSection",
      name: t("script.controlls.bpiumAdd.title"),
      type: "group"
    },
    {
      id: "catalogFormat",
      name: t("script.common.enterCatalog"),
      type: "dropdown",
      readOnly: false,
      config: {
        items: [
          {
            id: "catalog",
            name: t("script.common.fromList"),
            color: "E7E9ED"
          },
          {
            id: "var",
            name: t("script.common.fromVariables"),
            color: "E7E9ED"
          }
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ["catalog"],
        map: "input"
      }
    },
    {
      id: "catalogId",
      name: t("script.common.catalog"),
      type: "radiobutton",
      readOnly: false,
      config: {
        items: [],
        type: "select",
        data: "catalogs",
        map: "input"
      },
      visible: {
        catalogFormat: {
          $include: "catalog"
        }
      }
    },
    {
      id: "catalogIdFromVar",
      name: t("script.common.catalogId"),
      type: "text",
      readOnly: false,
      config: {
        placeholder: t("script.common.numberExpression"),
        type: "text",
        theme: "script",
        map: "input"
      },
      visible: {
        catalogFormat: {
          $include: "var"
        }
      }
    },
    {
      id: "format",
      name: t("script.common.valueFormat"),
      type: "dropdown",
      readOnly: false,
      config: {
        items: [
          {
            id: "keyvalue",
            name: t("script.common.fieldValue"),
            color: "E7E9ED"
          },
          {
            id: "json",
            name: "JSON",
            color: "E7E9ED"
          }
        ],
        defaultValue: true,
        multiselect: false,
        defaultEmptyValue: ["keyvalue"],
        map: "input"
      }
    },
    {
      id: "values1",
      name: t("script.common.fieldsValue"),
      type: "pair",
      readOnly: false,
      config: {
        placeholderValue: t("script.common.fieldId"),
        placeholderSubValue: t("script.common.valueExpression"),
        type: "script",
        theme: "script",
        data: "fields",
        parent: "catalogId",
        map: "input"
      },
      visible: {
        format: {
          $include: "keyvalue"
        }
      }
    },
    {
      id: "values2",
      name: t("script.common.fieldsValue"),
      type: "text",
      readOnly: false,
      hint: t("script.controlls.bpiumAdd.formatHint"),
      config: {
        type: "multiline",
        theme: "script",
        map: "input",
        placeholder: t("script.common.expression")
      },
      visible: {
        format: {
          $include: "json"
        }
      }
    },
    {
      id: "section1",
      name: t("script.common.result"),
      type: "group"
    },
    {
      id: "output",
      name: t("script.common.saveTo"),
      type: "text",
      readOnly: false,
      hint: t("script.controlls.bpiumAdd.outputHint"),
      config: {
        type: "text",
        map: "output",
        placeholder: t("script.common.variableName")
      }
    }
  ],
  exits: []
});
