import React, { Component } from "react";
import { Modal as Popup } from "antd";

// import trs from "../../../../../../../../getTranslations";
import i18n from "../../../../../../../../configs/i18n";
import LinkedItem from "../../../../../LinkedItem";
import LoadingSpin from "../../../../../../LoadingSpin";
import Icon from "../../../../../Icon";
import Modal from "./modal";
import { getFileType } from "../../getViewerType";
import styles from "../../../controls.less";

// in kbytes
function toMb(value) {
  var resVal = (Number(value) / 1000 / 1000).toFixed(1);
  if (resVal === "0.0") resVal = "0.1";
  return resVal + " " + i18n.t("record.fields.file.size");
}

function toProgress(file) {
  if (isNaN(file.loading) || Number.isNaN(file.loading)) {
    return "";
  }
  return parseInt(file.loading, 10) + "%";
}

class FileItem extends Component {
  state = {
    visible: false
  };

  onRemove = () => {
    this.props.removeFn(this.props.file);
  };

  toggleModal = () => {
    this.setState({
      visible: !this.state.visible
    });
  };

  render() {
    const { file, viewerComponent } = this.props;
    let item = {};
    const type = getFileType(file);
    const icon = type && type.icon;

    if (file.loading && !file.error) {
      item.icon = <LoadingSpin />;
    } else {
      item.icon = "icon " + (file.error ? "interface-54" : icon || "icon-13");
    }

    item.text = file.title;
    item.subText =
      file.size && file.loading ? (
        <span className={styles.viewerSize}>{toProgress(file)}</span>
      ) : (
        <a
          download
          target="_blank"
          className={styles.downloadLink}
          href={file.url}
          title={i18n.t("record.fields.file.download")}
        >
          <Icon type="icon interface-40" title={toMb(file.size)} />
        </a>
      );

    return (
      <div>
        <LinkedItem
          title={file.title}
          removable={!this.props.readOnly}
          item={item}
          titleOnRemove={i18n.t("record.fields.file.remove")}
          onClick={this.toggleModal}
          onClickRemove={this.props.onRemove && this.onRemove}
          classNameItem={file.error ? styles.uploadError : null}
          small={this.props.small}
          file={true}
        />
        <Popup
          open={this.state ? this.state.visible : false}
          maskClosable={false}
          closable={false}
          footer=""
          width={"auto"}
          wrapClassName="large-modal-bpium"
        >
          <Modal
            file={file}
            onClose={this.toggleModal}
            viewerComponent={viewerComponent}
          />
        </Popup>
      </div>
    );
  }
}

export default class ModalViewer extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.files.map((file, i) => {
          return <FileItem key={i} file={file} removeFn={this.props.onRemove || this.props.removeFn} onRemove={this.props.onRemove || this.props.removeFn} {...this.props} />;
        })}
      </React.Fragment>
    );
  }
}
