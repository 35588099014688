import Reflux from "reflux";

export default {
  createScene: Reflux.createAction({ sync: true }),
  closeScene: Reflux.createAction({ sync: true }),
  updateSceneData: Reflux.createAction({ sync: true }),
  changeMultimodalVisible: Reflux.createAction({ sync: true }),

  setCallbacks: Reflux.createAction({ sync: true }),
  getCallbacks: Reflux.createAction({ sync: true }),
  getCallback: Reflux.createAction({ sync: true }),
  deleteCallbacks: Reflux.createAction({ sync: true }),
  setVisible: Reflux.createAction({ async: true }),

  deleteRecordsFromScene: Reflux.createAction({ sync: true }),
  getSceneParent: Reflux.createAction({ sync: true }),
  createRecordCompleted: Reflux.createAction({ sync: false }),
  getRecordCompleted: Reflux.createAction({ sync: false }),
  getRecordFailed: Reflux.createAction({ sync: false }),
  deleteRecordCompleted: Reflux.createAction({ sync: false }),
  updateRecordCompleted: Reflux.createAction({ sync: false }),
  getRecordsCompleted: Reflux.createAction({ sync: false }),
  getRecordsFailed: Reflux.createAction({ sync: false }),
  getCatalogCompleted: Reflux.createAction({ sync: false }),
  deleteCatalogCompleted: Reflux.createAction({ sync: false }),
  getCatalogFailed: Reflux.createAction({ sync: false }),
  createRecordFailed: Reflux.createAction({ sync: false }),

  switchModal: Reflux.createAction({ sync: true }),
  copyViews: Reflux.createAction({ sync: true }),

  openNewRecord: Reflux.createAction({ sync: true }),
  openCloneRecord: Reflux.createAction({ sync: true }),
  openRecord: Reflux.createAction({ sync: true }),
  openCatalog: Reflux.createAction({ sync: true }),
  suggestRecords: Reflux.createAction({ sync: true }),

  deleteScene: Reflux.createAction({ sync: true }),
  cancelDeleteScene: Reflux.createAction({ sync: true }),
  _findSceneToDelete: Reflux.createAction({ sync: true }),
  _markSceneToDelete: Reflux.createAction({ sync: true }),

  openRecordsBatchUpdate: Reflux.createAction({ sync: false }),
  openRecordsBatchDelete: Reflux.createAction({ sync: false })
};
