import React from "react";
import PureRenderMixin from "react-addons-pure-render-mixin";
import PropTypes from "prop-types";
import cn from "classnames";

import TextInput from "../../common/UI/ControlList/controls/common/TextInput";

import styles from "./changes.less";

const ChangeText = props => {
  const isMultiline = props.config.get("type") === "multiline";
  const newValue = props.change.get("newValue");
  const oldValue = props.change.get("oldValue");
  if (isMultiline) {
    return (
      <TextInput
        value={newValue}
        readOnly
        multiline
        className={styles.inputHistory}
      />
    );
  } else {
    return (
      <div>
        {oldValue && (
          <TextInput
            className={cn(styles.removed, styles.inputHistory)}
            value={oldValue}
            readOnly
          />
        )}
        <TextInput value={newValue} readOnly className={styles.inputHistory} />
      </div>
    );
  }
};

ChangeText.propTypes = {
  change: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  isNewRecord: PropTypes.bool
};

export default ChangeText;
