import React from "react";
import PropTypes from "prop-types";
import ButtonItem from "../../common/UI/ControlList/controls/Button/ButtonItem";

import styles from "./changes.less";

const ChangeButton = props => {
    const newValue = props.change.get("newValue");

    const clickedButton = props.config && props.config.get("items").find((item) => {
        return item.get("id") == newValue;
    });

    if (!clickedButton) {
        return null;
    };
    return (
            <ButtonItem
                backgroundColor={"#" + clickedButton.get("color")}
                icon={clickedButton.get("icon")}
                className={styles.buttonItem}
            >
                {clickedButton.get("name")}
            </ButtonItem>
    );
};

ChangeButton.propTypes = {
    change: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    isNewRecord: PropTypes.bool
};

export default ChangeButton;