import _ from "lodash";
import Value from "../../../popup/data/data/Select/Value";
import * as VALUES_TYPES from "../../../../../../configs/reports/widget/valuesTypes";
import Immutable from "immutable";
const { getAvailableValues: getValues } = Value.prototype;

function compare(val1, val2) {
  // to be equal: '' and null
  return (val1 || "") == (val2 || "");
}

function find(array, value) {
  value = (value && value.toJS()) || {};

  return _.find(array, item => {
    return (
      compare(item.type, value.type) &&
      compare(item.subType, value.subType) &&
      compare(item.value, value.value)
    );
  });
}

export default function(widget, fields) {
  const values = getValues(fields);
  const value = find(values, widget.get("value"));

  if (value && value.type == VALUES_TYPES.FIELD) {
    const field = fields.find(field => {
      return field.get("id") === value.value;
    });
    value.field = field;
  }

  return Immutable.fromJS(value);
}
