import React, { useRef, useEffect, useState, useMemo } from "react";
import Immutable from "immutable";
import _ from "lodash";
import Mime from "mime-types";

import ChatHeader from "./ChatHeader";
import Messages from "./Messages";
import ChatSendMessage from "./ChatSendMessage";
import ChatEditMessage from "./ChatEditMessage";
import messageActions from "../../../../actions/messageActions";
import { getCountNewMessages } from "../../../../utils/getCountNewMessages";
import apiActions from "../../../../actions/apiActions";
import { connect } from "../../../StateProvider";

import styles from "./chat.less";
import sceneActions from "../../../../actions/sceneActions";

const TabChat = ({
  catalogId,
  recordId,
  chat,
  user,
  record,
  catalog,
  sceneId,
  visibleChatPopup,
  openChatInModal,
  isPopupOpen
}) => {
  const loadingMessages = chat.get("loadingMessages");
  const loadingAttachment = chat.get("loadingAttachment");
  const isBeingSentMessage = chat.get("isBeingSentMessage");
  const subscribe = record.getIn(["chatOptions", "subscribe"]);
  const totalMessages = record.getIn(["chatOptions", "messagesCount"]);
  const mentions =
    record.getIn(["chatOptions", "mentions"]) || Immutable.List();
  const replyMessage = chat.getIn(["newMessageToSend", "replyMessage"]);
  const text = chat.getIn(["newMessageToSend", "text", "text"]);
  const metions = chat.getIn(["newMessageToSend", "text", "metions"]);
  const allLoadedMessages = chat.get("allLoadedMessages");
  const editMessage = chat.get("editMessage");
  const msgerRef = useRef(null);
  const messages = chat.get("messages") || Immutable.List();
  const firstLoad = chat.get("firstLoad");
  const countNewMessages = useMemo(() => getCountNewMessages(messages), [
    messages
  ]);
  const lastScrollPosition = chat.get("lastScrollPosition");
  const firstItemIndex = chat.get("firstItemIndex");
  const total = chat.get("total");
  const startNewMessages = chat.get("startNewMessages");

  const [attachments, setAttachments] = useState(Immutable.List());
  const [progressState, setProgressState] = useState(Immutable.Map());
  const [uploadListenersState, setUploadListenersState] = useState({
    progress: [],
    load: [],
    error: []
  });
  const [shouldFocusInput, setShouldFocusInput] = useState(false); // состояние для фокуса на инпуте

  const inputFocus = () => {
    setShouldFocusInput(prev => !prev);
  };

  const xhrs = useRef({});

  useEffect(() => {
    return () => clearListener();
  }, []);

  useEffect(
    () => {
      if (editMessage && editMessage.get("attachments")) {
        setAttachments(editMessage.get("attachments"));
      } else {
        setAttachments(Immutable.List());
      }
    },
    [editMessage]
  );

  const onClickRemoveFile = file => {
    const index = attachments.findIndex(
      f =>
        (f.get("id") && f.get("id") === file.id) ||
        (f.get("temporaryId") && f.get("temporaryId") === file.temporaryId)
    );
    const xhr = xhrs.current[file.id];

    if (xhr) {
      xhr.abort();
      delete xhrs.current[file.id];
    }

    if (index !== -1) {
      const newValue = attachments.delete(index);
      setAttachments(newValue);
      //!file.loading && apiActions.removeFileRecord({ fileId: file.id });
      return newValue;
    }

    return attachments;
  };

  const pushUploadListeners = (event, value) => {
    const uploadListeners = { ...uploadListenersState };
    let uploadListenersArr = uploadListeners[event];

    if (value) {
      uploadListenersArr.push(value);
    } else {
      uploadListenersArr = [];
    }
    uploadListeners[event] = uploadListenersArr;

    setUploadListenersState(uploadListeners);
  };

  const updateProgress = (progress, uploadId) => {
    setProgressState(prev =>
      prev.set(uploadId, ((progress.loaded / progress.total) * 100).toFixed(2))
    );
  };

  function clearListener() {
    _.forOwn(xhrs.current, xhr => {
      xhr.abort();
      //Очистка всех листенеров
      _.forOwn(uploadListenersState, (listeneres, event) => {
        _.forEach(listeneres, listener => {
          xhr.removeEventListener(event, listener);
        });
        pushUploadListeners(event);
      });
    });

    if (attachments && attachments.find(val => val.get("loading"))) {
      let newValue = Immutable.List();
      attachments.forEach(val => {
        if (!val.get("loading")) {
          newValue = newValue.push(val);
        }
      });
      setAttachments(newValue);
    }
  }

  async function uploadFile(file) {
    const uploadId = Math.random();
    /* 
            промежуточная переменная для загрузки несколько файлов, 
            сет стейт сохраняет в области видимости пред значение 
            и в тот момент когда нам нужно изменить у файла что он загружен он пуст :(((
            Есть вариант сохранять в глобальный стей)))))
        */
    let array = Immutable.List([...attachments]);

    // add file in state
    let params = {
      name: file.name,
      size: file.size,
      mimeType: file.type || Mime.lookup(file.title), // sometimes browsers cant identify the file mimi-type
      typeStorage: "remoteStorage"
    };

    // setAttach
    const loadedFile = Immutable.fromJS({
      id: uploadId,
      title: file.name,
      loading: true,
      size: file.size,
      error: false,
      mimeType: file.type
    });
    array = array.push(loadedFile);
    setAttachments(prev => {
      return prev.push(loadedFile);
    });
    messageActions.setLoadingAttachment({ catalogId, recordId }, true);
    return apiActions
      .uploadFileRecord({}, params, uploadId)
      .then(data => {
        const fileId = data.fileId;
        // make request on s3
        // todo: make configure by type storage.
        let fd = new FormData();

        fd.append("key", data.fileKey);
        fd.append("acl", data.acl);
        // fd.append('success_action_redirect', data.redirect);

        fd.append("Content-Type", file.type ? file.type : "");
        fd.append("AWSAccessKeyId", data.AWSAccessKeyId);
        fd.append("Policy", data.police);
        fd.append("Signature", data.signature);

        fd.append("file", file);

        // todo: defer abort xhr.abort()
        var xhr = new XMLHttpRequest();
        xhrs.current[uploadId] = xhr;
        let progressListener = progress => {
          updateProgress(progress, uploadId);
        };
        xhr.upload.addEventListener("progress", progressListener, false);
        pushUploadListeners("progress", progressListener);
        let _data = data;
        let loadListener = res => {
          let data = {
            name: file.name,
            size: file.size,
            mimeType: file.type,
            url: _data.fileKey
          };
          apiActions.updateFileRecord({ fileId }, data).then((res, ...args) => {
            let newFile = Immutable.Map({
              id: res.id,
              title: res.title,
              mimeType: res.mimeType,
              size: file.size,
              url: res.url,
              loading: false,
              metadata: res.metadata
            });
            const index = array.findIndex(f => f.get("id") == uploadId);
            if (index !== -1) {
              setAttachments(prev => prev.set(index, newFile));
              messageActions.setLoadingAttachment(
                { catalogId, recordId },
                false
              );
            }
            // this.completeUpload(uploadId, newFile);
          });
        };
        xhr.addEventListener("load", loadListener, false);
        pushUploadListeners("load", loadListener);
        let errorListener = data => {
          console.log("errr", uploadId);
        };
        xhr.addEventListener("error", errorListener, false);
        pushUploadListeners("error", errorListener);
        xhr.addEventListener(
          "abort",
          function uploadCanceled(data) {
            messageActions.setLoadingAttachment({ catalogId, recordId }, false);
            // this.removeUploadingFile({id: uploadId});
          },
          false
        );

        xhr.open("POST", data.uploadUrl, true);
        xhr.send(fd);
      })
      .catch(() => {
        messageActions.setLoadingAttachment({ catalogId, recordId }, false);
        // remove if failed create.
        // this.removeUploadingFile({ id: uploadId });
      });
  }

  function openRecord(params) {
    if (params) {
      sceneActions.openRecord(params);
    }
  }

  const onChangeMute = subscribe => {
    apiActions.updateChatOption(
      { catalogId, recordId, chatOptionId: recordId },
      { subscribe },
      {}
    );
  };

  const scrollToBottom = behavior => {
    if (msgerRef.current) {
      setTimeout(() => {
        msgerRef.current.scrollToIndex({ index: total - 1, behavior });
      }, 0);
    }
  };

  return (
    <div className={styles.msger}>
      <ChatHeader
        openChatInModal={openChatInModal}
        visibleChatPopup={visibleChatPopup}
        isPopupOpen={isPopupOpen}
        loadingMessages={loadingMessages}
        onChangeMute={onChangeMute}
        subscribe={subscribe}
      />
      <Messages
        inputFocus={inputFocus}
        total={total}
        messages={messages}
        firstLoad={firstLoad}
        lastScrollPosition={lastScrollPosition}
        countNewMessages={countNewMessages}
        firstItemIndex={firstItemIndex}
        catalogId={catalogId}
        recordId={recordId}
        loadingMessages={loadingMessages}
        user={user}
        msgerRef={msgerRef}
        allLoadedMessages={allLoadedMessages}
        startNewMessages={startNewMessages}
        scrollToBottom={scrollToBottom}
        openRecord={openRecord}
      />
      {editMessage ? (
        <ChatEditMessage
          text={editMessage.get("text")}
          messageId={editMessage.get("id")}
          attachments={attachments}
          author={editMessage.get("author")}
          catalogId={catalogId}
          recordId={recordId}
          loadingAttachment={loadingAttachment}
          isBeingSentMessage={isBeingSentMessage}
          progressState={progressState}
          mentions={mentions}
          uploadFile={uploadFile}
          onClickRemoveFile={onClickRemoveFile}
          setAttachments={setAttachments}
        />
      ) : (
        <ChatSendMessage
          shouldFocusInput={shouldFocusInput}
          text={text}
          metions={metions}
          replyMessage={replyMessage}
          catalogId={catalogId}
          catalog={catalog}
          sceneId={sceneId}
          recordId={recordId}
          loadingAttachment={loadingAttachment}
          isBeingSentMessage={isBeingSentMessage}
          progressState={progressState}
          attachments={attachments}
          mentions={mentions}
          setAttachments={setAttachments}
          uploadFile={uploadFile}
          onClickRemoveFile={onClickRemoveFile}
          scrollToBottom={scrollToBottom}
        />
      )}
    </div>
  );
};

export default connect(
  TabChat,
  {
    user: ["user"]
  },
  ({ ...props }, { user }) => {
    const formaredUser = Immutable.Map({
      recordId: user.get("id"),
      recordTitle: user.get("title")
    });
    return {
      ...props,
      user: formaredUser
    };
  }
);
