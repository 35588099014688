import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import routes from "../../routes";
import getLink from "../common/router/getLink";
import { connect } from "../StateProvider";
import sceneActions from "../../actions/sceneActions";

class LinkedRecordOpen extends React.PureComponent {
  static propTypes = {
    linkProps: PropTypes.object,
    params: PropTypes.object
  };

  openRecord = e => {
    e.stopPropagation();
    e.preventDefault();

    let { sceneId, params, container } = this.props;
    params = { ...params, parentSceneId: sceneId };
    sceneActions.openRecord(params, {}, container);
    this.props.onClick && this.props.onClick();
  };

  checkParams = params => {
    const { sectionId } = params;
    if (!sectionId) {
      params.sectionId = "0";
    }
    return params;
  };

  render() {
    const { linkProps, params, wrapperClassName } = this.props;
    const key = this.props.key || params.catalogId + ":" + params.recordId;
    const href = getLink(null, routes.record, this.checkParams(params));
    return (
      <div
        className={wrapperClassName}
        style={{ overflow: "hidden" }}
        key={key}
      >
        <Link onClick={this.openRecord} to={href} {...linkProps} />
      </div>
    );
  }
}

export default connect(
  LinkedRecordOpen,
  { catalogs: ["catalogs"] },
  (props, { catalogs }) => {
    const { catalogId } = props.params;
    const sectionId = catalogs.getIn([catalogId, "sectionId"]);
    return {
      ...props,
      sectionId
    };
  }
);
