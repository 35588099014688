import React from "react";
import Immutable from "immutable";
import _ from "lodash";
import PropTypes from "prop-types";
import cn from "classnames";
import LinkedItem from "../UI/LinkedItem";

import Icon from '../../common/UI/Icon';

import styles from "./dataTypes.less";

class ObjectField extends React.PureComponent {
  static propTypes = {
    value: PropTypes.object,
    config: PropTypes.object,
    extended: PropTypes.bool
  };

  onClick(e) {
    const event = e.nativeEvent;
    const isKey =
      event.metaKey || event.altKey || event.ctrlKey || event.shiftKey;
    if (isKey) {
      e.metaKey = false;
      e.altKey = false;
      e.ctrlKey = false;
      e.shiftKey = false;
      e.stopPropagation(); // open modal and prevent show record in sidebar
    } else {
      e.preventDefault(); // open record in sidebar and prevent modal
      return true;
    }
  }

  render() {
    const values = this.props.value || Immutable.List();
    if (!values.size) {
      return <span />; // для позиционирования количества записей, чтобы он всегда был в правом углу
    }

    if (this.props.extended || true) {
      // multi items
      const className = cn(this.props.containerClassName, styles.listField);

      return (
        <ul className={className}>
          {values.map((value, i) => {
            const params = {
              sectionId: value.get("sectionId"),
              catalogId: value.get("catalogId"),
              recordId: value.get("recordId")
            };

            return (
              <li key={i} className={styles.listItem}>
                <LinkedItem
                  item={{
                    icon: value.get("catalogIcon"),
                    text: value.get("recordTitle")
                  }}
                  params={params}
                  linkProps={{
                    onClick: this.onClick
                  }}
                  type="modal"
                  simple={true}
                  className={styles.LinkedItemRow}
                />
              </li>
            );
          })}
        </ul>
      );
    } else {
      // simple as 1 item
      const text = values.map(u => u.get("recordTitle"));
      const icon = values.getIn(["0", "catalogIcon"]);
      return (
        <span className={cn(this.props.containerClassName, styles.simpleField)}>
          <Icon type={"icon " + icon} className={styles.icon} />
          {values.size > 1 ? (
            <span className={styles.prefixData}>{values.size}: </span>
          ) : null}
          {text.join(", ")}
        </span>
      );
    }
  }
}

export default ObjectField;
