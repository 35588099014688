// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".recordHeader__iconTabs___AUsat {\n  font-size: 15px;\n  position: relative;\n  bottom: -4px;\n}\n.recordHeader__iconChatTabs___iGdf9 {\n  font-size: 16px;\n  position: relative;\n  bottom: -2px;\n}\n.recordHeader__iconBadge___sD-Vh:after {\n  content: \"\";\n  position: absolute;\n  background-color: rgba(255, 0, 0, 0.75);\n  width: 6px;\n  height: 6px;\n  border-radius: 50%;\n  right: -2px;\n  top: 7px;\n}\n.recordHeader__messageCount___mBuQu {\n  margin-left: 5px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Record/RecordHeader/recordHeader.less"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,kBAAA;EACA,YAAA;AADF;AAIA;EACE,eAAA;EACA,kBAAA;EACA,YAAA;AAFF;AAKA;EACE,WAAA;EACA,kBAAA;EACA,uCAAA;EACA,UAAA;EACA,WAAA;EACA,kBAAA;EACA,WAAA;EACA,QAAA;AAHF;AAMA;EACE,gBAAA;AAJF","sourcesContent":["@import (reference) \"../../../styles/helpers/index.less\";\n\n.iconTabs {\n  font-size: 15px;\n  position: relative;\n  bottom: -4px;\n}\n\n.iconChatTabs {\n  font-size: 16px;\n  position: relative;\n  bottom: -2px;\n}\n\n.iconBadge:after {\n  content: \"\";\n  position: absolute;\n  background-color: @BADGE_COLOR;\n  width: 6px;\n  height: 6px;\n  border-radius: 50%;\n  right: -2px;\n  top: 7px;\n}\n\n.messageCount {\n  margin-left: @PADDING_BASE / 2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconTabs": "recordHeader__iconTabs___AUsat",
	"iconChatTabs": "recordHeader__iconChatTabs___iGdf9",
	"iconBadge": "recordHeader__iconBadge___sD-Vh",
	"messageCount": "recordHeader__messageCount___mBuQu"
};
export default ___CSS_LOADER_EXPORT___;
