import React from "react";
import moment from "moment";
import _ from "lodash";

import FIELD_TYPES from "../../configs/fieldTypes";
import { VALUE_STATUSES } from "../../configs/import";
import { FiltersKeys } from "../utils/getKeyRange";
import getKeyRange from "../utils/getKeyRange";
import fieldDateVisibleRules from "../utils/fieldDateVisibleRules";

const dateFormats = [
  "DD.MM.YYYY",
  "DD-MM-YYYY",
  "DD/MM/YYYY",
  "DD MM YYYY",
  "MM.DD.YYYY",
  "MM-DD-YYYY",
  "MM/DD/YYYY",
  "MM DD YYYY",
  "YYYY-MM-DD",
  "YYYYMMDD",
  "YYYY-DD-MM",
  "YYYYDDMM"
];

const dateFormatsWithTime = [
  "DD.MM.YYYY HH:mm:ss",
  "DD.MM.YYYY HH.mm.ss",
  "DD.MM.YYYY HH:mm",
  "DD.MM.YYYY HH.mm",
  "DD.MM.YYYY HHmm",
  "DD.MM.YYYY H:mm:ss",
  "DD.MM.YYYY H.mm.ss",
  "DD.MM.YYYY H:mm",
  "DD.MM.YYYY H.mm",
  "DD.MM.YYYY Hmm",
  "DD.MM.YYYYHHmmss",
  "DD.MM.YYYYHHmmssSSS",
  "DD-MM-YYYY HH:mm:ss",
  "DD-MM-YYYY HH.mm.ss",
  "DD-MM-YYYY HH:mm",
  "DD-MM-YYYY HH.mm",
  "DD-MM-YYYY HHmm",
  "DD-MM-YYYY H:mm:ss",
  "DD-MM-YYYY H.mm.ss",
  "DD-MM-YYYY H:mm",
  "DD-MM-YYYY H.mm",
  "DD-MM-YYYY Hmm",
  "DD-MM-YYYYHHmmss",
  "DD-MM-YYYYHHmmssSSS",
  "DD/MM/YYYY HH:mm:ss",
  "DD/MM/YYYY HH.mm.ss",
  "DD/MM/YYYY HH:mm",
  "DD/MM/YYYY HH.mm",
  "DD/MM/YYYY HHmm",
  "DD/MM/YYYY H:mm:ss",
  "DD/MM/YYYY H.mm.ss",
  "DD/MM/YYYY H:mm",
  "DD/MM/YYYY H.mm",
  "DD/MM/YYYY Hmm",
  "DD/MM/YYYYHHmmss",
  "DD/MM/YYYYHHmmssSSS",
  "DD MM YYYY HH:mm:ss",
  "DD MM YYYY HH.mm.ss",
  "DD MM YYYY HH:mm",
  "DD MM YYYY HH.mm",
  "DD MM YYYY HHmm",
  "DD MM YYYY H:mm:ss",
  "DD MM YYYY H.mm.ss",
  "DD MM YYYY H:mm",
  "DD MM YYYY H.mm",
  "DD MM YYYY Hmm",
  "DD MM YYYYHHmmss",
  "DD MM YYYYHHmmssSSS",
  "MM DD YYYY HH:mm:ss",
  "MM DD YYYY HH.mm.ss",
  "MM DD YYYY HH:mm",
  "MM DD YYYY HH.mm",
  "MM DD YYYY HHmm",
  "MM DD YYYY H:mm:ss",
  "MM DD YYYY H.mm.ss",
  "MM DD YYYY H:mm",
  "MM DD YYYY H.mm",
  "MM DD YYYY Hmm",
  "MM DD YYYYHHmmss",
  "MM DD YYYYHHmmssSSS",
  "YYYY-MM-DD HH:mm:ss",
  "YYYY-MM-DD HH.mm.ss",
  "YYYY-MM-DD HH:mm",
  "YYYY-MM-DD HH.mm",
  "YYYY-MM-DD HHmm",
  "YYYY-MM-DD H:mm:ss",
  "YYYY-MM-DD H.mm.ss",
  "YYYY-MM-DD H:mm",
  "YYYY-MM-DD H.mm",
  "YYYY-MM-DD Hmm",
  "YYYY-MM-DDHHmmss",
  "YYYY-MM-DDHHmmssSSS",
  "YYYYMMDD HH:mm:ss",
  "YYYYMMDD HH.mm.ss",
  "YYYYMMDD HH:mm",
  "YYYYMMDD HH.mm",
  "YYYYMMDD HHmm",
  "YYYYMMDD H:mm:ss",
  "YYYYMMDD H.mm.ss",
  "YYYYMMDD H:mm",
  "YYYYMMDD H.mm",
  "YYYYMMDD Hmm",
  "YYYYMMDDHHmmss",
  "YYYYMMDDHHmmssSSS"
];

const getValueByFormats = (value, field, locale = "ru") => {
  const withTime = field.getIn(["config", "time"]);
  const localeData = moment.localeData(locale);

  let formatedValue;

  if (withTime) {
    formatedValue = moment(
      value,
      [
        localeData.longDateFormat("L") + " " + localeData.longDateFormat("LT"),
        localeData.longDateFormat("L") + localeData.longDateFormat("LT")
      ],
      locale
    );
  } else {
    formatedValue = moment(value, localeData.longDateFormat("L"), locale);
  }

  if (Date.validateValue(formatedValue, field)) {
    return formatedValue;
  }

  if (withTime) {
    formatedValue = moment(value, dateFormatsWithTime, locale);
  } else {
    formatedValue = moment(value, dateFormats, locale);
  }

  if (Date.validateValue(formatedValue, field)) {
    return formatedValue;
  }

  return false;
};

export default class Date {
  static type = FIELD_TYPES.DATE;
  static createApi = () => new this();
  static components = {
    inline: require("../../components/common/dataTypes/DateField").default,
    control: require("../../components/common/UI/ControlList/controls/Date")
      .default,
    selector: require("../../components/Fields/selectors/DateSelector").default
  };

  static getComponent = type => Date.components[type];

  static getEmptyValue = () => null;

  static parseValue = (value, field, catalogId, locale) => {
    value = _.trim(value);

    /* парс пустого значения */
    if (Date.isEmpty(value)) {
      value = Date.getEmptyValue();
      return { value, status: VALUE_STATUSES.VALID };
    }

    if (!field) {
      return { value, status: VALUE_STATUSES.INVALID };
    }

    let formatedValue = getValueByFormats(value, field, locale);

    if (formatedValue) {
      const withTime = field.getIn(["config", "time"]);

      if (!withTime) {
        formatedValue = formatedValue
          .hours(11)
          .minutes(0)
          .seconds(0);
      }

      return { value: formatedValue, status: VALUE_STATUSES.VALID };
    }

    return { value, status: VALUE_STATUSES.INVALID };
  };

  static validateValue = (validateValue, field) => {
    if (moment(validateValue).isValid()) {
      return true;
    }

    if (moment.isMoment(validateValue) && validateValue.isValid()) {
      return true;
    }

    if (!field) {
      return false;
    }
  };

  static isEmpty = value => !(value && value.length);

  static compare = (value1, value2) => {
    if (!value1 || !value2) {
      return value1 === value2;
    }
    return moment(value1).isSame(value2, "minute");
  };

  static createComponent = (field, value, type) => {
    const Component = Date.components[type];
    return ({ containerClassName }) => {
      if (Date.isEmpty(value)) {
        return null;
      }
      return (
        <Component
          config={field.get("config")}
          value={value}
          containerClassName={containerClassName}
        />
      );
    };
  };
  static convertFilterToRecordValue = fieldFilters => {
    if (!fieldFilters) return;

    let values = [];

    fieldFilters.forEach(filterValues => {
      if (!filterValues) return;

      const filterValue = filterValues.value;

      const { keyRange } = getKeyRange(filterValue);
      switch (keyRange) {
        case FiltersKeys.FIXED:
          if (filterValue.at && filterValue.to) {
            const at = moment(filterValue.at)
              .set({ second: 0, millisecond: 0 })
              .toISOString();
            const to = moment(filterValue.to)
              .set({ second: 0, millisecond: 0 })
              .toISOString();

            // обнуление времени, получаем чистую дату. для дальнейшей проверки
            const dateAt = moment(at)
              .set({ hour: 0, minute: 0 })
              .toISOString();
            const dateTo = moment(to)
              .set({ hour: 0, minute: 0 })
              .toISOString();

            if (at === to) {
              // если дата и время совпадают, то записываем его
              const value = filterValue.at;
              values.push(value);
            } else if (dateAt === dateTo) {
              // если только дата совпадает, то записываем дату время ставим 00:00
              values.push(dateAt);
            }
            // если дата и время не совпадают, то пушим первое значение
            values.push(filterValue.at);
          } else {
            // в некоторых случаях может придти объект более одного значения, необходимо отфильтровать только at или to
            const value = filterValue.at || filterValue.to;
            values.push(value);
          }
          break;
        case FiltersKeys.TODAY:
          const today = moment()
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .toISOString();
          values.push(today);
          break;
        case FiltersKeys.YESTERDAY:
          const yesterday = moment()
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .add(-1, "days")
            .toISOString();
          values.push(yesterday);
          break;
        default:
      }
    });
    return values[0];
  };
  static getDefaultValue = field => {
    const defaultValue = field.getIn(["config", "defaultEmptyValue"]);
    return defaultValue;
  };

  static validateRequired = (value) => {
    const empty = !value;
    return empty;
  }

  static boardWitchColor = () => {
    return false;
  }

  static visibleRules = (value) => {
    return fieldDateVisibleRules(value);
  }

  static sortRecordsInCards = (field, records) => {
    const fieldId = field.get("id");
    // sort by value
    return records.sortBy(r => r.getIn(["values", fieldId]));
  }

  static checkChangeYourself = (field, value) => {
    // тут свич кейсом опишем какие типы можно изменять вот например пока нельзя изменять емаил
    const type = field.getIn(["config", "type"]);
    switch (type) {
      default:
        return false
    }
  }
}
