import React, { useEffect, useState } from 'react';
import { Dropdown } from 'antd';

import Icon from '../../../../common/UI/Icon';
import LoadingSpin from '../../../../common/LoadingSpin';

import styles from "./chatStatusMessage.less";

const ChatStatusMessage = ({ message, t, resendMessage, cancelResendMessage }) => {

    const [statusOptions, setStatusOptions] = useState({
        component: <LoadingSpin className={styles.iconStatusMessage} />
    });
    const [items, setItems] = useState([]);
    const sending = message && message.get("sending");

    useEffect(() => {
        const items = [];
        if (sending == "error") {
            items.push({
                key: "resend",
                label: <a onClick={() => resendMessage(message)}>{t("record.chat.sendMessage.resendOptions.resend")}</a>,
            }, {
                key: "cancel",
                label: <a onClick={() => cancelResendMessage(message)}>{t("record.chat.sendMessage.resendOptions.cancel")}</a>
            });

            setStatusOptions({
                component: <Icon className={styles.iconStatusMessageError} type={"icon interface-57"} />
            });
        };

        setItems(items);
    }, [sending]);

    return (
        <Dropdown
            menu={{
                items,
            }}
            placement="bottomRight"
            trigger={['click']}
        >
            {statusOptions.component}
        </Dropdown>
    );
};

export default ChatStatusMessage;