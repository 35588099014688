import React, { useState } from "react";
import cn from "classnames";

import { Cell } from "fixed-data-table-2";

import Icon from "../Icon";
import ButtonTransparent from "../ButtonTransparent";
import LoadingSpin from "../../LoadingSpin";

import styles from "./table.less";
import { CELL_TYPES } from "./getCellType";

const ValueCell = props => {
  const [hover, setHover] = useState(false);

  const onButtonHover = () => setHover(true);
  const onButtonUnhover = () => setHover(false);

  const onActive = event => {
    event.stopPropagation();
    setHover(false);
    props.onActive();
  };

  const cellClassNames = cn(
    { [styles.cellHovered]: hover },
    props.className,
    styles.cell
  );

  const icon =
    props.cellType !== CELL_TYPES.CONTROL
      ? "arrows-chevron-medium-1-01"
      : "edition-32";

  return (
    <Cell
      className={cellClassNames}
      columnKey={props.columnKey}
      height={props.heigth}
      width={props.width}
    >
      {props.children}
      {props.process ? (
        <LoadingSpin className={styles.cellSpin} />
      ) : (
        props.canActive && (
          <ButtonTransparent
            onMouseEnter={onButtonHover}
            onMouseLeave={onButtonUnhover}
            className={styles.cellButton}
            onClick={onActive}
            onMouseDown={e => e.stopPropagation()}
          >
            <Icon
              onMouseEnter={onButtonHover}
              onMouseLeave={onButtonUnhover}
              type={`icon ${icon}`}
            />
          </ButtonTransparent>
        )
      )}
    </Cell>
  );
};

export default ValueCell;
