import React, { PureComponent } from "react";
import Immutable, { fromJS } from "immutable";
import { withRouter } from "react-router";
import _ from "lodash";
import PropTypes from "prop-types";
import raf from "raf";
import ImmutablePropTypes from "react-immutable-proptypes";

import ControlList from "../../../common/UI/ControlList";
import recordActions from "../../../../actions/recordActions";
import { connect } from "../../../StateProvider";
import FIELD_TYPES from "../../../../configs/fieldTypes";
import SCENE_CONTAINER from "../../../../configs/sceneContainer";
import SCENE_TYPE from "../../../../configs/sceneTypes";
import TabChatController from "../chatTab/TabChatController";
import { setState } from "reflux";
import styles from "./mainTab.less";

const log = require("debug")("CRM:Component:Record:RecordFields");

const contolFieldMapper = {
  [FIELD_TYPES.USER]: require("./fields/User").default,
  [FIELD_TYPES.OBJECT]: require("./fields/Object").default,
  [FIELD_TYPES.CONTACT]: require("./fields/Contact").default,
  [FIELD_TYPES.FILE]: require("./fields/File").default
};

class RecordFields extends PureComponent {
  static propTypes = {
    record: PropTypes.object,
    recordId: PropTypes.string,
    catalog: PropTypes.object,
    catalogId: PropTypes.string,
    fields: PropTypes.object,
    values: PropTypes.object,
    onSaveField: PropTypes.func,
    onUpdateField: PropTypes.func,
    isNewRecord: PropTypes.bool,
    readOnly: PropTypes.bool,
    compact: PropTypes.bool,
    controllWithCheckbox: PropTypes.bool,
    changefieldEditableStatus: PropTypes.func,
    fieldsEditableStatus: ImmutablePropTypes.map
  };

  constructor() {
    super();
    this.editingCintrolId = null;
    this.state = {
      data: { configs: Immutable.fromJS({}), values: Immutable.fromJS({}) },
      visibles: null
    };
  }

  onHiddenChange = hidden => {
    const catalogId = this.props.catalogId;
    const recordId = this.props.recordId;
    recordActions.setRecordHiddenFields(catalogId, recordId, hidden);
  };

  onSaveField = (controlId, value) => {
    const catalogId = this.props.catalogId;
    const recordId = this.props.recordId;
    const fields = this.props.catalog.get("fields");
    const field = fields.find(f => f.get("id") === controlId);

    const type = field.get("type");
    const formattedValue =
      this.props.record && contolFieldMapper[type]
        ? contolFieldMapper[type].onChange(value, controlId, this.props.record)
        : value;

    const values = fromJS({ [controlId]: formattedValue });

    this.props.record &&
      recordActions.updateValues(catalogId, recordId, values);

    this.props.onSaveField &&
      this.props.onSaveField({
        catalogId: catalogId,
        recordId: recordId,
        fieldId: controlId,
        data: formattedValue
      });

    recordActions.clearErrorField(catalogId, recordId, controlId);
  };

  onUpdateField = (controlId, data) => {
    raf(() => {
      log("onEndEditing", controlId, data);
      const catalogId = this.props.catalogId;
      const recordId = this.props.recordId;
      const field = this.props.fields.find(f => f.get("id") === controlId);
      const type = field.get("type");
      const formattedValue = contolFieldMapper[type]
        ? contolFieldMapper[type].onEndEditing(data)
        : data;

      // value can be map (not immutable map)
      let simpleValue = formattedValue;
      if (_.isArray(simpleValue)) {
        simpleValue = Immutable.fromJS(formattedValue);
        simpleValue = simpleValue.toJS && simpleValue.toJS();
      }

      const values = fromJS({ [controlId]: formattedValue });
      this.props.record &&
        recordActions.raiseChanges(catalogId, recordId, values);
    });
  };

  mapFields(props) {
    const { catalog, record, catalogId, sceneViews, sceneId } = props;
    let { fields, recordId, viewId, values } = props;

    // fix: catalog is not passed in RecordDropdown (for linked record ex fields)
    viewId = props.viewId || 0;
    recordId = record && recordId;
    values = props.values || Immutable.fromJS({});
    if (!(fields && fields.size)) {
      return { configs: Immutable.fromJS({}), values: Immutable.fromJS({}) };
    }

    // map fields
    const errors = record && record.get("errors");

    fields = fields.map(field => {
      const fieldId = field.get("id");
      const fieldType = field.get("type");

      // rights for all control list (from base privilege for catalog)
      let readOnly;

      // thats how we check if field is extendent
      if (field.get("extended")) {
        // RULE: ExtFieldEditable < RecordFieldReadOnly (parentField)

        // rights from EXTENDED fields has higher priority than from record, catalog, view
        readOnly = !field.get("editable");

        // rights from parent field has higher priority
        if (props.readOnly) {
          readOnly = props.readOnly;
        }
      } else {
        // rights from Record
        readOnly = props.readOnly;

        // rightrs for NEW record
        if (record) {
          if (record.get("isNew")) {
            // RULE: CatalogFieldReadOnly < ViewFieldReadOnly

            // rights for field from Catalog
            const catalogFieldPrivilege =
              catalog && catalog.getIn(["fieldPrivilegeCodes", fieldId]);
            switch (catalogFieldPrivilege) {
              case "view":
                readOnly = true;
                break;
              case "edit":
                readOnly = false;
                break;
              default:
                break;
            }

            const catalogViews = catalog && catalog.get("views");
            const views = catalogViews && catalogViews.merge(sceneViews);
            // rights for field from View
            const viewFieldPrivilege =
              views && views.getIn([viewId, "fieldPrivilegeCodes", fieldId]);

            switch (viewFieldPrivilege) {
              case "view":
                readOnly = true;
                break;
              case "edit":
                readOnly = false;
                break;
              default:
                break;
            }
          } else {
            // RULE: RecordReadOnly < RecordFieldReadOnly

            // rights from EXISTING record
            let recordFieldPrivilege = record.getIn([
              "fieldPrivilegeCodes",
              fieldId
            ]);
            switch (recordFieldPrivilege) {
              case "view":
                readOnly = true;
                break;
              case "edit":
                readOnly = false;
                break;
              default:
                break;
            }
          }

          // api only fields always not editable
          if (field.get("apiOnly")) {
            //readOnly = true;
          }
        }
      }

      /*       console.log(field.get("name"), props.readOnly, field.get("extended"), field.get("editable"),  ">>", readOnly )
      console.log(field.get("name"), fieldId, record.getIn(["fieldPrivilegeCodes"]) ) */

      // example - mass change records
      if (props.controllWithCheckbox) {
        const fieldEditableStatus =
          props.fieldsEditableStatus && props.fieldsEditableStatus.get(fieldId);
        readOnly = !fieldEditableStatus || props.readOnly;
      }

      // set rights to edit properties
      field = field.set("readOnly", readOnly);

      // visible filter params
      field = field.set("visible", field.get("visibleRules"));

      // set other params
      field = field.set("error", errors && errors.get(fieldId));
      if (record) {
        field = field.set(
          "updateProcess",
          record.getIn(["updateProcesses", "fields", fieldId])
        );
      }

      // extra map for field types
      if (contolFieldMapper[fieldType]) {
        const object = contolFieldMapper[fieldType].config({
          sceneId,
          field,
          value: values.get(fieldId),
          catalogId,
          recordId,
          onChange: this.onSaveField,
          onEndEditing: this.onUpdateField
        });
        values = values.set(fieldId, object.value);
        return object.field;
      } else {
        return field;
      }
    });
    return { configs: fields, values };
  }

  componentDidMount() {
    const data = this.mapFields(this.props);

    this.setState({
      data
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const oldCatalog = this.props.catalog;
    const oldRecord = this.props.record;
    const oldFields = this.props.fields;
    const oldValues = this.props.values;
    const oldfieldsEditableStatus = this.props.fieldsEditableStatus;

    const newCatalog = nextProps.catalog;
    const newRecord = nextProps.record;
    const newFields = nextProps.fields;
    const newValues = nextProps.values;
    const newfieldsEditableStatus = nextProps.fieldsEditableStatus;

    if (
      !(oldFields && oldFields.equals(newFields)) ||
      !(oldCatalog && oldCatalog.equals(newCatalog)) ||
      !(oldRecord && oldRecord.equals(newRecord)) ||
      !(oldValues && oldValues.equals(newValues)) ||
      oldfieldsEditableStatus != newfieldsEditableStatus ||
      this.props.readOnly !== nextProps.readOnly
    ) {
      this.setState({
        data: this.mapFields(nextProps)
      });
    }
  }

  render() {
    const catalogId = this.props.catalogId;
    const recordId = this.props.recordId;
    const sceneId = this.props.sceneId;
    const vis = this.visible;

    return (
      <div className={styles.recordContainer}>
        <div className={styles.controlListContainer}>
          <div className={styles.grayCol} />
          <ControlList
            sceneId={sceneId}
            data={this.state.data}
            onChange={this.onSaveField}
            onSelectedFieldsChange={this.onSelectedFieldsChange}
            onHiddenChange={this.onHiddenChange}
            onEndEditing={this.onUpdateField}
            compact={this.props.compact}
            keyForStorage={`${catalogId}.record`}
            params={{ catalogId, recordId }}
            modeMassUpdate={this.props.modeMassUpdate}
            readOnly={this.props.readOnly} // uses only on batchUpdate as higher signal of disablity of all controlls
            fieldsEditableStatus={this.props.fieldsEditableStatus}
            changeCheckboxValue={this.props.changeCheckboxValue}
            changeSelectValue={this.props.changeSelectValue}
            onPlaceHolderClick={this.props.onPlaceHolderClick}
            placeHolder={this.props.placeHolder}
            notChangeTitle={this.props.notChangeTitle}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  withRouter(RecordFields),
  {
    catalogs: ["catalogs"],
    records: ["records"],
    activeSceneId: ["modal", "activeScene"],
    scenes: ["scenes"]
  },
  (props, { catalogs, records, scenes, activeSceneId }) => {
    let sceneId = props.sceneId;
    let sceneViews = Immutable.Map({});
    const _scene = sceneId ? scenes && scenes.get(sceneId) : null;
    let visibleChatPopup = false;
    if (_scene && _scene.get("container") === SCENE_CONTAINER.POPUP) {
      visibleChatPopup = true;
      sceneId = activeSceneId; // на всякий случай
    }

    if (_scene && _scene.get("type") === SCENE_TYPE.RECORD) {
      const parentScene = scenes && scenes.get(_scene.get("parentSceneId"));
      if (parentScene && parentScene.get("type") === SCENE_TYPE.CATALOG) {
        sceneViews = parentScene.get("views");
      }
    }

    const catalog = catalogs && catalogs.get(props.catalogId);
    const record = records.getIn([props.catalogId, props.recordId]);
    return {
      ...props,
      catalog,
      record,
      sceneViews,
      visibleChatPopup
    };
  }
);
