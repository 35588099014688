import cn from "classnames";

import dndContext from "../../../../services/dndContext";
import BaseMenuList from "./BaseMenuList";

import styles from "./abstractMenu.less";

class Vertical extends BaseMenuList {
  render() {
    const { vertical, direction: flexDirection } = this.props;
    return this.renderList(
      {
        ...vertical,
        menu: cn(vertical.menu, styles.menuVertical)
      },
      { flexDirection }
    );
  }
}

export default dndContext(Vertical);
