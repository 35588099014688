import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import LinkedCatalogHeader from "./LinkedCatalogHeader";
import LinkedRecord from "./LinkedRecord";
import LoadMoreLinkedDataTabs from "./LoadMoreLinkedDataTabs";
import userSettingsActions from "../../../../actions/userSettingsActions";
import { connect } from "../../../StateProvider";

class LinkedCatalog extends React.PureComponent {
  state = {
    isOpen: this.props.isOpen
  };

  onClickHeader = () => {
    const { catalogId, catalogInfo } = this.props;
    const linkedCatalogId = catalogInfo.get("id");

    this.setState(
      ({ isOpen }) => ({ isOpen: !isOpen }),
      () => {
        userSettingsActions.setKey(
          [
            "catalogs",
            catalogId,
            "records",
            "links",
            "collaps",
            linkedCatalogId
          ],
          this.state.isOpen
        );
      }
    );
  };

  render() {
    const { isOpen } = this.state;
    const {
      catalogId,
      catalogInfo,
      record,
      loadLinkedData,
      sceneId
    } = this.props;
    const records = catalogInfo.get("records");

    return (
      <React.Fragment>
        <LinkedCatalogHeader
          catalogInfo={catalogInfo}
          record={record}
          catalogId={catalogId}
          sceneId={sceneId}
          loadLinkedData={loadLinkedData}
          onClickHeader={this.onClickHeader}
        />
        {isOpen && (
          <React.Fragment>
            {records.valueSeq().map(record => (
              <LinkedRecord
                key={record.get("id")}
                record={record}
                catalogId={catalogInfo.get("id")}
                sectionId={catalogInfo.get("sectionId")}
                sceneId={sceneId}
              />
            ))}
            <LoadMoreLinkedDataTabs
              linkedDataItem={catalogInfo}
              recordId={record.get("id")}
              catalogId={catalogId}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

LinkedCatalog.propTypes = {
  catalogId: PropTypes.string.isRequired,
  catalogInfo: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired
};

export default connect(
  LinkedCatalog,
  {
    userSettings: ["userSettings"]
  },
  (props, { userSettings }) => {
    const { catalogId, catalogInfo } = props;
    const linkedCatalogId = catalogInfo.get("id");

    let isOpen = userSettings.getIn([
      "catalogs",
      catalogId,
      "records",
      "links",
      "collaps",
      linkedCatalogId,
      "value",
    ]);

    isOpen = _.isUndefined(isOpen) ? true : isOpen;

    return {
      ...props,
      isOpen
    };
  }
);
