import React from "react";
import PureRenderMixin from "react-addons-pure-render-mixin";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';

import ChangeDirection from "./ChangeDirection";
import Category from "../../common/UI/Category";

import styles from "./changes.less";

const ChangeCategory = props => {
  const { t } = useTranslation();
  let oldValues = props.change
    .get("oldValue")
    .filter(value => props.change.get("newValue").indexOf(value) === -1)
    .map(value => {
      let item = props.config
        .get("items")
        .find(item => item.get("id") == value);
      if (item) {
        return {
          name: item.get("name"),
          color: "#" + item.get("color")
        };
      }
      return {
        name: t("record.history.unknown"),
        color: ""
      };
    });
  let newValues = props.change
    .get("newValue")
    .filter(value => props.change.get("oldValue").indexOf(value) === -1)
    .map(value => {
      let item = props.config
        .get("items")
        .find(item => item.get("id") == value);
      if (item) {
        return {
          name: item.get("name"),
          color: "#" + item.get("color")
        };
      }
      return {
        name: t("record.history.unknown"),
        color: ""
      };
    });

  if (
    (oldValues && oldValues.size) === 1 &&
    (newValues && newValues.size) === 1
  ) {
    const fromObj = (
      <Category
        title={oldValues.get(0).name}
        readOnly
        checked
        backgroundColor={oldValues.get(0).color}
      />
    );
    const toObj = (
      <Category
        title={newValues.get(0).name}
        readOnly
        checked
        backgroundColor={newValues.get(0).color}
      />
    );

    return (
      <ChangeDirection
        arrowCN={styles.arrowCNCategory}
        fromObj={fromObj}
        toObj={toObj}
      />
    );
  } else {
    return (
      <div>
        {oldValues.map((value, i) => {
          return (
            <Category
              key={i}
              title={value.name}
              readOnly
              checked
              className={styles.removed}
              backgroundColor={value.color}
            />
          );
        })}
        {newValues.map((value, i) => {
          return (
            <Category
              key={i}
              title={value.name}
              readOnly
              checked
              backgroundColor={value.color}
            />
          );
        })}
      </div>
    );
  }
};

ChangeCategory.propTypes = {
  change: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  isNewRecord: PropTypes.bool
};

export default ChangeCategory;
