import React from "react";
import moment from "moment";
import Immutable from "immutable";

import FIELD_TYPES from "../../configs/fieldTypes";
import { URL_FORMAT } from "./formats";
import _ from "lodash";
import useRange from "./hooks/useRange.hook";
import availableFieldTypes from "./availableFieldTypes";
import FieldApi from "../../models/FieldApi";

export const emptyResource = { id: -42, header: () => <div /> };

export function toMinimizeDate(date) {
  const res = moment(date)
    .hour(0)
    .minute(0)
    .second(0)
    .toDate();

  return res;
}

export function compareTimeCount(start, end, minimumAsMinutes) {
  const duration = moment.duration(moment(end).diff(start)).asMinutes();
  return duration <= minimumAsMinutes;
}

export function shouldRenderEmptyResource(events, resources, layoutType) {
  if (!resources) {
    return;
  }
  const existEventsWithoutResources = events.some(
    e => e.resourceId === emptyResource.id
  );
  const existEmptyResource = resources.some(r => r.id === emptyResource.id);
  return (
    (existEventsWithoutResources ||
      layoutType === FIELD_TYPES.OBJECT ||
      layoutType === FIELD_TYPES.USER ||
      layoutType === FIELD_TYPES.STARS ||
      layoutType === FIELD_TYPES.CHECKBOXES ||
      layoutType === FIELD_TYPES.DROPDOWN) &&
    !existEmptyResource
  );
}
export function isToday(date, view) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const propRange = useRange(view, date);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currentRange = useRange(view);
  return propRange[0] === currentRange[0] && propRange[1] === currentRange[1];
}

export function getAvailableSortFields(fields) {
  return fields
    ? fields
        .filter(field =>
          availableFieldTypes.sortField.includes(field.get("type"))
        )
        .map(field => ({ key: field.get("id"), text: field.get("name") }))
        .toJS()
    : [];
}

export function getAvailableLayoutFields(fields) {
  return fields
    ? fields
        .filter(field =>
          availableFieldTypes.split.includes(field.get("type"))
        )
        .map(field => ({ key: field.get("id"), text: field.get("name") }))
        .toJS()
    : [];
}

export function getFieldsByType(fields, type) {
  return fields
    ? fields
        .filter(f => f.get("type") === type)
        .map(f => ({ key: f.get("id"), text: f.get("name") }))
        .toJS()
    : [];
}

export function shouldRenderScheduling(view, settings) {
  const splitId = settings.getIn(["split", "key"]);
  return view === "day" && splitId;
}

export function prepareDateForUrl(date) {
  const now = moment().format(URL_FORMAT);
  date = moment(date).format(URL_FORMAT);
  return date === now ? "today" : date;
}

const _filterVisibleValues = (visibleFields, values) => {
  return values.filter((_, id) => {
    return visibleFields.getIn([id, "visible", "visible"]);
  });
};

const _filterAvalableValues = (values, fields) => {
  return values.filter((_, id) => {
    const field = fields.find(f => f.get("id") === id);
    return (
      field && availableFieldTypes.filterableFields.includes(field.get("type"))
    );
  });
};

export function filterValuesByVisibleFields(
  values,
  fields,
  visibleFields,
  fieldsOrder
) {
  values = _filterAvalableValues(values, fields);
  values = visibleFields ? _filterVisibleValues(visibleFields, values) : values;

  values = values.filter((value, id) => {
    const field = fields.find(field => field.get("id") === id);
    return !FieldApi.isEmpty(field, value);
  });

  if (fieldsOrder) {
    let valuesByOrder = Immutable.Map();

    fieldsOrder.forEach(id => {
      const value = values.get(id);

      if (value !== undefined) {
        valuesByOrder = valuesByOrder.set(id, value);
      }
    });

    return valuesByOrder;
  }
  return values;
}
