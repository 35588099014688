import getAxis from "../chart/utils/getAxis";
import getValue from "../chart/utils/getValue";

export default function(widget, fields) {
  const value = getValue(widget, fields);
  const axis = getAxis(widget, fields);

  let titles = [value && value.get("title"), axis && axis.get("title")];

  return titles.filter(t => t).join(" / ");
}
