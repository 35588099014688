import React from "react";
import cn from "classnames";
import _ from "lodash";


import Editable from "./editable";
import View from "./view.js";
import Totals from "./totals";
import getEmptyName from "./getEmptyName";
import recordActions from "../../../../../actions/recordActions";

import EditWidgetPopup from "../../popup/edit";
import WidgetsActivities from "./WidgetsActivities";
import { renderModaltoBody } from "../../../../common/Modal";

import styles from "../widget.less";

function editWidget(e, catalog, scene, board, widget, readonly, license) {
  renderModaltoBody(EditWidgetPopup, {
    catalog,
    scene,
    board,
    widget,
    license
  });
}

function stopPropogate(e) {
  e.stopPropagation();
};

export default function WidgetHeader(props) {
  const {
    widget,
    catalog,
    scene,
    board,
    fields,
    totalsData,
    readonly,
    editable,
    license,
    isFull,
    addFullWidgetUid,
    removeFullWidgetUid,
    inEditMode,
    allowEditTitle,
    downloadChart
  } = props;


  const HeaderTitle = inEditMode && (_.isUndefined(allowEditTitle) ? true : allowEditTitle) ? Editable : View;


  const title = widget.get("name") || getEmptyName(widget, fields);
  const axis = widget.get("axis");
  const widgetId = widget.get("id");
  const sceneId = scene && scene.get("sceneId");

  function onEdit(e) {
    return editWidget(
      e,
      catalog,
      scene,
      board,
      widget,
      readonly || !editable,
      license
    );
  };

  function onDownloadChart(e) {
    return downloadChart();
  };

  function onExportExcel(e) {
    return recordActions.requestForExportRecords({ sceneId, viewId: "0" });
  };


  return (
    <div
      className={cn(styles.widgetHeader, {
        [styles.widgetHeaderDraggable]: !readonly && !inEditMode,
        [styles.widgetHeaderEditMode]: !!inEditMode
      })}
    >
      <div className={styles.widgetHeaderData}>
        <HeaderTitle {...props} title={title} />
        {axis ? (
          <Totals inEditMode={inEditMode} data={totalsData} widget={widget} />
        ) : null}
      </div>
      {!inEditMode ? (
        <div
          className={styles.widgetActions}
          onTouchStart={stopPropogate}
          onMouseDown={stopPropogate}
          onDrag={stopPropogate}
        >
          <WidgetsActivities
            widgetId={widgetId}
            chartType={widget && widget.get("chartType")}
            isFull={isFull}
            readonly={readonly}
            onDownloadChart={onDownloadChart}
            removeFullWidgetUid={removeFullWidgetUid}
            addFullWidgetUid={addFullWidgetUid}
            onEdit={onEdit}
            onExportExcel={onExportExcel}
          />
        </div>
      ) : null}
    </div>
  );
}
