import React from "react";
import { withRouter } from "react-router";
import _ from "lodash";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";

import RecordFields from "./RecordFields";

import styles from "./mainTab.less";

const log = require("debug")("CRM:Component:Record:TabMain");

const TabMain = props => {
  /*
    const tabLinkedData = !this.props.isNewRecord && (
      <TabLinkedData {...this.props} catalogId={catalogId} />
    );
    */

  return (
    <div className={styles.tabMainContainer}>
      <RecordFields {...props} viewId={props.match.params.viewId} />
    </div>
  );
};

TabMain.propTypes = {
  recordId: PropTypes.string.isRequired,
  catalogId: PropTypes.string.isRequired,
  onSaveField: PropTypes.func,
  onCreate: PropTypes.func,
  isNewRecord: PropTypes.bool,
  readOnly: PropTypes.bool,
  changefieldEditableStatus: PropTypes.func,
  fieldsEditableStatus: ImmutablePropTypes.map
};
export default withRouter(TabMain);
