import React from "react";
import { withRouter } from "react-router";

import Data from './Data/StatisticData';
import Widget from '../Reports/Board/Widget';
import styles from "./StatisticsData.less";
import SelectWidgetChartType from "../Reports/Board/popup/data/selectChart";
import { useState } from "react";
import Immutable from "immutable";
import _ from "lodash";
import { connect } from "../StateProvider";

const StatisticsData = (props) => {
    const initialWidget = Immutable.Map({
        id: null,
        uid: _.uniqueId("new_")
    });
    const [widget, setWidget] = useState(initialWidget);
    const onChange = (obj, newWidget = null) => {
        setWidget(() => {
            if (newWidget) {
                return newWidget;
            };
            return widget.mergeDeep(obj);
        });
    };
    return (
        <div className={styles.container}>
            <Data {...props} widget={widget} onChange={onChange} />
            <SelectWidgetChartType {...props} onChange={onChange} widget={widget} className={styles.viewTitle} />
            <div className={styles.widgetContainer}>
                <Widget {...props} widget={widget} inEditMode={true} allowEditTitle={false} className={styles.widget}/>
            </div>
        </div>
    );
};

export default connect(
    withRouter(StatisticsData),
    {
        license: ["license"]
    },
    (props, { license }) => {
  
      return {
        license,
        ...props
      };
    }
  );