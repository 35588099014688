import { fromJS } from "immutable";
import FIELD_TYPES from "../../../configs/fieldTypes";
import FieldApi from "../../../models/FieldApi";

export default function sortRecords(records, fields, fieldId, sortType) {

  records = fromJS(records);

  const field = fields.find(f => f.get("id") === fieldId);
  if (!field) {
    return records;
  }

  if (!records) {
    return;
  }

  records = FieldApi.sortRecordsInCards(field, records);
  
  if (sortType < 0) {
      records = records.reverse();
  }

  return records;
}
