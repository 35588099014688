// eslint-disable-next-line strict
"use strict";
import { assign, some } from "lodash";

import {
  getExternalLabelMid,
  hasExternalLabel
} from "bpmn-js/lib/util/LabelUtil";

var UpdateLabelHandler = require("bpmn-js/lib/features/label-editing/cmd/UpdateLabelHandler");
var LabelUtil = require("bpmn-js/lib/features/label-editing/LabelUtil");

var is = require("bpmn-js/lib/util/ModelUtil").is,
  isExpanded = require("bpmn-js/lib/util/DiUtil").isExpanded;

// from /lib/core/draw/BpmnRenderer.js
var LABEL_STYLE = {
  fontFamily: "Arial, sans-serif",
  fontSize: 11,
  lineHeight: 1.12
};
var ELEMENT_STYLE = {
  fontFamily: "Arial, sans-serif",
  fontSize: 12,
  lineHeight: 1.18
};

function LabelEditingProvider(eventBus, canvas, directEditing, commandStack) {
  this._canvas = canvas;
  this._commandStack = commandStack;

  directEditing.registerProvider(this);

  commandStack.registerHandler("element.updateLabel", UpdateLabelHandler);

  // listen to dblclick on non-root elements
  eventBus.on("element.dblclick", function(event) {
    //if (is(event.element, 'bpmn:TextAnnotation') || isLabel(event.element)) {
    directEditing.activate(event.element);
    //}
  });

  // complete on followup canvas operation
  eventBus.on(
    ["element.mousedown", "drag.init", "canvas.viewbox.changed"],
    function(event) {
      directEditing.complete();
    }
  );

  // cancel on command stack changes
  eventBus.on(["commandStack.changed"], function() {
    directEditing.cancel();
  });

  if ("ontouchstart" in document.documentElement) {
    // we deactivate automatic label editing on mobile devices
    // as it breaks the user interaction workflow
    // TODO(nre): we should temporarily focus the edited element here
    // and release the focused viewport after the direct edit operation is finished
  } else {
    eventBus.on("create.end", 500, function(e) {
      var element = e.shape,
        canExecute = e.context.canExecute;

      if (!canExecute) {
        return;
      }

      //if (is(element, 'bpmn:Task') || is(element, 'bpmn:TextAnnotation') ||
      //    (is(element, 'bpmn:SubProcess') && !isExpanded(element))) {
      if (isLabelExternal(element)) {
        directEditing.activate(element);
      }
      if (is(element, "bpmn:TextAnnotation")) {
        directEditing.activate(element);
      }
    });
  }
}

LabelEditingProvider.$inject = [
  "eventBus",
  "canvas",
  "directEditing",
  "commandStack"
];

export default LabelEditingProvider;

/**
 * Activate direct editing for activities and text annotations.
 *
 * @param  {djs.model.Base} element
 *
 * @return {Object} an object with properties bounds (position and size) and text
 */
LabelEditingProvider.prototype.activate = function(element) {
  var text = LabelUtil.getLabel(element);

  if (text === undefined) {
    return;
  }

  var properties = this.getEditingBBox(element);

  properties.text = text;

  var options = {};

  // external labels
  if (isLabelExternal(element)) {
    assign(options, {
      autoResize: true
    });
  }

  // text annotations
  if (is(element, "bpmn:TextAnnotation")) {
    assign(options, {
      resizable: true,
      autoResize: true
    });
  }

  assign(properties, {
    options: options
  });

  return properties;
};

/**
 * Get the editing bounding box based on the element's size and position
 *
 * @param  {djs.model.Base} element
 *
 * @return {Object} an object containing information about position and size (fixed or minimum and/or maximum)
 */

LabelEditingProvider.prototype.getEditingBBox = function(element) {
  var canvas = this._canvas;

  var target =
    isLabelExternal(element) && element.label ? element.label : element;

  var bbox = canvas.getAbsoluteBBox(target);

  var mid = {
    x: bbox.x + bbox.width / 2,
    y: bbox.y + bbox.height / 2
  };

  // default position
  var bounds = { x: bbox.x, y: bbox.y };

  var zoom = canvas.zoom();

  var defaultStyle = ELEMENT_STYLE,
    externalStyle = LABEL_STYLE;

  // take zoom into account
  var externalFontSize = externalStyle.fontSize * zoom,
    externalLineHeight = externalStyle.lineHeight,
    defaultFontSize = defaultStyle.fontSize * zoom,
    defaultLineHeight = defaultStyle.lineHeight;

  var style = {
    fontFamily: LABEL_STYLE.fontFamily,
    fontWeight: LABEL_STYLE.fontWeight
  };

  // adjust for expanded pools AND lanes
  if (is(element, "bpmn:Lane") || isExpandedPool(element)) {
    assign(bounds, {
      width: bbox.height,
      height: 30 * zoom,
      x: bbox.x - bbox.height / 2 + 15 * zoom,
      y: mid.y - (30 * zoom) / 2
    });

    assign(style, {
      fontSize: defaultFontSize + "px",
      lineHeight: defaultLineHeight,
      paddingTop: 7 * zoom + "px",
      paddingBottom: 7 * zoom + "px",
      paddingLeft: 5 * zoom + "px",
      paddingRight: 5 * zoom + "px",
      transform: "rotate(-90deg)"
    });
  }

  // internal labels for tasks and collapsed call activities,
  // sub processes and participants
  if (
    isAny(element, ["bpmn:Task", "bpmn:CallActivity"]) ||
    isCollapsedPool(element) ||
    isCollapsedSubProcess(element)
  ) {
    assign(bounds, {
      width: bbox.width,
      height: bbox.height
    });

    assign(style, {
      fontSize: defaultFontSize + "px",
      lineHeight: defaultLineHeight,
      paddingTop: 7 * zoom + "px",
      paddingBottom: 7 * zoom + "px",
      paddingLeft: 5 * zoom + "px",
      paddingRight: 5 * zoom + "px"
    });
  }

  // internal labels for expanded sub processes
  if (isExpandedSubProcess(element)) {
    assign(bounds, {
      width: bbox.width,
      x: bbox.x
    });

    assign(style, {
      fontSize: defaultFontSize + "px",
      lineHeight: defaultLineHeight,
      paddingTop: 7 * zoom + "px",
      paddingBottom: 7 * zoom + "px",
      paddingLeft: 5 * zoom + "px",
      paddingRight: 5 * zoom + "px"
    });
  }

  var paddingTop = 7 * zoom,
    paddingBottom = 7 * zoom,
    paddingLeft = 4 * zoom,
    paddingRight = 4 * zoom,
    width = 90 * zoom + paddingLeft + paddingRight + 1;

  // external labels for events, data elements, gateways, groups and connections
  if (target.labelTarget) {
    assign(bounds, {
      width: width,
      height: bbox.height * externalLineHeight + paddingTop + paddingBottom + 2,
      x: mid.x - width / 2,
      y: bbox.y - 12 * zoom + paddingTop - 1
    });

    assign(style, {
      fontSize: externalFontSize + "px",
      lineHeight: externalLineHeight,
      paddingTop: paddingTop + "px",
      paddingBottom: paddingBottom + "px",
      paddingLeft: paddingLeft + "px",
      paddingRight: paddingRight + "px"
    });
  }

  // external label not yet created
  if (
    isLabelExternal(target) &&
    !hasExternalLabel(target) &&
    !isLabel(target)
  ) {
    var externalLabelMid = getExternalLabelMid(element);

    var absoluteBBox = canvas.getAbsoluteBBox({
      x: externalLabelMid.x,
      y: externalLabelMid.y,
      width: 0,
      height: 0
    });

    var height = externalFontSize + paddingTop + paddingBottom;

    assign(bounds, {
      width: width,
      height: height,
      x: absoluteBBox.x - width / 2,
      y: absoluteBBox.y - height / 2
    });

    assign(style, {
      fontSize: externalFontSize + "px",
      lineHeight: externalLineHeight,
      paddingTop: paddingTop + "px",
      paddingBottom: paddingBottom + "px"
    });
  }

  // text annotations
  if (is(element, "bpmn:TextAnnotation")) {
    assign(bounds, {
      width: bbox.width,
      height: bbox.height,
      minWidth: 30 * zoom,
      minHeight: 10 * zoom
    });

    assign(style, {
      textAlign: "left",
      paddingTop: 7 * zoom + "px",
      paddingBottom: 7 * zoom + "px",
      paddingLeft: 4 * zoom + "px",
      paddingRight: 4 * zoom + "px",
      fontSize: defaultFontSize + "px",
      lineHeight: defaultLineHeight
    });
  }

  return { bounds: bounds, style: style };
};

LabelEditingProvider.prototype.update = function(element, newLabel) {
  LabelUtil.setLabel(element, newLabel, true);
  this._commandStack.execute("element.updateLabel", {
    element: element,
    newLabel: newLabel
  });
};

// helpers //////////////////////

function isCollapsedSubProcess(element) {
  return is(element, "bpmn:SubProcess") && !isExpanded(element);
}

function isExpandedSubProcess(element) {
  return is(element, "bpmn:SubProcess") && isExpanded(element);
}

function isCollapsedPool(element) {
  return is(element, "bpmn:Participant") && !isExpanded(element);
}

function isExpandedPool(element) {
  return is(element, "bpmn:Participant") && isExpanded(element);
}

function isEmptyText(label) {
  return !label || !label.trim();
}

function isAny(element, types) {
  return some(types, function(t) {
    return is(element, t);
  });
}

function isLabel(element) {
  return element && !!element.labelTarget;
}

function isLabelExternal(semantic) {
  return (
    is(semantic, "bpmn:Event") ||
    is(semantic, "bpmn:Gateway") ||
    is(semantic, "bpmn:DataStoreReference") ||
    is(semantic, "bpmn:DataObjectReference") ||
    is(semantic, "bpmn:DataInput") ||
    is(semantic, "bpmn:DataOutput") ||
    is(semantic, "bpmn:SequenceFlow") ||
    is(semantic, "bpmn:MessageFlow") ||
    is(semantic, "bpmn:Group") ||
    is(semantic, "bpmn:Task")
  );
}
