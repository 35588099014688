export default t => ({
  hidden: true,
  element: "bpmn:SequenceFlow",
  service: "",
  title: t("script.controlls.sequenceFlow.title"),
  helplink: "",
  class: "",
  config: [
    {
      id: "expression",
      name: t("script.controlls.sequenceFlow.condition"),
      type: "text",
      readOnly: false,
      config: {
        type: "multiline",
        theme: "script",
        map: "expression"
      }
    }
  ],
  exits: []
});
