// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".chat__msger___dMgMb {\n  display: flex;\n  flex-flow: column wrap;\n  justify-content: space-between;\n  width: 100%;\n  height: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Record/RecordBody/chatTab/chat.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,WAAA;EACA,YAAA;AADF","sourcesContent":["@import (reference) \"../../../../styles/helpers/index.less\";\n\n.msger {\n  display: flex;\n  flex-flow: column wrap;\n  justify-content: space-between;\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"msger": "chat__msger___dMgMb"
};
export default ___CSS_LOADER_EXPORT___;
