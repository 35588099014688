import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import _ from "lodash";
import moment from "moment";
import Immutable from "immutable";
import vm from "vm-browserify"

import { connect } from "../../../StateProvider";
import ControlList from "../../../common/UI/ControlList";
import Config from "./config";

import scriptActions from "../../../../actions/scriptActions";
import makeApiRequest from "../../../../utils/makeApiRequest";

import styles from "../../script.less";

//  static vars
const processCatalogId = String(8);
const processScriptFieldId = String(3);
const processInputFieldId = String(8);
const scriptsCatalogId = String(6);

function stringify(obj_from_json) {
  if (typeof obj_from_json !== "object" || Array.isArray(obj_from_json)){
      // not an object, stringify using native function
      return JSON.stringify(obj_from_json);
  }
  // Implements recursive object serialization according to JSON spec
  // but without quotes around the keys.
  let props = Object
      .keys(obj_from_json)
      .map(key => `${key}:${stringify(obj_from_json[key])}`)
      .join(",");
  return `{${props}}`;
}

class Sandbox extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    this.setConfig()
  }

  componentDidUpdate(prevProps) {
    if (this.props.t !== prevProps.t) {
        this.setConfig()
    }
  }

  setConfig = () => {
    let config = Config(this.props.t);
    scriptActions.storeSandboxData("config", config);

    // get list of last run processes
    this.getProcessList().then( records => {
      config = config.map(f => {
        if ( records && f.id == "processId" ) {
          f.config.items = records && _.map(records, r=> { return {id: r.id, name: r.id + ": " + r.title}} );
        }
        return f;
      })

      // set config
      scriptActions.storeSandboxData("config", config);
      scriptActions.storeSandboxData("records", records);
    });
    
    // default value for context
    const context = this.props.values && this.props.values.get("context");
    if ( ! context ) {
      scriptActions.setSandboxValue("context", "{\n\t\n}");
    }
  }

  onChange = (fieldId, value) => {
    const sandbox = this.props.sandbox;
    const config = sandbox && sandbox.get("config");
    const field = config.find(f => f.get("id") === fieldId);

    if (!field) {
      return;
    }

    scriptActions.setSandboxValue(fieldId, value);

    // special logic for fields
    if ( fieldId == "context" || fieldId == "source" ) {
      this.runCode();
    } else if ( fieldId == "processId" && value ) {
      const records = sandbox && sandbox.get("records");
      const process = records && records.find(r => r.get("id") == value);
      
      if ( process ) {
        let context = process.getIn(["values", processInputFieldId]);
        scriptActions.setSandboxValue("context", context);
      }
      
    }
  }

  getProcessList = () => {
    const recordId = this.props.params && this.props.params.recordId;

    if ( recordId ) {
      const query = {
        filters: [{fieldId: processScriptFieldId, value: [{catalogId: scriptsCatalogId, recordId: recordId}]}],
        fields: [processInputFieldId],
        limit: 10
      }
  
      return this.getRecords(processCatalogId, query);
    }
  }

  getRecords = (catalogId, query) => {
      return makeApiRequest(
        `/catalogs/${catalogId}/records/`,
        {query}
      ).then(r => r.body);
  }

  runCode = () => {
    // get context
    let context = this.props.values.get("context");
    context = context || "{}";
    try {
      context = `(()=>{return ${context}})()`;
      context = vm.runInNewContext(context);
    } catch (error) {
      scriptActions.setSandboxValue("result", String( "// context error \n" + error));
      return;
    }


    // add moment & lodash to context
    context = _.isObject(context) ? context : {};
    context._ = _;
    context.moment = moment;

    // run code
    const source = this.props.values.get("source") || "";
    let result;
    if ( source ) {
      try {
        result = vm.runInNewContext(source, context);
        result = JSON.stringify(result);
      } catch (error) {
        result = "// code error \n" + error;
      }
    }
    
    // save result
    scriptActions.setSandboxValue("result", result);
  }

  render() {
    const {
      sandbox,
      values,
      params,
      t
    } = this.props;

    return (
       <ControlList
            data={{
                configs: ( sandbox && sandbox.get("config") ) || Immutable.List(),
                values: values || Immutable.Map()
            }}
            params={params}
            onChange={this.onChange}
            onEndEditing={this.onChange}
            keyForStorage={"sandbox"}
        />
    );
  }
}

Sandbox.propTypes = {
  component: PropTypes.object,
  className: PropTypes.string,
  t: PropTypes.func
};


export default connect(
  Sandbox,
  {
    sandbox: ["scripts", "sandbox"],
    values: ["scripts", "sandbox", "values"]
  }
);
