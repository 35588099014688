import Reflux from "reflux";

export default {
  loadMessages: Reflux.createAction({ sync: false }),
  sendMessages: Reflux.createAction({ sync: false }),
  removeMessage: Reflux.createAction({ sync: false }),
  setLoadingAttachment: Reflux.createAction({ sync: false }),
  setBeingSentMessage: Reflux.createAction({ sync: false }),
  setMessageText: Reflux.createAction({ sync: false }),
  editMessageText: Reflux.createAction({ sync: false }),
  cancelReplyMessage: Reflux.createAction({ sync: false }),
  cancelEditMessage: Reflux.createAction({ sync: false }),
  getReplyMessage: Reflux.createAction({ sync: false }),
  getEditMessage: Reflux.createAction({ sync: false }),
  resendMessage: Reflux.createAction({ sync: false }),
  cancelResendMessage: Reflux.createAction({ sync: false }),
  copyTextToClipboard: Reflux.createAction({ sync: false }),
  allMessageRead: Reflux.createAction({ sync: false })
};