import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import DefaultViewer from "./viewers/Default";
import { getViewer } from "./getViewerType";

const FileViewer = prevProps => {
  const { files, updateProgress, ...props } = prevProps;

  let filesByViewer = {};
  let fileViewers = {};

  // group files by viewers
  files &&
    files.forEach(file => {
      let Viewer = getViewer(file);
      // if viewer not found or viered decline file
      if (
        file.loading || // if file is loading or
        file.error || // if file is broken or
        !Viewer || // if Viewer is not exist or
        (Viewer.isMyFile && !Viewer.isMyFile(file))
      ) {
        // if is subcheckes returns false ?
        // then render default component
        Viewer = DefaultViewer;
      }

      // collect file
      const name = Viewer.viewerName; // to take name of component like __name__
      fileViewers[name] = Viewer; // { nameOfViewer: ViewerComponent } (_.cloneDeep(Viewer) ?)
      filesByViewer[name] = filesByViewer[name] || []; // { nameOfViewer: collectedFiles }
      filesByViewer[name].push(file); // add new files to collection
    });
  // sort viewers
  let sortedViewers = [];
  _.forEach(fileViewers, (Viewer, name) => {
    sortedViewers.push({
      Viewer,
      name,
      priority: Viewer.priority || Infinity
    });
  });
  sortedViewers = _.sortBy(sortedViewers, "priority"); // sorting by priority for ImageViewer first

  // create viewers
  fileViewers = sortedViewers.map(({ Viewer, name }) => {
    return (
      <div>
        <Viewer
          key={name}
          {...props}
          files={filesByViewer[name]}
          allFiles={files}
          updateProgress={updateProgress}
        />
      </div>
    );
  });

  return fileViewers || [];
};

FileViewer.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      mimeType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      title: PropTypes.string,
      url: PropTypes.string
    })
  )
};

export default FileViewer;
