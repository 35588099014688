import React, { useEffect, useState } from "react";
import nl2br from "react-nl2br";
import sanitizeHtml from "sanitize-html";

import AlertWrapper from "./AlertWrapper";

const Alert = props => {
  const [cleanContent, setCleanContent] = useState();

  useEffect(
    () => {
      const cleanContent = sanitizeHtml(nl2br(props.text), {
        allowedAttributes: {
          "*": [
            "href",
            "name",
            "target",
            "src",
            "srcset",
            "alt",
            "title",
            "width",
            "height",
            "loading",
            "style"
          ]
        }
      });

      setCleanContent(cleanContent);
    },
    [props.text]
  );

  return (
    <AlertWrapper
      onOk={props.onOk}
      okText={props.okText}
      onCancel={props.onCancel}
      headerText={props.headerText}
    >
      <p dangerouslySetInnerHTML={{ __html: cleanContent }} />
    </AlertWrapper>
  );
};

export default Alert;
