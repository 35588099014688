import React from "react";
import PureRenderMixin from "react-addons-pure-render-mixin";
import _ from "lodash";
import PropTypes from "prop-types";

import NumberField from "../../common/dataTypes/NumberField";
import ChangeDirection from "./ChangeDirection";

import styles from "./changes.less";

class ChangeNumber extends React.PureComponent {
  empty = value => _.isUndefined(value) || _.isNull(value);

  render() {
    const fromObj = !this.empty(this.props.change.get("oldValue")) ? (
      <NumberField
        value={this.props.change.get("oldValue")}
        config={this.props.config}
        withUnit={this.empty(this.props.change.get("newValue"))}
      />
    ) : null;
    const toObj = !this.empty(this.props.change.get("newValue")) ? (
      <NumberField
        value={this.props.change.get("newValue")}
        config={this.props.config}
        withUnit={true}
      />
    ) : null;

    return (
      <ChangeDirection
        containerClass={styles.textWrapper}
        fromObj={fromObj}
        toObj={toObj}
      />
    );
  }
}

ChangeNumber.propTypes = {
  change: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  isNewRecord: PropTypes.bool
};

export default ChangeNumber;
