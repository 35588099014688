import React from "react";
import Immutable from "immutable";
import PropTypes from "prop-types";
import _ from "lodash";

import Checkbox from "../../Checkbox";

function getValuesMap(values) {
  var map = {};
  (values || []).forEach(v => {
    map[v] = true;
  });
  return Immutable.fromJS(map);
}

class CheckboxList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      values: getValuesMap(this.props.value)
    };
  }

  onChangeItem = itemId => {
    if (!this.props.editable) {
      return;
    }

    const newValues = this.state.values.set(
      itemId,
      !this.state.values.get(itemId)
    );
    this.setState({
      values: newValues
    });

    let values = [];
    newValues.forEach((v, id) => {
      if (v) {
        values.push(id);
      }
    });

    this.props.onChange && this.props.onChange(values);
    this.props.onEndEditing && this.props.onEndEditing(values);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!Immutable.is(nextProps.value, this.props.value)) {
      this.setState({ values: getValuesMap(nextProps.value) });
    }
  }
  render() {
    let items = this.props.config.get("items");
    const { editable } = this.props;

    if (!editable) {
      items = items.filter(item => this.state.values.get(item.get("id")));
    }

    return (
      <div>
        {items.map((item, key) => {
          const id = item.get("id");
          const checked = this.state.values.get(id);

          return (
            <Checkbox
              key={id}
              checked={checked}
              readOnly={!editable}
              onChange={_.bind(this.onChangeItem, this, id)}
              title={item.get("name")}
            />
          );
        })}
      </div>
    );
  }
}

CheckboxList.propTypes = {
  value: PropTypes.object,
  config: PropTypes.object,
  onChange: PropTypes.func,
  onEndEditing: PropTypes.func,
  editable: PropTypes.bool
};

export default CheckboxList;
