import _ from "lodash";
import i18n from "./configs/i18n";

// const log = require('debug')('CRM:Translations');

var cache = {};

let translations = {
  ru: require("../translations/ru.json")
};

try {
  translations.en = require("../translations/en.json");
} catch (err) {}

export default function(path, count) {
  var result = translations[i18n.language || "ru"];

  if (!path) {
    return result;
  }

  var pathWithCount = path + (count !== null ? count : "");

  if (cache[pathWithCount]) {
    return cache[pathWithCount];
  }

  var pathArr = path.split(".");

  while (pathArr.length && result) {
    result = result[pathArr.shift()];
  }

  if (count != null) {
    let _count = count % 100;
    if (_count >= 20) {
      _count %= 10;
    }
    let text;
    _.forEach(result, (t, c) => {
      if (parseInt(c, 10) <= _count) {
        text = t;
      }
    });
    result = count + " " + text;
  }

  if (!result) {
    // log(`No translation for '${path}'`);
  } else {
    cache[pathWithCount] = result;
  }

  if (result === undefined) {
    return "NO TRANSLATION (" + path + ")";
  }

  return result;
}
