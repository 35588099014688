import React from "react";
import Immutable, { fromJS } from "immutable";
import PropTypes from "prop-types";
import cn from "classnames";
import _ from "lodash";

import { connect } from "../../../StateProvider";
import FieldApi from "../../../../models/FieldApi";
import FIELD_TYPES from "../../../../configs/fieldTypes";
import getCellType, { CELL_TYPES } from "./getCellType";

import FieldPopover from "./FieldPopover";
import CellValue from "./ValueCell";


import styles from "./table.less";
import raf from "raf";

const controlFieldMappers = {
  [FIELD_TYPES.OBJECT]: require("../../../Record/RecordBody/mainTab/fields/Object")[
    "default"
  ],
  [FIELD_TYPES.USER]: require("../../../Record/RecordBody/mainTab/fields/User")[
    "default"
  ],
  [FIELD_TYPES.CONTACT]: require("../../../Record/RecordBody/mainTab/fields/Contact")[
    "default"
  ],
  [FIELD_TYPES.FILE]: require("../../../Record/RecordBody/mainTab/fields/File")[
    "default"
  ]
};

class CellData extends React.PureComponent {
  state = {
    active: false
  };

  static propTypes = {
    catalogId: PropTypes.string,
    sceneId: PropTypes.string,
    recordId: PropTypes.string,
    currentRecord: PropTypes.bool,
    field: PropTypes.object,
    record: PropTypes.object,
    onChange: PropTypes.func,
    onStartEditing: PropTypes.func,
    onEndEditing: PropTypes.func,
    onRefuseEditing: PropTypes.func
  };

  allowActive = () => {
    const { field, editable, recordIsOpen } = this.props;
    const active = this.state.active;
    const enableSelect = _.isBoolean(field.getIn(["config", "enableSelect"]))
      ? field.getIn(["config", "enableSelect"])
      : true;

    return !active && editable && !recordIsOpen && enableSelect;
  };

  mapField = (field, value) => {
    const { catalogId, recordId, recordExist } = this.props;
    const type = field.get("type");
    const mapper = controlFieldMappers[type];
    const additionalConfig =
      type === FIELD_TYPES.OBJECT && fromJS({ enableCreate: false });

    let params = {
      additionalConfig,
      catalogId,
      field,
      value
    };

    if (recordExist) {
      params = {
        ...params,
        recordId
      };
    }

    return mapper && mapper.config(params);
  };

  prepareFieldComponent = canEdit => {
    let { field, value, sceneId, record, validateValueByField } = this.props;
    const fieldId = field.get("id");

    const fieldIsValid = validateValueByField
      ? validateValueByField(value, field)
      : true;

    const cellType = getCellType(field);
    const pending = _.isUndefined(canEdit);

    let Component;

    const componentProps = {
      sceneId: sceneId,
      field: field,
      fieldId: field.get("id"),
      config: field.get("config"),
      fieldType: field.get("type"),
      value: value,
      onChange: this.onChangeValue,
      onEndEditing: () => this.onEndEditing()
    };

    if (this.state.active && pending) {
      Component = FieldApi.getComponent(field, "inline");
      componentProps.containerClassName = cn(styles.cellInline);
      return <Component {...componentProps} />;
    }

    if (this.state.active) {
      // check if field is valid render boilerplate
      if (!fieldIsValid) {
        value = FieldApi.getEmptyValue(field);
        componentProps.value = value;
      }

      const object = this.mapField(field, value);

      object && (componentProps.controlConfig = object.field);
      object && (componentProps.value = object.value);

      Component =
        cellType !== CELL_TYPES.POPOVER
          ? FieldApi.getComponent(field, cellType)
          : FieldPopover;
    } else {
      /* если поле сконвертированно, то отображаем как обычно, в противном случае инлайн элементы отображаем в текстовом варианте (нужно для импорта) */
      if (!fieldIsValid) {
        const fieldWithDefaultType = field.set("type", FIELD_TYPES.TEXT);
        const originValue = record && record.getIn(["originValues", fieldId]);

        componentProps.value = originValue;

        Component = FieldApi.getComponent(fieldWithDefaultType, "inline");
      } else {
        Component = FieldApi.getComponent(field, "inline");
        componentProps.containerClassName = cn(styles.cellInline);
      }
    }

    if (this.state.active) {
      switch (cellType) {
        case CELL_TYPES.SELECTOR:
          componentProps.autoFocus = true;
          componentProps.originalValue = value;
          componentProps.className = styles.selectorControl;
          componentProps.dropdownClassName = styles.dropdownClassName;
          break;
        case CELL_TYPES.CONTROL:
          componentProps.autoFocus = true;
          componentProps.className = styles.cellControl;
          break;
        case CELL_TYPES.POPOVER:
          componentProps.originalValue = value;
          componentProps.fieldClassName = styles.cellFieldPopover;
        default:
          break;
      }
    }

    if (this.state.active) {
      componentProps.editable = canEdit;
    }

    return <Component {...componentProps} />;
  };

  startEditing = () => {
    this.setState({ active: true });

    // функция может быть ассинхронной, например загрузка записи
    this.props.onStartEditing(this.props.record, this.props.field);
  };

  onEndEditing = (props = this.props) => {
    raf(() => {
      const { value, fieldId, recordId } = props;
      const onEndEditing = props.onEndEditing;

      this.setState({ active: false });

      onEndEditing && onEndEditing(recordId, fieldId, value);
    });
  };

  canEditRecordValue() {
    const { canEditRecordValue, record, field } = this.props;

    return canEditRecordValue ? canEditRecordValue(record, field) : true;
  }

  refuseEditing = () => {
    const { recordId, fieldId } = this.props;

    this.setState({
      active: false
    });

    this.props.onRefuseEditing && this.props.onRefuseEditing(recordId, fieldId);
    this.onEndEditing();
  };

  onChangeValue = value => {
    const fieldId = this.props.field.get("id");
    const fieldType = this.props.field.get("type");
    const recordId = this.props.recordId;
    const record = this.props.record;

    const mapper = controlFieldMappers[fieldType];

    if (mapper) {
      value = mapper.onChange(value, fieldId, record);
    }

    this.props.onChange && this.props.onChange(recordId, fieldId, value);
  };

  componentDidUpdate(prevProps, prevState) {
    const active = this.state.active;

    const editable = this.props.editable;
    const prevEditable = prevProps.editable;

    const fieldId = this.props.fieldId;
    const prevFieldId = prevProps.fieldId;

    const recordId = this.props.recordId;
    const prevRecordId = prevProps.recordId;

    if (!active) {
      return;
    }

    const canEdit = this.canEditRecordValue() && editable;

    if (canEdit === false) {
      return this.refuseEditing();
    }

    if (recordId !== prevRecordId || fieldId !== prevFieldId) {
      return this.onEndEditing(prevProps);
    }

    if (!editable && prevEditable) {
      return this.refuseEditing();
    }
  }

  componentWillUnmount() {
    if (this.state.active) {
      this.onEndEditing();
    }
  }

  render() {
    const {
      saving,
      inProcess,
      field,
      record,
      getCellClassName,
      recordId,
      fieldId,
      onSelectCell,
      ...props
    } = this.props;

    const active = this.state.active;
    const cellType = getCellType(field);

    const canEdit = this.canEditRecordValue();
    const pending = active && _.isUndefined(canEdit);

    const selectedAsControl = active && cellType !== CELL_TYPES.POPOVER;
    const selectedAsPopover = active && cellType === CELL_TYPES.POPOVER;

    const extraClassName = getCellClassName && getCellClassName(field, record);

    const process = inProcess || saving || pending;

    const className = cn(
      this.props.className,
      {
        [styles.cellControlSelected]: selectedAsControl,
        [styles.cellPopoverSelected]: selectedAsPopover,
        [styles.cellProcess]: process
      },
      extraClassName
    );

    return (
      <CellValue
        className={className}
        columnKey={props.columnKey}
        height={props.heigth}
        width={props.width}
        process={process}
        cellType={getCellType(field)}
        canActive={this.allowActive()}
        onActive={this.startEditing}
      >
        {this.prepareFieldComponent(canEdit)}
      </CellValue>
    );
  }
}

export default CellData;
