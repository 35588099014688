import Immutable from "immutable";
import _ from "lodash";
import moment from "moment";

import FIELD_TYPES from "../../../configs/fieldTypes";
import * as AXIS_SUB_TYPES from "../../../configs/grid/axisSubTypes";

import { formatDate } from "../../../utils/formatDate";

const NullAxisValue = "__null";

export default function formatAxisValues(
  axis,
  scene,
  viewId,
  records = scene.get("records"),
  locale
) {
  if (!axis) {
    return [];
  }
  if (!scene) {
    return [];
  }

  const hasRecords = records && records.size;

  // field
  const field = axis.get("field");
  const axisFieldId = field.get("id");
  const axisFieldType = field.get("type");

  // filter
  let filter = scene.getIn(["views", viewId, "filters", axisFieldId]);
  filter = filter && filter.toJS ? filter.toJS() : filter;

  if (filter) {
    filter = _.map(filter, f => f.value);
  }

  let skipFilter; // will be set depending on field type

  let dataValues; // possible values in config& data
  let axisValues = []; // result values for axis in field.value format

  switch (axisFieldType) {
    case FIELD_TYPES.DROPDOWN:
    case FIELD_TYPES.CHECKBOXES:
      // get possible values from config
      dataValues = field
        .getIn(["config", "items"])
        .map(item => item.get("id"))
        .toJS();

      filter = filter && _.flatten(filter);

      // filter possible values by set filter
      dataValues =
        filter && !_.isEmpty(filter)
          ? _.intersection(dataValues, filter)
          : dataValues;
      // create list of axis values {key, value}
      axisValues = dataValues.map(item => {
        return { key: item, value: Immutable.fromJS([item]) };
      });

      if (!filter || _.isEmpty(filter)) {
        // empty value
        axisValues.push({
          key: NullAxisValue,
          value: Immutable.fromJS([])
        });
      }

      break;

    case FIELD_TYPES.RADIOBUTTON:
      // get possible values from config
      dataValues = field
        .getIn(["config", "items"])
        .map(item => item.get("id"))
        .toJS();

      filter = filter && _.flatten(filter);

      // filter possible values by set filter
      dataValues =
        filter && !_.isEmpty(filter)
          ? _.intersection(dataValues, filter)
          : dataValues;
      // create list of axis values {key, value}
      axisValues = dataValues.map(item => {
        return { key: item, value: item };
      });

      if (!filter || _.isEmpty(filter)) {
        // empty value
        axisValues.push({
          key: NullAxisValue,
          value: Immutable.fromJS(null)
        });
      }
      break;

    case FIELD_TYPES.STARS:
      dataValues = [0, 1, 2, 3, 4, 5];

      filter = filter && _.flatten(filter);

      // filter possible values by set filter
      dataValues =
        filter && !_.isEmpty(filter)
          ? _.intersection(dataValues, filter)
          : dataValues;
      // create list of axis values {key, value}
      axisValues = dataValues.map(item => {
        return { key: String(item), value: item };
      });

      // 0 = empty value

      break;

    case FIELD_TYPES.DATE:
      if (!hasRecords) {
        return [];
      } // skip before data loaded

      const subType = axis.get("subType");
      axisValues = {};

      records &&
        records.forEach(r => {
          const date = r.getIn(["values", axisFieldId]);
          if (!date) {
            return;
          }
          const key = _getAxisByDateSubTypeKey(subType, date);
          const value = _getAxisByDateSubTypeValue(subType, date, locale);

          axisValues[key] = {
            key,
            value
          };
        });

      // sorting
      axisValues = _.sortBy(axisValues, item => item.key);
      switch (subType) {
        case AXIS_SUB_TYPES.HOUR:
        case AXIS_SUB_TYPES.DAY:
        case AXIS_SUB_TYPES.WEEK:
        case AXIS_SUB_TYPES.MONTH:
        case AXIS_SUB_TYPES.YEAR:
          axisValues = axisValues.reverse();
      }

      // empty value
      axisValues.push({
        key: NullAxisValue,
        value: null
      });
      break;

    case FIELD_TYPES.USER:
      if (!hasRecords) {
        return [];
      } // skip before data loaded

      filter = filter && _.flatten(filter);

      skipFilter = !filter || _.isEmpty(filter);

      skipFilter = skipFilter || !!_.find(filter, f => f.id == "CURRENT_USER"); // skip if filter is set to "я сотрудник"
      skipFilter = skipFilter || !!_.find(filter, f => f.id == "$EMPTY"); // skip if filter is set to "не задано"

      axisValues = {};
      records &&
        records.forEach(r => {
          r.getIn(["values", axisFieldId]).forEach(item => {
            const key = item.get("id");
            const filtered =
              skipFilter || _.find(filter, item => item.id === String(key));

            if (!axisValues[key] && filtered) {
              axisValues[key] = {
                key,
                value: Immutable.fromJS([item])
              };
            }
          });
        });

      axisValues = _.sortBy(axisValues, item => item.value.getIn([0, "title"]));

      if (skipFilter) {
        // empty value
        axisValues.push({
          key: NullAxisValue,
          value: Immutable.fromJS([])
        });
      }
      break;

    case FIELD_TYPES.OBJECT:
      if (!hasRecords) {
        return [];
      } // skip before data loaded

      filter = filter && _.flatten(filter);

      skipFilter =
        !filter ||
        _.isEmpty(filter) ||
        _.find(filter, item => item.catalogId === "USER_FIELD"); // skip if filter is set to "как у меня"

      axisValues = {};
      records &&
        records.forEach(r => {
          r.getIn(["values", axisFieldId]).forEach(item => {
            const catalogId = item.get("catalogId");
            const recordId = item.get("recordId");
            const key = catalogId + ":" + recordId;

            const filtered =
              skipFilter ||
              _.find(
                filter,
                item =>
                  item.catalogId === catalogId && item.recordId === recordId
              );
            if (!axisValues[key] && filtered) {
              axisValues[key] = {
                key,
                value: Immutable.fromJS([item])
              };
            }
          });
        });
      axisValues = _.sortBy(axisValues, item =>
        item.value.getIn([0, "recordTitle"])
      );

      if (skipFilter) {
        // empty value
        axisValues.push({
          key: NullAxisValue,
          value: Immutable.fromJS([])
        });
      }
      break;

    case FIELD_TYPES.SWITCH: 
      dataValues = [true, false];

      axisValues = dataValues.map(item => {
        return { key: String(item), value: item };
      });

      break;
  }

  return axisValues;
}

export function _parseRecordValueAsAxisValues(value, axis) {
  const field = axis.get("field");
  const fieldType = field && field.get("type");
  const subType = axis.get("subType");

  switch (fieldType) {
    case FIELD_TYPES.TEXT:
    case FIELD_TYPES.NUMBER:
    case FIELD_TYPES.STARS:
    case FIELD_TYPES.PROGRESS:
    case FIELD_TYPES.RADIOBUTTON:
      // simple value
      return value !== null
        ? Immutable.fromJS([value])
        : Immutable.fromJS([NullAxisValue]);

    case FIELD_TYPES.DATE:
      // multivariants
      return value !== null
        ? Immutable.fromJS([_getAxisByDateSubTypeKey(subType, value)])
        : Immutable.fromJS([NullAxisValue]);

    case FIELD_TYPES.DROPDOWN:
    case FIELD_TYPES.CHECKBOXES:
      // simple arrays
      return value && value.size ? value : Immutable.fromJS([NullAxisValue]);

    case FIELD_TYPES.USER:
      // array of keys of users
      return value && value.size
        ? value.map(i => i.get("id"))
        : Immutable.fromJS([NullAxisValue]);

    case FIELD_TYPES.OBJECT:
      // array of keys of linked records
      return value && value.size
        ? value.map(item => {
            return item.get("catalogId") + ":" + item.get("recordId");
          })
        : Immutable.fromJS([NullAxisValue]);

    case FIELD_TYPES.SWITCH: 
      // array of keys type boolean
      return typeof value == "boolean"
        ? Immutable.fromJS([String(value)])
        : Immutable.fromJS([NullAxisValue]);
  }

  return Immutable.fromJS([NullAxisValue]);
}
export function _getAxisByDateSubTypeFormat(subType) {
  let format;

  switch (subType) {
    case AXIS_SUB_TYPES.HOUR:
      format = "YYYY-MM-DDTHH";
      break;
    case AXIS_SUB_TYPES.HOUR_OF_DAY:
      format = "HH";
      break;
    case AXIS_SUB_TYPES.DAY:
      format = "YYYY-MM-DD";
      break;
    case AXIS_SUB_TYPES.DAY_OF_WEEK:
      format = "d"; // sunday is FIRST
      break;
    case AXIS_SUB_TYPES.WEEK_OF_YEAR:
      format = "WW";
      break;
    case AXIS_SUB_TYPES.WEEK:
      /* ЕСЛИ ЧТО-ТО СЛОМАЛОСЬ, ТО ЭТО ТУТ */
      // return "YYYY-WW";
      format = "YYYY-WW";
      break;
    case AXIS_SUB_TYPES.MONTH_OF_YEAR:
      format = "MM";
      break;
    case AXIS_SUB_TYPES.MONTH:
      format = "YYYY-MM";
      break;
    case AXIS_SUB_TYPES.YEAR:
      format = "YYYY";
      break;
  }

  return format;
}
export function _getAxisByDateSubTypeKey(subType, value) {
  const format = _getAxisByDateSubTypeFormat(subType);
  let key = moment(value).format(format);

  // make Sunday the last
  if (subType === AXIS_SUB_TYPES.DAY_OF_WEEK) {
    key = key === "0" ? "7" : key;
  }

  return key;
}
export function _getAxisByDateSubTypeValue(subType, value, locale) {
  const CURRENT_YEAR = moment().year();

  if (value === null) {
    return null;
  }

  let date = moment(value);

  switch (subType) {
    case AXIS_SUB_TYPES.HOUR:
      return formatDate(value, locale) + ", " + date.format("HH:00");

    case AXIS_SUB_TYPES.HOUR_OF_DAY:
      return date.format("HH:00");

    case AXIS_SUB_TYPES.DAY_OF_WEEK:
      return _.startCase(date.format("dddd"));

    case AXIS_SUB_TYPES.MONTH:
      return (
        _.startCase(date.format("MMMM")) +
        (date.year() === CURRENT_YEAR ? "" : " " + moment(value).format("YYYY"))
      );

    case AXIS_SUB_TYPES.MONTH_OF_YEAR:
      return _.startCase(date.format("MMMM"));

    case AXIS_SUB_TYPES.DAY:
      return value && formatDate(value, locale);

    case AXIS_SUB_TYPES.WEEK:
      const weekDay = date.format("E");
      return value && formatDate(date.subtract(weekDay - 1, "days"), locale);

    case AXIS_SUB_TYPES.WEEK_OF_YEAR:
      return moment(value).format("W") + " неделя";

    case AXIS_SUB_TYPES.YEAR:
      return moment(value).format("YYYY");
  }

  return value;
}
