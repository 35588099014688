import React from "react";

import styles from "../../calendar.less";

const ResourseHeader = ({ resource }) => {
  const Header = resource.header;
  return <Header containerClassName={styles.headerFieldContainer} />;
};

export default ResourseHeader;
