import React from "react";

import Config from "./Config";
import FieldConfig from "../../common/FieldConfig";
import FilterNewMessages from "../../Records/FilterNewMessages";
import { withTranslation } from "react-i18next";

import styles from "./header.less";

class Header extends React.PureComponent {
  state = {};

  render() {
    const { catalog, settings, scene, t } = this.props;
    if (!catalog) {
      return;
    }
    const recordsCountNewMessages =
      scene && scene.get("recordsCountNewMessages");

    return (
      <div className={styles.optionsContainer}>
        <FilterNewMessages
          sceneId={this.props.sceneId}
          viewId={this.props.viewId}
          recordsCountNewMessages={recordsCountNewMessages}
          titleNewMessages={this.props.t("buttons.filterNewMessages")}
          titleAll={this.props.t("buttons.filterAllRecords")}
        />
        <Config catalog={catalog} settings={settings} viewMode="cards" />
        <FieldConfig
          catalogId={catalog.get("id")}
          fields={catalog.get("fields")}
          viewMode="cards"
          defaultVisible={false}
        />
      </div>
    );
  }
}

export default withTranslation()(Header);
