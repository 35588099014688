import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import PureRenderMixin from "react-addons-pure-render-mixin";
import createFragment from "react-addons-create-fragment";

import styles from "./changes.less";

const ChangeContact = props => {
  let mixedList = {};

  props.change.get("oldValue").map((value, i) => {
    if (!mixedList[value.get("id")]) {
      mixedList[value.get("id")] = { old: null, new: null };
    }
    return (mixedList[value.get("id")]["old"] = (
      <li className={styles.removed} key={i}>
        <span>{value.get("contact")}</span>
        &nbsp;
        <span className={styles.comment}>
          <small>{value.get("comment")}</small>
        </span>
      </li>
    ));
  });
  props.change.get("newValue").map((value, i) => {
    if (!mixedList[value.get("id")]) {
      mixedList[value.get("id")] = { old: null, new: null };
    }
    return (mixedList[value.get("id")]["new"] = (
      <li key={i}>
        <span>{value.get("contact")}</span>
        &nbsp;
        <span className={styles.comment}>
          <small>{value.get("comment")}</small>
        </span>
      </li>
    ));
  });

  return (
    <ul className={styles.textWrapper}>
      {_.map(mixedList, el => {
        return createFragment(el);
      })}
    </ul>
  );
};

ChangeContact.propTypes = {
  change: PropTypes.object.isRequired,
  isNewRecord: PropTypes.bool
};

export default ChangeContact;
