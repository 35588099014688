// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".chatSwitchSubscribe__chatSwitchSubscribeContainer___Oj4tg {\n  display: flex;\n  align-items: center;\n}\n.chatSwitchSubscribe__chatSwitchSubscribeIcon___2beSh {\n  font-size: 19px;\n  color: #b6b8ba;\n  position: relative;\n  right: -10px;\n  top: -1px;\n  padding-right: 10px;\n}\n.chatSwitchSubscribe__chatSwitchSubscribeNotifications___TawP1 {\n  color: #b6b8ba;\n  position: relative;\n  top: -1px;\n  padding-right: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Record/RecordBody/chatTab/ChatSwitchSubscribe/chatSwitchSubscribe.less"],"names":[],"mappings":"AAGA;EACI,aAAA;EACA,mBAAA;AAFJ;AAKA;EACI,eAAA;EACA,cAAA;EACA,kBAAA;EACA,YAAA;EACA,SAAA;EACA,mBAAA;AAHJ;AAMA;EACI,cAAA;EACA,kBAAA;EACA,SAAA;EACA,mBAAA;AAJJ","sourcesContent":["@import (reference) \"../../../../../styles/helpers/index.less\";\n\n\n.chatSwitchSubscribeContainer {\n    display: flex;\n    align-items: center;\n}\n\n.chatSwitchSubscribeIcon {\n    font-size: @TEXT_SIZE_TITLE;\n    color: @TEXT_DISABLED;\n    position: relative;\n    right: -@PADDING_BASE;\n    top: -1px;\n    padding-right: @PADDING_BASE;\n}\n\n.chatSwitchSubscribeNotifications {\n    color: @TEXT_DISABLED;\n    position: relative;\n    top: -1px;\n    padding-right: @PADDING_BASE;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatSwitchSubscribeContainer": "chatSwitchSubscribe__chatSwitchSubscribeContainer___Oj4tg",
	"chatSwitchSubscribeIcon": "chatSwitchSubscribe__chatSwitchSubscribeIcon___2beSh",
	"chatSwitchSubscribeNotifications": "chatSwitchSubscribe__chatSwitchSubscribeNotifications___TawP1"
};
export default ___CSS_LOADER_EXPORT___;
