import { useCallback, useLayoutEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import guid from "guid";

import FieldApi from "../../../models/FieldApi";
import fieldTypes from "../../../configs/fieldTypes";

import {
  toMinimizeDate,
  emptyResource,
  filterValuesByVisibleFields
} from "../helpers";
import { useEffect } from "react";

const useEvents = (
  catalogId,
  sceneId,
  fields,
  settings,
  adjustedRecords,
  withScheduling,
  calendarView,
  fieldsOrder,
  visibleFields,
  getColorByItemId
) => {
  let [adjustedEvents, setAdjustedEvents] = useState([]);
  const startFieldId = settings.getIn(["start", "key"]);
  const endFieldId =
    settings.getIn(["end", "key"]) || settings.getIn(["start", "key"]);

  const isWithTime = settingName => {
    const fieldId =
      settings.getIn([settingName, "key"]) || settings.getIn(["start", "key"]);
    const field = fields.find(f => f.get("id") === fieldId);
    const withTime = !!(field && field.getIn(["config", "time"]));
    return withTime;
  };

  const convertRecordToEvents = record => {
    const events = [];
    const colorFieldId = settings.getIn(["color", "key"]);
    const colorField = fields.find(f => f.get("id") === colorFieldId);
    const values = record.get("values");
    const start = isWithTime("start")
      ? record.getIn(["values", startFieldId])
        ? moment(record.getIn(["values", startFieldId])).toDate()
        : null
      : toMinimizeDate(record.getIn(["values", startFieldId]));

    const end = isWithTime("end")
      ? record.getIn(["values", endFieldId])
        ? moment(record.getIn(["values", endFieldId])).toDate()
        : null
      : toMinimizeDate(record.getIn(["values", endFieldId]));

    const preparedEvent = {
      title: record.getIn(["title"]),
      start,
      end,
      color: getColorByItemId(values),
      catalogId,
      recordId: record.get("id"),
      id: guid.raw(),
      droppable: true
    };

    if (calendarView !== "month") {
      preparedEvent.values = filterValuesByVisibleFields(
        record.get("values"),
        fields,
        visibleFields,
        fieldsOrder
      );
    }

    if (withScheduling) {
      const splitId = settings.getIn(["split", "key"]);
      const splitField = fields.find(f => f.get("id") === splitId);
      const layoutValue = record.getIn(["values", splitId]);
      if (splitField && !FieldApi.isEmpty(splitField, layoutValue)) {
        if (FieldApi.valueIs(splitField, layoutValue, "object")) {
          layoutValue.forEach(item => {
            const event = {
              ...preparedEvent,
              id: guid.raw(),
              resourceId: FieldApi.calcId(splitField, item)
            };
            if (colorField && splitField.get("type") === fieldTypes.DROPDOWN) {
              event.color = getColorByItemId(item);
            }
            events.push(event);
          });
        } else {
          preparedEvent.resourceId = FieldApi.calcId(splitField, layoutValue);
          events.push(preparedEvent);
        }
      } else {
        preparedEvent.resourceId = emptyResource.id;
        events.push(preparedEvent);
      }
    } else {
      events.push(preparedEvent);
    }
    return events;
  };

  const initEvents = (records, suggestedRecords) => {
    let newEvents = [];

    if (!records) {
      return;
    }

    let [...recordKeys] = records.keys();
    recordKeys = recordKeys.sort((a, b) => a - b);

    recordKeys.forEach(recordKey => {
      const record = records.get(recordKey);
      newEvents = newEvents.concat(
        convertRecordToEvents(record, suggestedRecords)
      );
    });
    return newEvents;
  };

  const initAdjustedEvents = useCallback(
    () => {
      const newEvents = initEvents(adjustedRecords);
      setAdjustedEvents(newEvents);
    },
    [
      adjustedRecords,
      settings.get("color"),
      settings.get("split"),
      visibleFields,
      fieldsOrder
    ]
  );

  const updateEvent = updatedEvent => {
    if (
      !settings.get("end") ||
      settings.getIn(["end", "key"]) === settings.getIn(["start", "key"])
    ) {
      updatedEvent.end = updatedEvent.start;
    }

    const newEvents = adjustedEvents.map(event => {
      if (event.id === updatedEvent.id) {
        return updatedEvent;
      }
      return event;
    });

    setAdjustedEvents(newEvents);
  };

  useEffect(() => initAdjustedEvents(), [initAdjustedEvents]);

  /* перенсти или удалить вообще (append dragEvent) */
  const onDropEventFromOutside = updatedEvent => {
    if (
      !settings.get("end") ||
      settings.getIn(["end", "key"]) === settings.getIn(["start", "key"])
    ) {
      updatedEvent.end = updatedEvent.start;
    }

    const newEvents = [...adjustedEvents, updatedEvent];

    setAdjustedEvents(newEvents);
  };

  const onDropEventFromOutsideFailed = event => {
    _.remove(adjustedEvents, _event => _event.recordId === event.recordId);

    setAdjustedEvents(adjustedEvents);
  };

  return {
    adjustedEvents,
    initEvents,
    updateEvent,
    onDropEventFromOutside,
    onDropEventFromOutsideFailed,
    convertRecordToEvents
  };
};

export default useEvents;
