// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".recordActivities__linkDropdown___\\+CmJO {\n  color: inherit;\n}\n.recordActivities__linkDropdown___\\+CmJO:hover {\n  color: inherit;\n}\n.recordActivities__settingIcon___gpXRq {\n  padding: 0 10px 0 5px;\n  opacity: 0.75;\n}\n.recordActivities__settingRemove___ahcT7 {\n  color: #c82d2d !important;\n}\n.recordActivities__buttonRefresh___JzY9r {\n  font-size: 1.15em;\n}\n.recordActivities__buttonRefreshLoading___qKACr:before {\n  animation: recordActivities__loading___61uop 1s linear infinite;\n}\n@keyframes recordActivities__loading___61uop {\n  0% {\n    transform: rotate(0);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n.recordActivities__buttonClear___\\+\\+QYy {\n  margin-right: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Record/RecordHeader/recordActivities.less"],"names":[],"mappings":"AAEA;EACE,cAAA;AADF;AAEE;EACE,cAAA;AAAJ;AAIA;EACE,qBAAA;EACA,aAAA;AAFF;AAIA;EACE,yBAAA;AAFF;AAKA;EACE,iBAAA;AAHF;AAKA;EACE,+DAAA;AAHF;AAIE;EACE;IACE,oBAAA;EAFJ;EAIE;IACE,yBAAA;EAFJ;AACF;AAMA;EACE,kBAAA;AAJF","sourcesContent":["@import (reference) \"../../../styles/helpers/index.less\";\n\n.linkDropdown {\n  color: inherit;\n  &:hover {\n    color: inherit;\n  }\n}\n\n.settingIcon {\n  padding: 0 @PADDING_BASE 0 @PADDING_BASE / 2;\n  opacity: 0.75;\n}\n.settingRemove {\n  color: @BTN_DANGER !important;\n}\n\n.buttonRefresh {\n  font-size: 1.15em;\n}\n.buttonRefreshLoading:before {\n  animation: loading 1s linear infinite;\n  @keyframes loading {\n    0% {\n      transform: rotate(0);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n}\n\n.buttonClear {\n  margin-right: @PADDING_BASE;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkDropdown": "recordActivities__linkDropdown___+CmJO",
	"settingIcon": "recordActivities__settingIcon___gpXRq",
	"settingRemove": "recordActivities__settingRemove___ahcT7",
	"buttonRefresh": "recordActivities__buttonRefresh___JzY9r",
	"buttonRefreshLoading": "recordActivities__buttonRefreshLoading___qKACr",
	"loading": "recordActivities__loading___61uop",
	"buttonClear": "recordActivities__buttonClear___++QYy"
};
export default ___CSS_LOADER_EXPORT___;
