import React from 'react';
import classNames from 'classnames';

import ButtonTransparent from '../../../../common/UI/ButtonTransparent';
import Icon from '../../../../common/UI/Icon';

import styles from "./chatStickScrollToBottom.less";

const ChatStickScrollToBottom = ({ scroll, isShowScrollBtn, countNewMessages }) => {
    return (
        <ButtonTransparent
            onClick={scroll}
            className={classNames(styles.msgerChatScrollToBottom, {
                [styles.msgerChatScrollToBottomShow]: isShowScrollBtn,
                [styles.msgerChatScrollToBottomShowBadge]: countNewMessages !== 0
            })} data-badge={Math.min(99, Math.max(0, countNewMessages))}>
            <Icon className={styles.msgerChatScrollToBottomIcon} type="icon arrows-chevron-medium-1-01" />
        </ButtonTransparent>
    );
};

export default ChatStickScrollToBottom;