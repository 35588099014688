import React, { Component } from "react";
import Immutable from "immutable";
import PropTypes from "prop-types";

import recordActions from "../../actions/recordActions";
import { connect } from "../StateProvider";
import RecordsBody from "./RecordsBody";

import FIELD_TYPES from "../../configs/fieldTypes";

class Records extends Component {
  static propTypes = {
    catalog: PropTypes.object,
    catalogId: PropTypes.string,
    scene: PropTypes.object,
    sceneId: PropTypes.string,
    viewId: PropTypes.string
  };

  UNSAFE_componentWillMount() {
    this.prepareFieldsToRender(this.props);
  }

  componentDidMount() {
    const { catalogId, sceneId, viewId } = this.props;
    recordActions.requestForRecords(catalogId, sceneId, {
      viewId: viewId
    });
  }

  componentDidUpdate(prevProps) {
    const {
      catalog,
      scene,
      sceneId,
      viewId,
      fieldsOrder,
      userSettingsfields,
      sortType,
      sortField
    } = prevProps;

    const {
      catalog: newCatalog,
      catalogId: newCatalogId,
      scene: newScene,
      sceneId: newSceneId,
      viewId: newViewId,
      fieldsOrder: newFieldsOrder,
      userSettingsfields: prevUserSettingsfields,
      sortType: newSortType,
      sortField: newSortField
    } = this.props;

    const filters = scene.getIn(["views", viewId, "filters"]);
    const newFilters =
      newScene && newScene.getIn(["views", newViewId, "filters"]);
    const fields = catalog.get("fields");
    const nextFields = newCatalog && newCatalog.get("fields");

    const sortTypeChanged = sortType && sortType != newSortType;
    const sortFieldChanged = sortField && sortField != newSortField;

    /*
      fixed error with double request for records & error with fetch all records requst after new view created
      Should work good. Because when a view is selected, it also change filters, so next requestForRecords is called just after 
    */

    if (newSceneId !== sceneId) {
      recordActions.requestForRecordsImmediate(
        newCatalogId,
        newSceneId,
        { viewId: newViewId },
        "table"
      );
    } else if (
      (newFilters && newFilters !== filters) ||
      (newViewId && newViewId !== viewId)
    ) {
      recordActions.requestForRecords(
        newCatalogId,
        newSceneId,
        { viewId: newViewId },
        "table"
      );
    }

    if (sortTypeChanged || sortFieldChanged) {
      recordActions.requestForRecords(
        newCatalogId,
        newSceneId,
        { viewId: newViewId },
        "table"
      );
    }

    if (
      fields !== nextFields ||
      fieldsOrder !== newFieldsOrder ||
      userSettingsfields !== prevUserSettingsfields
    ) {
      this.prepareFieldsToRender(this.props);
    }
  }

  prepareFieldsToRender({ userSettingsfields, catalog, fieldsOrder }) {
    // set ordering by default.
    const _fieldsOrder = fieldsOrder ? fieldsOrder.toJS() : [];
    const fields = catalog.get("fields");

    let fieldsToRender = new Immutable.List();

    if (fields) {
      fields.forEach(field => {
        const type = field.get("type");
        const colId = field.get("id");
        let visible =
          userSettingsfields &&
          userSettingsfields.getIn([colId, "visible", "visible"]);
        visible = visible === undefined ? true : visible;

        if (!visible || type === FIELD_TYPES.GROUP || type === FIELD_TYPES.BUTTON) {
          return;
        }

        fieldsToRender = fieldsToRender.push(field);
      });

      fieldsToRender = fieldsToRender
        .map(field => {
          // get index from collection
          const index = _fieldsOrder.indexOf(field.get("id"));
          return {
            field: field,
            order: index !== -1 ? index : 9999
          };
        })
        // apply orderFields from user settings.
        .sort((f1, f2) => f1.order - f2.order)
        .map(o => o.field);
    }

    this.setState({ fieldsToRender: fieldsToRender });
  }

  render() {
    const {
      catalog,
      catalogId,
      scene,
      sceneId,
      sortingRecords,
      viewId,
      userSettingsfields,
      fieldsOrder,
      openRecordsInModal,
      isWebForm
    } = this.props;

    const { fieldsToRender } = this.state;
    const fields = catalog.get("fields");

    return fieldsToRender && scene.get("records") ? (
      <RecordsBody
        fieldsToRender={fieldsToRender}
        allFields={fields}
        catalogId={catalogId}
        scene={scene}
        sceneId={sceneId}
        viewId={viewId} // for fetchRows with filters
        isWebForm={isWebForm}
        sortingRecordSetting={sortingRecords}
        userSettingsfields={userSettingsfields} // for getting width column
        fieldsOrder={fieldsOrder}
        openRecordsInModal={openRecordsInModal}
      />
    ) : null;
  }
}

// const withQueryParamsComponent = withQueryParams(Records);

export default connect(
  Records,
  {
    userSettings: ["userSettings", "catalogs"]
  },
  ({ catalogId, ...props }, { userSettings }) => {
    return {
      sortingRecords: userSettings.getIn([
        catalogId,
        "viewMode",
        "table",
        "sortingRecords"
      ]),
      fieldsOrder: userSettings.getIn([
        catalogId,
        "viewMode",
        "table",
        "fieldsOrder",
        "fieldsOrder"
      ]),
      userSettingsfields: userSettings.getIn([
        catalogId,
        "viewMode",
        "table",
        "fields"
      ]),
      sortType: userSettings.getIn([
        catalogId,
        "viewMode",
        "table",
        "sortingRecords",
        "sortType"
      ]),
      sortField: userSettings.getIn([
        catalogId,
        "viewMode",
        "table",
        "sortingRecords",
        "sortField"
      ]),
      catalogId,
      ...props
    };
  }
);
