import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import styles from "./dataTypes.less";

const ProgressField = props => {
  const className = cn(props.containerClassName, styles.simpleField);

  if (props.value == null) {
    return <span />; // для позиционирования количества записей, чтобы он всегда был в правом углу
  } else {
    return <span className={className}>{Math.round(props.value) + "%"}</span>;
  }
};

ProgressField.propTypes = {
  value: PropTypes.number,
  config: PropTypes.object
};

export default ProgressField;
