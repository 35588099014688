import React from "react";
import PureRenderMixin from "react-addons-pure-render-mixin";
import Immutable from "immutable";
import { Row, Col } from "antd";
import { Route, Redirect, withRouter, Switch } from "react-router";
import { withTranslation } from "react-i18next";

import Widget from "../../Widget";
import TabsMenu from "../../../../common/menu/TabsMenu";

// tabs
import Data from "./data";
import DataFilter from "./filter";
import SelectChart from "./selectChart";

import styles from "../popup.less";

class WidgetPopupData extends React.PureComponent {
  tabs = Immutable.List([
    Immutable.Map({
      id: "data",
      name: this.props.t("reports.widget.modals.common.tabs.data.title"),
      route: {
        path: "/data"
      }
    }),
    Immutable.Map({
      id: "filter",
      name: this.props.t("reports.widget.modals.common.tabs.filter.title"),
      route: {
        path: "/filter"
      }
    })
  ]);

  render() {
    const { catalog } = this.props;
    const props = { ...this.props, ...{ catalog } };

    return (
      <div>
        {props.location.pathname == "/" ? (
          <Redirect to={this.tabs.getIn([0, "route"]).path} />
        ) : null}

        <TabsMenu items={this.tabs} className={styles.tabsMenu} />

        <Switch>
          <Route path="/data" render={() => <Data {...props} />} />
          <Route path="/filter" render={() => <DataFilter {...props} />} />
        </Switch>
        <SelectChart {...props} />
        <div className={styles.chartZoneContainer}>
          <div className={styles.widgetContainerInModal}>
            <Widget {...props} inEditMode={true} />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(WidgetPopupData));
