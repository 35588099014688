import _ from "lodash";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "antd";
import { withTranslation } from "react-i18next";

import Icon from '../UI/Icon';
import ButtonTransparent from "../../common/UI/ButtonTransparent";
import FieldConfigMenu from "./FieldConfigMenu";

import styles from "./fieldConfig.less";

class FieldConfig extends PureComponent {
  static propTypes = {
    fields: PropTypes.object.isRequired,
    catalogId: PropTypes.string.isRequired,
    viewMode: PropTypes.string.isRequired
  };

  state = {
    visible: false,
    discardedFiltersKeys: []
  };

  handleVisibleChange = flag => this.setState({ visible: flag });

  render() {
    return (
      <Dropdown
        dropdownRender={() =>
          <FieldConfigMenu
            visibleFields={this.props.visibleFields}
            fields={this.props.fields}
            catalogId={this.props.catalogId}
            viewMode={this.props.viewMode}
            defaultVisible={this.props.defaultVisible}
          />
        }
        overlayStyle={{ minWidth: "250px" }}
        trigger={["click"]}
        placement="bottomRight"
        onOpenChange={this.handleVisibleChange}
        open={this.state.visible}
      >
        <ButtonTransparent
          className={this.props.className || styles.buttonFieldConfig}
          title={this.props.t("record.filter.name")}
        >
          <Icon type="icon text-36" />
        </ButtonTransparent>
      </Dropdown>
    );
  }
}

export default withTranslation()(FieldConfig);
