import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Radio } from "antd";
import { useTranslation } from "react-i18next";

import NavLink from "../../common/router/Link";
import routes from "../../../routes";
import { DataContext } from "../DataProvider";
import { prepareDateForUrl } from "../helpers";

import ButtonTransparent from "../../common/UI/ButtonTransparent";

import styles from "../calendar.less";

const views = [, "month", "week", "day"];

const ViewTabs = props => {
  const { t } = useTranslation();
  const { setUrlItemsToStore } = useContext(DataContext);
  return (
    <div className={styles.viewTabs}>
      <Radio.Group value={props.view}>
        {views.map(view => {
          return (
            <Radio.Button value={view} key={view}>
              <NavLink
                route={routes.catalogSettings}
                params={{ type: view, date: prepareDateForUrl(props.date) }}
                render={({ link }) => {
                  return (
                    <Link
                      onClick={() => {
                        setUrlItemsToStore({
                          date: prepareDateForUrl(props.date),
                          view
                        });
                      }}
                      to={link}
                    >
                      {t(`calendar.viewTabs.${view}`)}
                    </Link>
                  );
                }}
              />
            </Radio.Button>
          );
        })}
      </Radio.Group>
      {!props.isToday && (
        <ButtonTransparent className={styles.todayBtn}>
          <NavLink
            route={routes.calendarSettings}
            params={{ date: "today" }}
            render={({ link }) => {
              return (
                <Link
                  onClick={() => {
                    setUrlItemsToStore({ date: "today", view: props.view });
                  }}
                  to={link}
                >
                  {t("calendar.viewTabs.today")}
                </Link>
              );
            }}
          />
        </ButtonTransparent>
      )}
    </div>
  );
};

export default ViewTabs;
