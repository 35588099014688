import React from "react";
import Immutable from "immutable";
import _ from "lodash";

import keys from "../../../../../configs/keys";
import apiActions from "../../../../../actions/apiActions";
import ChatInput from "./ChatInput/ChatInput";
import messageActions from "../../../../../actions/messageActions";

const ChatSendMessage = ({
  catalogId,
  recordId,
  loadingAttachment,
  isBeingSentMessage,
  text,
  replyMessage,
  mentions,
  metions,
  progressState,
  attachments,
  uploadFile,
  onClickRemoveFile,
  setAttachments,
  scrollToBottom,
  catalog,
  sceneId,
  shouldFocusInput
}) => {
  const notOnlyLineBreaks =
    text &&
    text.length !== 0 &&
    text.length == text.replace(/[^\n]/g, "").length;
  const readyToShip =
    ((text && text.trim() && text && text.length) > 0 ||
      attachments.size > 0) &&
    !loadingAttachment &&
    !notOnlyLineBreaks;

  function cancelReplyMessage() {
    messageActions.cancelReplyMessage({ catalogId, recordId });
  }

  function sendMessage() {
    const message = Immutable.fromJS({
      text,
      attachments: attachments,
      replyMessageId: replyMessage ? replyMessage.get("id") : null,
      mentions: metions
    });
    apiActions.createMessage({ catalogId, recordId }, message);
    setAttachments(Immutable.List([]));
    scrollToBottom();
  }

  function onChange(value, metions) {
    messageActions.setMessageText(
      { catalogId, recordId },
      Immutable.fromJS({
        text: value,
        metions
      })
    );
  }

  function onKeyDown(e) {
    if (e.keyCode === keys.ENTER && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  }

  function handleFile(e) {
    const file = e.file;
    if (file) {
      uploadFile(file);
    }
  }

  return (
    <ChatInput
      shouldFocusInput={shouldFocusInput}
      icon={"icon communication-47"}
      text={text}
      catalog={catalog}
      sceneId={sceneId}
      replyMessage={replyMessage}
      readyToShip={readyToShip}
      attachments={attachments}
      updateProgress={progressState}
      loadingAttachment={loadingAttachment}
      isBeingSentMessage={isBeingSentMessage}
      mentions={mentions}
      onChange={onChange}
      onKeyDown={onKeyDown}
      sendMessage={sendMessage}
      handleFile={handleFile}
      onClickRemoveFile={onClickRemoveFile}
      cancelReplyMessage={cancelReplyMessage}
    />
  );
};

export default ChatSendMessage;
