import React from 'react';
import { withTranslation } from 'react-i18next';

import AvatarUser from '../../../../../common/UI/AvatarUser';
import Icon from '../../../../../common/UI/Icon';

import styles from "./chatInput.less";

const RenderSuggestion = (props) => {
    let { subText, icon, display: name, t } = props;
    subText = (icon && !subText) ? t("record.chat.sendMessage.mention.currentRecord") : subText;
    return (
        <div>
            {!icon? (
                <AvatarUser className={styles.msgerInputareaAvatarSuggestion} userName={name} size={"small"} />
            )
                : (
                    <Icon className={styles.msgerInputareaIconSuggestion} type={`icon ${icon}`} />
                )
            }
            <span className={styles.msgerInputareaNameSuggestion}>{name}</span>
            <span className={styles.msgerInputareaSubTextSuggestion}>{subText}</span>
        </div>
    );
};

export default withTranslation()(RenderSuggestion);