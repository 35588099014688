import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Icon from "../../../common/UI/Icon";

const Header = props => {
  const { t } = useTranslation();

  return props.recordsCount ? (
    <div className={props.className}>
      <Icon type={"icon anticon-icon interface-5"}/> 
      {t("calendar.suggestRecords.header") +
        " " +
        t("record.groupRecords.count", { count: +props.recordsCount })}
    </div>
  ) : null;
};

Header.propTypes = {
  className: PropTypes.string,
  recordsCount: PropTypes.string
};

export default Header;
