import React from "react";
import PropTypes from "prop-types";
import PureRenderMixin from "react-addons-pure-render-mixin";
import editorActions from "../../../actions/editorActions";
import { Checkbox } from "antd";
import { withTranslation } from "react-i18next";
import Icon from "../../common/UI/Icon";

import styles from "./fields.less";

class UserField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      multiselect: !!this.props.field.getIn(["config", "multiselect"]),
      defaultValue: !!this.props.field.getIn(["config", "defaultValue"]),
      subscribeAllChatMessages: !!this.props.field.getIn(["config", "subscribeAllChatMessages"])
    };
  }

  onChangeMultiselect = e => {
    let val = e.target.checked;
    this.setState({ multiselect: val });
    editorActions.changeFieldConfig(
      this.props.sectionId,
      this.props.fieldIndex,
      {
        multiselect: val,
        defaultValue: this.state.defaultValue,
        subscribeAllChatMessages: this.state.subscribeAllChatMessages
      }
    );
  };

  onChangeDefaultValue = e => {
    let defaultValue = e.target.checked;
    this.setState({
      defaultValue: defaultValue
    });
    editorActions.changeFieldConfig(
      this.props.sectionId,
      this.props.fieldIndex,
      {
        multiselect: this.state.multiselect,
        defaultValue: defaultValue,
        subscribeAllChatMessages: this.state.subscribeAllChatMessages
      }
    );
  };

  onChangeSubscribeAllChatMessages = e => {
    let subscribe = e.target.checked;
    this.setState({
      subscribeAllChatMessages: subscribe
    });
    editorActions.changeFieldConfig(
      this.props.sectionId,
      this.props.fieldIndex,
      {
        multiselect: this.state.multiselect,
        defaultValue: this.state.defaultValue,
        subscribeAllChatMessages: subscribe
      }
    );
  };

  render() {
    const { t } = this.props;
    return (
      <div className="field-type-user">
        <Checkbox
          className={styles.checkboxInline}
          disabled={this.props.disabled}
          checked={this.state.multiselect}
          onChange={this.onChangeMultiselect}
          title={t("fieldTypes.user.multiselectHint")}
        >
          {t("fieldTypes.user.multiselect")}
        </Checkbox>
        <Checkbox
          className={styles.checkboxInline}
          disabled={this.props.disabled}
          checked={this.state.defaultValue}
          onChange={this.onChangeDefaultValue}
        >
          {t("fieldTypes.user.default")}
        </Checkbox>
        <Checkbox
          className={styles.checkboxInline}
          disabled={this.props.disabled}
          checked={this.state.subscribeAllChatMessages}
          onChange={this.onChangeSubscribeAllChatMessages}
        >
          {t("fieldTypes.user.abbreviatedSubscribeAllChatMessages")}
          <Icon className={styles.iconChat} type="icon communication-91" />
        </Checkbox>
      </div>
    );
  }
}

UserField.propTypes = {
  field: PropTypes.object.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  sectionId: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default withTranslation()(UserField);
